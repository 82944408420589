import {
  IDetails, IZone, ShopProductStatus
} from '@/interfaces/shopProductInterface';

export interface IShopInfo {
  id: string;
  tenant_id: number;
  name?: string;
  platform_domain?: string;
  platform_type?: string;
  product_summaries?: IShopProductSummary[];
  status?: ShopStatus;
  supported_languages: string[];
  currency_iso: string;
  billing_payment_method_id: string|null;
  shipping_profiles: IShippingProfile[];
}

export interface IShippingProfile {
  name: string;
  zones: IZone[];
}

export interface IShopProductSummary {
  details: IDetails;
  shop_id: string;
  default_preview_url: string|null;
  tenant_id: string;
  guid: string;
  status: ShopProductStatus;
  platform_id?: string;
  platform_status?: string;
  last_updated: string;
}

export enum PlatformType {
  Shopify = 'shopify',
}

export enum ShopStatus {
  generic = 0,
  enabled = 1,
  disabled = 2,
}
