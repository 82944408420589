import { loaded, OrderedThumbs } from '@/modules/editor/editorConstants';
import { fabric } from 'fabric';
import {
  IEditorCanvas,
  IEditorEditorImage,
  IEditorImage,
  IEditorThumb,
} from '@/interfaces/editorInterfaces';
import logger from '@/logger/logger';

export class ImageCache {
  private static singleton: ImageCache;

  public static get Instance() {
    return this.singleton || (this.singleton = new this());
  }

  public isLoaded(url: string): boolean {
    return Object.prototype.hasOwnProperty.call(loaded, url);
  }

  public markLoaded(url: string): void {
    loaded[url] = true;
  }

  public markWhenLoaded(url: string): void {
    if (!this.isLoaded(url)) {
      fabric.util.loadImage(
        url,
        (): void => {
          this.markLoaded(url);
        },
        this,
        'anonymous'
      );
    }
  }

  public getTotalZoom(
    canvas: IEditorCanvas,
    image: IEditorEditorImage
  ): number {
    let imageZoom = 1.0;
    if (image.imagePosition && 0 < image.imagePosition.zoom) {
      imageZoom = image.imagePosition.zoom;
    } else if (image.width && image.image && image.image.width) {
      imageZoom = image.width / image.image.width;
    }

    return (canvas.getZoom ? canvas.getZoom() : 1) * imageZoom;
  }

  public getOptimalUrl(
    image: IEditorImage,
    optimalSize: string,
    autoCorrect: boolean,
    autoRedEye: boolean
  ): string {
    const optimalThumb = image.getThumb(optimalSize);
    return this.getUrl(image, optimalThumb, autoCorrect, autoRedEye);
  }

  public getUrl(
    image: IEditorImage,
    thumb: IEditorThumb,
    autoCorrect: boolean,
    autoRedEye: boolean
  ): string {
    return image.getUrl(thumb.name, autoCorrect, autoRedEye);
  }

  public smartLoad(
    element: HTMLImageElement,
    image: IEditorImage,
    scale: number,
    loadThumbIfCached: boolean,
    callback: (
      img: HTMLImageElement | null,
      optimal: boolean,
      dontRetry?: boolean
    ) => void,
    autoCorrect: boolean,
    autoRedEye: boolean,
    hasFilterEffect: boolean,
    imageWithFrame: boolean,
    editorPhoto?: IEditorEditorImage
  ): void {
    // first, we need to find optimal thumb size
    const optimalSize = this.getOptimalSize(
      image,
      scale,
      hasFilterEffect,
      imageWithFrame,
      editorPhoto
    );
    const optimalUrl = this.getOptimalUrl(
      image,
      optimalSize,
      autoCorrect,
      autoRedEye
    );

    // if the optimal thumb is not loaded, we search the cache
    if (!this.isLoaded(optimalUrl)) {
      // variables for thumb search
      let thumb: IEditorThumb;
      let thumbUrl: string;
      const optimalIndex: number = OrderedThumbs.indexOf(optimalSize);

      // optimization: if a larger thumb is already loaded, use as optimal
      // end the loop at -1 as we don't want to get the full size
      for (let i = optimalIndex + 1; i < OrderedThumbs.length - 1; i++) {
        thumb = image.getThumb(OrderedThumbs[i]);

        if (thumb) {
          thumbUrl = this.getUrl(image, thumb, autoCorrect, autoRedEye);

          if (this.isLoaded(thumbUrl)) {
            if (
              !element.src ||
              element.src.replace(/file:\/+/, '') !==
                thumbUrl.replace(/file:\/+/, '')
            ) {
              this.loadImage(thumbUrl, callback, true);
            } else {
              callback(element, true);
            }
            return;
          }
        }
      }

      // if the optimal image is not yet loaded, try to look for a smaller thumb that is immediately available
      // this should not happen for StaticCanvas
      if (loadThumbIfCached) {
        let startIndex = optimalIndex;
        if (OrderedThumbs[optimalIndex] == 'full') {
          startIndex -= 1;
        }
        for (let i = startIndex; i-- > 0; ) {
          thumb = image.getThumb(OrderedThumbs[i]);

          if (thumb) {
            thumbUrl = this.getUrl(image, thumb, autoCorrect, autoRedEye);

            if (this.isLoaded(thumbUrl)) {
              if (
                !element.src ||
                element.src.replace(/file:\/+/, '') !==
                  thumbUrl.replace(/file:\/+/, '')
              ) {
                this.loadImage(thumbUrl, callback, false);
              } else {
                callback(element, false);
              }
              break;
            }
          }
        }
      }
    }

    // at last, start loading original right away
    if (
      !element.src ||
      element.src.replace(/file:\/+/, '') !== optimalUrl.replace(/file:\/+/, '')
    ) {
      this.loadImage(optimalUrl, callback, true);
    } else {
      callback(element, true);
    }
  }

  public getOptimalSize(
    image: IEditorImage,
    scale: number,
    _?: boolean,
    __?: boolean,
    editorPhoto?: IEditorEditorImage
  ): string {
    let largestAvailableThumb = 'full';

    // Mobile devices are getting small images which leads to bad 3D previews...  Load an image that takes into account
    // the resolution of the mobile device.
    if (typeof window !== 'undefined') {
      // "window" is not defined in JRS.
      scale *= window.devicePixelRatio || 1;
    }

    let isCanvasEmpty = false;
    if (
      editorPhoto &&
      editorPhoto.canvas &&
      editorPhoto.canvas.getZoom() !== 1
    ) {
      if (
        editorPhoto.canvas.getObjects().length === 1 &&
        editorPhoto.imagePosition &&
        editorPhoto.imagePosition.zoom === 1
      ) {
        isCanvasEmpty = true;
      }
    }
    for (let i = 0; i < OrderedThumbs.length - 1; i++) {
      const thumbSize: string = OrderedThumbs[i];
      const thumb: IEditorThumb = image.getThumb(thumbSize);
      if (thumb && image.thumbs[thumbSize] && editorPhoto) {
        largestAvailableThumb = thumbSize;
        // in customizer when canvas is empty and the photo is uploaded on the canvas for the first time, imagePosition is not set
        // yet and it's default to one, thus the scale cannot be compared with thumb.width/image.width (PS-62617)
        const thumbScale = isCanvasEmpty
          ? thumb.width / editorPhoto.imagePosition.width
          : thumb.width / image.width;
        if (thumbScale >= scale) {
          return thumbSize;
        }
      }
    }

    return largestAvailableThumb;
  }

  /**
   * Loads image element from given url and passes it to a callback
   * @param {String} url URL representing an image
   * @param {Function} originalCallback Callback; invoked with loaded image
   * @param {boolean} isOptimal
   */
  public loadImage(
    url: string,
    originalCallback: (
      img: HTMLImageElement | null,
      optimal: boolean,
      dontRetry: boolean
    ) => void,
    isOptimal: boolean
  ): void {
    // const getFilename = (fileUrl: string): string => {
    //   // NOTE: We know this is a valid directory because we created it in the template-picker-controller...
    //   return (
    //     'template-image/' + fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
    //   );
    // };

    const callback = (img: HTMLImageElement | null): void => {
      this.markLoaded(url);
      // call original callback
      try {
        originalCallback(img, isOptimal, false);
      } catch (ex: unknown) {
        img = null;
        logger.error(' Unable to load image: ' + url, ex);
        if (typeof global !== 'undefined') {
          const globalObj: {
            renderResult: {
              success: boolean;
              url: string;
              message: string;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } = global as any;
          globalObj.renderResult = globalObj.renderResult || {
            success: false,
            url: '',
            message:
              'Error: ' +
              ((ex as { message: string }).message
                ? (ex as { message: string }).message
                : ex),
          };
          globalObj.renderResult.success = false;
          globalObj.renderResult.url = url;
        }
        originalCallback(null, isOptimal, true);
      }
    };

    const loadFromWeb = (webUrl: string): void => {
      // console.log('loading image: ' + webUrl);
      fabric.util.loadImage(webUrl, callback, this, 'anonymous');
    };

    loadFromWeb(url);
  }

  /**
   *
   * @param img
   * @param {String} url URL representing an image
   * @param {Function} callback Callback; invoked with loaded image
   * @param {boolean} isOptimal
   */
  public loadImageWithElement(
    img: HTMLImageElement | null,
    url: string,
    callback: (img: HTMLImageElement | null, optimal: boolean) => void,
    isOptimal: boolean
  ): void {
    if (img) {
      img.onload = (): void => {
        if (img) {
          img.onload = img.onerror = null;
        }

        // mark image as loaded
        this.markLoaded(url);

        // execute the callback
        if (callback) {
          callback.call(null, img, isOptimal);
        }

        // nullify the image
        img = null;
      };

      img.onerror = function (): void {
        if (img) {
          fabric.log('Error loading ' + img.src);
          img = img.onload = img.onerror = null;
        }
        if (callback) {
          callback.call(null, null, isOptimal);
        }
      };

      // set the source of the image
      img.src = url;
    }
  }
}
