import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';

// Components
import App from '@/App.vue';
import router from '@/router';
import logger from './logger/logger';

import { registerPlugins } from '@/plugins';
import vuetify from '@/plugins/vuetify';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';

import { getBrowserLanguage } from '@/helpers/languageHelpers';

import en from '@/locales/en.js';
import es from '@/locales/es.js';
import { IItePreviews } from '@/interfaces/iItePreviews';
import { ISolaceClient } from '@/interfaces/iSolaceClient';
import { IKeycloakClient } from '@/interfaces/iKeycloakClient';
import { useTenantStore } from '@/stores/tenantStore';
import { IConfig, loadConfig } from '@/helpers/configHelper';
import { default as installMatomo } from '@/plugins/matomo';


ServiceLocator.setService<ISolaceClient>(ServiceType.SolaceClient, async ():Promise<ISolaceClient> => {
  const { SolaceClient }  = await import('@/services/solaceClient');
  return new SolaceClient();
});

ServiceLocator.setService<IKeycloakClient>(ServiceType.KeyCloak, async ():Promise<IKeycloakClient> => {
  const { KeycloakClient } = await import('@/services/keycloakClient');
  return new KeycloakClient();
});

ServiceLocator.setService<IItePreviews>(ServiceType.ItePreviews, async ():Promise<IItePreviews> => {
  const { ItePreviews } = await import('@/services/itePreviews');
  return new ItePreviews();
});

async function init() {

  const config:IConfig = await loadConfig();

  if (config.services.signup === 'klaviyo') {
    const script = document.createElement('script');
    script.async = true;
    script.src = config.services.klaviyo.url;
    document.head.appendChild(script);
  }

  const app = createApp(App);

  app.config.warnHandler = (msg, _, trace) => {
    logger.warn(msg, trace);
  };

  app.config.errorHandler = (err: unknown) => {
    logger.error(err);
  };

  const i18n = createI18n({
    legacy: false,
    locale: getBrowserLanguage(),
    fallbackLocale: 'en',
    messages: {
      en,
      es
    }
  });

  registerPlugins(app);

  // Optional: Provide the config to Vue components
  app.provide('config', config);
  app.config.globalProperties.$config = config;

  app.use(createPinia());
  app.use(i18n);
  app.use(vuetify);
  app.use(logger);
  app.use(installMatomo, {
    host: config.services.sfa.url,
    siteId: config.services.sfa.id,
    router,
    debug: true,

  });

  const renderApp = () => {
    app.use(router);
    app.mount('#app');
  };

  useTenantStore().initialize(renderApp);
}

init().catch(error => {
  logger.error('Unable to init', error);
});

