import { IEditorFont } from '@/interfaces/editorInterfaces';
import { IFontFile } from '@/interfaces/fontsInterface';

export class Font implements IEditorFont {
  public name: string;
  public styles: ('r' | 'b' | 'i' | 'bi')[];
  public public: boolean;
  public directory: string;

  constructor(private fontData: IFontFile) {
    // public properties
    this.name = fontData.name;
    this.styles = fontData.styles;
    this.public = fontData.public;
    this.directory = fontData.directory;
  }

  // getters
  public getImage(): string {
    return this.fontData.image;
  }

  public getCSS(): string {
    return this.directory + '/' + this.fontData.css;
  }

  public getTTF(style: 'r' | 'b' | 'i' | 'bi'): string {
    return this.fontData.faces[style] || '';
  }

  public canItalicize(isBold: boolean): boolean {
    return isBold ? this.hasStyle('bi') : this.hasStyle('i');
  }

  public canBold(isItalic: boolean): boolean {
    return isItalic ? this.hasStyle('bi') : this.hasStyle('b');
  }

  public hasStyle(style: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.fontData.faces, style);
  }
}
