import { fabric } from 'fabric';
import { IEditorCanvas } from '@/interfaces/editorInterfaces';

export const Group = fabric.util.createClass(fabric.ActiveSelection, {
  type: 'group',

  // prefixed with sf to not override fabric delegated properties
  sfDelegatedProperties: ['locked'],

  initialize: function (objects: never, options: never): void {
    // initialize
    this.callSuper('initialize', objects, options);

    for (let i = 0; i < this.sfDelegatedProperties.length; i++) {
      const prop = this.sfDelegatedProperties[i];
      // set the property for the class
      this[prop] = this.get(prop);
    }
  },

  /**
   * Override the setter to that size modifications when there is a transformation propagates to objects
   * in the group.
   *
   * @private
   */
  _set: function (key: string, value: string | number): void {
    if (
      (key === 'width' || key === 'height') &&
      this.canvas &&
      this.canvas._currentTransform
    ) {
      const numberValue = value as number;
      const posKey = key === 'width' ? 'left' : 'top';

      // convert the change into a scale, and apply it at the object level
      const scale = numberValue / this[key];

      this._objects.forEach(function (object: fabric.Object): void {
        object.set(key, (object[key] || 0) * scale);

        // @todo: this only works for zones that are not rotated
        object.set(posKey, (object[posKey] || 0) * scale);
      });
    }

    this.callSuper('_set', key, value);
  },

  onDeselect: function (): boolean | undefined {
    if (this.canvas.getObjects().indexOf(this) < 0) {
      this.destroy();
      return false;
    }
  },

  /**
   * Override the setter so that we can account for storefront delegated properties.
   *
   * @param prop
   * @returns {*}
   */
  get: function (prop: string): string | number {
    if (this.sfDelegatedProperties.indexOf(prop) >= 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this._objects.reduce(function (a: any, b: any): any {
        return a && b[prop];
      }, true);
    }

    return this.callSuper('get', prop);
  },

  /**
   * TODO: add proper typescript for the object...
   *
   * Change all properties that are relative to the group to be relative to the canvas.
   *
   * @param object
   * @returns {*}
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _ungroupifyObject: function (object: any): void {
    // first, backup data for when we groupify again
    object.ify = {
      angle: object.angle,
      left: object.left,
      top: object.top,
    };

    // calculate transformations applied by the group
    const matrix = object.calcTransformMatrix();
    const options = fabric.util.qrDecompose(matrix);
    const center = new fabric.Point(options.translateX, options.translateY);

    // apply those transformations to the object
    object.angle = options.angle;
    object.setPositionByOrigin(center, 'center', 'center');

    // return the object
    return object;
  },

  /**
   * Change all properties that are relative to the canvas to be relative to the group.
   *
   * @param object
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _groupifyObject: function (object: any): void {
    // if we have a backup from ungroupify, use it here
    if (object.ify) {
      object.set(object.ify);
      delete object.ify;

      return;
    }

    // otherwise, manually calculate position of object inside group
    const objectLeft = object.getLeft();
    const objectTop = object.getTop();
    const center = this.getCenterPoint();

    object.set({
      originalLeft: objectLeft,
      originalTop: objectTop,
      left: objectLeft - center.x,
      top: objectTop - center.y,
    });

    object.setCoords();
  },

  _getBoundingRectangle: function (
    target: {
      getBoundingRect: () => {
        left: number;
        top: number;
        width: number;
        height: number;
      };
    },
    canvas: IEditorCanvas
  ): object {
    const boundingRect = { left: 0, top: 0, width: 0, height: 0 };
    const targetBoundingRect = target.getBoundingRect();
    boundingRect.left =
      (targetBoundingRect.left + this.left) * canvas.getZoom() +
      canvas.marginLeft;
    boundingRect.top =
      (targetBoundingRect.top + this.top) * canvas.getZoom() + canvas.marginTop;
    boundingRect.width = targetBoundingRect.width * canvas.getZoom();
    boundingRect.height = targetBoundingRect.height * canvas.getZoom();
    return boundingRect;
  },

  _findGroupByUid: function (canvas: fabric.Canvas): undefined | fabric.Object {
    const allObjects = canvas.getObjects();
    for (let i = 0; i < allObjects.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.uid == (allObjects[i] as any).uid) {
        return allObjects[i];
      }
    }
    return undefined;
  },
});
