// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as WebFont from 'webfontloader';
import { IEditorFont } from '@/interfaces/editorInterfaces';
import { defineStore } from 'pinia';
import { DEFAULT_FONT } from '@/modules/editor/editorConstants';
import { ref } from 'vue';
import { IFontFile } from '@/interfaces/fontsInterface';
import { PublishTopicKeys, SubscribeTopicKeys } from '@/constants/topicEnums';
import { Font } from '@/modules/editor/font';
import logger from '@/logger/logger';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { ISolaceClient } from '@/interfaces/iSolaceClient';

const FVD_MAP = { r: 'n4', b: 'n7', i: 'i4', bi: 'i7' };
export const useFontStore = defineStore('fonts', () => {
  const fontsMap: {
    [fontName: string]: IEditorFont;
  } = {};

  // noinspection JSMismatchedCollectionQueryUpdate
  const fonts: IEditorFont[] = [];
  let solaceClient: ISolaceClient | undefined;
  const solaceReady:Promise<void> = new Promise((resolve,reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, err=> {
      reject(err);
    });

  });


  const promises: {
    [fontName: string]: Promise<void>;
  } = {};
  const promiseComplete: {
    [fontName: string]: boolean;
  } = {};

  const fontFiles = ref<IFontFile[]>([]);

  function subscribeToFontsCurrent(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const fontsSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_FONTS_CURRENT);
      if (fontsSub) {
        solaceClient.mapSubscriptionJson<IFontFile[]>(fontsSub, payload => {
          fontFiles.value = payload;
          fontFiles.value.forEach((fontFile: IFontFile) => {
            const font = new Font(fontFile);
            fonts.push(font);
            fontsMap[font.name] = font;
          });
          // sort the data
          fonts.sort((a: IEditorFont, b: IEditorFont): number => {
            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;
          });
          console.log('Fonts result: ', fontFiles.value);
        });
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function getCacheForFonts(): Promise<void> {
    return new Promise((resolve, reject) => {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }
        const fontsSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_FONTS_CURRENT);
        solaceClient.checkCache(fontsSub).then(() => {
          resolve();
        }, err => {
          reject(err);
        });
      }, err=> {
        reject(err);
      });
    });
  }

  function queryFonts(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_FONTS_TRIGGER);
      if (reqTopic) {
        solaceClient.publishMessageString(reqTopic, undefined, err => {
          console.error('Error publishing queryFonts', err);
        });
      } else {
        console.warn(
          `Could not find topic for query fonts: ${PublishTopicKeys.PUB_FONTS_TRIGGER}`
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function getFonts() {
    if (fontFiles.value.length === 0) {
      subscribeToFontsCurrent();
      getCacheForFonts().then(() => {
        console.debug('Successfully retrieved fonts from cache');
      }).catch(error => {
        console.debug(error);
        queryFonts();
      });
    }
  }

  function get(name: string): IEditorFont | null {
    return convertToFont(name);
  }

  function isLoaded(name: string, style: string): boolean {
    const cacheKey = getCacheKey(name, style);

    return (
      Object.prototype.hasOwnProperty.call(promises, cacheKey) &&
      promiseComplete[cacheKey]
    );
  }

  function areLoaded(fontDescriptions: {
    [fontFamily: string]: string[];
  }): boolean {
    const families = Object.keys(fontDescriptions);

    for (let i = 0; i < families.length; i++) {
      const family = families[i];

      for (let j = 0; j < fontDescriptions[family].length; j++) {
        if (!isLoaded(family, fontDescriptions[family][j])) {
          return false;
        }
      }
    }

    return true;
  }

  function load(
    font: IEditorFont,
    style: 'r' | 'b' | 'i' | 'bi'
  ): Promise<void> {
    const cacheKey = getCacheKey(font.name, style);

    // if the promise doesn't exist, create one
    if (!Object.prototype.hasOwnProperty.call(promises, cacheKey)) {
      const cssAndWoffPromises: Promise<void>[] = [];
      const webFontPromise = new Promise<void>(
        (resolve: () => void, reject: (err: string) => void): void => {
          WebFont.load({
            custom: {
              families: [font.name + ':' + convertStyleToFVD(style)],
              urls: [font.getCSS()]
            },
            active: (): void => {
              resolve();
            },
            fontinactive: (): void => {
              reject('fontinactive');
            },
            timeout: 5000
          });
        }
      );

      cssAndWoffPromises.push(webFontPromise);

      //the font is not "ready" until we have the css and have opened it
      promiseComplete[cacheKey] = false;
      promises[cacheKey] = Promise.all(cssAndWoffPromises).then(
        () => {
          promiseComplete[cacheKey] = true;
        },
        (err) => {
          logger.error('Unable to load font: ' + font.name, err);
        }
      );
    }

    return promises[cacheKey];
  }

  function loadMultiple(fontDescriptions: {
    [familyName: string]: ('r' | 'b' | 'i' | 'bi')[];
  }): Promise<void[]> {
    preLoad(fontDescriptions);
    const promiseArray = Object.keys(fontDescriptions).map(
      (family: string): Promise<void>[] => {
        return fontDescriptions[family].map((style: string): Promise<void> => {
          return promises[getCacheKey(family, style)];
        });
      }
    );
    return Promise.all(promiseArray.flat());
  }

  function preLoad(fontDescriptions: {
    [familyName: string]: ('r' | 'b' | 'i' | 'bi')[];
  }): void {
    let font: IEditorFont | null = null;
    let cacheKey;
    const cacheKeys: string[] = [];
    const families: string[] = [];
    const urls: string[] = [];

    // figure out what to load
    Object.keys(fontDescriptions).forEach((name: string): void => {
      // convert to font
      font = convertToFont(name);

      // stop if font not found
      if (!font) {
        return;
      }

      // figure out which styles to load
      const stylesToLoad: ('r' | 'b' | 'i' | 'bi')[] = [];

      fontDescriptions[name].forEach((style) => {
        cacheKey = getCacheKey((font as IEditorFont).name, style);

        if (!Object.prototype.hasOwnProperty.call(promises, cacheKey)) {
          stylesToLoad.push(style);
          cacheKeys.push(cacheKey);
        }
      });

      // if all the styles requested for this font are already loaded / loading, skip
      if (stylesToLoad.length === 0) {
        return;
      }

      // append data to arrays
      families.push(
        font.name + ':' + stylesToLoad.map(convertStyleToFVD).join(',')
      );
      urls.push(font.getCSS());
    });

    // if there are fonts to pre-load, do so here
    if (cacheKeys.length > 0) {
      const cssAndWoffPromises: Promise<void>[] = [];

      const webFontPromise = new Promise<void>(
        (resolve: () => void, reject: (error: string) => void): void => {
          WebFont.load({
            custom: { families, urls },
            active: (): void => {
              console.log('Loaded fonts ', families);
              resolve();
            },
            fontinactive: (): void => {
              reject('fontinactive');
            },
            timeout: 5000
          });
        }
      );

      cssAndWoffPromises.push(webFontPromise);

      cacheKeys.forEach((cacheKeyValue: string): void => {
        //the font is not "ready" until we have the css and have opened it
        promises[cacheKeyValue] = Promise.all(cssAndWoffPromises).then(
          () => {
            promiseComplete[cacheKeyValue] = true;
          },
          (err) => {
            logger.error(
              'Unable to load font: ' + font?.name ?? cacheKeyValue,
              err
            );
          }
        );
      });
    }
  }

  function getFirstLoadedFont(): Promise<string | null> {
    return new Promise((resolve: (font: string | null) => void): void => {
      const allFonts = Object.keys(fontsMap);

      for (let i = 0; i < allFonts.length; i++) {
        const cacheKey = getCacheKey(allFonts[i], 'r');
        if (
          Object.prototype.hasOwnProperty.call(promises, cacheKey) &&
          promiseComplete[cacheKey]
        ) {
          resolve(allFonts[i]);
        }
      }
      resolve(null);
    });
  }

  function convertToFont(font: string | IEditorFont): IEditorFont | null {
    if (typeof font === 'string') {
      font = fontsMap[font] ? fontsMap[font] : fontsMap[DEFAULT_FONT];
    }

    return font;
  }

  function getCacheKey(name: string, style: string): string {
    return name + '-' + (style ? style : 'r');
  }

  function convertStyleToFVD(style: 'r' | 'b' | 'i' | 'bi'): string {
    return FVD_MAP[style] || 'n4';
  }

  return {
    load,
    fontFiles,
    isLoaded,
    get,
    areLoaded,
    loadMultiple,
    getFirstLoadedFont,
    getFonts
  };
});
