<template>
  <v-container
    id="app-bar-component"
    :fluid="true"
    class="px-0 py-0"
  >
    <v-row class="px-4 my-0">
      <v-col
        class="d-flex justify-start"
      >
        <router-link to="/">
          <v-img
            width="175"
            max-width="175"
            src="../assets/images/printsuite-logo.png"
          ></v-img>
        </router-link>
      </v-col>
      <v-col
        v-if="isLoggedIn"
        class="d-flex justify-end align-center"
      >
        <div class="pa-2 locale"><div>{{ locale }} &#47; US</div></div>
        <v-menu>
          <template #activator="{props}">
            <v-avatar
              color="primary"
              size="large"
              v-bind="props"
              class="avatar-button"
            >
              {{ userInitials }}
            </v-avatar>
          </template>
          <v-list
            density="compact"
          >
            <v-list-item
              id="user-info"
              :subtitle="user.email"
              :title="user.firstName + ' ' + user.lastName"
            >
              <template #prepend>
                <v-avatar
                  color="primary"
                >
                  {{ userInitials }}
                </v-avatar>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(avatarItem, index) in avatarItems"
              :key="index"
              :title="avatarItem.title"
              @click="performAction(avatarItem)"
            >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        v-if="!isLoggedIn"
        xs="3"
        sm="2"
        class="d-flex justify-end align-center"
      >
        <v-btn
          id="login-button"
          class="mr-2 px-6"
          color="primary"
          variant="outlined"
          @click="logIn"
        >
          {{ t('AppBar.login') }}
        </v-btn>
        <v-btn
          id="register-button"
          class="m-2 px-4"
          color="primary"
          variant="flat"
          @click="signUp"
        >
          {{ t('AppBar.signUp') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ROUTE_BILLING, ROUTE_SETTINGS } from '@/constants/routeConstants';
import { useTenantStore } from '@/stores/tenantStore';
import {storeToRefs} from 'pinia';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import router from '@/router';
import settingsIcon from '@/assets/images/dashboard/settings.svg';
import pricingIcon from '@/assets/images/dashboard/pricing.svg';
import { IConfig, getConfig } from '@/helpers/configHelper';
interface IAvatarItem {
  title: string;
  item: string;
  src: string;
}
enum AvatarItems {
  settings = 'settings',
  billing = 'billing',
  signOut = 'signOut'
}
const { t, locale } = useI18n();
const tenantStore = useTenantStore();
const {userInitials, isLoggedIn, user} = storeToRefs(tenantStore);
const avatarItems = ref<IAvatarItem[]>([
  {
    title: t('AvatarMenuItems.settings'),
    item: AvatarItems.settings,
    src: settingsIcon

  },
  {
    title: t('AvatarMenuItems.billing'),
    item: AvatarItems.billing,
    src: pricingIcon
  },
  {
    title: t('AvatarMenuItems.signOut'),
    item: AvatarItems.signOut,
    src: ''
  }

]);

let config:IConfig|undefined;

function logIn() {
  tenantStore.doLogIn('/');
}

function signUp() {
  if (config && config.services.signup === 'klaviyo' && config.services.klaviyo.key) {
    /* eslint-disable */
    (window as any)._klOnsite = (window as any)._klOnsite || []; (window as any)._klOnsite.push(['openForm', config.services.klaviyo.key]);
    /* eslint-enable */
  } else {
    tenantStore.doSignUp();
  }
}


onMounted(async () => {
  try {
    config = getConfig();
  } catch (error) {
    console.error('Failed to load platform redirect:', error);
  }
});


function performAction(avatarItem: IAvatarItem) {
  switch (avatarItem.item) {
    case AvatarItems.settings:
      router.push(ROUTE_SETTINGS).catch(err => {
        console.error('Error going to settings', err);
      });
      break;
    case AvatarItems.billing:
      router.push(ROUTE_BILLING).catch(err => {
        console.error('Error going to billing page', err);
      });
      break;
    case AvatarItems.signOut:
      tenantStore.doLogOut();
      break;
    default:
      console.warn('Unknown menu action');
  }
}

</script>

<style lang="scss">
  #app-bar-component {
    .avatar-button:hover {
      cursor: pointer;
    }
    .locale {
      text-transform: uppercase;
    }
  }

  @media (max-height: 800px) {
    #user-info .v-list-item-subtitle {
      line-height: 1.1rem; /* PRST-446 Printsuite.devel - Cut-off text when under 1536x776px */
    }
  }
</style>
