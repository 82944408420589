import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ShopProductCreationPhase } from '@/interfaces/editorInterfaces';
import { BillingViewState } from '@/interfaces/walletInterface';

export const useUIStateStore = defineStore('uiState', () => {
  const navigationDrawerIsShowing = ref<boolean>(false);
  const appBarIsShowing = ref<boolean>(true);
  const footerIsShowing = ref<boolean>(false);
  const specificDesignSwitch = ref(false);
  const snackBarText = ref('');
  const isIn3DPreviewMode = ref(false);
  const isIn3DPreviewFullSizeMode = ref(false);
  const isFullSizeItePreview = ref(false);
  const saveShopProductInfo = ref(false);
  const shopProductPhase = ref<ShopProductCreationPhase|null>(null);
  const billingViewState = ref<BillingViewState|null>(null);
  const isSavingShopProductAsDraft = ref(false);

  function hideNavigationDrawer(): void {
    navigationDrawerIsShowing.value = false;
  }

  function showNavigationDrawer(): void {
    navigationDrawerIsShowing.value = true;
  }

  function hideAppBar(): void {
    appBarIsShowing.value = false;
  }

  function showAppBar(): void {
    appBarIsShowing.value = true;
  }

  function hideFooter(): void {
    footerIsShowing.value = false;
  }

  function showFooter(): void {
    footerIsShowing.value = true;
  }

  function setShopProductPhase(phase: ShopProductCreationPhase|null): void {
    shopProductPhase.value = phase;
  }

  function setBillingViewState(viewState: BillingViewState|null): void {
    billingViewState.value = viewState;
  }

  function setSpecificDesignSwitch(value: boolean): void {
    specificDesignSwitch.value = value;
  }

  function setSaveShopProductInfo(value: boolean): void {
    saveShopProductInfo.value = value;
  }

  function setIsSavingShopProductAsDraft(value: boolean): void {
    isSavingShopProductAsDraft.value = value;
  }

  function setIsIn3DPreviewMode(value: boolean): void {
    isIn3DPreviewMode.value = value;
    isFullSizeItePreview.value = true;
    if (!value) {
      isIn3DPreviewFullSizeMode.value = false;
      isFullSizeItePreview.value = false;
    }
  }

  function setIsIn3DPreviewFullSizeMode(value: boolean): void {
    isIn3DPreviewFullSizeMode.value = value;
    if (value) {
      setIsFullSizeItePreview(false);
    }
  }

  function setIsFullSizeItePreview(value: boolean): void {
    isFullSizeItePreview.value = value;
    if (value) {
      setIsIn3DPreviewFullSizeMode(false);
    }
  }

  return {
    navigationDrawerIsShowing,
    appBarIsShowing,
    footerIsShowing,
    shopProductPhase,
    billingMethodViewState: billingViewState,
    hideNavigationDrawer,
    showNavigationDrawer,
    saveShopProductInfo,
    isSavingShopProductAsDraft,
    showAppBar,
    hideAppBar,
    showFooter,
    hideFooter,
    specificDesignSwitch,
    isIn3DPreviewMode,
    isIn3DPreviewFullSizeMode,
    isFullSizeItePreview,
    setSpecificDesignSwitch,
    setIsIn3DPreviewMode,
    setIsIn3DPreviewFullSizeMode,
    setIsFullSizeItePreview,
    snackBarText,
    setSaveShopProductInfo,
    setShopProductPhase,
    setBillingViewState,
    setIsSavingShopProductAsDraft,
  };
});
