import { fabric } from 'fabric';
import { IEditorImage } from '@/interfaces/editorInterfaces';
import { Image } from '@/modules/editor/fabricShapes/image';
import { Helpers } from '@/modules/editor/helpers';

export const Frame = fabric.util.createClass(Image, {
  type: 'frame',
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,

  initialize: function (image: IEditorImage, options: never): void {
    this.callSuper('initialize', image, options);
    this._firstRender = true;
  },

  getFrameWidth: function (): number {
    return this.width - this.getMarginLeft() - this.getMarginRight();
  },

  getFrameHeight: function (): number {
    return this.height - this.getMarginTop() - this.getMarginBottom();
  },

  /**
   * Lazy load the creation of the image. It will be created on the first render() call.
   *
   * @param ctx
   * @param noTransform
   */
  render: function (ctx: CanvasRenderingContext2D, noTransform: boolean): void {
    const effects = this.group.effects;

    // add pass-through so frames without images (masks, borders) go through the object rendering pipeline
    if (effects.borderSize && !effects.mask) {
      if (this._firstRender) {
        this.applyBorder();
        this._firstRender = false;
      }

      return fabric.Object.prototype.render.apply(this, [ctx]);
    }

    this.callSuper('render', ctx, noTransform);
  },

  /**
   * Because we added a pass through in the render() function, _render() will still execute even if we don't
   * have an image. This creates a problem in node.js as node-canvas will crash if we try to render a null
   * image.
   *
   * @param ctx
   * @param noTransform
   * @private
   */
  _render: function (
    ctx: CanvasRenderingContext2D,
    noTransform: boolean
  ): void {
    const effects = this.group.effects;

    if (this.image) {
      this.callSuper('_render', ctx, noTransform);
    } else if (effects.borderSize && !effects.mask) {
      this._stroke(ctx);
      this._renderStroke(ctx);
    }
  },

  applyBorder: function (): void {
    const effects = this.group.effects;

    // perform certain actions if there is a border
    if (effects.borderSize) {
      // if there is
      if (this.image) {
        this.removeImage();
      }

      // if a color is not set yet, set a default
      if (!effects.borderColor) {
        effects.borderColor = '#000000';
      }
    }

    this.set({
      strokeWidth: effects.borderSize,
      stroke: effects.borderColor || '#000000',
    });
  },

  getMarginLeft: function (): number {
    // if we have a frame
    if (this.image) {
      return this.width * (this.image.marginLeft / 100);
    }

    let margin = 0;
    if (this.group.effects) {
      if (this.group.effects.borderSize) {
        margin += parseInt(this.group.effects.borderSize, 10);
      }
      if (
        this.group.canvas &&
        this.group.canvas.wrap &&
        Helpers.validWrap(this.group.canvas.wrap) &&
        this.group.type === 'background'
      ) {
        margin +=
          this.group.canvas.wrap.bleed_back_l +
          this.group.canvas.wrap.bleed_side_l;
      }
    }

    return margin;
  },

  getMarginRight: function (): number {
    // if we have a frame
    if (this.image) {
      return this.width * (this.image.marginRight / 100);
    }

    let margin = 0;
    if (this.group.effects) {
      if (this.group.effects.borderSize) {
        margin += parseInt(this.group.effects.borderSize, 10);
      }
      if (
        this.group.canvas &&
        this.group.canvas.wrap &&
        Helpers.validWrap(this.group.canvas.wrap) &&
        this.group.type === 'background'
      ) {
        margin +=
          this.group.canvas.wrap.bleed_back_r +
          this.group.canvas.wrap.bleed_side_r;
      }
    }

    return margin;
  },

  getMarginTop: function (): number {
    // if we have a frame
    if (this.image) {
      return this.height * (this.image.marginTop / 100);
    }

    let margin = 0;
    if (this.group.effects) {
      if (this.group.effects.borderSize) {
        margin += parseInt(this.group.effects.borderSize, 10);
      }
      if (
        this.group.canvas &&
        this.group.canvas.wrap &&
        Helpers.validWrap(this.group.canvas.wrap) &&
        this.group.type === 'background'
      ) {
        margin +=
          this.group.canvas.wrap.bleed_back_t +
          this.group.canvas.wrap.bleed_side_t;
      }
    }

    return margin;
  },

  getMarginBottom: function (): number {
    // if we have a frame
    if (this.image) {
      return this.height * (this.image.marginBottom / 100);
    }

    let margin = 0;
    if (this.group.effects) {
      if (this.group.effects.borderSize) {
        margin += parseInt(this.group.effects.borderSize, 10);
      }
      if (
        this.group.canvas &&
        this.group.canvas.wrap &&
        Helpers.validWrap(this.group.canvas.wrap) &&
        this.group.type === 'background'
      ) {
        margin +=
          this.group.canvas.wrap.bleed_back_b +
          this.group.canvas.wrap.bleed_side_b;
      }
    }

    return margin;
  },

  replaceImage: function (
    image: IEditorImage,
    reset: boolean,
    callback: () => void
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const effects = this.group.effects;

    // actually replace image
    this.callSuper('replaceImage', image, false, function (): void {
      // remove mask if there is one
      if (effects.mask) {
        delete effects.mask;
      }

      // remove border if there is one
      self.group.removeBorder();
      // call placePhoto if reset is true
      if (!self.group.photoObject.zoomed && reset) {
        self.group.photoObject.placePhoto();
      }
      self.group.photoObject.setPreviousZoom();

      // execute callback
      callback();
    });
  },

  /**
   * @private
   *
   * Draws the stroke on the inside.
   *
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  //@todo do we still need this
  _stroke: function (ctx: CanvasRenderingContext2D): void {
    if (!this.stroke || this.strokeWidth === 0) {
      return;
    }
    // calculate adjusted size of rectangle due to stroke
    const rectWidth = this.width - this.strokeWidth;
    const rectHeight = this.height - this.strokeWidth;

    const w = rectWidth / 2;
    const h = rectHeight / 2;
    ctx.beginPath();
    ctx.moveTo(-w, -h);
    ctx.lineTo(w, -h);
    ctx.lineTo(w, h);
    ctx.lineTo(-w, h);
    ctx.lineTo(-w, -h);
    ctx.closePath();
  },
});
