import { defineStore } from 'pinia';
import { computed, nextTick, ref, shallowRef, triggerRef } from 'vue';
import { IPreviewData } from '@/interfaces/baseInterface';
import { EditorCanvas } from '@/modules/editor/editorCanvas';
import { EditorPages } from '@/modules/editor/editorPage';
import { ElementCollection } from '@/modules/editor/elementCollection';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { Helpers } from '@/modules/editor/helpers';
import {
  EditorLayerType,
  EditorPanelView,
  ICanvasErrorConfig,
  IEditorCanvas,
  IEditorElement,
  IEditorPage,
  IEditorShape,
  IEditorText,
  ISelectedObject,
  Orientation,
} from '@/interfaces/editorInterfaces';

import { useEditorTextStore } from '@/stores/editorTextStore';
import { useUndoRedoStore } from '@/stores/undoRedoStore';
import { fabric } from 'fabric';
import {
  FontClampHigh, FontClampLow,
  FontSizePercentage,
  FontSizeScale,
  MARGIN_AROUND_CANVAS,
  MARGIN_AROUND_PREVIEW_CANVAS
} from '@/modules/editor/editorConstants';
import { useEditorImageStore } from '@/stores/editorImageStore';
import { IEvent } from 'fabric/fabric-impl';
import { useEditorProductStore } from '@/stores/editorProductStore';
import { useSessionStore } from '@/stores/sessionStore';
import { LayerOnCanvas } from '@/modules/editor/layerOnCanvas';
import {deepToRaw, throttle } from '@/helpers/vueHelpers';
import {
  findPreviewDataBasedOnProductAttributeSelection,
  getCanvasLayersPreviewData
} from '@/helpers/productInfoHelpers';
import { useUIStateStore } from '@/stores/UIStateStore';
import { AligningGuidelines } from '@/modules/editor/aligningGuidelines';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { doesProductHaveBackLayer } from '@/helpers/itePreviewHelper';
import { doesProductHaveMask } from '@/helpers/itePreviewHelper';
import { IItePreviews } from '@/interfaces/iItePreviews';
import { KeyboardInteraction } from '@/modules/editor/keyboardInteraction';

export const useEditorCanvasStore = defineStore('editorCanvas', () => {

  const currentPageIndex = ref<number>(0);
  const errorConfiguration = ref<ICanvasErrorConfig>({
    zoomWarning: 2,
    zoomError: 3,
  });
  const fabricCanvas = shallowRef<IEditorCanvas | undefined>();
  const editorPages = shallowRef<EditorPages | undefined>();
  const canvasBackgroundColor = '#ffffff';
  const canvasObjectsAreLoaded = ref<boolean>(false);
  const currentActiveSelectionAlignment = ref<string>('left');
  const currentActiveSelectionAlignmentToPrintableArea = ref<string>('left');
  const currentSelectedObjectIndex = ref<ISelectedObject | undefined>();
  const editorLayerCanvasObjects = shallowRef<fabric.Object[]>([]); // canvas objects that are shown in layers left panel should be updated upon deletion from the image toolbar
  const editorCanvasContainerWidth = ref<number>(0);
  const editorCanvasContainerHeight = ref<number>(0);
  const textStore = useEditorTextStore();
  const editorImageStore = useEditorImageStore();
  const editorProductStore = useEditorProductStore();
  const uiStateStore = useUIStateStore();

  const isTextZoneSelected = ref(false);
  const isImageZoneSelected = ref(false);

  const previewCanvases: IEditorCanvas[] = [];
  const previewDataUrls = ref<string[]>([]);
  const refreshCanvas = ref(false);


  let itePreviews:IItePreviews|undefined;
  const itePreviewReady = new Promise<void>((resolve,reject) => {
    ServiceLocator.getService<IItePreviews>(ServiceType.ItePreviews).then(ite => {
      itePreviews = ite;
      itePreviews.initItePreviews();
      resolve();
    }, err => {
      reject(err);
    });
  });

  const currentEditorPanelView = ref<EditorPanelView>(
    EditorPanelView.productAttributes
  );

  const getCanvasBackgroundColor = computed((): string => {
    return hasTransparentCanvasBackgroundColor() ? 'rgba(0,0,0,0)' : canvasBackgroundColor;
  });

  const getEditorPages = computed((): EditorPages | undefined => {
    return editorPages.value;
  });

  const generateThrottledPagerPreviews = throttle( (pageIndex: number, canvas: HTMLCanvasElement, containerWidth: number, containerHeight: number) => {
    generatePagerPreviews(pageIndex, canvas, containerWidth, containerHeight).catch(err => {
      console.error('Error generating pager preview', err);
    });
  }, 2000);


  function setCurrentEditorPanelView(view: EditorPanelView): void {
    currentEditorPanelView.value = view;
    uiStateStore.setIsIn3DPreviewMode(false);
  }

  function setEditorCanvasSize(width: number, height: number): void {
    editorCanvasContainerWidth.value = width;
    editorCanvasContainerHeight.value = height;
  }

  function setRefreshCanvas(value: boolean): void {
    refreshCanvas.value = value;
  }

  function hasTransparentCanvasBackgroundColor(): boolean {
    const sessionStore = useSessionStore();
    const currentBaseProduct = sessionStore.currentBase;
    return currentBaseProduct && currentBaseProduct.production_type ? doesProductHaveBackLayer(currentBaseProduct) : false;
  }

  function subscribeToObjectSelectChange(): void {
    fabricCanvas.value?.on('text:changed', handleTextChange);
    fabricCanvas.value?.on('selection:updated', handleSelectionChange);
    fabricCanvas.value?.on('selection:created', handleSelectionChange);
    fabricCanvas.value?.on('selection:cleared', handleSelectionCleared);

  }

  function subscribeToCanvasObjectChanges(): void {
    fabricCanvas.value?.on('object:added', handleObjectAdded);
    fabricCanvas.value?.on('object:modified', saveCanvasObjectsState);
    fabricCanvas.value?.on('object:moving', keepObjectInPrintableAreaOnMoving);
  }

  function unsubscribeToCanvasObjectChanges(): void {
    fabricCanvas.value?.off('object:added', handleObjectAdded);
    fabricCanvas.value?.off('object:modified', saveCanvasObjectsState);
    fabricCanvas.value?.off('object:moving', keepObjectInPrintableAreaOnMoving);
  }

  function unsubscribeToObjectSelectChange(): void {
    fabricCanvas.value?.off('text:changed', handleTextChange);
    fabricCanvas.value?.off('selection:updated', handleSelectionChange);
    fabricCanvas.value?.off('selection:created', handleSelectionChange);
    fabricCanvas.value?.off('selection:cleared', handleSelectionCleared);

  }

  function handleObjectAdded(): void {
    updateEditorLayerCanvasObjects();
  }

  function updateEditorLayerCanvasObjects(): void {
    if (fabricCanvas.value) {
      editorLayerCanvasObjects.value = fabricCanvas.value.getObjects();
    }
  }

  function changeCanvasObjectOrder(object: fabric.Object, newIndex: number): void {
    if (newIndex > -1 && fabricCanvas.value) {
      fabricCanvas.value.moveTo(object, newIndex);
      updateEditorLayerCanvasObjects();
      fabricCanvas.value.renderAll();
      const selectedObj = getSelectedObject();
      if (selectedObj) {
        setCurrentSelectedObjectIndex(selectedObj);
      }
      saveCanvasObjectsState().catch(err => {
        console.error('Error saving canvas objects state', err);
      });
    }
  }

  // this function is called whenever there is a change in canvas
  async function saveCanvasObjectsState(): Promise<void> {
    const sessionStore = useSessionStore();
    const undoRedoStore = useUndoRedoStore();
    updateCurrentProjectEdits();
    if (sessionStore.currentActiveProductSkuIdList.length > 0) {
      editorProductStore.setCachedItePreviews(sessionStore.currentActiveProductSkuIdList[0], []); // reset cached ite previews
    }
    const selectedObject = getSelectedObject() as IEditorShape|null;
    if (editorPages.value) {
      const clonedPage = getEditorPageFromCanvas();
      if (clonedPage) {
        undoRedoStore.keepCurrentPageState(clonedPage, currentPageIndex.value, selectedObject);
      }
    }
    updatePreviewDataUrls();
  }

  function keepObjectInPrintableAreaOnMoving(event: IEvent): void {
    const target = event.target as fabric.Object;
    // only for text objects for now. It causes alot of issues on image objects when they are scaled beyond the canvas boundry
    if (target.type === 'sf-text' && fabricCanvas.value && fabricCanvas.value.viewportTransform) {
      const page: IEditorPage | undefined = getCurrentPage();
      const activeObjectCenter = target.getCenterPoint();
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = target.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / fabricCanvas.value.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / fabricCanvas.value.viewportTransform[0];
      const scaledObjectBounding = getScaledObjectBounding(target);
      if (page) {
        // left edge
        if (activeObjectLeft - activeObjectWidth / 2 <= 0) {
          target.set('left', scaledObjectBounding.width / 2);
        }
        // right edge
        if (activeObjectLeft + activeObjectWidth / 2 >= page.width) {
          target.set('left', page.width - scaledObjectBounding.width / 2);
        }
        // top edge
        if (activeObjectTop - activeObjectHeight / 2 <= 0) {
          target.set('top', scaledObjectBounding.height / 2);
        }
        // bottom edge
        if (activeObjectTop + activeObjectHeight / 2 >= page.height) {
          target.set('top', page.height - scaledObjectBounding.height / 2);
        }
      }
    }
  }

  function getScaledObjectBounding(target:fabric.Object):{ left:number; top:number; width:number; height:number } {
    if (fabricCanvas.value) {
      const zoom = 1 / fabricCanvas.value.getZoom();
      const marginLeft = fabricCanvas.value.marginLeft;
      const marginTop = fabricCanvas.value.marginTop;
      const boundingRect = target.getBoundingRect();
      boundingRect.left = (boundingRect.left - marginLeft) * zoom;
      boundingRect.top = (boundingRect.top - marginTop) * zoom;
      boundingRect.width *= zoom;
      boundingRect.height *= zoom;
      return boundingRect;
    }
    return { left: 0, top: 0, width: 0, height: 0 };
  }

  function undo(): void {
    const undoRedoStore = useUndoRedoStore();
    const newPageState = undoRedoStore.undoPage(currentPageIndex.value);
    if (newPageState && editorPages.value) {
      const currentImagePanelView = editorImageStore.currentImagePanelView;
      const currentTextPanelView = textStore.currentTextPanelView;
      editorPages.value.pages[currentPageIndex.value] = newPageState.page;
      triggerRef(editorPages);
      setupFabricCanvasFromPage(editorPages.value);
      if (fabricCanvas.value) {
        Helpers.setActiveObjectByUid(fabricCanvas.value, newPageState.selectedObjectUid);
        if (isImageZoneSelected.value && currentImagePanelView) {
          editorImageStore.setCurrentImagePanelView(currentImagePanelView);
        } else if (isTextZoneSelected.value && currentTextPanelView) {
          textStore.setCurrentTextPanelView(currentTextPanelView);
        }
      }
    }
  }

  function redo(): void {
    const undoRedoStore = useUndoRedoStore();
    const newPageState = undoRedoStore.redoPage(currentPageIndex.value);
    if (newPageState && editorPages.value) {
      editorPages.value.pages[currentPageIndex.value] = newPageState.page;
      triggerRef(editorPages);
      setupFabricCanvasFromPage(editorPages.value);
      if (fabricCanvas.value) {
        Helpers.setActiveObjectByUid(fabricCanvas.value, newPageState.selectedObjectUid);
      }
    }
  }

   function updatePreviewDataUrls() {
    const sessionStore = useSessionStore();
    const currentBaseProduct = sessionStore.currentBase;
    if (currentBaseProduct) {
      const project = editorProductStore.findProject(sessionStore.currentActiveProductSkuIdList[0]);
      if (project && project.pages.length > 0) {
        const pagePreviewElement = Helpers.getPagePreviewDOMElement();
        if (pagePreviewElement) {
          const canvas = Helpers.createPagePreviewCanvasElement(currentPageIndex.value);
          const width = pagePreviewElement.clientWidth;
          const height = pagePreviewElement.clientHeight;
          // noinspection TypeScriptValidateTypes
          generateThrottledPagerPreviews(currentPageIndex.value, canvas, width, height);
        }
      }
    }
  }

  async function generatePagerPreviews(pageIndex: number, canvas: HTMLCanvasElement, containerWidth: number, containerHeight: number): Promise<void> {
    await itePreviewReady;
    if (!itePreviews) {
      throw new Error('ITE Previews not defined');
    }
    itePreviews.subscribe();
    const sessionStore = useSessionStore();
    const currentBaseProduct = sessionStore.currentBase;
    const project = editorProductStore.findProject(sessionStore.currentActiveProductSkuIdList[0]);
    if (currentBaseProduct && project && project.pages.length > 0) {
      // get pager previews from either the canvas snapshot directly or the related ITE preview
      if (editorPages.value) {
        await editorProductStore.updateProjectsPagePreviews(sessionStore.currentActiveProductSkuIdList[0], currentBaseProduct);
        const orientation = Helpers.getOrientation(editorPages.value.pages[pageIndex].width, editorPages.value.pages[pageIndex].height);
        const itePreviewResults = await itePreviews.createEditorPreviews(sessionStore.currentActiveProductSkuIdList[0], [project.pages[pageIndex]], currentBaseProduct, [orientation], true);
        // pager previews come either directly form the canvas snapshot or from the ITE previews
        if (itePreviewResults.length > 0) {
          const currentPagePreview = itePreviewResults.find((itePreviewResult) => itePreviewResult.pageIndex === pageIndex);
          if (currentPagePreview) {
            previewDataUrls.value[pageIndex] = currentPagePreview.preview;
          }
        } else {
          const snapCanvas = await createPreviewCanvases(pageIndex, canvas, containerWidth, containerHeight);
          previewDataUrls.value[pageIndex] = snapCanvas.toDataURL();
        }
      }
    }
  }

  function handleSelectionCleared(): void {
    const editorTextStore = useEditorTextStore();
    isImageZoneSelected.value = false;
    isTextZoneSelected.value = false;
    currentSelectedObjectIndex.value = undefined;
    editorImageStore.closeImageEditPanel();
    editorTextStore.closeTextEditPanel();
  }

  function handleSelectionChange(event: IEvent): void {
    isTextZoneSelected.value = !!(
      event.selected &&
      fabricCanvas.value &&
      event.selected.length === 1 &&
      event.selected[0].type === 'sf-text'
    );

    isImageZoneSelected.value = !!(
      event.selected &&
      fabricCanvas.value &&
      event.selected.length === 1 &&
      (event.selected[0] as IEditorShape).photoObject !== undefined &&
      ((event.selected[0] as IEditorShape).photoObject.image || (!(event.selected[0] as IEditorShape).photoObject.image && (event.selected[0] as IEditorShape).customerEditable))
    );
    if (isTextZoneSelected.value) {
      textStore.handleSelectionChange(event);
      if (currentEditorPanelView.value === EditorPanelView.productAttributes || currentEditorPanelView.value === EditorPanelView.images) {
        setCurrentEditorPanelView(EditorPanelView.layers);
      }
    } else if (isImageZoneSelected.value) {
      editorImageStore.handleImageEditsChange(event);
      if (currentEditorPanelView.value === EditorPanelView.productAttributes || currentEditorPanelView.value === EditorPanelView.texts) {
        setCurrentEditorPanelView(EditorPanelView.layers);
      }
    }
    if (event.selected && event.selected.length === 1) {
      setCurrentSelectedObjectIndex(event.selected[0]);
    }
    currentActiveSelectionAlignment.value =
      getCurrentActiveSelectionAlignment();
    currentActiveSelectionAlignmentToPrintableArea.value = getActiveSelectionAlignmentToPrintableArea();
  }

  function setCurrentSelectedObjectIndex(object: fabric.Object): void {
    currentSelectedObjectIndex.value = {
      objectIndexOnCanvas: fabricCanvas.value
        ?.getObjects()
        .indexOf(object) as number
    };
  }

  // for texts only for now... non-text objects will be done later
  function changeObjectEditableMode(isEditable: boolean): void {
    const selectedObject = getSelectedObject();
    if (selectedObject && selectedObject.type === 'sf-text') {
      (selectedObject as IEditorText).customerEditable = isEditable;
      fabricCanvas.value?.renderAll();
      saveCanvasObjectsState().catch(err => {
        console.error('Error saving canvas objects state', err);
      });
    }
  }

  function getSelectedObject(): fabric.Object | null {
    return fabricCanvas.value ? fabricCanvas.value.getActiveObject() : null;
  }

  function deleteSelectedObject(): void {
    const selectedObject = getSelectedObject() as IEditorShape|null;
    if (selectedObject) {
      deleteObject(selectedObject);
      fabricCanvas.value?.renderAll();
      saveCanvasObjectsState().catch(err => {
        console.error('Error saving canvas objects state', err);
      });
    }
  }

  function deleteObject(object: IEditorShape): void {
    if (fabricCanvas.value) {
      fabricCanvas.value.remove(object);
      updateEditorLayerCanvasObjects();
      if (object.type === 'sf-text') {
        textStore.setCurrentTextObjects();
      }
    }
  }

  function handleTextChange(event: IEvent): void {
    textStore.handleTextChange(event);
    saveCanvasObjectsState().catch(err => {
      console.error('Error saving canvas objects state', err);
    });
  }

  function getCurrentActiveSelectionAlignment(): string {
    if (fabricCanvas.value) {
      const activeObject = fabricCanvas.value.getActiveObject();
      if (activeObject && activeObject.type == 'activeSelection') {
        const groupWidth = activeObject.getBoundingRect(true).width;
        const groupHeight = activeObject.getBoundingRect(true).height;
        const selectedObjects = fabricCanvas.value.getActiveObjects();
        const leftAlign = selectedObjects.every((selectedObject) => {
          const objectWidth = selectedObject.getBoundingRect().width;
          const leftAlignValue = Math.floor(
            -(groupWidth / 2) + objectWidth / 2
          );
          return selectedObject.left === leftAlignValue;
        });

        const rightAlign = selectedObjects.every((selectedObject) => {
          const objectWidth = selectedObject.getBoundingRect().width;
          const rightAlignValue = Math.floor(groupWidth / 2 - objectWidth / 2);
          return selectedObject.left === rightAlignValue;
        });

        const centerAlign = selectedObjects.every((selectedObject) => {
          return selectedObject.left === 0;
        });

        const topAlign = selectedObjects.every((selectedObject) => {
          const objectHeight = selectedObject.getBoundingRect().height;
          const topAlignValue = Math.floor(-groupHeight / 2 + objectHeight / 2);
          return selectedObject.top === topAlignValue;
        });

        const middleAlign = selectedObjects.every((selectedObject) => {
          return selectedObject.top === 0;
        });

        const bottomAlign = selectedObjects.every((selectedObject) => {
          const objectHeight = selectedObject.getBoundingRect().height;
          const bottomAlignValue = Math.floor(
            groupHeight / 2 - objectHeight / 2
          );
          return selectedObject.top === bottomAlignValue;
        });

        return leftAlign
          ? 'left'
          : rightAlign
            ? 'right'
            : centerAlign
              ? 'center'
              : topAlign
                ? 'top'
                : middleAlign
                  ? 'middle'
                  : bottomAlign
                    ? 'bottom'
                    : '';
      }
    }
    return '';
  }

  function getActiveSelectionAlignmentToPrintableArea(): string {
    const page = getCurrentPage();
    if (fabricCanvas.value && page) {
      const zoom = fabricCanvas.value.getZoom();
      const printableAreaWidth = page.width;
      const printableAreaHeight = page.height;
      const activeObject = fabricCanvas.value.getActiveObject();
      if (activeObject && activeObject.type == 'activeSelection') {
        const selectedObjects = fabricCanvas.value.getActiveObjects();
        const leftAlign = selectedObjects.every((selectedObject) => {
          const objectWidth = selectedObject.getBoundingRect().width / zoom;
          return selectedObject.left == Math.floor(objectWidth / 2);
        });

        const rightAlign = selectedObjects.every((selectedObject) => {
          const objectWidth = selectedObject.getBoundingRect().width / zoom;
          return selectedObject.left == Math.floor(printableAreaWidth - objectWidth / 2);
        });

        const centerAlign = selectedObjects.every((selectedObject) => {
          return selectedObject.left == Math.floor(printableAreaWidth / 2);
        });

        const topAlign = selectedObjects.every((selectedObject) => {
          const objectHeight = selectedObject.getBoundingRect().height / zoom;
          return selectedObject.top == Math.floor(objectHeight / 2);
        });

        const middleAlign = selectedObjects.every((selectedObject) => {
          return selectedObject.top == Math.floor(printableAreaHeight / 2);
        });

        const bottomAlign = selectedObjects.every((selectedObject) => {
          const objectHeight = selectedObject.getBoundingRect().height / zoom;
          return selectedObject.top == Math.floor(printableAreaHeight - objectHeight / 2);
        });

        return leftAlign
          ? 'left'
          : rightAlign
            ? 'right'
            : centerAlign
              ? 'center'
              : topAlign
                ? 'top'
                : middleAlign
                  ? 'middle'
                  : bottomAlign
                    ? 'bottom'
                    : '';
      }
    }
    return '';
  }

  function handleObjectsAlignment(
    position: 'left' | 'right' | 'top' | 'bottom' | 'middle' | 'center'
  ): void {
    if (fabricCanvas.value) {
      const activeObject = fabricCanvas.value.getActiveObject();
      if (activeObject && activeObject.type == 'activeSelection') {
        const groupWidth = activeObject.getBoundingRect(true).width;
        const groupHeight = activeObject.getBoundingRect(true).height;
        const selectedObjects = fabricCanvas.value.getActiveObjects();
        if (position === 'left') {
          selectedObjects.forEach((selectedObject) => {
            const objectWidth = selectedObject.getBoundingRect().width;
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: Math.floor(-(groupWidth / 2) + objectWidth / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'left';
        } else if (position === 'right') {
          selectedObjects.forEach((selectedObject) => {
            const objectWidth = selectedObject.getBoundingRect().width;
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: Math.floor(groupWidth / 2 - objectWidth / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'right';
        } else if (position === 'center') {
          selectedObjects.forEach((selectedObject) => {
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: 0,
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'center';
        } else if (position === 'top') {
          selectedObjects.forEach((selectedObject) => {
            const objectHeight = selectedObject.getBoundingRect().height;
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: Math.floor(-groupHeight / 2 + objectHeight / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'top';
        } else if (position === 'middle') {
          selectedObjects.forEach((selectedObject) => {
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: 0,
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'middle';
        } else if (position === 'bottom') {
          selectedObjects.forEach((selectedObject) => {
            const objectHeight = selectedObject.getBoundingRect().height;
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: Math.floor(groupHeight / 2 - objectHeight / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignment.value = 'bottom';
        }
        fabricCanvas.value.renderAll();
        saveCanvasObjectsState().catch(err => {
          console.error('Error saving canvas objects state', err);
        });
      }
    }
  }

  function alignObjectsToThePrintableArea(
    position: 'left' | 'right' | 'top' | 'bottom' | 'middle' | 'center'
  ): void {
    if (fabricCanvas.value) {
      const page = getCurrentPage();
      let selectedObjects = fabricCanvas.value.getActiveObjects();
      if (selectedObjects.length > 1) {
        const selectedObjIndexes = selectedObjects.map((selectedObject) => {
          return fabricCanvas.value?.getObjects().indexOf(selectedObject);
        });
        fabricCanvas.value.discardActiveObject();
        selectedObjects = [];
        fabricCanvas.value
          .getObjects()
          .forEach((object: fabric.Object, index: number) => {
            if (selectedObjIndexes.includes(index)) {
              selectedObjects.push(object);
            }
          });
      }

      if (page) {
        const zoom = fabricCanvas.value.getZoom();
        const printableAreaWidth = page.width;
        const printableAreaHeight = page.height;
        if (position === 'left') {
          selectedObjects.forEach((selectedObject) => {
            const objectWidth = selectedObject.getBoundingRect().width / zoom;
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: Math.floor(objectWidth / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'left';
        } else if (position === 'right') {
          selectedObjects.forEach((selectedObject) => {
            const objectWidth = selectedObject.getBoundingRect().width / zoom;
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: Math.floor(printableAreaWidth - objectWidth / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'right';
        } else if (position === 'center') {
          selectedObjects.forEach((selectedObject) => {
            if (selectedObject.left !== undefined) {
              selectedObject.set({
                left: Math.floor(printableAreaWidth / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'center';
        } else if (position === 'top') {
          selectedObjects.forEach((selectedObject) => {
            const objectHeight = selectedObject.getBoundingRect().height / zoom;
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: Math.floor(objectHeight / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'top';
        } else if (position === 'middle') {
          selectedObjects.forEach((selectedObject) => {
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: Math.floor(printableAreaHeight / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'middle';
        } else if (position === 'bottom') {
          selectedObjects.forEach((selectedObject) => {
            const objectHeight = selectedObject.getBoundingRect().height / zoom;
            if (selectedObject.top !== undefined) {
              selectedObject.set({
                top: Math.floor(printableAreaHeight - objectHeight / 2),
              });
            }
            selectedObject.setCoords();
          });
          currentActiveSelectionAlignmentToPrintableArea.value = 'bottom';
        }
        if (selectedObjects.length > 1) {
          const activeSelection = new fabric.ActiveSelection(selectedObjects, {
            canvas: fabricCanvas.value,
          });
          fabricCanvas.value.setActiveObject(activeSelection);
        }
        fabricCanvas.value.renderAll();
        saveCanvasObjectsState().catch(err => {
          console.error('Error saving canvas objects state', err);
        });

      }
    }
  }

  function handleDrop(event: DragEvent): void {
    event.preventDefault();
    if (fabricCanvas.value) {
      const target = fabricCanvas.value.findTarget(event, true) as IEditorShape|null;
      const isTargetAnImageZone = !!(target && target.photoObject && target.photoObject.image);
      const targetToReplace = isTargetAnImageZone ? target : null;
      editorImageStore.handlePhotoDrop(targetToReplace);
    }
  }

  function handleDragOver(event: DragEvent): void {
    event.preventDefault();
    const marginLeft = fabricCanvas.value?.marginLeft;
    const marginTop = fabricCanvas.value?.marginTop;

    const canvasWidth = fabricCanvas.value?.width;
    const canvasHeight = fabricCanvas.value?.height;
    if (
      event.dataTransfer &&
      canvasWidth &&
      canvasHeight &&
      marginLeft &&
      marginTop &&
      (event.offsetX <= marginLeft ||
        event.offsetY <= marginTop ||
        event.offsetY >= canvasHeight - marginTop ||
        event.offsetX >= canvasWidth - marginLeft)
    ) {
      event.dataTransfer.dropEffect = 'none';
    }
  }

  function setEditorPagesFromProject(): void {
    const sessionStore = useSessionStore();
    const currentBaseProduct = sessionStore.currentBase;
    if (currentBaseProduct?.id) {
      const editorProject = editorProductStore.findProject(sessionStore.currentActiveProductSkuIdList[0]);
      if (editorProject) {
        editorPages.value = new EditorPages(editorProject.pages);
      }
    }
  }

  function initializeEditorCanvas(): void {
    canvasObjectsAreLoaded.value = false;
    nextTick().then(() => {
      if (editorPages.value) {
        const canvas = document.querySelector('#printSuiteCanvas');
        const editorCanvas = new EditorCanvas();
        if (!fabricCanvas.value) {
          fabricCanvas.value = new editorCanvas.CreateEditorCanvas(canvas, {
            backgroundColor: getCanvasBackgroundColor,
          });
          AligningGuidelines.initAligningGuidelines(fabricCanvas.value as IEditorCanvas);
          KeyboardInteraction.initKeyboardInteraction(fabricCanvas.value as IEditorCanvas);
          subscribeToObjectSelectChange();
          subscribeToCanvasObjectChanges();
          fabricCanvas.value?.wrapperEl?.addEventListener(
            'drop',
            handleDrop,
            false
          );
          fabricCanvas.value?.wrapperEl?.addEventListener(
            'dragover',
            handleDragOver,
            false
          );
        }
        const sessionStore = useSessionStore();
        const currentBase = sessionStore.currentBase;
        if (
          currentBase &&
          currentBase.dynamic_data.optimal_d !== undefined &&
          currentBase.dynamic_data.warning_d !== undefined &&
          currentBase.dynamic_data.minimum_d !== undefined &&
          currentBase.dynamic_data.optimal_x !== undefined &&
          currentBase.dynamic_data.optimal_y !== undefined
        ) {
          const page = editorPages.value.pages[currentPageIndex.value];
          const imageScale =
            Math.max(page.width, page.height) /
            Math.max(
              currentBase.dynamic_data.optimal_x,
              currentBase.dynamic_data.optimal_y
            );

          errorConfiguration.value = {
            zoomWarning:
              (currentBase.dynamic_data.optimal_d /
                currentBase.dynamic_data.warning_d) *
              imageScale || 1.5,
            zoomError:
              (currentBase.dynamic_data.optimal_d /
                currentBase.dynamic_data.minimum_d) *
              imageScale || 2,
          };
        }

        setupFabricCanvasFromPage(editorPages.value);
      }
    }, err=> {
      console.error('Error initializing editor canvas - nextTick failed', err);
    });

  }

  function goToPage(pageIndex: number): void {
    if (fabricCanvas.value && editorPages.value) {
      // save edits on the current page before changing pages
      editorPages.value.pages[currentPageIndex.value].elements = getPageElements();
    }
    currentPageIndex.value = pageIndex;
    initializeEditorCanvas();

  }

  function getCurrentPage(): IEditorPage|undefined {
    return editorPages.value?.pages[currentPageIndex.value];
  }

  function updateCurrentProjectEdits(): void {
    const sessionStore = useSessionStore();
    const currentProject = editorProductStore.findProject(sessionStore.currentActiveProductSkuIdList[0]);
    if (currentProject && editorPages.value) {
      editorPages.value.pages[currentPageIndex.value].elements = getPageElements();
      if (fabricCanvas.value) {
        currentProject.pages[currentPageIndex.value].edits.freeObject = Helpers.toFreeObjects(fabricCanvas.value);
      }
      currentProject.pages[currentPageIndex.value].edits.width = editorPages.value.pages[currentPageIndex.value].width;
      currentProject.pages[currentPageIndex.value].edits.height = editorPages.value.pages[currentPageIndex.value].height;
    }
  }

  function getPageName(pageIndex: number): string {
    return editorPages.value && editorPages.value.pages.length > 0 ? (editorPages.value.pages[pageIndex].pageName || '') : '';
  }

  function setupFabricCanvasFromPage(editorPage: EditorPages): void {

    const width = editorPage.pages[currentPageIndex.value].width;
    const height = editorPage.pages[currentPageIndex.value].height;
    const sessionStore = useSessionStore();
    const base = sessionStore.currentBase;
    if (base) {
      let shouldDrawCanvasBorder = false;
      const layerPreviewDataList = getCanvasLayersPreviewData(base, currentPageIndex.value, sessionStore.currentActiveProductSkuIdList[0]);
      const backLayer = layerPreviewDataList.find((layerPreviewData) => {
        return layerPreviewData.media_context === EditorLayerType.BackLayer;
      });
      if (backLayer) {
        const attrs = backLayer.attributes;
        if (attrs) {
          const bottomMargin = attrs.margin_bottom ? parseInt(attrs.margin_bottom, 10) : 0;
          const topMargin = attrs.margin_top ? parseInt(attrs.margin_top, 10) : 0;
          const leftMargin = attrs.margin_left ? parseInt(attrs.margin_left, 10) : 0;
          const rightMargin = attrs.margin_right ? parseInt(attrs.margin_right, 10) : 0;
          shouldDrawCanvasBorder = bottomMargin > 0 || topMargin > 0 || leftMargin > 0 || rightMargin > 0; // draw border if back layer is serounding the printable area
        }
      }
      const extraSpace = createCanvasMargins(width);
      if (fabricCanvas.value) {
        Helpers.resizeAndClipCanvas(
          fabricCanvas.value,
          editorCanvasContainerWidth.value,
          editorCanvasContainerHeight.value,
          width,
          height,
          MARGIN_AROUND_CANVAS,
          extraSpace,
          shouldDrawCanvasBorder,
        );
        const pageElements = new ElementCollection([
          editorPage.pages[currentPageIndex.value],
        ]);
        EditorTranslator.Instance.boardSetter(
          fabricCanvas.value,
          pageElements,
        );

        layerPreviewDataList.forEach((layerPreviewData) => {
          if (fabricCanvas.value) {
            LayerOnCanvas.renderLayer(layerPreviewData, width, height, fabricCanvas.value);
          }
        });

        fabricCanvas.value.renderAll();
        if (fabricCanvas.value.objectsToLoad === 0) {
          canvasObjectsAreLoaded.value = true;
          updateEditorLayerCanvasObjects();
          textStore.setCurrentTextObjects();
          updateCurrentProjectEdits();
          updatePreviewDataUrls();
        } else {
          fabricCanvas.value.on('after:render', (): void => {
            if (fabricCanvas.value?.objectsToLoad === 0) {
              fabricCanvas.value.off('after:render');
              canvasObjectsAreLoaded.value = true;
              updateEditorLayerCanvasObjects();
              textStore.setCurrentTextObjects();
              updateCurrentProjectEdits();
              updatePreviewDataUrls();
            }
          });
        }

      }
    }
  }

  function createCanvasMargins(pageWidth: number): {
    extraSpaceTop: number;
    extraSpaceBottom: number;
    extraSpaceLeft: number;
    extraSpaceRight: number
  } {

    const extraSpaces: {
      extraSpaceTop: number,
      extraSpaceLeft: number,
      extraSpaceBottom: number,
      extraSpaceRight: number
    }[] = [];
    const sessionStore = useSessionStore();
    const base = sessionStore.currentBase;
    if (base) {
      const layerPreviewDataList = getCanvasLayersPreviewData(base, currentPageIndex.value, sessionStore.currentActiveProductSkuIdList[0]);
      layerPreviewDataList.forEach((layerPreviewData) => {
        const attrs = layerPreviewData.attributes;
        if (attrs) {
          const bottomMargin = attrs.margin_bottom ? parseInt(attrs.margin_bottom, 10) : 0;
          const topMargin = attrs.margin_top ? parseInt(attrs.margin_top, 10) : 0;
          const leftMargin = attrs.margin_left ? parseInt(attrs.margin_left, 10) : 0;
          const rightMargin = attrs.margin_right ? parseInt(attrs.margin_right, 10) : 0;
          extraSpaces.push(Helpers.calculateEditableAreaMarginsFromLayerPreview(layerPreviewData.width, pageWidth, {
            topMargin: topMargin,
            bottomMargin: bottomMargin,
            leftMargin: leftMargin,
            rightMargin: rightMargin
          }));
        }
      });
    }
    if (extraSpaces.length > 0) {
      return extraSpaces.reduce((acc, val) => {
        return {
          extraSpaceTop: Math.max(acc.extraSpaceTop, val.extraSpaceTop),
          extraSpaceBottom: Math.max(acc.extraSpaceBottom, val.extraSpaceBottom),
          extraSpaceLeft: Math.max(acc.extraSpaceLeft, val.extraSpaceLeft),
          extraSpaceRight: Math.max(acc.extraSpaceRight, val.extraSpaceRight)
        };
      });
    } else {
      return {
        extraSpaceTop: 0,
        extraSpaceBottom: 0,
        extraSpaceLeft: 0,
        extraSpaceRight: 0
      };
    }
  }

  function createPreviewCanvases(pageIndex: number, canvas: HTMLCanvasElement, canvasContainerWidth: number, canvasContainerHeight: number): Promise<HTMLCanvasElement> {
    return new Promise((resolve: (snapCanvas: HTMLCanvasElement) => void): void => {

      if (!previewCanvases[pageIndex]) {
        const editorCanvas = new EditorCanvas();
        previewCanvases[pageIndex] = new editorCanvas.CreateEditorCanvas(canvas, {
          backgroundColor: getCanvasBackgroundColor,
          interactive: false,
          containerClass: 'page-preview-canvas-container',
        });
      }
      const previewCanvas = previewCanvases[pageIndex];
      const width = editorPages.value ? editorPages.value.pages[pageIndex].width : 0;
      const height = editorPages.value ? editorPages.value.pages[pageIndex].height : 0;

      const layerPreviewDataList: IPreviewData[] = [];
      const extraSpace = createCanvasMargins(width);
      const sessionStore = useSessionStore();
      const base = sessionStore.currentBase;
      if (base && doesProductHaveBackLayer(base)) {
        const canvasLayers = [{ hasBackLayer: doesProductHaveBackLayer(base) },
          { hasMask: doesProductHaveMask(base) }];
        canvasLayers.forEach((canvasLayer) => {
          const layerType = canvasLayer.hasBackLayer ? EditorLayerType.BackLayer : canvasLayer.hasMask ? EditorLayerType.Mask : '';
          if (layerType) {
            const layerPreviewData = findPreviewDataBasedOnProductAttributeSelection(
              layerType,
              base,
              pageIndex,
              sessionStore.currentActiveProductSkuIdList[0]
            );
            if (layerPreviewData) {
              layerPreviewDataList.push(layerPreviewData);
            }
          }
        });
      }

      Helpers.resizeAndClipCanvas(
        previewCanvas,
        canvasContainerWidth,
        canvasContainerHeight,
        width,
        height,
        MARGIN_AROUND_PREVIEW_CANVAS,
        extraSpace,
        false
      );
      if (editorPages.value) {
        let pageElements: ElementCollection | null;
        if (pageIndex === currentPageIndex.value) {
          const editorPageFromCanvas = getEditorPageFromCanvas();
          pageElements = editorPageFromCanvas ? new ElementCollection([editorPageFromCanvas]) : null;
        } else {
          pageElements = new ElementCollection([
            editorPages.value.pages[pageIndex],
          ]);
        }
        if (pageElements) {
          EditorTranslator.Instance.boardSetter(
            previewCanvas,
            pageElements,
          );
        }
      }
      layerPreviewDataList.forEach((layerPreviewData) => {
        LayerOnCanvas.renderLayer(layerPreviewData, width, height, previewCanvas);
      });

      previewCanvas.renderAll();
      if (previewCanvas.objectsToLoad !== 0) {
        previewCanvas.on('after:render', (): void => {
          if (previewCanvas.objectsToLoad === 0) {
            previewCanvas.off('after:render');
            resolve(previewCanvas.toCanvasElement());

          }
        });
      } else {
        resolve(previewCanvas.toCanvasElement());
      }

    });
  }

  function getEditorPageFromCanvas(): IEditorPage | undefined {
    if (editorPages.value) {
      const clonedPage = structuredClone(deepToRaw<IEditorPage>(editorPages.value.pages[currentPageIndex.value]));
      clonedPage.elements = getPageElements();
      return clonedPage;
    }

    return undefined;
  }

  function getPageElements(): IEditorElement[] {
    return fabricCanvas.value ? fabricCanvas.value.getObjects().map(shape => EditorTranslator.Instance.toElement(shape as IEditorShape)) : [];
  }

  function changeOrientation(orientation: Orientation): void {
    if (fabricCanvas.value && editorPages.value) {
      EditorPages.setPageOrientation(
        editorPages.value.pages[currentPageIndex.value],
        orientation
      );
      triggerRef(editorPages);
      setupFabricCanvasFromPage(editorPages.value);
    }
  }

  function disposeCanvasEditor(): void {
    if (fabricCanvas.value) {
      unsubscribeToObjectSelectChange();
      unsubscribeToCanvasObjectChanges();
      AligningGuidelines.removeEventListeners();
      KeyboardInteraction.removeEventListeners();
      fabricCanvas.value?.wrapperEl?.removeEventListener('drop', handleDrop);
      fabricCanvas.value?.wrapperEl?.removeEventListener('dragover', handleDragOver);
      fabricCanvas.value.dispose();
      fabricCanvas.value = undefined;
    }
  }

  function disposePreviewCanvases(): void {
    previewCanvases.forEach((canvas: IEditorCanvas): void => {
      canvas.dispose();
    });
    if (editorPages.value && editorPages.value.pages) {
      for (let i = 0; i < editorPages.value.pages.length; i++) {
        Helpers.removePagePreviewCanvasElement(i);
      }
    }
    previewCanvases.splice(0, previewCanvases.length);
  }

  function clearEditorStoreValues(): void {
    unsubscribeToObjectSelectChange();
    unsubscribeToCanvasObjectChanges();
    previewDataUrls.value = [];
    refreshCanvas.value = false;
    disposePreviewCanvases();
    disposeCanvasEditor();
    currentPageIndex.value = 0;
    currentSelectedObjectIndex.value = undefined;
    canvasObjectsAreLoaded.value = false;
    editorPages.value = undefined;
  }

  function setIsTextZoneSelected(value: boolean): void {
    isTextZoneSelected.value = value;
  }

  function setIsImageZoneSelected(value: boolean): void {
    isImageZoneSelected.value = value;
  }

  function getDefaultTextFontSize(): number {
    if (editorPages.value) {
      const pageWidth = editorPages.value.pages[currentPageIndex.value].width;
      const pageHeight = editorPages.value.pages[currentPageIndex.value].height;
      const value = Math.round((Math.max(pageWidth, pageHeight) / FontSizeScale) * FontSizePercentage);
      return Helpers.clamp(FontClampLow, 2 * Math.round(value / 2), FontClampHigh);
    }
    return 0;

  }

  return {
    canvasObjectsAreLoaded,
    currentEditorPanelView,
    fabricCanvas,
    editorLayerCanvasObjects,
    isTextZoneSelected,
    isImageZoneSelected,
    currentActiveSelectionAlignment,
    currentActiveSelectionAlignmentToPrintableArea,
    currentSelectedObjectIndex,
    currentPageIndex,
    previewDataUrls,
    errorConfiguration,
    refreshCanvas,
    editorCanvasContainerWidth,
    editorCanvasContainerHeight,
    getCanvasBackgroundColor,
    getEditorPages,
    clearEditorStoreValues,
    initializeEditorCanvas,
    handleObjectsAlignment,
    getSelectedObject,
    changeObjectEditableMode,
    deleteSelectedObject,
    changeOrientation,
    alignObjectsToThePrintableArea,
    generatePagerPreviews,
    goToPage,
    getPageName,
    setEditorPagesFromProject,
    updateCurrentProjectEdits,
    setRefreshCanvas,
    setEditorCanvasSize,
    setCurrentEditorPanelView,
    setIsTextZoneSelected,
    setIsImageZoneSelected,
    setCurrentSelectedObjectIndex,
    saveCanvasObjectsState,
    undo,
    redo,
    deleteObject,
    changeCanvasObjectOrder,
    getCurrentPage,
    updateEditorLayerCanvasObjects,
    getDefaultTextFontSize
  };
});
