import { fabric } from 'fabric';
import { Image } from '@/modules/editor/fabricShapes/image';
import {
  IEditorEditorFrame,
  IEditorFrame,
  IEditorImage,
  IEffects,
  IImagePosition,
} from '@/interfaces/editorInterfaces';
import { IObjectOptions } from 'fabric/fabric-impl';
import { Helpers } from '@/modules/editor/helpers';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { useEditorImageStore } from '@/stores/editorImageStore';

interface IPhotoRect {
  width: number;
  height: number;
  left: number;
  top: number;
}

interface IPhotoPos {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export const Photo = fabric.util.createClass(Image, {
  type: 'photo',
  ghost: null,
  zoomed: false,
  previousMinimumZoom: 1,
  // cache for minimum bounding rectangle
  mbr: null,
  mbrAt: 0,

  // variable for masking
  mask: null,
  maskOf: null,

  initialize: function (
    photo: IEditorImage,
    options: {
      imagePosition: IImagePosition;
      effects: IEffects;
      fillColor: string;
      customerEditable: boolean;
    }
  ): void {
    const position = options.imagePosition;
    const rotation = options.effects.rotation || 0;

    const photoOptions = {
      width: position.width * position.zoom,
      height: position.height * position.zoom,
      left: position.x + (position.width * position.zoom) / 2,
      top: position.y + (position.height * position.zoom) / 2,
      imagePosition: position,
      flipX: options.effects.flipHorizontal,
      flipY: options.effects.flipVertical,
      angle: rotation,
      fillColor: options.fillColor,
      customerEditable: options.customerEditable,
      clipTo: function (ctx: CanvasRenderingContext2D): void {
        // save the context so that if there is a rotated, it doesn't modify the original context
        ctx.save();

        // counter any flipping
        const scaleX = (this as IObjectOptions).scaleX;
        const scaleY = (this as IObjectOptions).scaleY;

        if ((this.flipX || this.flipY) && scaleX && scaleY) {
          ctx.scale(
            scaleX * (this.flipX ? -1 : 1),
            scaleY * (this.flipY ? -1 : 1)
          );
        }

        // if the photo has an angle, the clip needs to counter-rotate
        if (this.angle) {
          ctx.rotate(fabric.util.degreesToRadians(-this.angle));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _this = this as any;

        const frame = _this.group.frameObject;
        const frameWidth = frame.getFrameWidth();
        const frameHeight = frame.getFrameHeight();
        const effects = _this.group.effects;

        // if there is a mask, we need to clip to the mask
        if (effects.mask) {
          // if the mask has not been created yet, do that first
          if (!_this.mask || _this.maskOf !== effects.mask) {
            _this.maskOf = effects.mask;
            _this.mask = new fabric.Path(_this.maskOf);
            _this.mask.fill = null;
          }

          // set mask dimensions
          _this.mask.scaleX = frameWidth / _this.mask.width;
          _this.mask.scaleY = frameHeight / _this.mask.height;
          _this.mask.left = -this.left;
          _this.mask.top = -this.top;

          // usually, borders are applied on the frame object
          // except if we have a mask, we want to draw it right on the mask
          if (effects.borderSize) {
            _this.mask.stroke = effects.borderColor;
            _this.mask.strokeWidth =
              (effects.borderSize / _this.mask.scaleY) * 2;
          } else {
            _this.mask.strokeWidth = 0;
          }

          // render the mask clip
          _this.mask.render(ctx);
        } else {
          // cleanup for mask - find a good place
          if (!effects.mask) {
            _this.mask = null;
          }

          ctx.rect(
            -this.left - frame.width / 2 + frame.getMarginLeft(),
            -this.top - frame.height / 2 + frame.getMarginTop(),
            frameWidth,
            frameHeight
          );
        }

        // restore so angle isn't applied
        ctx.restore();
      },
    };
    this.callSuper('initialize', photo, photoOptions);
  },

  getFrameWidth: function (): number {
    return this.group.frameObject.getFrameWidth();
  },

  getFrameHeight: function (): number {
    return this.group.frameObject.getFrameHeight();
  },

  getFrame: function (): IEditorEditorFrame {
    return this.group.frameObject;
  },

  /**
   * @note: either there is an image and its ready, or there is no image.
   *
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   * @param noTransform
   */
  _render: function (
    ctx: CanvasRenderingContext2D,
    noTransform: boolean
  ): void {
    // if the image is not set, or not ready, show a gray box
    if (this.image === null || !this.ready) {
      const group = this.group;
      const width = group.width;
      const height = group.height;

      // counter any previous transformation
      ctx.save();
      ctx.scale(
        this.scaleX * (this.flipX ? -1 : 1),
        this.scaleY * (this.flipY ? -1 : 1)
      );
      ctx.rotate(-fabric.util.degreesToRadians(this.angle));
      ctx.translate(-this.left, -this.top);

      // draw the rect
      ctx.beginPath();
      ctx.rect(-width / 2, -height / 2, width, height);

      ctx.closePath();
      if (this.group) {
        this.renderFillForGroup(ctx);
      } else {
        this._renderFill(ctx);
      }
      this._renderStroke(ctx);
      ctx.restore();
    } else if (this.image) {
      // firefox is sometimes failing when a higher quality thumb is substituted, and hence there is sometimes
      // a case when an image is "ready", but element.src is null
      // firefox errors out when the element.src is null, but most browsers handle it.
      if (
        this._element &&
        this._element.nodeName === 'IMG' &&
        !this._element.src
      ) {
        return;
      }

      this.callSuper('_render', ctx, noTransform);
    }

    // apply vignette if there is one
    // lots of room for optimization here (pre-calculate everything)
    //      -> lazy for now as lots of invalidations (this.left, this.top, width, height)
    const effects = this.group ? this.group.effects : this.effects;
    if (effects.vignetteOpacity) {
      const frameWidth = this.getFrameWidth();
      const frameHeight = this.getFrameHeight();
      let color = effects.vignetteColor || '#000000';
      const blur = Math.max(effects.vignetteBlur || 40, 1);
      let opacity = effects.vignetteOpacity || 100;
      const rX = frameWidth / 2;
      const rY = frameHeight / 2;

      // properties to adjust scale for an elliptical gradient
      let scaleX;
      let invScaleX;
      let scaleY;
      let invScaleY;
      let grd;

      // create radial gradient
      if (rX >= rY) {
        scaleX = 1;
        invScaleX = 1;
        scaleY = rY / rX;
        invScaleY = rX / rY;

        grd = ctx.createRadialGradient(
          -this.left,
          -this.top * invScaleY,
          0,
          -this.left,
          -this.top * invScaleY,
          rX
        );
      } else {
        scaleY = 1;
        invScaleY = 1;
        scaleX = rX / rY;
        invScaleX = rY / rX;

        grd = ctx.createRadialGradient(
          -this.left * invScaleX,
          -this.top,
          0,
          -this.left * invScaleX,
          -this.top,
          rY
        );
      }

      // if color is rgba convert it to hex
      if (color.indexOf('rgb') >= 0) {
        // convert from rgb to hex and keep opacity value
        color = EditorTranslator.rgb2hex(effects.vignetteColor);
        opacity =
          EditorTranslator.getOpacityFromRGBA(effects.vignetteColor) * 100;
        effects.vignetteColor = color;
        effects.vignetteOpacity = opacity;
      }
      // add color stops for gradient
      grd.addColorStop(
        (100 - blur) / 100,
        EditorTranslator.buildRGBA(color, 0)
      );
      //     grd.addColorStop((100 - blur) / 100, imports.SF.Util.Translator.setOpacityInRGBA(color, 0));
      grd.addColorStop(1, EditorTranslator.buildRGBA(color, opacity));
      //     grd.addColorStop(1, color);

      // draw the gradient
      ctx.save();
      ctx.fillStyle = grd;

      // counter transform
      ctx.scale(
        this.scaleX * (this.flipX ? -1 : 1),
        this.scaleY * (this.flipY ? -1 : 1)
      );
      ctx.rotate(-fabric.util.degreesToRadians(this.angle));

      // apply scale
      ctx.scale(scaleX, scaleY);

      ctx.fillRect(
        (-this.left - frameWidth / 2) * invScaleX,
        (-this.top - frameHeight / 2) * invScaleY,
        frameWidth * invScaleX,
        frameHeight * invScaleY
      );
      ctx.restore();
    }
  },

  renderFillForGroup: function (
    canvasRenderingContext2D: CanvasRenderingContext2D
  ): void {
    if (!this.fill) {
      return;
    }

    canvasRenderingContext2D.save();
    this._applyPatternGradientTransform(canvasRenderingContext2D, this.fill);
    if (this.fillRule === 'evenodd') {
      canvasRenderingContext2D.fill('evenodd');
    } else {
      canvasRenderingContext2D.fillStyle = this.fill;
      canvasRenderingContext2D.fill();
    }
    canvasRenderingContext2D.restore();
  },

  /**
   * Update internal position when we update left and top.
   *
   * @note this won't be called when initialized because position won't be set
   * @param key
   * @param value
   */
  _set: function (key: string, value: number): void {
    // if we are change the angle, but are in a group that flipped, we have to invert the angle
    // the last condition is (flipX XOR flipY), too bad that isn't a native construct..
    if (key === 'angle') {
      while (value > 180) {
        value -= 360;
      }

      while (value < -180) {
        value += 360;
      }

      if (
        this.group &&
        (this.group.flipX ? !this.group.flipY : this.group.flipY)
      ) {
        value = -value;
      }
    }

    // if we have an image ghosting this object, we need to proxy certain values
    if (
      this.ghost &&
      ['top', 'left', 'width', 'height', 'angle'].indexOf(key) >= 0
    ) {
      this.ghost.set(key, value);
    }

    if (this.imagePosition) {
      if (key === 'left') {
        this.imagePosition.x = value - this.width / 2;
      } else if (key === 'top') {
        this.imagePosition.y = value - this.height / 2;
      } else if (key === 'width') {
        this.imagePosition.x = this.left - value / 2;
      } else if (key === 'height') {
        this.imagePosition.y = this.top - value / 2;
      }
    }

    this.callSuper('_set', key, value);
  },

  /**
   * Handles the pan functionality.
   *
   * @param dX
   * @param dY
   */
  pan: function (dX: number, dY: number): void {
    let total;

    if (!this.lockMovementX) {
      total = this.left + dX;

      if (this.doesFit(total)) {
        this.set('left', total);
      }
    }

    if (!this.lockMovementY) {
      total = this.top + dY;

      if (this.doesFit(null, total)) {
        this.set('top', total);
      }
    }
  },

  /**
   * Handles the zoom functionality
   *
   */
  zoom: function (delta: number, bestFit: boolean): void {
    if (!this.image) {
      return;
    }
    const newZoom = this.imagePosition.zoom + delta;
    const newWidth = this.imagePosition.width * newZoom;
    const newHeight = this.imagePosition.height * newZoom;
    const newLeft = this.left;
    const newTop = this.top;
    const fit = bestFit === undefined ? true : bestFit;
    // we only need to perform a best fit when zooming out
    if (fit && delta < 0) {
      const o = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop,
      };

      // ensure it fits
      const doesFit = this.doesFit(newLeft, newTop, newWidth, newHeight, o);
      if (this.angle != 0) {
        // _moveAwayFromEdge works only for 90,180,270 angles (it reposition the image if it doesn't fit)...
        if (doesFit || [90, 180, 270].indexOf(Math.abs(this.angle)) >= 0) {
          // set the new data
          this.set(o);
          this.imagePosition.zoom = newZoom;
        }
      } else {
        // set the new data
        this.set(o);
        // also, adjust the zoom
        this.imagePosition.zoom = doesFit
          ? newZoom
          : o.width / this.imagePosition.width;
      }
    } else {
      this.set('width', newWidth);
      this.set('height', newHeight);
      this.imagePosition.zoom = newZoom;
    }
  },

  innerRotate: function (angle: number): void {
    const angleRange: number = Math.abs(this.angle - angle);
    const angleIncrement = angle > this.angle ? 1 : -1;
    this.group.canvas._currentZoom = null;

    //Rotate photo calls a function fit. It is only effective with in a certain range. about 5 degreesof rotation.
    // The loop is a patch. the correct solution would be to increase the fit functions range
    for (let i = 0; i < angleRange; i++) {
      this.setCurrentZoom();
      this.rotatePhoto(this.angle + angleIncrement);
    }
    this.setCurrentZoom();

    this.rotatePhoto(angle);

    this.group.canvas._currentZoom = null;
    const imageStore = useEditorImageStore();
    imageStore.handleImageEditsChange();
  },

  rotatePhoto: function (angle: number): void {
    // rotate around frame center
    const prevLeft = this.left;
    const prevTop = this.top;
    const previousAngle: number = this.angle;

    // figure out center
    const p = new fabric.Point(prevLeft, prevTop);
    const origin = new fabric.Point(0, 0);
    const rotated = fabric.util.rotatePoint(
      p,
      origin,
      fabric.util.degreesToRadians(angle - previousAngle)
    );

    this.set({
      left: rotated.x,
      top: rotated.y,
      angle: angle,
    });

    // now that the angle is set, we need to make sure the photo is always inside the inner window
    const fit = this.fit();

    // if we have zoomed in during the current innerRotation (current = since mouse down), and
    // we now have room to zoom out, then do so until we reach the last zoom.
    if (fit && this.group.canvas) {
      this.zoomOutUntilPreviousZoom();
    }
  },

  setCurrentZoom: function (): void {
    if (!this.group.canvas._currentZoom) {
      this.group.canvas._currentZoom = this.group.userZoom;
    } else {
      this.group.canvas._currentZoom = Math.min(
        this.group.canvas._currentZoom,
        this.group.getZoom()
      );
    }
  },

  /**
   * Very simple utility function that zooms out until we get a close match to before
   * the current transformation started.
   */
  zoomOutUntilPreviousZoom: function (): void {
    // if there is current transformation set, don't bother
    let previousZoom = this.group.canvas._currentZoom;
    const minZoom = this.getMinZoom();

    if (this.group.canvas._currentTransform) {
      previousZoom = this.group.canvas._currentTransform.zoom;
    }

    if (
      !previousZoom ||
      previousZoom >= this.imagePosition.zoom ||
      minZoom === this.imagePosition.zoom
    ) {
      return;
    }

    this.zoom(Math.max(minZoom, previousZoom) - this.imagePosition.zoom, false);
  },

  setPreviousZoom: function (): void {
    // if there is current transformation set, don't bother
    this.previousMinimumZoom = this.imagePosition.zoom;
  },

  getMinZoom: function (): number {
    const box = this.getMinimumBoundingRectangle(
      this.angle,
      this.left,
      this.top
    );
    const w = box[1] - box[0];
    const h = box[3] - box[2];
    return (
      1 /
      Helpers.zoomToFit(
        w,
        h,
        this.imagePosition.width,
        this.imagePosition.height
      )
    );
  },

  fit: function (): boolean {
    const photoRect: IPhotoRect = { left: 0, top: 0, width: 0, height: 0 };
    const fit = this.doesFit(null, null, null, null, photoRect);

    if (!fit && photoRect.width != 0 && photoRect.height != 0) {
      this.set(photoRect);
      // if size has changed, set zoom
      if (photoRect.width) {
        this.imagePosition.zoom = photoRect.width / this.imagePosition.width;
      }
    } else if (!fit && photoRect.width == 0 && photoRect.height == 0) {
      this.set({
        top: 0,
        left: 0,
      });
    }

    return fit;
  },

  /**
   * Big function that ensures the inner window of (left, top, width, height) fits inside a rotated window.  If photoRect is passed in, it is updated
   *
   * The function is further complicated because you can pass in an object in which it will set left / top
   * maxima's.
   */
  doesFit: function (
    newLeft?: number,
    newTop?: number,
    newWidth?: number,
    newHeight?: number,
    photoRect?: IPhotoRect
  ): boolean {
    const left = Helpers.round(newLeft || this.left, -2);
    const top = Helpers.round(newTop || this.top, -2);
    const width = Helpers.round(newWidth || this.width, -2);
    const height = Helpers.round(newHeight || this.height, -2);

    // if we don't have any rotation, re-direct to a much more simpler function
    // secondary reason is that it does a much better job at re-position if no fit is found.
    if (!this.angle) {
      return this.simpleDoesFit(left, top, width, height, photoRect);
    }

    // outer rectangle (without rotation)
    const outerWidth = width;
    const outerHeight = height;
    const outerX = Helpers.round(-width / 2, -2);
    const outerY = Helpers.round(-height / 2, -2);

    // get minimum bounding rectangle
    const mbr = this.getMinimumBoundingRectangle(this.angle, left, top);

    // does it fit?
    const leftFit = outerX <= mbr[0];
    const rightFit = outerX + outerWidth >= mbr[1];
    const topFit = outerY <= mbr[2];
    const bottomFit = outerY + outerHeight >= mbr[3];
    const doesFit = leftFit && rightFit && topFit && bottomFit;

    // optimized check if we are not passing in an object to do best fit, or if it already fits
    if (doesFit || !photoRect) {
      return doesFit;
    }

    // at this point, the inner image did not fit, we have to do one of the following:
    // 1) If resize only is set, we need to increase the size of the inner window to fit (not to the mbr) (@todo)
    // 2) If the window is smaller than the mbr, increase whole photo size
    // 3) Re-position photo so it fits by calling _moveAwayFromEdge

    // first check: ensure width and height is adequate
    const innerRotatedWidth = mbr[1] - mbr[0];
    const innerRotatedHeight = mbr[3] - mbr[2];

    if (innerRotatedWidth > outerWidth || innerRotatedHeight > outerHeight) {
      // set object with the best minimum best fit

      const zoom =
        1 /
        Helpers.zoomToFit(
          innerRotatedWidth,
          innerRotatedHeight,
          this.imagePosition.width,
          this.imagePosition.height
        );
      photoRect.width = this.imagePosition.width * zoom;
      photoRect.height = this.imagePosition.height * zoom;

      photoRect.left = 0;
      photoRect.top = 0;
    }

    this._moveAwayFromEdge(
      leftFit,
      topFit,
      rightFit,
      bottomFit,
      left,
      top,
      mbr,
      outerX,
      outerY,
      outerWidth,
      outerHeight,
      photoRect
    );

    return false;
  },

  /**
   * Move towards the correct edge.
   *
   * @param {Boolean} leftFit
   * @param {Boolean} topFit
   * @param {Boolean} rightFit
   * @param {Boolean} bottomFit
   * @param {Number} left
   * @param {Number} top
   * @param {Number} mbr
   * @param {Number} outerX
   * @param {Number} outerY
   * @param {Number} outerWidth
   * @param {Number} outerHeight
   * @param {IPhotoRect} o
   * @private
   */
  _moveAwayFromEdge: function (
    leftFit: boolean,
    topFit: boolean,
    rightFit: boolean,
    bottomFit: boolean,
    left: number,
    top: number,
    mbr: number[],
    outerX: number,
    outerY: number,
    outerWidth: number,
    outerHeight: number,
    o: IPhotoRect
  ): void {
    // try to actually move towards an edge
    const minX = mbr[0];
    const maxX = mbr[1];
    const minY = mbr[2];
    const maxY = mbr[3];
    const angle = this.angle < 0 ? 360 + this.angle : this.angle;
    // For now we just zoom out if we are rotating on a non right angle TODO:
    if (angle == 90 || angle == 180 || angle == 270 || angle == 0) {
      if (!leftFit || !rightFit) {
        let diffX = !leftFit ? outerX - minX : outerX + outerWidth - maxX;

        // do we need to invert diffX?
        diffX *= angle < 180 ? -1 : 1;

        if ((angle >= 0 && angle < 90) || (angle >= 180 && angle < 270)) {
          o.left = left + diffX;
        } else {
          o.top = top + diffX;
        }
      }

      if (!topFit || !bottomFit) {
        let diffY = !topFit ? outerY - minY : outerY + outerHeight - maxY;

        // do we need to invert diffY?
        diffY *= (angle >= 0 && angle < 90) || angle >= 270 ? -1 : 1;

        if ((angle >= 0 && angle < 90) || (angle >= 180 && angle < 270)) {
          o.top = top + diffY;
        } else {
          o.left = left + diffY;
        }
      }

      // if more than one edge collides, re-do
      if (
        (leftFit ? 0 : 1) +
          (topFit ? 0 : 1) +
          (rightFit ? 0 : 1) +
          (bottomFit ? 0 : 1) >
        1
      ) {
        this.doesFit(o.left, o.top, o.width, o.height, o);
      }
    }
    //console.log('DOES NOT FIT: ' + (!leftFit ? 'left ' : '') + (!topFit ? 'top ' : '') + (!rightFit ? 'right ' : '') + (!bottomFit ? 'bottom' : ''));

    // this is a refactor of what we have below, commented out right now as this still doesn't work when
    // more than two edges collide

    // if there is a current transformation, we need to modify some details
    const t = this.group.canvas ? this.group.canvas._currentTransform : null;
    if (t) {
      if (o.left) {
        t.innerLeft = o.left;
      }

      if (o.top) {
        t.innerTop = o.top;
      }
    }
  },

  getMinimumBoundingRectangle: function (
    angle: number,
    left: number,
    top: number
  ): number {
    const frame = this.getFrame();
    const w = frame.width;
    const h = frame.height;
    const mL = frame.getMarginLeft();
    const mT = frame.getMarginTop();
    const mR = frame.getMarginRight();
    const mB = frame.getMarginBottom();

    if (
      this.mbr !== null &&
      this.mbrAt === angle + left + top + w + h + mL + mT + mR + mB
    ) {
      return this.mbr;
    }

    this.mbr = Helpers.boundingBox(angle, w, h, left, top, mL, mT, mR, mB);
    this.mbrAt = angle + left + top + w + h + mL + mT + mR + mB;

    return this.mbr;
  },

  /**
   * Much more efficient and faster does fit for images that don't have any rotation.  If photoRect is passed in, it gets set.
   *
   * @param left
   * @param top
   * @param width
   * @param height
   * @param photoRect
   */
  simpleDoesFit: function (
    left: number,
    top: number,
    width: number,
    height: number,
    photoRect?: IPhotoRect
  ): boolean {
    // measure the maxima's on both sides
    const frame = this.getFrame();
    let box = this._getFrameBox(width, height, frame);
    const fits =
      left >= box.right &&
      left <= box.left &&
      top >= box.bottom &&
      top <= box.top;

    // perform best fit if necessary
    if (!fits && photoRect) {
      const frameWidth = frame.getFrameWidth();
      const frameHeight = frame.getFrameHeight();

      // if the image becomes smaller than the window, re-set size to minimum bounding rectangle
      if (width < frameWidth || height < frameHeight) {
        const zoom =
          1 /
          Helpers.zoomToFit(
            frameWidth,
            frameHeight,
            this.imagePosition.width,
            this.imagePosition.height
          );
        photoRect.width = this.imagePosition.width * zoom;
        photoRect.height = this.imagePosition.height * zoom;

        // because the width and height has changed, we need to redefine maximas
        box = this._getFrameBox(photoRect.width, photoRect.height, frame);
      }

      if (left < box.right) {
        photoRect.left = box.right;
      } else if (left > box.left) {
        photoRect.left = box.left;
      }

      if (top < box.bottom) {
        photoRect.top = box.bottom;
      } else if (top > box.top) {
        photoRect.top = box.top;
      }
    }

    return fits;
  },

  _getFrameBox: function (
    width: number,
    height: number,
    frame: IEditorEditorFrame
  ): IPhotoPos {
    const centerX = (width - (frame.width || 0)) / 2; // Mobile can't properly resolve IEditorEditorFrame.. TODO: FIX IN MOBILE...
    const centerY = (height - (frame.height || 0)) / 2;
    const widthDiff = width - frame.getFrameWidth();
    const heightDiff = height - frame.getFrameHeight();
    const left =
      widthDiff === 0 ? 0 : Helpers.round(centerX + frame.getMarginLeft());
    const right =
      widthDiff === 0 ? 0 : Helpers.round(-centerX - frame.getMarginRight());
    const top =
      heightDiff === 0 ? 0 : Helpers.round(centerY + frame.getMarginTop());
    const bottom =
      heightDiff === 0 ? 0 : Helpers.round(-centerY - frame.getMarginBottom());
    return {
      left: left,
      right: right,
      top: top,
      bottom: bottom,
    };
  },

  toObject: function (propertiesToInclude: never): never[] {
    const images = fabric.util.object.extend(
      this.callSuper('toObject', propertiesToInclude),
      {
        image: this.image,
        effects: this.effects ? fabric.util.object.clone(this.effects) : null,
      }
    );

    delete images.src;

    return images;
  },

  replaceImage: function (
    image: IEditorImage,
    reset: boolean,
    callback: () => void
  ): void {
    const self = this as {
      group: IEditorFrame;
      placePhoto: () => void;
    };

    // actually replace image
    this.callSuper('replaceImage', image, reset, (): void => {
      if (reset && self.group && self.group.type == 'group') {
        self.group.resetPhoto();
      } else {
        // else, place photo
        // @note: re-setting photo already place's it.
        self.placePhoto();
      }

      // call the passed in callback
      callback();
    });
  },

  placePhoto: function (): void {
    const frame = this.getFrame();

    const position = EditorTranslator.Instance.getImagePosition(
      frame.width,
      frame.height,
      frame.getMarginLeft(),
      frame.getMarginTop(),
      frame.getMarginRight(),
      frame.getMarginBottom(),
      this.image,
      this.angle // this is the photo objects angle, so inner rotation!
    );

    // set image position
    this.imagePosition = position;

    // set properties -- stolen from constructor
    this.set({
      width: position.width * position.zoom,
      height: position.height * position.zoom,
      left: 0,
      top: 0,
    });
  },
});
