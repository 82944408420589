import {
  IEditorElement,
  IEditorElementCollection,
  IEditorPage,
} from '@/interfaces/editorInterfaces';

export class ElementCollection implements IEditorElementCollection {
  public width: number;
  public height: number;
  // noinspection JSMismatchedCollectionQueryUpdate - this is a false positive by Intellij, this is used in a ton of places...
  public visiblePages: IEditorPage[] = [];
  public elements: IEditorElement[] = [];

  constructor(public pages: IEditorPage[], showingIndex?: number) {
    showingIndex = this.isInteger(showingIndex) ? showingIndex : undefined;
    this.width = 0;
    this.height = 0;

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      this.height = Math.max(page.height, this.height);
      if (showingIndex === undefined || showingIndex == page.index) {
        this.width += page.width;
        this.visiblePages.push(page);
      }

      this.elements = this.elements.concat(page.elements);
    }
  }

  private isInteger(value: string | number | undefined): boolean {
    return (
      typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  }
}
