import { defineStore } from 'pinia';
import {
  ICatalogItem,
  ISuperSkuAttribute,
  ISuperSkuProduct,
  ISuperSkuProductAttribute
} from '@/interfaces/catalogInterfaces';
import { computed, ref } from 'vue';
import { PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels } from '@/constants/topicEnums';
import { IAttributeList, IProductInfo } from '@/interfaces/baseInterface';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import * as productInfoHelpers from '@/helpers/productInfoHelpers';
import { isAnyProduct, isCategory, isProduct, isSuperSku } from '@/helpers/catalogHelpers';
import { ISolaceClient } from '@/interfaces/iSolaceClient';


export const useCatalogStore = defineStore('catalog', () => {
  const _catalog = ref<ICatalogItem[]>([]);
  const _categoriesMap = new Map<number, ICatalogItem>();
  const _bases = ref(new Map<number, IProductInfo>());
  const _subscribedListOfBases: number[] = [];
  const _receivedListOfBases: number[] = [];
  let _solaceClient: ISolaceClient | undefined;
  const _solaceReady:Promise<void> = new Promise<void>((resolve, reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then((solaceClient) => {
      _solaceClient = solaceClient;
      resolve();
    }, (err: Error): void => {
      reject(err);
    });
  });

  const highestLevelCategories = computed<ICatalogItem[]>(
    (): ICatalogItem[] => {
      if (rootCatalogItem.value) {
        return _catalog.value.filter(
          (catalogItem: ICatalogItem) =>
            !isAnyProduct(catalogItem) &&
            catalogItem.visible &&
            catalogItem.parent_id === (rootCatalogItem.value as ICatalogItem).id
        ).toSorted((a, b) => a.position - b.position);
      }
      return [];
    }
  );

  const rootCatalogItem = computed((): ICatalogItem | null => {
    return _catalog.value.find(
      (catalogItem: ICatalogItem) => catalogItem.parent_id === 0
    ) || null;
  });


  const bases = computed((): Map<number, IProductInfo> => {
    return _bases.value;
  });

  function getCatalog(): ICatalogItem[] {
    return _catalog.value;
  }


  function getCategoryById(id: number): ICatalogItem | null {
    if (_categoriesMap.has(id)) {
      return _categoriesMap.get(id) || null;
    }
    return null;
  }

  function getProductInfo(catalogItem: ICatalogItem): IProductInfo | null {
    if (isProduct(catalogItem)) {
      return _bases.value.get(catalogItem.id) || null;
    }
    return null;
  }


  function getSuperSkuProductInfos(catalogItem: ICatalogItem): IProductInfo[] {
    const productInfos: IProductInfo[] = [];
    if (isSuperSku(catalogItem)) {
      catalogItem.attributes?.forEach((superSkuAttribute: ISuperSkuAttribute) => {
        superSkuAttribute.values.forEach((superSkuProductAttribute: ISuperSkuProductAttribute) => {
            superSkuProductAttribute.products.forEach((superSkuProduct: ISuperSkuProduct) => {
              const productInfo = _bases.value.get(superSkuProduct.id);
              if (productInfo) {
                productInfos.push(productInfo);
              }
            });
          }
        );
      });
    }
    return productInfos;
  }

  function getAnyProductInfo(catalogItem: ICatalogItem): IProductInfo | null {
    return isProduct(catalogItem)
      ? getProductInfo(catalogItem)
      : isSuperSku(catalogItem)
        ? getSuperSkuProductInfos(catalogItem)[0]
        : null;
  }

  function getCatalogProductAttributesDisplayValues(
    catalogItem: ICatalogItem,
    attributes: IAttributeList,
    language: string
  ): string[] {
    const attributeTranslatedValues: string[] = [];
    if (isAnyProduct(catalogItem)) {
      const productInfo = getAnyProductInfo(catalogItem);
      if (productInfo) {
        return productInfoHelpers.getAttributesDisplayValues(productInfo, attributes, language);
      }
    }
    return attributeTranslatedValues;
  }

  function getCatalogProductAttributesDisplayName(
    catalogItem: ICatalogItem,
    attributes: IAttributeList,
    language: string
  ): string {
    if (isAnyProduct(catalogItem)) {
      const productInfo = getAnyProductInfo(catalogItem);
      if (productInfo) {
        return productInfoHelpers.getAttributesDisplayName(productInfo, attributes, language);
      }
    }
    return '';
  }

  function subscribeToCatalogResults(): void {
    _solaceReady.then(() => {
      if (!_solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }

      const categoriesSub = _solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_CATEGORIES_CURRENT);

      if (categoriesSub) {
        _solaceClient.mapSubscriptionJson<ICatalogItem[]>(categoriesSub, payload => {
          for (const catalogItem of payload) {
            if (isCategory(catalogItem)) {
              _categoriesMap.set(catalogItem.id, catalogItem);
            }
          }
          _catalog.value = payload;
        });
      }
    }, err => {
      console.error('Solace ready error', err);
    });
  }

  function subscribeToBaseResults(baseId: number): void {
    _solaceReady.then(() => {
      if (!_solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const baseCurrentSub = _solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_BASE_CURRENT);
      if (baseCurrentSub) {
        const replacedBaseCurrentSub = baseCurrentSub.replace(TopicVarLevels.BASE_ID, baseId.toString());
        _subscribedListOfBases.push(baseId);
        _solaceClient.mapSubscriptionJson<IProductInfo>(replacedBaseCurrentSub, payload => {
          if (!_bases.value.has(payload.id)) {
            _receivedListOfBases.push(payload.id);
          }
          _bases.value.set(payload.id, payload);
        });
      }
    }, err => {
      console.error('Solace ready error', err);
    });
  }

  function getCacheForCatalogResults(): Promise<void> {
    return new Promise((resolve, reject) => {
      _solaceReady.then(() => {
        if (!_solaceClient) {
          reject('Solace client is not defined -- Error');
        } else {
          const catalogSub = _solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_CATEGORIES_CURRENT);
          _solaceClient.checkCache(catalogSub).then(() => {
            resolve();
          }, err => {
            reject(err);
          });
        }
      }, err => {
        reject(err);
      });
    });
  }

  function getCacheForBaseResults(baseId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      _solaceReady.then(() => {
        if (!_solaceClient) {
          reject(new Error('Solace client is not defined -- Error'));
        } else {
          const baseSub = _solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_BASE_CURRENT);
          const replacedBaseSub = baseSub.replace(TopicVarLevels.BASE_ID, baseId.toString());
          _solaceClient.checkCache(replacedBaseSub).then(() => {
            resolve();
          }, err => {
            reject(err);
          });
        }
      }, err => {
        reject(err);
      });
    });
  }

  function queryCatalog(): void {
    _solaceReady.then(() => {
      if (!_solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = _solaceClient.getPubTopic(PublishTopicKeys.PUB_CATALOG_CATEGORIES_TRIGGER);
      if (reqTopic) {
        _solaceClient.publishMessageString(reqTopic, undefined, err => {
          console.warn('Error publishing catalog categories trigger', err);
        });
      } else {
        console.warn(`Could not find topic for query categories: ${PublishTopicKeys.PUB_CATALOG_CATEGORIES_TRIGGER}`);
      }
    }, err => {
      console.error('Solace ready error', err);
    });
  }

  function queryBase(baseId: number): void {
    _solaceReady.then(() => {
      if (!_solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = _solaceClient.getPubTopic(PublishTopicKeys.PUB_CATALOG_BASE_TRIGGER);
      const replacedReqTopic = reqTopic.replace(TopicVarLevels.BASE_ID, baseId.toString());

      if (replacedReqTopic) {
        _solaceClient.publishMessageString(replacedReqTopic, undefined, err => {
          console.warn('Error publishing base trigger', err);
        });
      } else {
        console.warn(`Could not find topic for query base #${baseId}: ` + PublishTopicKeys.PUB_CATALOG_BASE_TRIGGER);
      }
    }, err => {
      console.error('Solace ready error', err);
    });
  }

  function requestCatalog() {
    if (_catalog.value.length === 0) {
      subscribeToCatalogResults();
      getCacheForCatalogResults().then(() => {
        console.debug('Successfully retrieved categories from cache');
      }).catch(error => {
        console.debug(error);
        queryCatalog();
      });
    }
  }

  function requestBasesById(baseIds: number[]) {
    baseIds.forEach(baseId => {
      if (!_subscribedListOfBases.includes(baseId)) {
        subscribeToBaseResults(baseId);
      }

      if (!_receivedListOfBases.includes(baseId)) {
        getCacheForBaseResults(baseId).then(() => {
          console.debug(`Successfully retrieved base #${baseId} from cache`);
        }).catch(error => {
          console.debug(error);
          queryBase(baseId);
        });
      }
    });
  }

  function getChildrenCatalogItems(catalogItem: ICatalogItem): ICatalogItem[] {
    const parentId = catalogItem.id;

    return _catalog.value.filter(
      (item: ICatalogItem) =>
        isCategory(catalogItem) &&
        item.visible &&
        item.parent_id === parentId
    );

  }

  function getChildrenCategories(catalogItem: ICatalogItem): ICatalogItem[] {
    const parentId = catalogItem.id;

    return _catalog.value.filter(
      (item: ICatalogItem) =>
        isCategory(item) &&
        item.visible &&
        item.parent_id === parentId
    );

  }


  function isNonProductSubCategory(catalogItem: ICatalogItem): boolean {
    return getChildrenCategories(catalogItem).length > 0;
  }

  return {
    highestLevelCategories,
    rootCatalogItem,
    requestCatalog,
    getCatalog,
    bases,
    getCategoryById,
    getChildrenCatalogItems,
    requestBasesById,
    isNonProductSubCategory,
    getProductInfo,
    getCatalogProductAttributesDisplayValues,
    getCatalogProductAttributesDisplayName,
    getSuperSkuProductInfos
  };
});
