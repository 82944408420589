/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@/styles/main.scss';
// make sure to also import the coresponding css
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'; // Ensure you are using css-loader

// Composables
import { createVuetify } from 'vuetify';



export const printSuiteTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#f34490',
    'primary-darken-1': '#da3d81',
    secondary: '#707070',
    'secondary-darken-1': '#505050',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'incomplete-state': '#D3D3D3',
    'light-background-color': '#f2f2f2',
    'chip-selected-color': '#c6dcef',
    'chip-selected-color-light': '#f0f8ff',
    'editor-button-active-color': '#c6dcef',
    'editor-button-active-color-darker': '#6f93b3',
    'switch-active-color': '#1976D2',
    'editor-icons-color': '#549ACF',
    'specific-design-circle-color': '#77dd77',
    'chip-text-attribute-color': '#f2f2f2',
    'chip-text-color': '#000000',
    'chip-filter-color': '#707070',
    'expansion-panel-border-color': '#cccccc',
    'default-icon-color': '#221D1F',
    'on-surface': '#221D1F',
    'on-background': '#221D1F',

    // These might
    'landing-secondary-background': '#f7f7f7',
    'landing-accent-background': '#ffd6e4'
},
  variables: {
    'theme-primary-overlay-multiplier': 2,
    'high-emphasis-opacity': 1,
    'button-hover-opacity': 0.62
  }
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'printSuiteTheme',
    themes: {
      printSuiteTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});
