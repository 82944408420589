export interface IButton {
  action?: () => void;
  label: string;
  variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain';
  color?: string;
}

export interface IQuestion {
  value: string;
  title: string;
  buttons: {
      value: string;
      label: string;
      size: string;
    }[];
}

export enum AppBarBreadCrumbState {
  Design = 'Design',
  Mockups = 'Mockups',
  Pricing = 'Pricing',
  Details = 'Details',
}
