/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

import { loadFonts } from './webfontloader';
import { App } from 'vue';

export function registerPlugins(app?: App) {
  console.log('Registering plugins for app', app);
  loadFonts().catch(err => {
    if (err) {
      console.error('Failed to load fonts', err);
    }
  });
}
