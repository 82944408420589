import { ICurrency } from '@/interfaces/tenantInterface';

export function format(priceInPennies: number, currentCurrency: ICurrency): string {
  return doFormat(priceInPennies, currentCurrency.formatSetting, currentCurrency);
}

export function unFormat(price: string, currentCurrency: ICurrency): number {
  return Math.round(parseFloat(price.replaceAll(currentCurrency.symbol, '')) * currentCurrency.cents_per_dollar);
}

export function valueInDollar(priceInPennies: number, currentCurrency: ICurrency): string {
  const amount: number = priceInPennies / currentCurrency.cents_per_dollar;
  let newAmount: string;

  if (currentCurrency.cents_per_dollar == 100) {
    newAmount = amount.toFixed(2);
  } else {
    newAmount = amount.toFixed(0);
  }
  return newAmount;
}

export function stringPriceToFloatConverter(price: string, currency: ICurrency): number {
  return parseFloat(price.replaceAll(currency.symbol, ''));
}

function doFormat(priceInPennies: number, formatPreference: 'NUMERIC'|'SYM'|'ISO'|'ISO+SYM', currentCurrency: ICurrency): string {
  const amount: number = priceInPennies / currentCurrency.cents_per_dollar;
  let newAmount: string;

  if (currentCurrency.cents_per_dollar == 100) {
    newAmount = amount.toFixed(2);
  } else {
    newAmount = amount.toFixed(0);
  }

  switch (formatPreference) {
    case 'NUMERIC':
      return newAmount;
    case 'ISO':
      return currentCurrency.iso + ' ' + newAmount;
    case 'ISO+SYM':
      return currentCurrency.iso + ' ' + currentCurrency.symbol + newAmount;
    case 'SYM':
    default:
      return currentCurrency.symbol + newAmount;
  }
}

