// noinspection JSUnusedGlobalSymbols

import { App } from 'vue';
import { Router } from 'vue-router';


// See https://developer.matomo.org/api-reference/tracking-javascript
export interface IMatomo {


  addDownloadExtensions(extensions: string | string[]): void;

  addEcommerceItem(productSKU: string, productName?: string, productCategory?: string, price?: number, quantity?: number): void;

  addListener(e: HTMLElement): void;

  areCookiesEnabled(): boolean;

  clearEcommerceCart(): void;

  deleteCookies(): void;

  deleteCustomVariable(index: number, scope?: string): void;

  disableAlwaysUseSendBeacon(): void;

  disableBrowserFeatureDetection(): void;

  disableCampaignParameters(): void;

  disableCookies(): void;

  disableHeartBeatTimer(): void;

  disablePerformanceTracking(): void;

  disableQueueRequest(): void;

  discardHashTag(discard: boolean): void;

  enableBrowserFeatureDetection(): void;

  enableCrossDomainLinking(): void;

  enableFileTracking(): void;

  enableHeartBeatTimer(activeMilliseconds: number): void;

  enableJSErrorTracking(): void;

  enableLinkTracking(enabled?: boolean): void;

  forgetConsentGiven(): void;

  forgetCookieConsentGiven(): void;

  forgetUserOptOut(): void;

  getAttributionCampaignKeyword(): string;

  getAttributionCampaignName(): string;

  getAttributionInfo(): string;

  getAttributionReferrerTimestamp(): number;

  getAttributionReferrerUrl(): string;

  getCrossDomainLinkingUrlParameter(): string;

  getCurrentUrl(): string;

  getCustomData(): string;

  getCustomVariable(index: number, scope?: string): string;

  getEcommerceItems(): string[];

  getLinkTrackingTimer(): number;

  getMatomoUrl(): string;

  getRememberedConsent(): number | undefined;

  getRememberedCookieConsent(): number | undefined;

  getSiteId(): string;

  getTrackerUrl(): string;

  getUserId(): string;

  getVisitorId(): string;

  getVisitorInfo(): string[];

  hasConsent(): boolean;

  hasCookies(): boolean;

  hasRememberedConsent(): boolean;

  isConsentRequired(): boolean;

  isCrossDomainLinkingEnabled(): boolean;

  isUserOptedOut(): boolean;

  killFrame(): void;

  logAllContentBlocksOnPage(): void;

  optUserOut(): void;

  ping(): void;

  redirectFile(url: string): void;

  rememberConsentGiven(hoursToExpire: number): void;

  rememberCookieConsentGiven(hoursToExpire: number): void;

  removeDownloadExtensions(extension: string | string[]): void;

  removeEcommerceItem(productSku: string): void;

  requireConsent(): void;

  requireCookieConsent(): void;

  resetUserId(): void;

  setAPIUrl(url: string): void;

  setCampaignKeywordKey(keyword: string): void;

  setCampaignNameKey(name: string): void;

  setConsentGiven(): void;

  setCookieConsentGiven(): void;

  setCookieDomain(domain: string): void;

  setCookieNamePrefix(prefix: string): void;

  setCookiePath(path: string): void;

  setCookieSameSite(value: 'Lax' | 'None' | 'Strict'): void;

  setCustomUrl(url: string): void;

  setCustomVariable(index: number, name: string, value: string, scope?: string): void;

  setDoNotTrack(value: boolean): void;

  setDocumentTitle(title: string): void;

  setDomains(domains: string | string[]): void;

  setDownloadClasses(classes: string | string[]): void;

  setDownloadExtensions(extensions: string | string[]): void;

  setEcommerceView(productSKU: string, productName?: string, productCategory?: string, price?: number): void;

  setExcludedQueryParams(params: string | string[]): void;

  setExcludedReferrers(referrers: string | string[]): void;

  setIgnoreClasses(classes: string | string[]): void;

  setLinkClasses(classes: string | string[]): void;

  setLinkTrackingTimer(milliseconds: number): void;

  setPagePerformanceTiming(networkTimeMs?: number, serverTimeMs?: number, transferTimeMs?: number, domProcessingTimeMs?: number): void;

  setPageViewId(id: string): void;

  setReferralCookieTimeout(timeoutMs: number): void;

  setReferrerUrl(url: string): void;

  setRequestContentType(contentType: string): void;

  setRequestMethod(method: string): void;

  setRequestQueueInterval(interval: number): void;

  setSecureCookie(secure: boolean): void;

  setSessionCookieTimeout(timeoutMs: number): void;

  setSiteId(id: string): void;

  setTrackerUrl(url: string): void;

  setUserId(userId: string): void;

  setVisitorCookieTimeout(timeoutMs: number): void;

  setVisitorId(visitorId: string): void;

  storeCustomVariablesInCookie(): void;

  trackAllContentImpressions(): void;

  trackContentImpression(contentName: string, contentPiece: string, contentTarget: string): void;

  trackContentImpressionsWithinNode(node: HTMLElement): void;

  trackContentInteraction(contentInteraction: string, contentName: string, contentPiece: string, contentTarget: string): void;

  trackContentInteractionNode(node: HTMLElement, contentInteraction: string): void;

  trackEcommerceCartUpdate(grandTotal: number): void;

  trackEcommerceOrder(orderId: string, grandTotal: number, subTotal?: number, tax?: number, shipping?: number, discount?: number): void;

  trackEvent(category: string, action: string, name?: string, value?: number, details?: {[key:string]:string}): void;

  trackGoal(idGoal: number, customRevenue?: number): void;

  trackLink(url: string, linkType: string): void;

  trackPageView(customTitle?: string): void;

  trackSiteSearch(keyword: string, category?: string, resultsCount?: string): void;

  trackVisibleContentImpressions(checkOnScroll?: boolean, timeInterval?: number): void;

}

declare global {
  interface Window {
    _paq: string[][];
    Piwik: {
      getAsyncTracker():IMatomo;
    };
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IMatomoOptions {
  host: string;
  siteId: string;
  debug?: boolean;
  disableCookies?: boolean;
  requireCookieConsent?: boolean;
  enableHeartBeatTimer?: boolean;
  enableLinkTracking?: boolean;
  heartBeatTimerInterval?: number;
  requireConsent?: boolean;
  trackInitialView?: boolean;
  trackSiteSearch?: any;
  trackerFileName?: string;
  trackerUrl?: string;
  trackerScriptUrl?: string;
  userId?: string;
  cookieDomain?: string;
  domains?: string[];
  preInitActions?: string[];
  crossOrigin?: string;
  router?: Router;
}

const defaultOptions = {
  debug: false,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  enableLinkTracking: true,
  heartBeatTimerInterval: 15,
  requireConsent: false,
  trackInitialView: true,
  trackSiteSearch: false,
  trackerFileName: 'matomo',
  trackerUrl: undefined,
  trackerScriptUrl: undefined,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
  crossOrigin: undefined
};

export const matomoKey = 'Matomo';

function trackUserInteraction(options: IMatomoOptions, to: any, from?: any) {
  if (typeof options.trackSiteSearch === 'function') {
    const siteSearch = options.trackSiteSearch(to);
    if (siteSearch) {
      trackMatomoSiteSearch(options, siteSearch);
      return;
    }
  }
  trackMatomoPageView(options, to, from);
}

function trackMatomoSiteSearch(options: IMatomoOptions, res: {
  keyword: string,
  category: string,
  resultsCount: string
}) {
  const Matomo = getMatomo();

  const { keyword, category, resultsCount } = res;
  options.debug && console.debug('[vue-matomo] Site Search ' + keyword);
  Matomo.trackSiteSearch(keyword, category, resultsCount);
}

function trackMatomoPageView(options: IMatomoOptions, to: any, from: any) {
  const Matomo = getMatomo();

  let title;
  let url;
  let referrerUrl;

  if (options.router) {
    url = getResolvedHref(options.router, to.fullPath);
    referrerUrl = from && from.fullPath
      ? getResolvedHref(options.router, from.fullPath)
      : undefined;

    if (to.meta.analyticsIgnore) {
      options.debug && console.debug('[vue-matomo] Ignoring ' + url);
      return;
    }

    options.debug && console.debug('[vue-matomo] Tracking ' + url);
    title = to.meta.title || url;
  }

  if (referrerUrl) {
    Matomo.setReferrerUrl(window.location.origin + referrerUrl);
  }
  if (url) {
    Matomo.setCustomUrl(window.location.origin + url);
  }

  Matomo.trackPageView(title);
}

function initMatomo(Vue: App, options: IMatomoOptions) {
  const Matomo = getMatomo();

  Vue.provide('$matomo', Matomo);

  if (options.trackInitialView && options.router) {
    // Vue 3 must use currentRoute.value
    const currentRoute = options.router.currentRoute.value
      ? options.router.currentRoute.value
      : options.router.currentRoute;

    // Register first page view
    trackUserInteraction(options, currentRoute);
  }

  // Track page navigations if router is specified
  if (options.router) {
    options.router.afterEach((to, from) => {
      trackUserInteraction(options, to, from);

      if (options.enableLinkTracking) {
        Matomo.enableLinkTracking();
      }
    });
  }
}

function matomoExists(): Promise<void> {
  return new Promise<void>((resolve: () => void) => {
    const checkInterval = 50;
    const timeout = 3000;
    const waitStart = Date.now();

    const interval = setInterval(() => {
      if (window.Piwik) {
        clearInterval(interval);

        return resolve();
      }

      if (Date.now() >= waitStart + timeout) {
        clearInterval(interval);

        throw new Error(`[vue-matomo]: window.Piwik undefined after waiting for ${timeout}ms`);
      }
    }, checkInterval);
  });
}

export default function install(Vue: App, setupOptions: IMatomoOptions) {
  const options = Object.assign({}, defaultOptions, setupOptions);

  const { host, siteId, trackerFileName, trackerUrl, trackerScriptUrl } = options;
  const trackerScript = trackerScriptUrl || `${host}/${trackerFileName}.js`;
  const trackerEndpoint = trackerUrl || `${host}/${trackerFileName}.php`;

  window._paq = window._paq || [];

  window._paq.push(['setTrackerUrl', trackerEndpoint]);
  window._paq.push(['setSiteId', siteId]);

  if (options.requireConsent) {
    window._paq.push(['requireConsent']);
  }

  if (options.userId) {
    window._paq.push(['setUserId', options.userId]);
  }

  if (options.enableLinkTracking) {
    window._paq.push(['enableLinkTracking']);
  }

  if (options.disableCookies) {
    window._paq.push(['disableCookies']);
  }

  if (options.requireCookieConsent) {
    window._paq.push(['requireCookieConsent']);
  }

  if (options.enableHeartBeatTimer) {
    window._paq.push(['enableHeartBeatTimer', options.heartBeatTimerInterval.toString()]);
  }

  if (options.cookieDomain) {
    window._paq.push(['setCookieDomain', options.cookieDomain]);
  }

  if (options.domains) {
    window._paq.push(['setDomains', options.domains]);
  }

  options.preInitActions.forEach((action) => window._paq.push(action));

  loadScript(trackerScript, options.crossOrigin)
    .then(() => matomoExists())
    .then(() => initMatomo(Vue, options))
    .catch((error) => {
      if (error.target) {
        return console.error(
          `[vue-matomo] An error occurred trying to load ${error.target.src}. ` +
          'If the file exists you may have an ad- or trackingblocker enabled.'
        );
      }

      console.error(error);
    });
}

function getMatomo(): IMatomo {
  return window.Piwik.getAsyncTracker();
}

function loadScript(trackerScript: string, crossOrigin: string | undefined = undefined): Promise<Event> {
  return new Promise<Event>((resolve: (ev: Event) => any, reject: OnErrorEventHandlerNonNull) => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = trackerScript;

    if (crossOrigin && ['anonymous', 'use-credentials'].includes(crossOrigin)) {
      script.crossOrigin = crossOrigin;
    }

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}

function getResolvedHref(router: any, path: string) {
  return router.resolve(path).href;
}

/* eslint-enable @typescript-eslint/no-explicit-any */
