import {
  IEditorGraphicAsset,
  IThumbDictionary,
} from '@/interfaces/editorInterfaces';
import { Image } from '@/modules/editor/image';

export class GraphicAsset extends Image implements IEditorGraphicAsset {
  /**
   * Type can be: ['background', 'clipart', 'photo', 'edge']
   *
   * @param id
   * @param type
   * @param urlPrefix
   * @param thumbs
   */
  constructor(
    id: number,
    public type: string,
    urlPrefix: string,
    thumbs: IThumbDictionary
  ) {
    super(id, urlPrefix, thumbs);
  }
}
