/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import { IEditorFrame, IEditorImage, IEditorPhotoGroup } from '@/interfaces/editorInterfaces';
import { Photo } from '@/modules/editor/fabricShapes/photo';
import { Image } from '@/modules/editor/fabricShapes/image';
import { MAX_IMAGE_SIZE } from '@/modules/editor/editorConstants';
import { Helpers } from '@/modules/editor/helpers';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { Frame } from '@/modules/editor/fabricShapes/frame';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';

export const PhotoGroup = fabric.util.createClass(fabric.Group, {
  type: 'photo-group',
  //stateProperties: stateProperties,

  // SF.Editor.Image elements
  photoObject: null,
  frameObject: null,

  // information about inner rotation point
  hasInnerRotatingPoint: true,

  // whether or not the group is ready to render
  ready: false,

  // if there is any glow, shadow, or reflection, this will be used to draw
  node: null,
  nodeContext: null,
  nodeCache: null,

  initialize: function (photo: IEditorImage, frame: IEditorFrame, options: never): void {
    this.initializePhoto(photo, options);
    this.initializeFrame(frame, options);
    this.callSuper('initialize', [], options);

    // setup ready
    this.ready = !photo && !frame;

    // add the objects after..
    this.add(this.photoObject);
    this.add(this.frameObject);

    this.forEachControl((_: never, key: string) => {
      if (key === 'ir' || key === 'pan') {
        this.setControlVisibility(key, true);
      }
    });

    // setup proxy functions that map to the photo object
    const proxy = [
      'pan',
      'zoom',
      'innerRotate',
      'rotatePhoto',
      'setAutoCorrect',
      'hasAutoCorrect',
      'setAutoRedEye',
      'hasAutoRedEye',
      'getMinWidth',
      'getMinHeight',
      'resetPhotoSliders',
    ];
    for (let i = 0; i < proxy.length; i++) {
      const method = proxy[i];
      this[method] = this.photoObject[method].bind(this.photoObject);
    }

    // bind certain functions to the scope of this class
    this.sliderRotation = this.sliderRotation.bind(this);
    this.sliderZoom = this.sliderZoom.bind(this);
  },

  initializePhoto: function (image: IEditorImage, options: never): void {
    this.photoObject = new Photo(image, options);

    if (image) {
      this.photoObject.on('ready', this.onObjectLoaded.bind(this));
    }
  },

  initializeFrame: function (frame: IEditorFrame, options: { width: number; height: number }): void {
    this.frameObject = new Frame(frame, {
      width: options.width,
      height: options.height,
      left: 0,
      top: 0,
      effectsEnabled: false,
    });

    if (frame) {
      this.frameObject.on('ready', this.onObjectLoaded.bind(this));
    }
  },

  /**
   * Renders instance on a given context
   *
   * There are two separate drawing modes:
   * 1) When there is a shadow, render everything into a separate canvas
   * 2) Fallback to default is there is no shadow.
   *
   * @param {CanvasRenderingContext2D} ctx context to render instance on
   */
  render: function (ctx: CanvasRenderingContext2D): void {
    // do not render if object is not visible
    if (!this.visible) {
      return;
    }

    // if we don't have a shadow, fallback
    if (
      (!this.shadow && !this.highlightShadow && !(this.effects && this.effects.reflectionSize)) ||
      this.type == 'background'
    ) {
      // cleanup?
      if (this.node) {
        delete this.node;
        delete this.nodeContext;
      }

      return this.callSuper('render', ctx);
    }

    this._renderGlowShadowReflection(ctx);
  },

  /**
   * When an object is loaded (frame, photo), check if they are all loaded, and if so,
   * fire the ready event.
   *
   */
  onObjectLoaded: function (): void {
    // this seems hacky, but sometimes when images are cached already, both the edge and the photo are ready the first time around
    if (this.ready) {
      return;
    }

    for (let i = 0; i < this._objects.length; i++) {
      const o = this._objects[i];

      if (o.image !== null && o.ready === false) {
        return;
      }
    }

    // all elements are loaded, mark as such and let canvas know
    this.ready = true;
    this.fire('ready', {
      target: this,
    });
  },

  /**
   * @param key
   * @param value
   */
  _set: function (key: string, value: string | number): void {
    // @note: hack to allow animations for inner rotate
    if (key === 'innerRotate') {
      this.innerRotate(value);
      return;
    }

    this.callSuper('_set', key, value);

    if ((key === 'width' || key === 'height') && this.photoObject.group) {
      this.frameObject[key] = value;

      // fit object
      const fit = this.photoObject.fit();

      if (fit) {
        this.photoObject.zoomOutUntilPreviousZoom();
      }
    }
  },

  get: function (key: string): string | number {
    // @note: hack to allow animations for inner rotate
    if (key === 'innerRotate') {
      return this.getInnerRotation();
    }

    return this.callSuper('get', key);
  },

  ghost: function (): void {
    // create a lightweight ghost
    const o = this.photoObject;
    const image = new Image(o.image, {
      left: o.left,
      top: o.top,
      width: o.width,
      height: o.height,
      angle: o.angle,
      flipX: o.flipX,
      flipY: o.flipY,
      opacity: 0.3,
    });

    // add the ghost as a part of the group
    this.insertAt(image, 0, false);

    // let photo object know about ghost so it can proxy positional values
    o.ghost = image;
  },

  unGhost: function (): void {
    // remove the ghost
    this._objects.shift();

    // let photo object know
    this.photoObject.ghost = null;
  },

  isGhosted: function (): boolean {
    return !!this.photoObject.ghost;
  },

  isImageTooLarge: function (): boolean {
    return (
      this.photoObject.imagePosition.zoom * this.photoObject.image.width > MAX_IMAGE_SIZE ||
      this.photoObject.imagePosition.zoom * this.photoObject.image.height > MAX_IMAGE_SIZE
    );
  },

  isImageWarning: function (): boolean {
    return (
      this.photoObject.imagePosition.zoom * this.photoObject.image.width > MAX_IMAGE_SIZE * 0.9 ||
      this.photoObject.imagePosition.zoom * this.photoObject.image.height > MAX_IMAGE_SIZE * 0.9
    );
  },

  getZoom: function (): number {
    return this.photoObject.imagePosition.zoom;
  },

  getInnerRotation: function (): number {
    return this.photoObject.angle;
  },

  flipVertical: function (): void {
    this.photoObject.flipY = !this.photoObject.flipY;
  },

  getFlipY: function (): boolean {
    return this.photoObject.flipY;
  },

  flipHorizontal: function (): void {
    this.photoObject.flipX = !this.photoObject.flipX;
  },

  getFlipX: function (): boolean {
    return this.photoObject.flipX;
  },

  applyEffects: function (): void {
    this.photoObject.applyEffects();
    this.frameObject.applyEffects();
  },

  removePhoto: function (): void {
    this.photoObject.removeImage();
    this.canvas.toolTipZones = [];
  },

  removeEdge: function (): void {
    // set image to null
    this.frameObject.removeImage();
    this.photoObject.fit();
    this.photoObject.setPreviousZoom();
  },

  resetSliderZoom: function (): void {
    this.sliderZoom(0);
  },

  replacePhoto: function (): void {
    // adjust zoom and pan
    this.photoObject.placePhoto();
    this.sliderZoom(this.photoObject.getMinZoom());
  },

  resetPhoto: function (resetEffects = true): void {
    this.photoObject.zoomed = false;
    this.isLetterBoxed = false;

    // re-set zoom
    this.resetSliderZoom();

    // re-set orientation
    const initialRotationAngle = this.getInitialRotationAngle();
    this.resetOrientation(initialRotationAngle);
    this.sliderRotation(initialRotationAngle || 0);

    // unflip
    if (this.getFlipX()) {
      this.flipHorizontal();
    }

    if (this.getFlipY()) {
      this.flipVertical();
    }

    // in flash, reset photo also changes some of the effects (filter, and the sliders)
    // @note this should call applyFilters, but resetPhotoSliders() does it for us.
    if (this.effects && resetEffects) {
      if (this.effects.filter) {
        this.effects.filter = null;
      }

      if (this.effects.redEyes) {
        this.effects.redEyes = [];
      }

      // remove auto correct
      this.effects.autoCorrect = false;

      // reset sliders
      this.resetPhotoSliders();
    }

    this.replacePhoto();

    this.photoObject.zoomed = false;
    this.photoObject.setPreviousZoom();
  },

  resetEffects: function (): void {
    const initialRotation = this.effects.initialRotation || 0;

    this.effects = {};
    if (this.shadow) {
      this.shadow = {};
    }
    this.effects.initialRotation = initialRotation;
    this.applyEffects();
  },

  resetOrientation: function (initialAngle?: number): void {
    this.sliderRotation(initialAngle || 0);
    if (this.getFlipX()) {
      this.flipHorizontal();
    }

    if (this.getFlipY()) {
      this.flipVertical();
    }
  },

  sliderZoom: function (zoom: number): number {
    const photo = this.photoObject;
    const photoFrame = photo.getFrame();
    const photoImage = photo.image;
    if (!photoFrame || !photoImage) {
      return 1;
    }
    const min = Math.min(
      photo.getMinZoom(),
      1 / Helpers.zoomToFit(photoFrame.width, photoFrame.height, photoImage.width, photoImage.height)
    );
    // Hack, grouped photos don't have a max zoom so we will just return an arbitrarily large zoom.  You can't zoom them anyway, so it shouldn't matter.
    const editorCanvasStore = useEditorCanvasStore();
    const max = editorCanvasStore.errorConfiguration.zoomError;

    if (zoom !== undefined) {
      // convert zoom from being a portion of allowable range to a value
      zoom = min + (zoom / 100) * (max - min);

      if (zoom >= min && zoom < max) {
        // convert the zoom value to a delta and zoom to it
        const zoomBeforeChange = photo.imagePosition.zoom;
        if (zoom < zoomBeforeChange || !this.isImageTooLarge()) {
          photo.zoom(zoom - photo.imagePosition.zoom);
          if (photo.imagePosition.zoom == zoomBeforeChange) {
            photo.group.userZoom = min;
          } else {
            photo.group.userZoom = photo.imagePosition.zoom;
          }
        }
      }
    }

    // return the current zoom as a portion of the allowed range
    if (zoom !== undefined || !photoImage) {
      return ((Math.max(min, photo.imagePosition.zoom) - min) / (max - min)) * 100;
    } else {
      const initialZoom = Math.min(
        photo.getMinZoom(),
        1 / Helpers.zoomToFit(photoFrame.width, photoFrame.height, photoImage.width, photoImage.height)
      );
      return ((Math.max(initialZoom, photo.imagePosition.zoom) - initialZoom) / (max - initialZoom)) * 100;
    }
  },

  sliderRotation: function (rotation: number): void {
    if (rotation !== undefined) {
      this.photoObject.innerRotate(rotation);
    }

    return this.photoObject.angle;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toObject: function (propertiesToInclude: any): any {
    const o = fabric.util.object.extend(this.callSuper('toObject', propertiesToInclude), {
      photo: this.photoObject.image,
      imagePosition: fabric.util.object.extend({}, this.photoObject.imagePosition),
      locked: this.locked,
      fixed: this.fixed,

      frame: this.frameObject.image,

      effects: this.effects ? fabric.util.object.clone(this.effects) : null,
    });

    // pass rotation along
    o.effects.rotation = this.photoObject.angle;

    // pass along flipping information

    o.effects.flipVertical = this.photoObject.flipY;
    o.effects.flipHorizontal = this.photoObject.flipX;

    return o;
  },

  applyBorder: function (): void {
    this.frameObject.applyBorder();
  },

  removeBorder: function (): void {
    const effects = this.effects;
    if (effects.borderSize) {
      effects.borderSize = 0;
      effects.borderColor = null;
      this.applyBorder();
    }
    this.photoObject.fit();
  },

  removeVignette: function (): void {
    const effects = this.effects;
    if (effects.vignetteBlur) {
      effects.vignetteBlur = 0;
    }

    if (effects.vignetteOpacity) {
      effects.vignetteOpacity = 0;
    }
  },

  /**
   * Returns true if object state (one of its state properties) was changed
   * @return {Boolean} true if instance' state has changed since was called
   */
  hasStateChanged: function (): boolean {
    return this.stateProperties.some(function (prop: string): boolean {
      return (
        // @ts-ignore
        this.get(prop) !== this.originalState[prop] ||
        // @ts-ignore
        this.photoObject.get(prop) !== this.originalState['po_' + prop]
      );
    }, this);
  },

  saveState: function (options: { stateProperties: string[] }): IEditorPhotoGroup {
    this.stateProperties.forEach(function (prop: string): void {
      // @ts-ignore
      this.originalState[prop] = this.get(prop);
      // @ts-ignore
      this.originalState['po_' + prop] = this.photoObject.get(prop);
    }, this);

    if (options && options.stateProperties) {
      options.stateProperties.forEach(function (prop: string): void {
        // @ts-ignore
        this.originalState[prop] = this.get(prop);
      }, this);
    }

    return this;
  },

  applyVignetteDefaults: function (reset: boolean): void {
    const effects = this.effects;
    effects.vignetteColor = !effects.vignetteColor ? '#000000' : effects.vignetteColor;
    if (effects.vignetteColor.indexOf('rgb') >= 0) {
      const color = EditorTranslator.rgb2hex(effects.vignetteColor);
      const vignetteOpacity = EditorTranslator.getOpacityFromRGBA(effects.vignetteColor);
      effects.vignetteColor = color;
      effects.vignetteOpacity = vignetteOpacity * 100;
    }
    effects.vignetteBlur = reset || !effects.vignetteBlur ? 40 : effects.vignetteBlur;
    effects.vignetteOpacity = reset || !effects.vignetteOpacity ? 50 : effects.vignetteOpacity;
  },

  getInitialRotationAngle: function (): number {
    const photoObject = this.photoObject;
    const imageId = photoObject.image.id;
    const imageElement = document.querySelector('[data-id=' + '"' + imageId + '"' + ']') as HTMLImageElement;
    let initialRotationAngle: number;
    const matrix = imageElement
      ? getComputedStyle(imageElement).getPropertyValue('transform') ||
        getComputedStyle(imageElement).getPropertyValue('-webkit-transform')
      : null;
    if (matrix && matrix !== 'none') {
      const values = matrix.split('(')[1].split(')')[0].split(',');
      // don't use parseInt as the number can contain scientific E notation
      const a = +values[0];
      const b = +values[1];
      initialRotationAngle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    } else {
      initialRotationAngle = this.effects.initialRotation;
    }
    return initialRotationAngle;
  },

  _renderGlowShadowReflection: function (ctx: CanvasRenderingContext2D): void {
    ctx.save();
    this.transform(ctx);

    // if we are currently ghosting, draw it on the main layer, behind everything
    const ghost = this.photoObject ? this.photoObject.ghost : null;
    if (ghost) {
      ghost.render(ctx);
    }

    // figure out if we are drawing a glow, shadow, or reflection
    // if reflection is enabled, it takes priority
    const drawingReflection = this.effects && this.effects.reflectionSize;

    if (drawingReflection) {
      this._renderReflection(ctx);
    } else {
      this._renderShadow(ctx);
    }

    ctx.restore();
  },

  _renderNode: function (ctx: CanvasRenderingContext2D): void {
    ctx.drawImage(this.node, -this.width / 2, -this.height / 2);
  },

  _renderShadow: function (ctx: CanvasRenderingContext2D): void {
    this._setupNode(ctx, this.width, this.height);

    this._setShadow(ctx);
    this._renderNode(ctx);
    this._removeShadow(ctx);

    this.nodeContext.restore();
  },

  _renderReflection: function (ctx: CanvasRenderingContext2D): void {
    // init vars
    const zoom = this.canvas.getZoom();
    const opacity = this.effects.reflectionOpacity || 90;
    const size = this.effects.reflectionSize === undefined ? 50 : parseInt(this.effects.reflectionSize, 10);
    const distance = Math.round(1 / zoom) * (parseInt(this.effects.reflectionDistance, 10) || 0);

    // init canvas
    this._setupNode(ctx, this.width, this.height * 2 + distance);

    // get context
    const nodeContext = this.nodeContext;

    // reflect node context
    nodeContext.transform(1, 0, 0, -1, 0, 0);
    nodeContext.translate(0, -(this.height + distance));
    nodeContext.drawImage(this.node, -this.width / 2, -this.height / 2);

    // gradient
    nodeContext.globalCompositeOperation = 'destination-out';

    const grad = nodeContext.createLinearGradient(-this.width / 2, -this.height / 2, -this.width / 2, this.height / 2);
    const gradientSize = size / 100;

    grad.addColorStop(1 - gradientSize, 'rgba(255,255,255,1)');
    grad.addColorStop(1 - gradientSize + gradientSize * 0.25, 'rgba(255,255,255,' + (1 - opacity / 100) + ')');

    nodeContext.fillStyle = grad;
    nodeContext.fillRect(-this.width / 2, -this.height / 2, this.width, this.height);
    nodeContext.restore();

    this._renderNode(ctx);
  },

  _setupNode: function (_: CanvasRenderingContext2D, width: number, height: number): void {
    if (!this.node) {
      this.node = fabric.util.createCanvasElement();
      this.nodeContext = this.node.getContext('2d');
    }

    // ensure width
    if (this.node.width != width) {
      this.node.width = width;
    }

    // ensure height
    if (this.node.height != height) {
      this.node.height = height;
    }

    // clear the context if there was a previous render
    const nodeContext = this.nodeContext;
    nodeContext.clearRect(0, 0, width, height);

    // setup node context
    nodeContext.save();
    nodeContext.translate(this.width / 2, this.height / 2);

    const ghost = this.photoObject ? this.photoObject.ghost : null;

    // draw each object onto intermediary canvas
    for (let i = 0, len = this._objects.length; i < len; i++) {
      // skip ghost if we are ghosting
      if (ghost && this._objects[i] === ghost) {
        continue;
      }

      this._objects[i].render(nodeContext);
    }

    //nodeContext.restore();
  },
});

export const Foreground = fabric.util.createClass(PhotoGroup, {
  type: 'foreground',
  hoverCursor: 'initial',
  hasControls: false,

  initialize: function (image: IEditorImage, options: never): void {
    options = options || {};

    // a foreground is always locked - no matter the data
    // options.locked = true;
    // options.fixed = true;

    this.callSuper('initialize', image, null, options);
  },
});

export const Background = fabric.util.createClass(PhotoGroup, {
  type: 'background',
  hoverCursor: 'initial',

  initialize: function (
    image: IEditorImage,
    options: {
      hasControls?: boolean;
      isLetterBoxed?: boolean;
    }
  ): void {
    options = options || {};

    // a background is always locked - no matter the data
    // options.locked = true;
    // options.fixed = true;
    options.hasControls = !options.isLetterBoxed;

    this.callSuper('initialize', image, null, options);
  },

  letterBoxPhoto: function (): void {
    const snapAngle = Math.round(this.photoObject.angle / 90);
    // evaluates to true when the orientation of the photo has changed ie 90 / 207
    const isRotated = snapAngle % 2 != 0;

    //rotate to nearest right angle
    this.sliderRotation(snapAngle * 90);

    this.isLetterBoxed = true;
    this.hasControls = false;

    // adjust zoom and pan
    this.photoObject.placePhoto();

    //remove border / currently we can't support vignettes
    this.removeBorder();
    this.removeVignette();

    //when rotated 90 or 270 we must compare this.width to photoObject.height etc....
    if (isRotated) {
      //compare aspect rations of the zone to the photo
      if (this.width / this.height < this.photoObject.height / this.photoObject.width) {
        this.photoObject.width = this.photoObject.width * (this.width / this.photoObject.height);
        //noinspection JSSuspiciousNameCombination
        this.photoObject.height = this.width;
        this.photoObject.imagePosition.zoom = this.width / this.photoObject.imagePosition.height;
        this.photoObject.imagePosition.x = this.width / -2 - (this.photoObject.height - this.photoObject.width) / -2;
        this.photoObject.imagePosition.y =
          this.photoObject.width / -2 + (this.photoObject.height - this.photoObject.width) / -2;
      } else {
        this.photoObject.height = this.photoObject.height * (this.height / this.photoObject.width);
        //noinspection JSSuspiciousNameCombination
        this.photoObject.width = this.height;
        this.photoObject.imagePosition.zoom = this.height / this.photoObject.imagePosition.width;
        this.photoObject.imagePosition.x =
          this.photoObject.height / -2 + (this.photoObject.width - this.photoObject.height) / -2;
        this.photoObject.imagePosition.y = this.height / -2 - (this.photoObject.width - this.photoObject.height) / -2;
      }
    } else {
      //compare aspect rations of the zone to the photo
      if (this.width / this.height < this.photoObject.width / this.photoObject.height) {
        this.photoObject.height = this.photoObject.height * (this.width / this.photoObject.width);
        this.photoObject.width = this.width;
        this.photoObject.imagePosition.zoom = this.width / this.photoObject.imagePosition.width;
        this.photoObject.imagePosition.x = this.width / -2;
        this.photoObject.imagePosition.y = this.photoObject.height / -2;
      } else {
        this.photoObject.width = this.photoObject.width * (this.height / this.photoObject.height);
        this.photoObject.height = this.height;
        this.photoObject.imagePosition.zoom = this.height / this.photoObject.imagePosition.height;
        this.photoObject.imagePosition.x = this.photoObject.width / -2;
        this.photoObject.imagePosition.y = this.height / -2;
      }
    }
  },

  removeLetterBox: function (): void {
    this.isLetterBoxed = false;
    this.hasControls = true;
    // zoom back out
    this.photoObject.placePhoto();
  },

  toObject: function (propertiesToInclude: boolean): fabric.Object {
    const o = fabric.util.object.extend(this.callSuper('toObject', propertiesToInclude), {
      isLetterBoxed: this.isLetterBoxed,
    });

    delete o.src;

    return o;
  },
});
