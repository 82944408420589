import {
  IOption,
  IShopProduct,
  IShopProductPreviewStatus,
  IVariant,
  IVariantImagePreview, ShopProductStatus
} from '@/interfaces/shopProductInterface';

import { areObjectsEqual, deepToRaw } from '@/helpers/vueHelpers';
import { IPreviewData, IProductInfo } from '@/interfaces/baseInterface';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { OrientationAttribute, ProductAttribute } from '@/modules/editor/editorConstants';
import { Helpers } from '@/modules/editor/helpers';
import { Orientation } from '@/interfaces/editorInterfaces';
import { IProjectInfo } from '@/interfaces/projectInterface';
import { ISuperSkuAttribute } from '@/interfaces/catalogInterfaces';
import { IShopProductSummary, PlatformType } from '@/interfaces/shopInterface';
import shopifyIconUrl from '@/assets/images/icons-shopify.png';
import { uuid } from '@/helpers/uuidHelpers';
import { IItePreviews } from '@/interfaces/iItePreviews';

export function setShopProductPreviewsStatus(shopProduct: IShopProduct, enabledPreviewNames: string[]): void {
    shopProduct.previews_status.forEach((previewStatus) => {
      previewStatus.enabled = enabledPreviewNames.includes(previewStatus.name);
    });
}

export function setMainPreviewAsEnabled(shopProduct: IShopProduct): void {
  const mainPreviewStatus = shopProduct.previews_status.find(previewStatus => previewStatus.name === shopProduct.main_preview);
  if (mainPreviewStatus) {
    mainPreviewStatus.enabled = true;
  }
}

export function findPreviewsStatusByName(previewName: string, shopProduct: IShopProduct): IShopProductPreviewStatus[] {
  return shopProduct.previews_status.filter((previewStatus) => {
    return previewStatus.name === previewName;
  });
}

export function getEnabledPreviewsStatus(shopProduct: IShopProduct): IShopProductPreviewStatus[] {
  return shopProduct.previews_status.filter((previewStatus) => {
    return previewStatus.enabled;
  });
}

export function isShopProductPreviewEnabled(previewImage: IVariantImagePreview, shopProduct: IShopProduct): boolean {
  const previewsStatus = findPreviewsStatusByName(previewImage.name, shopProduct);
  return previewsStatus.length > 0 ? previewsStatus[0].enabled : false;
}

export function isShopProductMainPreview(previewImage: IVariantImagePreview, shopProduct: IShopProduct): boolean {
  return shopProduct.main_preview === previewImage.name;
}

export function isSuperSkuShopProduct(shopProduct: IShopProduct|null): boolean {
  return shopProduct !== null && shopProduct.super_sku_id > 0;
}

export function getUniqueBaseIdsFromShopProduct(shopProduct: IShopProduct): Set<number> {
  return new Set(shopProduct.variants.map((variant) => variant.base_id));
}

export function getUniqueProjectGuidsFromShopProduct(shopProduct: IShopProduct): Set<string> {
  return new Set(shopProduct.variants.map((variant) => variant.project_guid));
}

export function getSkuIdsFromShopProduct(shopProduct: IShopProduct): number[] {
  return shopProduct.variants.map((variant) => {
    return getSkuIdFromVariant(variant, shopProduct.guid);
  });
}

export function getSkuIdFromVariant(variant: IVariant, shopProductGuid: string): number {
  const productSku = variant.product_sku;
  const skuId = productSku.substring(shopProductGuid.length + 1, productSku.length);
  return parseInt(skuId, 10);
}

export function getVariantByProductSku(shopProduct: IShopProduct, productSku: string): IVariant|undefined {
  return shopProduct.variants.find((variant) => variant.product_sku === productSku);
}

export function getVariantByDefaultSku(shopProduct: IShopProduct): IVariant|undefined {
  return shopProduct.variants.find((variant) => variant.product_sku === shopProduct.default_sku);
}

export function getSkuIdFromProductSku(shopProduct: IShopProduct, productSku: string): string {
  return productSku.replace(`${shopProduct.guid}-`, '');
}

export function generateVariantProductSku(shopProductGuid: string, skuId: number): string {
  return `${shopProductGuid}-${skuId}`;
}

export function getUniqueProjectGuidsFromBase(shopProduct: IShopProduct, baseId: number): Set<string> {
  const variants = shopProduct.variants.filter((variant) => variant.base_id === baseId);
  return new Set(variants.map((variant) => variant.project_guid));
}

export function getVariantFromSkuId(shopProduct: IShopProduct, skuId: number): IVariant|undefined {
  return shopProduct.variants.find((variant) => getSkuIdFromVariant(variant, shopProduct.guid) === skuId);
}

export function extractShopProductGuidFromVariant(variant: IVariant, skuId: number): string {
  const isValidVariant = variant.product_sku.endsWith('-' + skuId.toString());
  if (isValidVariant) {
    return variant.product_sku.replace('-' + skuId.toString(), '');
  }
  return '';
}

export function areAllShopProductPreviewsReady(shopProduct: IShopProduct): boolean {
  const allPreviewNames = shopProduct.previews_status.map((preview) => preview.name);
  return shopProduct.variants.every((variant) => {
    allPreviewNames.every((previewName) => {
      return variant.image_previews.find((preview) => {
        return (preview.name === previewName) && preview.url;
      });
    });
    return variant.image_previews.find((preview) => {
      return allPreviewNames.includes(preview.name);
    });
  });
}

export function isShopProductDefaultPreviewReady(newShopProductDefaultPreview: string, oldShopProductDefaultPreview: string): boolean {
  return !!newShopProductDefaultPreview && newShopProductDefaultPreview !== oldShopProductDefaultPreview;
}

export function findVariantOptionsIntersection(variants1: IVariant[], variants2:  IVariant[]): IVariant[] {
  return variants1.filter((variant1) => {
    return variants2.some((variant2) => {
      const areOptionsEqual = areObjectsEqual(variant1.options, variant2.options);
      return variant1.product_sku === variant2.product_sku && variant1.project_guid === variant2.project_guid && variant1.base_id === variant2.base_id && areOptionsEqual;
    });
  });
}

export async function getOptionsWithPreviews(shopProduct: IShopProduct, product: IProductInfo, superSkuAttributes: ISuperSkuAttribute[]): Promise<IOption[]> {
  const itePreviews = await ServiceLocator.getService<IItePreviews>(ServiceType.ItePreviews);
  const options = shopProduct.options;

  // wrap edge style may not have a preview in preview data, but it does need a separate preview in variants (it's part of a project data)
  // super sku attributes do not have a preview in preview data either, but they need a separate preview in variants
  return options.filter((option) => {
    return itePreviews.doesAttributeHavePreviews(option.name, product.previews) || option.name === ProductAttribute.WRAP_EDGE_STYLE || isVariantOptionASuperSkuAttribute(superSkuAttributes, option);
  });
}

function isVariantOptionASuperSkuAttribute(superSkuAttributes: ISuperSkuAttribute[], option: IOption): boolean {
  return superSkuAttributes.some((superSkuAttribute) => {
    return superSkuAttribute.name === option.name;
  });
}

export async function getVariantsMapForSavingPreviews(variantsToCreateMapFrom: IVariant[], currentProducts: IProductInfo[], currentShopProduct: IShopProduct, superSkuAttributes: ISuperSkuAttribute[]): Promise<Map<string, IVariant[]>> {
  // map of <variant product sku, variants>
  // one variant product sku can represent multiple variants to prevent from saving redundant previews when an option does not change the preview (e.g. size attribute in TShirt)
  const variantsMapForPreviewGeneration: Map<string, IVariant[]> = new Map<string, IVariant[]>();
  for (let i = 0; i < variantsToCreateMapFrom.length; i++) {
    const variant = variantsToCreateMapFrom[i];
    const product = currentProducts.find((product) => {
      return product.id === variant.base_id;
    });
    if (product) {
      const optionNamesWithPreviews = (await getOptionsWithPreviews(currentShopProduct, product, superSkuAttributes)).map((option) => {
        return option.name;
      });
      const variantOptionNamesWithPreviews = Object.keys(variant.options).filter((optionName) => {
        return optionNamesWithPreviews.includes(optionName);
      });
      if (variantsMapForPreviewGeneration.size === 0) {
        variantsMapForPreviewGeneration.set(variant.product_sku, [variant]);
      } else {
        const isAVariantWithNewPreview = Array.from(variantsMapForPreviewGeneration.keys()).every((productSku) => {
          const foundVariant = getVariantByProductSku(currentShopProduct, productSku);
          return variantOptionNamesWithPreviews.some((optionName) => {
            return foundVariant?.options[optionName] !== variant.options[optionName];
          });
        });
        if (isAVariantWithNewPreview) {
          variantsMapForPreviewGeneration.set(variant.product_sku, [variant]);
        } else {
          // now this is a variant that does not have a new preview, and should be inserted in its proper variant group in the map
          const productSkuGroup = Array.from(variantsMapForPreviewGeneration.keys()).find((productSku) => {
            const foundVariant = getVariantByProductSku(currentShopProduct, productSku);
            return variantOptionNamesWithPreviews.every((optionName) => {
              return foundVariant?.options[optionName] === variant.options[optionName];
            });
          });
          if (productSkuGroup) {
            variantsMapForPreviewGeneration.get(productSkuGroup)?.push(variant);
          }
        }
      }
    }
  }
  return variantsMapForPreviewGeneration;
}

// Note: Special case for square size canvases. Landscape and portrait variants are the same for square size canvases. We keep only the landscape one.
export function shouldSkipVariant(project: IProjectInfo, skuOptions:  {[p: string]: string}): boolean {
  if (project.pages.length === 1 && Helpers.getOrientation(project.pages[0].edits.width, project.pages[0].edits.height) === Orientation.square) {
    const orientationOption = Object.keys(skuOptions).find((optionName) => {
      return optionName.toLowerCase() === OrientationAttribute;
    });
    if (orientationOption && skuOptions[orientationOption] === Orientation.portrait) {
      return true;
    }
  }
  return false;
}

export function getPlatformIconUrl(platformType: string): string {
  switch (platformType.toLowerCase()) {
    case PlatformType.Shopify:
      return shopifyIconUrl;
    default:
      return '';
  }
}

// Resetting some of the store specific info from the existing shop product...
export function createTemplateProductFromShopProduct(shopProduct: IShopProduct, tenantShopId: string): IShopProduct {
  const newShopProductGuid = uuid();
  const clonedShopProduct = structuredClone(deepToRaw(shopProduct));
  decoupleShopProductProjects(clonedShopProduct);
  clonedShopProduct.variants.forEach((variant) => {
    const skuId = getSkuIdFromVariant(variant, clonedShopProduct.guid);
    variant.product_sku = generateVariantProductSku(newShopProductGuid, skuId);
    variant.price_f = '';
    variant.price = 0;
  });

  return {
    details: { description: '', tags: [], title: clonedShopProduct.details.title },
    shop_id: tenantShopId,
    previews_status: [],
    main_preview: clonedShopProduct.main_preview,
    options: clonedShopProduct.options,
    product_type: clonedShopProduct.product_type,
    variants: clonedShopProduct.variants,
    guid: newShopProductGuid,
    super_sku_id: clonedShopProduct.super_sku_id,
    default_preview_url: '',
    status: ShopProductStatus.draft,
    translations: {},
    last_updated: '',
    default_sku: clonedShopProduct.default_sku,
  };
}

// Decouple project guids from the original shop product projects. This is needed to duplicate a template/draft or create a new one from an existing shop product.
export function decoupleShopProductProjects(shopProduct: IShopProduct) {
  const projectGuidsSet = new Set(shopProduct.variants.map(variant => variant.project_guid));
  Array.from(projectGuidsSet.values()).forEach((projectGuid) => {
    const variantsWithSameProjectGuid = shopProduct.variants.filter((v) => v.project_guid === projectGuid);
    const newProjectGuid = uuid();
    if (variantsWithSameProjectGuid.length > 1) {
      variantsWithSameProjectGuid.forEach((v) => {
        v.project_guid = newProjectGuid;
      });
    } else if (variantsWithSameProjectGuid.length === 1) {
      variantsWithSameProjectGuid[0].project_guid = newProjectGuid;
    }
  });
}

// Returns a product that has a real shop ID, and it has mockups/details/pricing info, but hasn't been published to a store
export function convertToDraftShopProduct(existingShopProduct: IShopProduct, shopId: string, decoupleProjects: boolean): IShopProduct {
  const clonedProduct = structuredClone(deepToRaw(existingShopProduct));
  if (decoupleProjects) {
    decoupleShopProductProjects(clonedProduct);
  }
  clonedProduct.status = ShopProductStatus.draft;
  clonedProduct.shop_id = shopId;
  return clonedProduct;
}

export function isTemplateOrDraftShopProduct(status: number) : boolean {
  return status === ShopProductStatus.draft;
}

export function isTemplateShopProduct(shopProductStatus: number, shopProductShopId: string, tenantShopId: string) : boolean {
  return isTemplateOrDraftShopProduct(shopProductStatus) && shopProductShopId === tenantShopId;
}

export function isDraftShopProduct(shopProductStatus: number, shopProductShopId: string, tenantShopId: string) : boolean {
  return isTemplateOrDraftShopProduct(shopProductStatus) && shopProductShopId !== tenantShopId;
}

export function isShopProductInImportProcessing(shopProductStatus: number): boolean {
  return shopProductStatus === ShopProductStatus.pending || shopProductStatus === ShopProductStatus.queuedToPublish;
}

export function isShopProductImportedToShop(shopProductStatus: number): boolean {
  return shopProductStatus === ShopProductStatus.active || shopProductStatus === ShopProductStatus.inactive;
}

export function updateShopProductPreviewsStatus(shopProduct: IShopProduct, previewName: string, status: boolean): void {
  const existingPreviewStatus = shopProduct.previews_status.find((previewStatus) => {
    return previewStatus.name === previewName;
  });
  if (existingPreviewStatus) {
    existingPreviewStatus.enabled = status;
  } else {
    shopProduct.previews_status.push({ name: previewName, enabled: status });
  }
}

export function updateShopProductPreviewImages(shopProduct: IShopProduct, itePreviewImage: {projectGuid: string, previewName: string, imageUrl: string}, previewData: IPreviewData): void {
  const variants = shopProduct.variants.filter((variant: IVariant) => {
    if (itePreviewImage.projectGuid === variant.project_guid) {
      if (previewData.flags && previewData.flags.A) {
        const attributeNameValues: {
          attributeName: string,
          attributeValue: string
        }[] = Object.keys(previewData.flags.A).map((attributeName: string) => {
          return {
            attributeName: attributeName,
            attributeValue: previewData.flags?.A[attributeName as keyof typeof previewData.flags.A] as string
          };
        });
        return attributeNameValues.every((attributeNameValue) => {
          return variant.options[attributeNameValue.attributeName] === attributeNameValue.attributeValue;
        });
      }
      return true;
    }
    return false;
  });

  if (variants.length > 0) {
    variants.forEach((variant: IVariant) => {
      const previewExist = variant.image_previews.find((preview) => {
        return preview.name === itePreviewImage.previewName;
      });
      if (!previewExist) {
        variant.image_previews.push({
          name: itePreviewImage.previewName,
          url: itePreviewImage.imageUrl,
        });
      } else {
        previewExist.url = itePreviewImage.imageUrl;
      }
    });
  }
}
// defaultSku is the product_sku of the variant conatining the main preview
export function setShopProductMainPreview(shopProduct: IShopProduct, mainPreviewName: string, defaultSku: string): void {
    shopProduct.main_preview = mainPreviewName;
    shopProduct.default_sku = defaultSku;
    updateShopProductPreviewsStatus(shopProduct, mainPreviewName, true);
}

export function getClonedShopProductProjects(originalProjects: IProjectInfo[], originalShopProduct: IShopProduct, clonedShopProduct: IShopProduct): IProjectInfo[] {
  const result: IProjectInfo[] = [];
  originalShopProduct.variants.forEach((variant) => {
    const project = originalProjects.find((project) => project.guid === variant.project_guid);
    if (project) {
      const clonedProject = structuredClone(deepToRaw(project));
      const skuId = getSkuIdFromVariant(variant, originalShopProduct.guid);
      const foundVariant = clonedShopProduct.variants.find((templateProductVariant) => getSkuIdFromVariant(templateProductVariant, clonedShopProduct.guid) === skuId);
      if (foundVariant) {
        clonedProject.guid = foundVariant.project_guid;
      }
      const projectExist = result.find((project) => project.guid === clonedProject.guid);
      if (!projectExist) {
        result.push(clonedProject);
      }
    }
  });
  return result;
}

export function sortShopProductSummariesByLastUpdated(shopProductSummaries: IShopProductSummary[]): IShopProductSummary[] {
  return shopProductSummaries.toSorted((a, b) => {
    return getTimeFromDateString(b.last_updated) - getTimeFromDateString(a.last_updated);
  });
}

export function sortShopProductsByNewestUpdated(shopProducts: IShopProduct[]): IShopProduct[] {
  return shopProducts.toSorted((a, b) => {
    return getTimeFromDateString(b.last_updated) - getTimeFromDateString(a.last_updated);
  });
}

export function sortShopProductsByOldestUpdated(shopProducts: IShopProduct[]): IShopProduct[] {
  return shopProducts.toSorted((a, b) => {
    return getTimeFromDateString(a.last_updated) - getTimeFromDateString(b.last_updated);
  });
}

export function sortShopProductsByTitle(shopProducts: IShopProduct[]): IShopProduct[] {
  return shopProducts.toSorted((a, b) => {
    return a.details.title.localeCompare(b.details.title);
  });
}

export function sortShopProductsSummariesByNewestUpdated(shopProductsSummaries: IShopProductSummary[]): IShopProductSummary[] {
  return shopProductsSummaries.toSorted((a, b) => {
    return getTimeFromDateString(b.last_updated) - getTimeFromDateString(a.last_updated);
  });
}

export function sortShopProductsSummariesByOldestUpdated(shopProductsSummaries: IShopProductSummary[]): IShopProductSummary[] {
  return shopProductsSummaries.toSorted((a, b) => {
    return getTimeFromDateString(a.last_updated) - getTimeFromDateString(b.last_updated);
  });
}

export function sortShopProductsSummariesByTitle(shopProductsSummaries: IShopProductSummary[]): IShopProductSummary[] {
  return shopProductsSummaries.toSorted((a, b) => {
    return a.details.title.localeCompare(b.details.title);
  });
}

export function getShopProductEditUrlTemplate(platformType: string, shopDomain: string, shopProductPlatformId: string): string {
  const shopProductPlatformIdParts = shopProductPlatformId.split('/');
  const productId = shopProductPlatformIdParts[shopProductPlatformIdParts.length - 1];
  switch (platformType.toLowerCase()) {
    case PlatformType.Shopify:
      return `https://${shopDomain}/admin/products/${productId}`;
    default:
      console.warn(`Platform type ${platformType} is not supported`);
      return '';
  }
}

export function getShippingEditUrlTemplate(platformType: string, shopDomain: string): string {
  switch (platformType.toLowerCase()) {
    case PlatformType.Shopify:
      return `https://${shopDomain}/admin/settings/shipping`;
    default:
      console.warn(`Platform type ${platformType} is not supported`);
      return '';
  }
}

function getTimeFromDateString(dateString: string): number {
  const date = new Date(dateString);
  const dateTime = date.getTime();
  if (isNaN(dateTime)) {
    console.warn(`Invalid date string: ${dateString}`);
  }
  // handling invalid date
  return !isNaN(dateTime) ? dateTime : new Date(0).getTime();
}

export function isABlobVariantImagePreview(variantImagePrview: IVariantImagePreview): boolean {
  return variantImagePrview.url.startsWith('blob:');
}

export function areMainPreviewsEqual(shopProduct1: IShopProduct, shopProduct2: IShopProduct): boolean {
  return shopProduct1.main_preview === shopProduct2.main_preview && shopProduct1.default_sku === shopProduct2.default_sku;
}

