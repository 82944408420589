import {
  IEditorElement,
  IEditorFontStyle,
  IEditorPage,
  IEditorShape,
  IEditorTextElement,
} from '@/interfaces/editorInterfaces';
import { TextElement } from '@/modules/editor/element';

export class FontHelper {
  public static fromPage(
    page: IEditorPage,
    fontDescriptions?: { [key: string]: string[] }
  ): { [key: string]: string[] } {
    fontDescriptions = fontDescriptions || {};

    page.elements
      .filter((element: IEditorElement): boolean => {
        return element instanceof TextElement;
      })
      .forEach((textElement: IEditorElement): void => {
        this.fromElement(textElement as IEditorTextElement, fontDescriptions);
      });

    return fontDescriptions;
  }

  public static fromElement(
    element: IEditorTextElement,
    descriptions?: { [key: string]: string[] }
  ): { [key: string]: string[] } {
    const fontDescriptions = descriptions || {};

    // find font used in rootStyle
    this.checkAndAdd(element.rootStyle, fontDescriptions);

    // find all fonts used in styles
    if (element.styles) {
      Object.keys(element.styles).forEach((style) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((element.styles as any)[style]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.keys(element.styles[style as any]).forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (char: any): void => {
              this.checkAndAdd(char, fontDescriptions);
            }
          );
        }
      });
    }

    return fontDescriptions;
  }

  public static fromShape(
    shape: IEditorShape,
    descriptions?: { [key: string]: ('r' | 'b' | 'i' | 'bi')[] }
  ): { [key: string]: ('r' | 'b' | 'i' | 'bi')[] } {
    const fontDescriptions = descriptions || {};

    this.checkAndAdd(shape, fontDescriptions);

    if (shape.styles) {
      Object.keys(shape.styles).forEach((style: string): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((shape.styles as any)[style]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.keys((shape.styles as any)[style]).forEach((font) => {
            this.checkAndAdd(font, fontDescriptions);
          });
        }
      });
    }

    return fontDescriptions;
  }

  public static checkAndAdd(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    font: any,
    fontDescriptions: { [key: string]: string[] }
  ): { [key: string]: string[] } {
    fontDescriptions = fontDescriptions || {};
    const fontFamily = font.fontFamily;

    // if a font family declaration exists
    if (fontFamily) {
      // add it to list if it doesn't exist
      if (!Object.prototype.hasOwnProperty.call(fontDescriptions, fontFamily)) {
        fontDescriptions[fontFamily] = [];
      }

      // get style
      const style = this.getStyleFromStylesObject(font);

      // add it as one of the styles if it doesn't already exist
      if (fontDescriptions[fontFamily].indexOf(style) === -1) {
        fontDescriptions[fontFamily].push(style);
      }
    }

    return fontDescriptions;
  }

  public static getStyleFromProperties(
    isBold: boolean,
    isItalic: boolean
  ): 'r' | 'b' | 'i' | 'bi' {
    if (isBold && isItalic) {
      return 'bi';
    }

    if (isBold) {
      return 'b';
    }

    return isItalic ? 'i' : 'r';
  }

  public static getStyleFromStylesObject(font: IEditorFontStyle): string {
    return this.getStyleFromProperties(
      font.fontWeight === 'bold',
      font.fontStyle === 'italic'
    );
  }
}
