<template>
  <div class="spinner">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.spinner {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 13px);
  z-index: 1000;

  div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(var(--v-theme-primary));
    position: absolute;
    top: calc(50% - 7px);
  }

  div:nth-child(1) {
    left: -20px;
    animation: spinner-animation 0.5s 0.3s ease-in-out alternate infinite;
  }

  div:nth-child(2) {
    left: 0;
    animation: spinner-animation 0.5s 0.2s ease-in-out alternate infinite;
  }

  div:nth-child(3) {
    left: 20px;
    animation: spinner-animation 0.5s 0.1s ease-in-out alternate infinite;
  }

  @keyframes spinner-animation {
    0% {
      transform: initial;
    }

    100% {
      transform: translateY(-15px);
    }
  }
}
</style>
