import { IPreviewData, IProductInfo } from '@/interfaces/baseInterface';
import { EditorLayerType } from '@/interfaces/editorInterfaces';

export function doesProductHaveBackLayer(productInfo: IProductInfo): boolean {
  return productInfo.previews.some((previewData: IPreviewData) => {
    return previewData.media_context === EditorLayerType.BackLayer;
  });
}

export function doesProductHaveOverlay(productInfo: IProductInfo): boolean {
  return productInfo.previews.some((previewData: IPreviewData) => {
    return previewData.media_context === EditorLayerType.Overlay;
  });
}

export function doesProductHaveMask(productInfo: IProductInfo): boolean {
  return productInfo.previews.some((previewData: IPreviewData) => {
    return previewData.media_context === EditorLayerType.Mask;
  });
}


export function generateVariantPreviewId(previewName: string, skuId: number): string {
  return previewName + '-' + skuId;
}

export function isJPEGFormat(outputFormat: string): boolean {
  return outputFormat.toLowerCase() === 'jpg' || outputFormat.toLowerCase() === 'jpeg';
}
