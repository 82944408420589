import {
  IAttributeList,
  IAttributeValue,
  IOptionTranslation,
  IPreviewData,
  IProductInfo,
  ISkuData, SchemaType
} from '@/interfaces/baseInterface';
import { isHiddenAttribute } from '@/helpers/catalogHelpers';
import { ISuperSkuProduct, ISuperSkuProductAttribute } from '@/interfaces/catalogInterfaces';
import { BrandAttribute } from '@/modules/editor/editorConstants';
import { doesProductHaveBackLayer, doesProductHaveMask, doesProductHaveOverlay } from '@/helpers/itePreviewHelper';
import { EditorLayerType } from '@/interfaces/editorInterfaces';

export function findAttribute(productInfo: IProductInfo, attributeName: string): IAttributeList|undefined {
  return productInfo.attribute_list.find((attribute: IAttributeList) => attribute.name === attributeName);
}

export function getVisibleOptionNames(skuData: ISkuData, productInfo: IProductInfo): string[] {
  const skuOptions = skuData.options;
  return Object.keys(skuOptions).filter((optionName) => {
    const attribute = findAttribute(productInfo, optionName);
    return attribute && !isHiddenAttribute(attribute);
  });
}

export function getAttributesDisplayName(
  productInfo: IProductInfo,
  attributes: IAttributeList,
  language: string
): string {
  const attributesTranslations = getAttributesOptionTranslations(productInfo, attributes, language);
  if (attributesTranslations) {
    return attributesTranslations.name;
  }
  return '';
}


export function getAttributesDisplayValue(
  productInfo: IProductInfo,
  productAttributes: IAttributeList,
  value: IAttributeValue,
  language: string
): string {
  const attributesTranslations = getAttributesOptionTranslations(productInfo, productAttributes, language);
  if (attributesTranslations) {
    if (attributesTranslations.values[value.name]) {
      return attributesTranslations.values[value.name];
    }
  }
  return '';
}

export function getAttributesDisplayValues(productInfo: IProductInfo, attributes: IAttributeList, language: string): string[] {
  const attributeTranslatedValues: string[] = [];
  const attributesTranslations = getAttributesOptionTranslations(productInfo, attributes, language);
  if (attributesTranslations) {
    if (attributes.values) {
      attributes.values.forEach((value: IAttributeValue) => {
        const attributeValueName = value.name;
        if (attributesTranslations.values[attributeValueName]) {
          attributeTranslatedValues.push(
            attributesTranslations.values[attributeValueName]
          );
        }
      });
    } else {
      const attributeValueName = attributes.default_value;
      if (attributesTranslations.values[attributeValueName]) {
        attributeTranslatedValues.push(
          attributesTranslations.values[attributeValueName]
        );
      }
    }
  }
  return attributeTranslatedValues;
}

function getAttributesOptionTranslations(
  productInfo: IProductInfo,
  attributes: IAttributeList,
  language: string
): IOptionTranslation | null {
  // for superSku we are getting the first product info for now...
  if (
    productInfo &&
    productInfo.translations &&
    productInfo.translations[language] &&
    productInfo.translations[language].option_translations
  ) {
    const optionTranslations =
      productInfo.translations[language].option_translations;
    if (optionTranslations) {
      return optionTranslations[attributes.name];
    }
  }
  return null;
}

export function findPreviewDataByMediaContext(mediaContext: string, base: IProductInfo): IPreviewData[] {
  return base.previews.filter((previewData: IPreviewData) => {
    return previewData.media_context === mediaContext;
  });
}

// attribute flag needs to exist on the preview, and it should match, otherwise it doesn't return the preview
export function findPreviewDataBasedOnProductAttributeSelection(
  mediaContext: string,
  base: IProductInfo,
  currentPageIndex: number,
  skuId: number
): IPreviewData|undefined {
  const previews = findPreviewDataByMediaContext(mediaContext, base);
  return previews.find((previewData: IPreviewData) => {
    if (previewData.flags && (!previewData.flags.P || parseInt(previewData.flags.P, 10) === currentPageIndex)) {
      if (previewData.flags.A) {
        return Object.keys(previewData.flags.A).some((attributeName: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const attributeValue = previewData.flags?.A[attributeName];
          let selectedAttr: {
            attributeName: string;
            attributeValue: string
          } = { attributeName: '', attributeValue: '' };

          const options = base.sku_data.find((skuData: ISkuData) => {
            return skuData.product_sku_id === skuId;
          })?.options;
          if (options) {
            const foundOptionKey = Object.keys(options).find((key: string) => {
              return key === attributeName && options[key] === attributeValue;
            });
            if (foundOptionKey) {
              selectedAttr = { attributeName: foundOptionKey, attributeValue: options[foundOptionKey] };
            }
          }

          return selectedAttr.attributeName === attributeName && selectedAttr.attributeValue === attributeValue;
        });
      }
      return true;
    }
  });

}

export function extractBaseSkuIds(base: IProductInfo, skuIdsToSearch: number[]): number[] {
  const allBaseSkuIds = base.sku_data?.map((sku: ISkuData) => sku.product_sku_id);
  return allBaseSkuIds?.filter((skuId: number) => skuIdsToSearch.includes(skuId)) || [];
}

// sku data array basically contains visible attribute value combinations, therefore you can't assume that any combination of product attribute values will be valid
export function getValidProductAttributeOptions(
  selectedProduct: IProductInfo,
  productAttribute: IAttributeList,
  activeProductSkuIdList: number[]
): IAttributeValue[] {
  const skuData: ISkuData[] = [];
  const skuDataValues: string[] = [];
  if (activeProductSkuIdList.length > 0) {
    const skus = selectedProduct.sku_data.filter((skuData: ISkuData) => {
      return activeProductSkuIdList.includes(skuData.product_sku_id);
    });
    if (skus.length > 0) {
      skuData.push(...skus);
    }
  } else {
    skuData.push(selectedProduct.sku_data[0]);
  }
  skuData.forEach((sku: ISkuData) => {
    const values = Object.keys(sku.options).map(
      (key: string) => {
        return sku.options[key];
      }
    );
    skuDataValues.push(...values);
  });

  return productAttribute.values?.filter(
    (productAttributeValue: IAttributeValue) => {
      return skuDataValues.includes(productAttributeValue.name);
    }
  ) || [];
}


export function findSkuData(product: IProductInfo, productAttributeValues: string[]): ISkuData[] {
  return product.sku_data.filter((skuData: ISkuData) => {
    const skuDataValues = Object.keys(skuData.options).map((key: string) => {
      return skuData.options[key];
    });
    return productAttributeValues.every(
      (productAttributeValue) => {
        return (
          productAttributeValue &&
          skuDataValues.includes(productAttributeValue)
        );
      }
    );
  });
}

export function findSkusWithProductAttribute(product: IProductInfo, attributeName: string, attributeValue: string): ISkuData[] {
  return product.sku_data.filter((skuData: ISkuData) => {
    return Object.keys(skuData.options).some((key: string) => {
      return key === attributeName && skuData.options[key] === attributeValue;
    });
  });
}

export function isSuperSkuProductAttributeActive(superSkuProductInfos: IProductInfo[], superSkuProductAttribute: ISuperSkuProductAttribute): boolean {
  return superSkuProductInfos.some(
    (superSkuProductInfo: IProductInfo) => {
      return superSkuProductAttribute.products
        .map((superSkuProduct: ISuperSkuProduct) => superSkuProduct.id)
        .includes(superSkuProductInfo.id);
    }
  );
}

export function isWhiteColor(color: string): boolean {
  return color !== '' && (color.toLowerCase() === '#ffffff' || color.toLowerCase() === '#fff');
}

export function isEmptyAttributeProduct(product: IProductInfo): boolean {
  return product.attribute_list.filter((attribute) => {
    return !isHiddenAttribute(attribute);
  }).length === 0;
}

export function getBrandAttribute(product: IProductInfo): IAttributeList | null {
  return product.attribute_list.find((attribute: IAttributeList) => !isHiddenAttribute(attribute) && attribute.name.toLowerCase() === BrandAttribute && attribute.schema_type === SchemaType.Text) || null;
}

export function getCanvasLayersPreviewData(base: IProductInfo, currentPageIndex: number, currentActiveProductSkuId: number): IPreviewData[] {
  const layerPreviewDataList: IPreviewData[] = [];
  const canvasLayers = [{ hasBackLayer: doesProductHaveBackLayer(base) },
    { hasOverlay: doesProductHaveOverlay(base) },
    { hasMask: doesProductHaveMask(base) }];
  canvasLayers.forEach((canvasLayer) => {
    const layerType = canvasLayer.hasBackLayer ? EditorLayerType.BackLayer : canvasLayer.hasOverlay ? EditorLayerType.Overlay : canvasLayer.hasMask ? EditorLayerType.Mask : '';
    if (layerType) {
      const layerPreviewData = findPreviewDataBasedOnProductAttributeSelection(
        layerType,
        base,
        currentPageIndex,
        currentActiveProductSkuId
      );
      if (layerPreviewData) {
        layerPreviewDataList.push(layerPreviewData);
      }
    }
  });
  return layerPreviewDataList;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

