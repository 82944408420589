export const enum PublishTopicKeys {
  PUB_LOG = 'pubLog',
  PUB_CATALOG_BASE_TRIGGER = 'pubCatalogBaseTrigger',
  PUB_CATALOG_CATEGORIES_TRIGGER = 'pubCatalogCategoriesTrigger',
  PUB_CATALOG_BASE_PROJECTS_TRIGGER = 'pubCatalogBaseProjectsTrigger',
  PUB_TENANT_TRIGGER = 'pubTenantTrigger',
  PUB_TENANT_UPDATE = 'pubTenantUpdate',
  PUB_TENANT_SHOP_REGISTER = 'pubTenantShopRegister',
  PUB_TENANT_SHOP_TRIGGER = 'pubTenantShopTrigger',
  PUB_TENANT_SHOP_UPDATE = 'pubTenantShopUpdate',
  PUB_TENANT_SHOP_DELETE = 'pubTenantShopDelete',
  PUB_TENANT_SHOP_PRODUCT_TRIGGER = 'pubTenantShopProductTrigger',
  PUB_TENANT_SHOP_PRODUCT_PROJECT_TRIGGER = 'pubTenantShopProductProjectTrigger',
  PUB_TENANT_SHOP_PRODUCT_UPDATE = 'pubTenantShopProductUpdate',
  PUB_TENANT_SHOP_PRODUCT_ADD = 'pubTenantShopProductAdd',
  PUB_TENANT_SHOP_PRODUCT_DELETE = 'pubTenantShopProductDelete',
  PUB_TENANT_SHOP_PRODUCT_PROJECT_ADD = 'pubTenantShopProductProjectAdd',
  PUB_TENANT_SHOP_PRODUCT_PROJECT_UPDATE = 'pubTenantShopProductProjectUpdate',
  PUB_FONTS_TRIGGER = 'pubFontsTrigger',
  PUB_IMAGE_ADD = 'pubImageAdd',
  PUB_IMAGES_AI_TRIGGER = 'pubImagesAiTrigger',
  PUB_IMAGE_DELETE = 'pubImageDelete',
  PUB_IMAGES_TRIGGER = 'pubImagesTrigger',
  PUB_ITE_IMAGE_TRIGGER = 'pubIteImageTrigger',
  PUB_SHOP_PRODUCT_PREVIEW_SAVE = 'pubShopProductPreviewSave',
  PUB_ORDER_FILTER_TRIGGER = 'pubOrderFilterTrigger',
  PUB_WALLETS_TRIGGER = 'pubWalletsTrigger',
  PUB_WALLET_UPDATE = 'pubWalletUpdate',
  PUB_WALLET_PAYMENT_METHOD_ADD = 'pubWalletPaymentMethodAdd',
  PUB_WALLET_PAYMENT_METHOD_DELETE = 'pubWalletPaymentMethodDelete',
  PUB_COUNTRIES_TRIGGER = 'pubCountriesTrigger',
}

/**
 *
 */
export const enum SubscribeTopicKeys {
  SUB_CATALOG_BASE_CURRENT = 'subCatalogBaseCurrent',
  SUB_CATALOG_CATEGORIES_CURRENT = 'subCatalogCategoriesCurrent',
  SUB_CATALOG_BASE_PROJECTS_CURRENT = 'subCatalogBaseProjectsCurrent',
  SUB_TENANT_CURRENT = 'subTenantCurrent',
  SUB_TENANT_SHOP_CURRENT = 'subTenantShopCurrent',
  SUB_TENANT_SHOP_PRODUCT_CURRENT = 'subTenantShopProductCurrent',
  SUB_TENANT_SHOP_PRODUCT_PROJECT_CURRENT = 'subTenantShopProductProjectCurrent',
  SUB_FONTS_CURRENT = 'subFontsCurrent',
  SUB_IMAGE_CURRENT = 'subImageCurrent',
  SUB_IMAGES_CURRENT = 'subImagesCurrent',
  SUB_IMAGES_AI_GENERATED = 'subImagesAiGenerated',
  SUB_ITE_IMAGE_CURRENT = 'subIteImageCurrent',
  SUB_ORDER_CURRENT = 'subOrderCurrent',
  SUB_ORDER_FILTER_CURRENT = 'subOrderFilterCurrent',
  SUB_WALLET_CURRENT = 'subWalletCurrent',
  SUB_WALLETS_CURRENT = 'subWalletsCurrent',
  SUB_WALLET_PAYMENT_METHOD_ADD = 'subWalletPaymentMethodAdd',
  SUB_COUNTRIES_CURRENT = 'subCountriesCurrent',
  SUB_PS_ERROR = 'subPsError',
  SUB_SF_ERROR = 'subSfError',
}

export const enum ForwardToTopicKeys {
  FORWARD_TO_SHOP_PRODUCT_PREVIEW_SAVE = 'forwardToShopProductPreviewSave'
}

/**
 *
 */
export const enum TopicVarLevels {
  CLIENT_GUID = '{client_guid}',
  PLATFORM_NAME = '{platform_name}',
  CATALOG_VSTORE_ID = '{catalog_vstore_id}',
  BASE_ID = '{base_id}',
  ENVIRONMENT = '{env}',
  SHOP_ID = '{shop_id}',
  SEVERITY = '{severity}',
  PRODUCT_ID = '{product_id}',
  PROJECT_ID = '{project_id}',
  IMAGE_ID = '{image_id}',
  PREVIEW_ID = '{preview_id}',
  PREVIEW_NAME = '{preview_name}',
  ACTION = '{action}',
  ORDER_ID = '{order_id}',
  SESSION_ID = '{session_id}',
  WALLET_ID = '{wallet_id}',
  PAYMENT_METHOD_ID = '{payment_method_id}',
}

