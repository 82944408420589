import { fabric } from 'fabric';
import {
  IEditorImage,
  IEditorShape,
  IEffects,
  IEditorRedEye,
} from '@/interfaces/editorInterfaces';
import { CUSTOMIZABLE_EMPTY_IMAGE_FILL_COLOR, ThumbSize } from '@/modules/editor/editorConstants';
import { ImageCache } from '@/modules/editor/imageCache';
import { FabricImageFilters } from '@/modules/editor/filters';
import { IBaseFilter } from 'fabric/fabric-impl';
import personalizeImage from '@/assets/images/editor/personalize-image.svg';

const personalizeImg = fabric.util.createImage();
personalizeImg.src = personalizeImage;

export const Image = fabric.util.createClass(fabric.Image, {
  className: 'image-base',
  type: 'sf-image',
  //stateProperties: stateProperties,
  image: null, // the image object (SF.Model.Image)
  elementAdded: false, // whether the image has been created or not
  ready: false, // whether an image has been loaded or not (doesn't have to be the optimal image), always true if no image
  optimalThumbLoaded: false,

  // effects
  effectsEnabled: true,
  EmptyImageFill: '',
  initialize: function (image: IEditorImage, options: { [key: string]: unknown }): void {
    if (options.customerEditable) {
      this.EmptyImageFill = CUSTOMIZABLE_EMPTY_IMAGE_FILL_COLOR;
    }
    this.setEmptyImageFillColor();
    this.setImage(image);
    this.callSuper('initialize', fabric.util.createImage(), options);
  },

  setEmptyImageFillColor: function (): void {
    if (this.EmptyImageFill) {
      this.fill = this.EmptyImageFill;
    } else {
      this.fill = '#FFFFFF';
    }
  },

  setImage: function (image: IEditorImage): void {
    this.image = image || null;
    this.ready = this.image === null;
    this.optimalThumbLoaded = false;
  },

  /**
   * When the element is loaded, generate a glfx texture and apply the effects
   *
   * @param ready
   * @param isOptimal
   */
  setReady: function (ready: boolean, isOptimal: boolean): void {
    this.ready = ready;

    // @note: this will fire as ready if we load an image that cannot be found.
    // we still need to let the application know this is ready, even though its a bad image.
    if (ready) {
      // we now have a possibly intermediate thumb, start rendering effects
      if (this.effectsEnabled && this.image) {
        this.applyEffects();
      }

      if (isOptimal) {
        this.optimalThumbLoaded = true;
      }

      // we are only "ready" if the optimal thumb is loaded
      if (isOptimal || this.image === null) {
        this.fire('ready', { target: this });
      }
    }
  },

  /**
   * Lazy load the creation of the image. It will be created on the first render() call.
   *
   * @param ctx
   * @param noTransform
   */
  render: function (ctx: CanvasRenderingContext2D, noTransform: boolean): void {
    // if the element is not created yet and we do have an image object, create it now
    if (!this.elementAdded && this.image) {
      this.elementAdded = true;

      this._loadImage(this.image, true, true);
    }

    // if we are rendering a photo, and the image is not ready, stop now.
    if (this.type !== 'photo' && (!this.image || !this.ready)) {
      return;
    }
    if (this.clipTo) {
      this.renderWithClipTo(ctx);
    } else {
      // execute fabric.js rendering for images
      this.callSuper('render', ctx, noTransform);
    }
  },

  renderWithClipTo(ctx: CanvasRenderingContext2D): void {
    if (this.isNotVisible()) {
      return;
    }
    if (
      this.canvas &&
      this.canvas.skipOffscreen &&
      !this.group &&
      !this.isOnScreen()
    ) {
      return;
    }
    ctx.save();
    this._setupCompositeOperation(ctx);
    this.drawSelectionBackground(ctx);
    this.transform(ctx);
    this._setOpacity(ctx);
    this._setShadow(ctx, this);
    this.clipContext(this, ctx);
    if (this.shouldCache()) {
      this.renderCache();
      this.drawCacheOnCanvas(ctx);
    } else {
      this._removeCacheCanvas();
      this.dirty = false;
      this.drawObject(ctx);
      if (this.objectCaching && this.statefullCache) {
        this.saveState({ propertySet: 'cacheProperties' });
      }
    }

    if (this.group && this.group.photoObject && !this.image && this.customerEditable) {
      const bounds = this.group.getBoundingRect();
      const size = 1 / 2 * Math.min(bounds.width, bounds.height);
      let iconWidth;
      let iconHeight;
      if (personalizeImg.height >= personalizeImg.width) {
        iconHeight = size;
        iconWidth = size * personalizeImg.width / personalizeImg.height;
      } else {
        iconWidth = size;
        iconHeight = size * personalizeImg.height / personalizeImg.width;
      }
      const zoom = this.canvas.getZoom();
      ctx.drawImage(personalizeImg, -iconWidth / (2 * zoom), -iconHeight / (2 * zoom), iconWidth / zoom, iconHeight / zoom);
    }
    if (this.clipTo) {
      ctx.restore();
    }
    ctx.restore();
  },

  clipContext: function (
    receiver: fabric.Object,
    ctx: CanvasRenderingContext2D
  ) {
    ctx.save();
    ctx.beginPath();
    (receiver as IEditorShape).clipTo(ctx);
    ctx.clip();
  },

  transform: function (ctx: CanvasRenderingContext2D): void {
    let m;
    if (
      this.group &&
      !this.group._transformDone &&
      this.canvas.getObjects().indexOf(this.group) < 0
    ) {
      m = this.calcTransformMatrix();
    } else {
      m = this.calcOwnMatrix();
    }
    ctx.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
  },

  checkForExifRotationFix: function (
    _: never,
    __: HTMLImageElement,
    ___: CanvasRenderingContext2D,
    w: number,
    h: number
  ): { w: number; h: number; counterRotated: boolean } {
    return { w: w, h: h, counterRotated: false };
  },

  reRenderCanvas: function (): void {
    if (this.group && this.group.canvas) {
      this.group.canvas.renderAll();
    }
  },

  setCrossOrigin: function (element: HTMLImageElement): void {
    element.crossOrigin = 'anonymous';
  },

  _renderFill: function (ctx: CanvasRenderingContext2D): void {
    const elementToDraw = this._element;
    const effects = this.getEffects();
    const { w, h } = this.checkForExifRotationFix(
      effects,
      elementToDraw,
      ctx,
      this.width,
      this.height
    );
    const sW = Math.min(
      elementToDraw.naturalWidth || elementToDraw.width,
      w * this._filterScalingX
    );
    const sH = Math.min(
      elementToDraw.naturalHeight || elementToDraw.height,
      h * this._filterScalingY
    );
    const x = -w / 2;
    const y = -h / 2;
    const sX = Math.max(0, this.cropX * this._filterScalingX);
    const sY = Math.max(0, this.cropY * this._filterScalingY);

    if (
      (elementToDraw && elementToDraw.src) ||
      this.type == 'clipart' ||
      this.getEffects()
    ) {
      ctx.drawImage(
        elementToDraw,
        sX,
        sY,
        elementToDraw.width,
        elementToDraw.height,
        x,
        y,
        w,
        h
      );
    } else if (elementToDraw) {
      ctx.drawImage(elementToDraw, sX, sY, sW, sH, x, y, w, h);
    }
  },

  dispose: function (): void {
    this.removeTexture(this.cacheKey);
    this.removeTexture(this.cacheKey + '_filtered');
    this._cacheContext = undefined;

    ['_originalElement', '_element', '_filteredEl', '_cacheCanvas'].forEach(
      (element: string): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thisElement = this as any;
        // cleanUpJsdomNode exists on fabric util but it's missing from the type definition
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fabric.util as any).cleanUpJsdomNode(thisElement[element]);

        if (thisElement[element] instanceof HTMLCanvasElement) {
          thisElement[element].width = 0;
          thisElement[element].height = 0;
        }
        thisElement[element] = undefined;
      }
    );
  },

  _loadImage: function (
    image: IEditorImage,
    useElement: boolean,
    loadThumbIfCached: boolean,
    callback?: (selfImage?: fabric.Image) => void
  ): void {
    // set the element (<img />)'s cross origin
    const element = useElement ? this.getElement() : fabric.util.createImage();
    this.setCrossOrigin(element);
    const onLoad = (img: HTMLImageElement | null, isOptimal: boolean): void => {
      // There is a bug where sometimes the optimal thumb is returned way before the smaller cached
      // thumb. We are catching that edge case here.
      if (!isOptimal && this.optimalThumbLoaded) {
        return;
      }
      // if img is null, it means there was an error
      if (img === null) {
        this.image = null;
        this.fire('modified');
        // for photos in a group to let canvas know the object has changed
        if (this.group && this.group.canvas) {
          if (
            this.group.type == 'group' &&
            this.group.canvas.getObjects().indexOf(this.group) >= 0
          ) {
            this.group.canvas.fire('object:modified', { target: this.group });
          } else {
            this.group.canvas.fire('object:modified', { target: this });
          }
        }
        // @todo: we should implement functionality that automatically deletes an image object if it's not found
        // especially for things like clipart, which are meaningless when the image is lost.
      }
      let imageElementReplaced = false;
      if (this._element !== img) {
        this._element = img;
        this._originalElement = img;
        imageElementReplaced = true;
      }
      this.setReady(true, isOptimal);
      if (callback) {
        callback(this);
      }
      if (
        (this.group && imageElementReplaced) ||
        (this._element && !this._element.complete)
      ) {
        this.reRenderCanvas();
      }
    };

    let hasFilterEffect = false;
    let imageWithFrame = false;
    if (this.getEffects() && this.getEffects().filter) {
      hasFilterEffect = true;
    }

    if (
      !this.image.type &&
      this.group &&
      this.group.frameObject &&
      this.group.frameObject.image != null
    ) {
      imageWithFrame = true;
    }

    ImageCache.Instance.smartLoad(
      element,
      image,
      ImageCache.Instance.getTotalZoom(this.canvas, this),
      loadThumbIfCached,
      onLoad,
      this.type === 'photo' && this.hasAutoCorrect(),
      this.type === 'photo' && this.hasAutoRedEye(),
      hasFilterEffect,
      imageWithFrame,
      this
    );
  },

  createCanvas: function (): HTMLCanvasElement {
    return document.createElement('canvas');
  },

  /**
   * All the interaction with glfx belongs here.
   */
  applyEffects: function (): void {
    if (!this.image || !this.ready || !this.effectsEnabled) {
      return;
    }

    // applying effects is expensive, so let's only do it when really necessary
    const effects = this.getEffects();
    if (!effects) {
      return;
    }

    // remove red eye
    // we are intentionally doing this before applying the filters
    if (effects && effects.redEyes && effects.redEyes.length > 0) {
      // paint the original image
      const canvas = fabric.util.createCanvasElement();
      canvas.width = this._originalElement.width;
      canvas.height = this._originalElement.height;
      this._element = canvas;
      // noinspection JSUnusedGlobalSymbols = bug in PHPStorm analysis?  It is clearly used right below
      this._elementCtx = this._element.getContext('2d');
      this._elementCtx.clearRect(
        0,
        0,
        this._originalElement.width,
        this._originalElement.height
      );
      this._elementCtx.drawImage(
        this._originalElement,
        0,
        0,
        this._originalElement.width,
        this._originalElement.height
      );
      const tmpElement = this._originalElement;
      tmpElement.src = null;
      this._originalElement = canvas;

      // each red eye is an object of {x, y, r}
      const el = this._element;
      const image = this.image;
      const imageData = this._elementCtx.getImageData(
        0,
        0,
        el.width,
        el.height
      );
      const pixels = imageData.data;
      const widthOffset = image.width / 2;
      const heightOffset = image.height / 2;

      const SFMCC_RED_THRESHOLD = 69;
      const SFMCC_GREEN_RATIO = 1.9;

      effects.redEyes.forEach(function (redEye: IEditorRedEye): void {
        const sourceScale = el.width / image.width;

        // calculate placement to scale of source
        // TODO: Is it r or radius????
        const rR = Math.round(redEye.r * sourceScale);
        const rX = Math.round((redEye.x + widthOffset) * sourceScale);
        const rY = Math.round((redEye.y + heightOffset) * sourceScale);
        const rD = rR * 2;

        // calculate origin
        const originX = rX - rR;
        const originY = rY - rR;

        // perform red eye
        for (let x = 0; x < rD; x++) {
          for (let y = 0; y < rD; y++) {
            if (Math.sqrt(Math.pow(x - rR, 2) + Math.pow(y - rR, 2)) < rR) {
              const i = (x + originX + (y + originY) * el.width) * 4;

              if (
                pixels[i] > pixels[i + 1] * SFMCC_GREEN_RATIO &&
                pixels[i] > SFMCC_RED_THRESHOLD
              ) {
                pixels[i] = Math.min(pixels[i], pixels[i + 1], pixels[i + 2]);
              }
            }
          }
        }
      });
      this._elementCtx.putImageData(imageData, 0, 0);
    }

    // figure out filters
    const filters: IBaseFilter[] = [];
    let saturation = effects.saturation || 0;
    if (effects.filter) {
      const duotoneColor = parseInt('00CCFF', 16);
      const color = {
        r: (100 * ((duotoneColor >>> 16) & 0xff)) / 256.0,
        g: (100 * ((duotoneColor >>> 8) & 0xff)) / 256.0,
        b: (100 * (duotoneColor & 0xff)) / 256.0,
      };
      const larkColor = {
        r: 100 / 2,
        g: (100 * 1.03) / 2,
        b: (100 * 1.05) / 2,
      };
      const junoColor = {
        r: (100 * 1.01) / 2,
        g: (100 * 1.04) / 2,
        b: 100 / 2,
      };
      const cremaColor = {
        r: (100 * 1.04) / 2,
        g: 100 / 2,
        b: (100 * 1.02) / 2,
      };
      const perpetuaColor = {
        r: (100 * 1.05) / 2,
        g: (100 * 1.1) / 2,
        b: 100 / 2,
      };
      const hudsonColor = {
        r: 100 / 2,
        g: 100 / 2,
        b: (100 * 1.25) / 2,
      };
      const kelvinColor = {
        r: (100 * 1.15) / 2,
        g: (100 * 1.05) / 2,
        b: 100 / 2,
      };
      switch (effects.filter) {
        case 'sepia':
          filters.push(new fabric.Image.filters.Sepia());
          break;

        case 'black&white':
          saturation = -180;
          break;

        case 'duotone':
          saturation = 0;

          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                color.r +
                '%' +
                ',' +
                color.g +
                '%' +
                ',' +
                color.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          break;

        // Clarendon: adds light to lighter areas and dark to darker areas
        case 'clarendon':
          // noinspection TypeScriptValidateJSTypes
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.1 }));
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.15 })
          );
          break;

        // Gingham: Vintage-inspired, taking some color out
        case 'gingham':
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.4 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: -0.15 }));
          break;

        // Moon: B/W, increase brightness and decrease contrast
        case 'moon':
          filters.push(new fabric.Image.filters.Saturation({ saturation: -1 }));
          filters.push(new fabric.Image.filters.Contrast({ contrast: -0.04 }));
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Lark: Brightens and intensifies colours but not red hues
        case 'lark':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.08 })
          );

          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                larkColor.r +
                '%' +
                ',' +
                larkColor.g +
                '%' +
                ',' +
                larkColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.12 })
          );
          break;

        // Reyes: a new vintage filter, gives your photos a “dusty” look
        case 'reyes':
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.48 })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.13 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: -0.05 }));
          break;

        // Juno: Brightens colors, and intensifies red and yellow hues
        case 'juno':
          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                junoColor.r +
                '%' +
                ',' +
                junoColor.g +
                '%' +
                ',' +
                junoColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.3 })
          );
          break;

        // Slumber: Desaturates the image as well as adds haze for a retro, dreamy look – with an emphasis on blacks and blues
        case 'slumber':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: -0.5 })
          );
          break;

        // Crema: Adds a creamy look that both warms and cools the image
        case 'crema':
          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                cremaColor.r +
                '%' +
                ',' +
                cremaColor.g +
                '%' +
                ',' +
                cremaColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: -0.05 })
          );
          break;

        // Ludwig: A slight hint of desaturation that also enhances light
        case 'ludwig':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.05 })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: -0.03 })
          );
          break;

        // Perpetua: Adding a pastel look, this filter is ideal for portraits
        case 'perpetua':
          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                perpetuaColor.r +
                '%' +
                ',' +
                perpetuaColor.g +
                '%' +
                ',' +
                perpetuaColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          break;

        // Aden: This filter gives a blue/pink natural look
        case 'aden':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 228 + ',' + 130 + ',' + 225 + ')',
              alpha: 0.13,
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.3 })
          );

          break;

        // Amaro: Adds light to an image, with the focus on the centre
        case 'amaro':
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.3 })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.15 })
          );
          break;

        // Mayfair: Applies a warm pink tone, subtle vignetting to brighten the photograph center and a thin black border
        case 'mayfair':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 230 + ',' + 115 + ',' + 108 + ')',
              alpha: 0.05,
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.15 })
          );
          break;

        // Rise: Adds a "glow" to the image, with softer lighting of the subject
        case 'rise':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 170 + ',' + 0 + ')',
              alpha: 0.1,
            })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.09 })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.1 })
          );
          break;

        // Hudson: Creates an "icy" illusion with heightened shadows, cool tint and dodged center
        case 'hudson':
          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                hudsonColor.r +
                '%' +
                ',' +
                hudsonColor.g +
                '%' +
                ',' +
                hudsonColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.1 }));
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.15 })
          );
          break;

        // Valencia: Fades the image by increasing exposure and warming the colors, to give it an antique feel
        case 'valencia':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 225 + ',' + 80 + ')',
              alpha: 0.08,
            })
          );
          filters.push(new FabricImageFilters.Saturation({ saturation: 0.1 }));
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.05 }));
          break;

        // X-Pro II: Increases color vibrance with a golden tint, high contrast and slight vignette added to the edges
        case 'xpro2':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 255 + ',' + 0 + ')',
              alpha: 0.07,
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.2 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.015 }));
          break;

        case 'willow':
          filters.push(new fabric.Image.filters.Saturation({ saturation: -1 }));
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 100 + ',' + 28 + ',' + 210 + ')',
              alpha: 0.03,
            })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Sierra: Gives a faded, softer look
        case 'sierra':
          filters.push(new fabric.Image.filters.Contrast({ contrast: -0.15 }));
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.1 })
          );
          break;

        // Lo-Fi: Enriches color and adds strong shadows through the use of saturation and "warming" the temperature
        case 'lofi':
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.15 }));
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.2 })
          );
          break;

        // Hefe: Hight contrast and saturation, with a similar effect to Lo-Fi but not quite as dramatic
        case 'hefe':
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.1 }));
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.15 })
          );
          break;

        // Nashville: Warms the temperature, lowers contrast and increases exposure to give a light "pink" tint – making it feel "nostalgic"
        case 'nashville':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 220 + ',' + 115 + ',' + 188 + ')',
              alpha: 0.12,
            })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: -0.05 }));
          break;

        // Stinson: washing out the colors ever so slightly
        case 'stinson':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.3 })
          );
          break;

        // Vesper: adds a yellow tint that
        case 'vesper':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 225 + ',' + 0 + ')',
              alpha: 0.05,
            })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.06 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.06 }));
          break;

        // Earlybird: Gives an older look with a sepia tint and warm temperature
        case 'earlybird':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 165 + ',' + 40 + ')',
              alpha: 0.2,
            })
          );
          break;

        // Brannan: Increases contrast and exposure and adds a metallic tint
        case 'brannan':
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.2 }));
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 140 + ',' + 10 + ',' + 185 + ')',
              alpha: 0.1,
            })
          );
          break;

        // Sutro: Burns photo edges, increases highlights and shadows dramatically with a focus on purple and brown colors
        case 'sutro':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: -0.1 })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: -0.1 })
          );
          break;

        // Toaster: Ages the image by "burning" the centre and adds a dramatic vignette
        case 'toaster':
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.1 })
          );
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 145 + ',' + 0 + ')',
              alpha: 0.2,
            })
          );
          break;

        // Walden: Increases exposure and adds a yellow tint
        case 'walden':
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 225 + ',' + 0 + ')',
              alpha: 0.02,
            })
          );
          break;

        // 1977: The increased exposure with a red tint gives the photograph a rosy, brighter, faded look.
        case 'nineteen':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 25 + ',' + 0 + ')',
              alpha: 0.15,
            })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Kelvin: Increases saturation and temperature to give it a radiant "glow"
        case 'kelvin':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 140 + ',' + 0 + ')',
              alpha: 0.1,
            })
          );

          filters.push(
            new fabric.Image.filters.BlendColor({
              color:
                'rgb' +
                '(' +
                kelvinColor.r +
                '%' +
                ',' +
                kelvinColor.g +
                '%' +
                ',' +
                kelvinColor.b +
                '%' +
                ')',
              mode: 'multiply',
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.35 })
          );
          break;

        // Maven: darkens images, increases shadows, and adds a slightly yellow tint overal
        case 'maven':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 225 + ',' + 240 + ',' + 0 + ')',
              alpha: 0.1,
            })
          );
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.25 })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.05 }));
          break;

        // Ginza: brightens and adds a warm glow
        case 'ginza':
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.06 })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Skyline: brightens to the image pop
        case 'skyline':
          filters.push(
            new fabric.Image.filters.Saturation({ saturation: 0.35 })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Dogpatch: increases the contrast, while washing out the lighter colors
        case 'dogpatch':
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.15 }));
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Brooklyn
        case 'brooklyn':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 25 + ',' + 240 + ',' + 252 + ')',
              alpha: 0.05,
            })
          );
          filters.push(
            new FabricImageFilters.SepiaWithConversionAmount({ amount: 0.3 })
          );
          break;

        // Helena: adds an orange and teal vibe
        case 'helena':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 208 + ',' + 208 + ',' + 86 + ')',
              alpha: 0.2,
            })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.15 }));
          break;

        // Ashby: gives images a great golden glow and a subtle vintage feel
        case 'ashby':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 160 + ',' + 25 + ')',
              alpha: 0.1,
            })
          );
          filters.push(
            new fabric.Image.filters.Brightness({ brightness: 0.1 })
          );
          break;

        // Charmes: a high contrast filter, warming up colors in your image with a red tint
        case 'charmes':
          filters.push(
            new FabricImageFilters.ColorFilter({
              color: 'rgb' + '(' + 255 + ',' + 50 + ',' + 80 + ')',
              alpha: 0.12,
            })
          );
          filters.push(new fabric.Image.filters.Contrast({ contrast: 0.05 }));
          break;

        default:
          console.warn('Unknown filter: ' + effects.filter);
      }
    }

    const brightness = effects.brightness || 0;
    const contrast = effects.contrast || 0;
    const sharpness = effects.sharpness || 0;
    const hue = effects.hue || 0;

    if (brightness !== 0) {
      filters.push(
        new fabric.Image.filters.Brightness({ brightness: brightness / 180 })
      );
    }

    if (contrast !== 0) {
      filters.push(
        new fabric.Image.filters.Contrast({ contrast: contrast / 180 })
      );
    }

    if (hue !== 0) {
      filters.push(
        new fabric.Image.filters.HueRotation({ rotation: hue / 180 })
      );
    }

    if (saturation !== 0) {
      filters.push(
        new fabric.Image.filters.Saturation({ saturation: saturation / 180 })
      );
    }

    if (sharpness !== 0) {
      if (sharpness >= 0) {
        filters.push(
          new FabricImageFilters.UnsharpMask({
            radius: 10,
            strength: sharpness / 20,
          })
        );
      } else {
        filters.push(
          new FabricImageFilters.BlurFilter({ blur: Math.abs(sharpness) })
        );
      }
    }

    this.applyFilters(filters);
  },

  toObject: function (propertiesToInclude: boolean): fabric.Object {
    const o = fabric.util.object.extend(
      this.callSuper('toObject', propertiesToInclude),
      {
        image: this.image,
        effects: this.effects ? fabric.util.object.clone(this.effects) : null,
        locked: this.locked,
        fixed: this.fixed,
      }
    );

    delete o.src;

    return o;
  },

  fullSizeImageLoaded: function (elementToDraw: HTMLImageElement): boolean {
    const fullSizeImage = this.image.getThumb(ThumbSize.FULL);
    if (fullSizeImage) {
      const decodedElementToDrawSrc = decodeURIComponent(elementToDraw.src);
      const decodedFullSizeImageSrc = decodeURIComponent(
        this.image.urlPrefix + fullSizeImage.name
      );
      return (
        fullSizeImage &&
        decodedElementToDrawSrc.indexOf(decodedFullSizeImageSrc) >= 0
      );
    }
    return false;
  },

  replaceImage: function (
    image: IEditorImage,
    reset: boolean,
    callback: () => void
  ): void {
    // we are adding the element below, so mark true if not already
    if (!this.elementAdded) {
      this.elementAdded = true;
    }

    // replace image
    this.image = image;
    this.cacheKey = '';

    // override callback if we are re-setting effects
    if (reset) {
      const initialCallback = callback;
      // ensure auto correct is off, otherwise it will load corrected image
      this.getEffects().autoCorrect = false;

      callback = (): void => {
        // if we are re-setting
        this.resetPhotoSliders();

        if (initialCallback) {
          initialCallback();
        }
      };
    }

    this._loadImage(image, false, true, callback);
  },

  resetPhotoSliders: function (): void {
    const effects = this.getEffects();

    if (!effects) {
      return;
    }

    // re-set effects
    delete effects.brightness;
    delete effects.contrast;
    delete effects.sharpness;
    delete effects.saturation;
    delete effects.hue;

    // re-set opacity
    (this.group || this).opacity = 1;

    // apply effects
    this.applyEffects();
  },

  resetOrientation: function (): void {
    if (this.getFlipX()) {
      this.flipHorizontal();
    }
    if (this.getFlipY()) {
      this.flipVertical();
    }
  },

  setAutoCorrect: function (autoCorrect: boolean, callback: () => void): void {
    // set data
    this.getEffects().autoCorrect = autoCorrect;

    // replace image (re-load)
    this._loadImage(this.image, false, false, callback);
  },

  setAutoRedEye: function (autoRedEye: boolean, callback: () => void): void {
    // set data
    this.getEffects().autoRedEye = autoRedEye;

    // replace image (re-load)
    this._loadImage(this.image, false, false, callback);
  },

  hasAutoRedEye: function (): boolean {
    if (!this.effectsEnabled) {
      return false;
    }
    return !!this.getEffects() && this.getEffects().autoRedEye;
  },

  hasAutoCorrect: function (): boolean {
    if (!this.effectsEnabled) {
      return false;
    }
    return !!this.getEffects() && this.getEffects().autoCorrect;
  },

  getEffects: function (): IEffects {
    if (this.group && this.group.type == 'group' && this.effects) {
      if (this.group.canvas && this.group.canvas.getObjects().indexOf(this.group._findGroupByUid(this.canvas))
      ) {
        return this.effects;
      }
    }
    return (this.group || this).effects;
  },

  removeImage: function (): void {
    this.image = null;
    this.ready = false;

    if (this._element.nodeName === 'IMG') {
      this._element.src = '';
    }
  },

  flipVertical: function (): void {
    this.flipY = !this.flipY;
  },

  getFlipY: function (): boolean {
    return this.flipY;
  },

  flipHorizontal: function (): void {
    this.flipX = !this.flipX;
  },

  getFlipX: function (): boolean {
    return this.flipX;
  },

  resetEffects: function (): void {
    this.effects = {};
    if (this.shadow) {
      this.shadow = {};
    }
    this.applyEffects();
  },

  getMinWidth: function (): number {
    return 25;
  },

  getMinHeight: function (): number {
    return 25;
  },
});
