import {
  IEditorPage,
  IEditorShape,
  IPageState,
  IPageStateStack
} from '@/interfaces/editorInterfaces';

import { MAX_UNDO_STACK_SIZE } from '@/modules/editor/editorConstants';
import { Helpers } from '@/modules/editor/helpers';
import { IProjectInfo } from '@/interfaces/projectInterface';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { cloneDeep } from 'lodash';

export const useUndoRedoStore = defineStore('undoRedo', () => {
  const undoRedoState = ref <Map<number, IPageStateStack>>(new Map<number, IPageStateStack>()); // map of page index and undo/redo stack
  // current state of the projects every time user switches to design phase
  let originalDesignPhaseProjects: IProjectInfo[] = [];

  // initial phase editor projects are set only once when the user first enters the editor
  let initialEditorProjects: IProjectInfo[] = [];

  function setOriginalDesignPhaseProject(projects: IProjectInfo[]): void {
    originalDesignPhaseProjects = projects;
  }

  function clearOriginalDesignPhaseProject(): void {
    originalDesignPhaseProjects = [];
  }

  function setInitialEditorProject(projects: IProjectInfo[]): void {
    initialEditorProjects = projects;
  }

  function clearUndoRedoStates(): void {
    undoRedoState.value.clear();
    initialEditorProjects = [];
    originalDesignPhaseProjects = [];
  }

  function getInitialEditorProjects(): IProjectInfo[] {
    return initialEditorProjects;
  }

  function getOriginalDesignPhaseProjects(): IProjectInfo[] {
    return originalDesignPhaseProjects;
  }

  function keepCurrentPageState(page: IEditorPage, pageIndex: number, selectedObject: IEditorShape|null): void {
    if (!undoRedoState.value.has(pageIndex)) {
      undoRedoState.value.set(pageIndex, {
        index: -1,
        pageStates: [],
      });
    }
    const pageStateStack = undoRedoState.value.get(pageIndex) as IPageStateStack;
    if (pageStateStack.pageStates.length === 0 || !Helpers.arePagesEqual(pageStateStack.pageStates[pageStateStack.index].page, page)) {
      if (pageStateStack.index < pageStateStack.pageStates.length - 1) {
        pageStateStack.pageStates.splice(pageStateStack.index + 1);
      }
      if (pageStateStack.pageStates.length > MAX_UNDO_STACK_SIZE) {
        pageStateStack.pageStates.shift();
        pageStateStack.index--;
      }
      const uid = selectedObject?.uid || '';
      pageStateStack.pageStates.push({page: page, selectedObjectUid: uid});
      pageStateStack.index = pageStateStack.pageStates.length - 1;
    }
  }

  function undoPage(pageIndex: number): IPageState|null {
    if (hasUndo(pageIndex)) {
      const pageStateStack = undoRedoState.value.get(pageIndex) as IPageStateStack;
      pageStateStack.index--;
      return cloneDeep(pageStateStack.pageStates[pageStateStack.index]);
    }
    return null;
  }

  function redoPage(pageIndex: number): IPageState|null {
    if (hasRedo(pageIndex)) {
      const pageStateStack = undoRedoState.value.get(pageIndex) as IPageStateStack;
      pageStateStack.index++;
      return cloneDeep(pageStateStack.pageStates[pageStateStack.index]);
    }
    return null;
  }

  function hasUndo(pageIndex: number): boolean {
    const pageStateStack = undoRedoState.value.get(pageIndex);
    return pageStateStack ? pageStateStack.index > 0 : false;
  }

  function hasRedo(pageIndex: number): boolean {
    const pageStateStack = undoRedoState.value.get(pageIndex);
    return pageStateStack ? pageStateStack.index < pageStateStack.pageStates.length - 1 : false;
  }

  return {
    undoRedoState,
    originalDesignPhaseProjects,
    initialEditorProjects,
    setOriginalDesignPhaseProject,
    clearOriginalDesignPhaseProject,
    setInitialEditorProject,
    clearUndoRedoStates,
    getInitialEditorProjects,
    getOriginalDesignPhaseProjects,
    keepCurrentPageState,
    undoPage,
    redoPage,
    hasUndo,
    hasRedo,
  };


});

