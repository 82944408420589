import {defineStore} from 'pinia';
import {PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels} from '@/constants/topicEnums';
import {IBaseProjectsList, IProjectInfo} from '@/interfaces/projectInterface';
import {ref} from 'vue';
import {ServiceLocator, ServiceType} from '@/services/serviceLocator';
import {IProductInfo} from '@/interfaces/baseInterface';
import { TopicWildCard } from '@/constants/topicConstants';
import { ISolaceClient } from '@/interfaces/iSolaceClient';

export const useBaseProjectStore = defineStore('baseProject', () => {
  const baseProjectsList = ref<IBaseProjectsList[] | undefined>();
  let solaceClient:ISolaceClient|undefined;
  const solaceReady:Promise<void> = new Promise((resolve:() => void, reject:(err:unknown) => void) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then((res) => {
      solaceClient = res;
      resolve();
    }, err => {
      reject(err);
    });
  });

  const publishedListOfProjectIds: number[] = [];

  function subscribeToBaseProjects(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const projectSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_BASE_PROJECTS_CURRENT);
      if (projectSub) {
        const replacedProjectSub = projectSub.replace(TopicVarLevels.BASE_ID, TopicWildCard);
        solaceClient.mapSubscriptionJson<IProjectInfo[]>(replacedProjectSub, (payload, topic) => {
          const publisherBaseId = topic.split('/')[8];
          if (baseProjectsList.value) {
            const baseProjectIndex = baseProjectsList.value.findIndex(
              (baseProject) => baseProject.base_id === Number(publisherBaseId)
            );
            if (baseProjectIndex !== -1) {
              baseProjectsList.value[baseProjectIndex].projects = payload;
            } else {
              baseProjectsList.value.push({
                base_id: Number(publisherBaseId),
                projects: payload,
              });
            }
          } else {
            baseProjectsList.value = [
              {
                base_id: Number(publisherBaseId),
                projects: payload,
              },
            ];
          }

          console.log('Projects result: ' + 'projectsData', payload);
        });
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }


  function queryBaseProjects(base: IProductInfo): void {
    getCacheForBaseProjects(base).then(() => {
      console.debug('Successfully retrieved base projects from cache');
    }).catch(error => {
      console.debug(error);
      triggerBaseProjects(base);
    });

  }

  function getCacheForBaseProjects(base: IProductInfo): Promise<void> {
    return new Promise((resolve:() => void, reject:(error:string) => void) => {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }

        let subTopic = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_CATALOG_BASE_PROJECTS_CURRENT);
        if (subTopic) {
          subTopic = subTopic.replace(TopicVarLevels.BASE_ID, base.id.toString());
          solaceClient.checkCache(subTopic).then(() => {
            resolve();
          }, err => {
            reject(err);
          });

        } else {
          reject('Could not find topic for subscribing to base projects: ' + SubscribeTopicKeys.SUB_CATALOG_BASE_PROJECTS_CURRENT);
        }
      }, err => {
        reject(err);
      });
    });
  }

  function triggerBaseProjects(base: IProductInfo): void {
    // if user fast click on bases there is a chance that the trigger is already published but the projects list in
    // project stores is not updated yet as it hasn't received the response yet
    if (!publishedListOfProjectIds.includes(base.id)) {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }

        let reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_CATALOG_BASE_PROJECTS_TRIGGER);
        if (reqTopic) {
          publishedListOfProjectIds.push(base.id);
          reqTopic = reqTopic.replace(TopicVarLevels.BASE_ID, base.id.toString());
          solaceClient.publishMessageString(reqTopic, undefined, err => {
            console.warn('Error publishing base projects trigger', err);
          });
        } else {
          console.warn(
            'Could not find topic for query base projects: ' +
            PublishTopicKeys.PUB_CATALOG_BASE_PROJECTS_TRIGGER
          );
        }
      }, err => {
        console.error('Getting solace failed', err);
      });
    }
  }


  return {
    baseProjectsList,
    subscribeToBaseProjects,
    queryBaseProjects
  };
});
