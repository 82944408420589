<template>
  <v-footer id="footer">
    <!--    <div id="footer-top-container">
      <div class="logo-and-links-container">
        <div class="logo"></div>
        <div class="social-media-container">
          <v-btn
            id="facebook"
            class="social-media-link"
            density="comfortable"
            variant="plain"
          ></v-btn>
          <v-btn
            id="instagram"
            class="social-media-link"
            density="comfortable"
            variant="plain"
          ></v-btn>
          <v-btn
            id="x"
            class="social-media-link"
            density="comfortable"
            variant="plain"
          ></v-btn>
          <v-btn
            id="youtube"
            class="social-media-link"
            density="comfortable"
            variant="plain"
          ></v-btn>
        </div>
      </div>
    </div>-->
    <div class="copyright-policy-container">
      <v-list class="policy-container">
        <template
          v-for="policy in policies"
          :key="policy.value"
        >
          <v-btn
            variant="plain"
            :ripple="false"
            class="policy-item text-capitalize"
            :to="policy.path"
          >
            {{ policy.title }}
          </v-btn>
        </template>
      </v-list>
      <p>
        {{ t('Footer.copyright', { date: new Date().getFullYear() }) }}
      </p>
    </div>
  </v-footer>
</template>
<script setup lang="ts">
import { ref } from 'vue';import { useI18n } from 'vue-i18n';

const { t } = useI18n();
// import { IFooterLinks } from '@/interfaces/uiElementInterfaces';

// When we actually pass the titles, use this...
// defineProps({
//   titles: {
//     type: Array as PropType<IFooterLinks[]>,
//     required: false,
//   },
// });
const policies = ref([
  {
    value: 1,
    title: t('Footer.intellectualPropertyPolicy'),
    path: '/intellectualPropertyPolicy',
  },
  {
    value: 2,
    title: t('Footer.termsOfServicePolicy'),
    path: '/termsOfService',
  },
  {
    value: 3,
    title: t('Footer.privacyPolicy'),
    path: '/privacyPolicy',
  },
  {
    value: 4,
    title: t('Footer.faq'),
    path: '/faq',
  },
]);
</script>
<style lang="scss">

#footer {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;


  #footer-top-container {
    border-top: 1px solid #f34490;
    margin: 0 0 3em 0;
    padding-top: 3em;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #footer .logo {
    width: 178px;
    height: 50px;
    background: url('../assets/images/printsuite-logo.png') no-repeat center;
    background-size: cover;
  }

  .logo-and-links-container {
    display: flex;
    width: 100%;
    padding: 0 2em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
  }

  .social-media-link {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    margin: 0 5px 10px;
    box-shadow: none;
    &.v-btn--variant-plain {
      opacity: 1;
      &:hover {
        opacity: var(--v-button-hover-opacity);
      }
    }
  }

  .social-media-link#facebook {
    background-image: url('../assets/images/footer/facebook.svg');
  }

  .social-media-link#x {
    background-image: url('../assets/images/footer/x.svg');
  }

  .social-media-link#youtube {
    background-image: url('../assets/images/footer/youtube.svg');
  }

  .social-media-link#instagram {
    background-image: url('../assets/images/footer/instagram.svg');
  }

  .policy-container {
    background-color: #e6e6e6;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .copyright-policy-container {
    background-color: #e6e6e6;
    margin: 0;
    padding: 2em 0;
    width: 100%;
  }

  .policy-item {
    max-width: 20%;
    &.v-btn--variant-plain:focus {
      opacity: .62;
    }
  }

  p {
    text-align: center;
  }
}

</style>
