export interface IProductInfo {
  attribute_list: IAttributeList[];
  classification: string;
  updated: number;
  default_sku_id: number;
  dynamic_data: IDynamicData;
  product_sub_type: string;
  id: number;
  production_type: string;
  name: string;
  sku_data: ISkuData[];
  tax_code: string;
  translations?: { [key: string]: IProductTranslations };
  previews: IPreviewData[];
  tags: string[];
}

export interface IAttributeList {
  default_value: string;
  name: string;
  presentation_type: string;
  schema_type: string;
  values?: IAttributeValue[];

}

export interface IAttributeValue {
  name: string;
  swatch_file?: string;
}

export enum PresentationType {
  ListBox = 'list',
  ImageListBox = 'image-list',
  ColorListBox = 'color-list',
  ImageSwatch = 'swatch',
  ColorSwatch = 'color-swatch',
  ListSwatch = 'list-swatch',
  Range = 'range',
  Hidden = 'hidden',
}

export enum SchemaType {
  Text = 'text',
  Orientation = 'orientation',
  collection = 'collection',
  WrapEdgeStyle = 'wrap_edge_style',
}

export interface IDynamicData {
  allow_borders?: boolean;
  aspect_x?: number;
  aspect_y?: number;
  binding_type?: string;
  bleed_back_b?: number;
  bleed_back_l?: number;
  bleed_back_r?: number;
  bleed_back_t?: number;
  bleed_safety_b?: number;
  bleed_safety_l?: number;
  bleed_safety_r?: number;
  bleed_safety_t?: number;
  bleed_side_b?: number;
  bleed_side_l?: number;
  bleed_side_r?: number;
  bleed_side_t?: number;
  cost_per_page?: number;
  cover_height?: number;
  cover_photo_h?: number;
  cover_photo_margin_b?: number;
  cover_photo_margin_l?: number;
  cover_photo_margin_r?: number;
  cover_photo_margin_t?: number;
  cover_photo_minimum_d?: number;
  cover_photo_optimal_d?: number;
  cover_photo_w?: number;
  cover_photo_warning_d?: number;
  cover_photo_wrap_b?: number;
  cover_photo_wrap_l?: number;
  cover_photo_wrap_r?: number;
  cover_photo_wrap_t?: number;
  cover_width?: number;
  enforce_production_size?: boolean;
  gutter?: number;
  has_cover_photo?: string;
  has_keyhole?: string;
  has_printable_jacket?: string;
  inside_covers?: string;
  keyhole_position_l?: number;
  keyhole_position_t?: number;
  keyhole_size_h?: number;
  keyhole_size_w?: number;
  keyhole_svg_string?: string;
  mask_margin_b?: number;
  mask_margin_l?: number;
  mask_margin_r?: number;
  mask_margin_t?: number;
  max_pages?: number;
  min_pages?: number;
  minimum_d?: number;
  minimum_x?: number;
  minimum_y?: number;
  number_of_fly_pages?: number;
  optimal_d?: number;
  optimal_x?: number;
  optimal_y?: number;
  output_format?: string;
  printable_jacket_bleed_b?: number;
  printable_jacket_bleed_l?: number;
  printable_jacket_bleed_r?: number;
  printable_jacket_bleed_t?: number;
  printable_jacket_flap_b?: number;
  printable_jacket_flap_f?: number;
  printable_jacket_h?: number;
  printable_jacket_minimum_d?: number;
  printable_jacket_optimal_d?: number;
  printable_jacket_w?: number;
  printable_jacket_warning_d?: number;
  rotatable?: number;
  ruler_unit?: string;
  spine_extra_page_size?: number;
  spine_extra_page_threshold?: number;
  spine_orientation?: string;
  spine_size_by_max_page_count?: ISpineSizeByMaxPageCount;
  spine_width?: number;
  warning_d?: number;
  warning_x?: number;
  warning_y?: number;
  wrap_back_presentation?: string;
  wrap_corner_type?: string;
  wrap_edge_style?: string;
  wrap_options?: string;
}

export interface ISpineSizeByMaxPageCount {
  maxPageCount?: string;
}

export interface ISkuData {
  dynamic_data: IDynamicData;
  enabled: boolean;
  fulfillment_driver_id: number;
  guid: string;
  item_code: string;
  lab_process: string;
  options: { [key: string]: string };
  price_data: IPriceData;
  product_sku_id: number;
  service_level_id: number;
  sf_item_code: string;
  sfid: string;
  status_code: number;
  tertiary_item_code: string;
  upsell_skus?: string;
  visible: boolean;
  shipping_profile: string;
}

export interface IPriceData {
  base_price: number;
  currency_iso: string;
  package_quantity?: number;
  price_f: string;
  base_price_f: string;
  qpb?: { [key: string]: { [key: string]: number } };
}

export interface IProductTranslations {
  actual_price_text?: string;
  description: string;
  display_name: string;
  factory_description?: string;
  long_description: string;
  option_translations?: { [key: string]: IOptionTranslation };
  original_price_text?: string;
  savings_text?: string;
  short_description: string;
}

export interface IOptionTranslation {
  name: string;
  values: { [key: string]: string };
}

export interface IPreviewData {
  attributes?:   { margin_left?: string, margin_right?: string, margin_top?: string, margin_bottom?: string };
  description:   string;
  file_path:     string;
  flags?:        { [key: string]: string };
  guid:          string;
  height:        number;
  ite_string?:   string;
  media_context: string;
  media_type:    string;
  thumbs:        IThumb[];
  title:         string;
  url:           string;
  width:         number;
  id: number;
}

export interface IThumb {
  file_path:  string;
  height:     number;
  thumb_type: string;
  url:        string;
  width:      number;
}

