import {createRouter, createWebHistory} from 'vue-router';
import {useTenantStore} from '@/stores/tenantStore';
import {
  ROUTE_DASHBOARD,
  ROUTE_PRODUCT_CATALOG,
  ROUTE_HOW_IT_WORKS,
  ROUTE_LANDING,
  ROUTE_EDITOR,
  ROUTE_INTELLECTUAL_PROPERTY_POLICY,
  ROUTE_PRIVACY_POLICY,
  ROUTE_DESIGN_MALL_INFO,
  ROUTE_PERSONALIZED_PRINT_ON_DEMAND_INFO,
  ROUTE_TERMS_OF_SERVICE,
  ROUTE_FAQ,
  ROUTE_QUESTIONNAIRE,
  ROUTE_STORES,
  ROUTE_SETTINGS,
  ROUTE_404,
  ROUTE_ORDERS,
  ROUTE_BILLING,
  ROUTE_TEMPLATES,
  CHILD_ROUTE_STORES_SHOP_LIST,
  CHILD_ROUTE_STORES_SHOP_PRODUCTS,
  CHILD_ROUTE_STORES_SHOP_SETTINGS,
  CHILD_ROUTE_STORES_SHOP_PRODUCT_SUMMARY_DETAILS, ROUTE_HELP_SUPPORT, ROUTE_EMAIL_SUPPORT
} from '@/constants/routeConstants';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: ROUTE_DASHBOARD.path,
      name: ROUTE_DASHBOARD.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_PRODUCT_CATALOG.path,
      name: ROUTE_PRODUCT_CATALOG.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_LANDING.path,
      name: ROUTE_LANDING.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_HOW_IT_WORKS.path,
      name: ROUTE_HOW_IT_WORKS.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_PERSONALIZED_PRINT_ON_DEMAND_INFO.path,
      name: ROUTE_PERSONALIZED_PRINT_ON_DEMAND_INFO.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_DESIGN_MALL_INFO.path,
      name: ROUTE_DESIGN_MALL_INFO.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_EDITOR.path,
      name: ROUTE_EDITOR.name,
      component: () => import('@/components/StubComponent.vue'),
      meta: {transition: 'unset'}
    },
    {
      path: ROUTE_INTELLECTUAL_PROPERTY_POLICY.path,
      name: ROUTE_INTELLECTUAL_PROPERTY_POLICY.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_PRIVACY_POLICY.path,
      name: ROUTE_PRIVACY_POLICY.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_TERMS_OF_SERVICE.path,
      name: ROUTE_TERMS_OF_SERVICE.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_FAQ.path,
      name: ROUTE_FAQ.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_QUESTIONNAIRE.path,
      name: ROUTE_QUESTIONNAIRE.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_STORES.path,
      name: ROUTE_STORES.name,
      component: () => import('@/components/StubComponent.vue'),
      children: [
        {
          path: CHILD_ROUTE_STORES_SHOP_LIST.path,
          name: CHILD_ROUTE_STORES_SHOP_LIST.name,
          component: () => import('@/components/StubComponent.vue')
        },
        {
          path: CHILD_ROUTE_STORES_SHOP_PRODUCTS.path,
          name: CHILD_ROUTE_STORES_SHOP_PRODUCTS.name,
          component: () => import('@/components/StubComponent.vue')
        },
        {
          path: CHILD_ROUTE_STORES_SHOP_PRODUCT_SUMMARY_DETAILS.path,
          name: CHILD_ROUTE_STORES_SHOP_PRODUCT_SUMMARY_DETAILS.name,
          component: () => import('@/components/StubComponent.vue')
        },
        {
          path: CHILD_ROUTE_STORES_SHOP_SETTINGS.path,
          name: CHILD_ROUTE_STORES_SHOP_SETTINGS.name,
          component: () => import('@/components/StubComponent.vue')
        },
      ],
    },
    {
      path: ROUTE_TEMPLATES.path,
      name: ROUTE_TEMPLATES.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_SETTINGS.path,
      name: ROUTE_SETTINGS.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_ORDERS.path,
      name: ROUTE_ORDERS.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_BILLING.path,
      name: ROUTE_BILLING.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_HELP_SUPPORT.path,
      name: ROUTE_HELP_SUPPORT.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_EMAIL_SUPPORT.path,
      name: ROUTE_EMAIL_SUPPORT.name,
      component: () => import('@/components/StubComponent.vue')
    },
    {
      path: ROUTE_404.path,
      name: ROUTE_404.name,
      component: () => import('@/components/StubComponent.vue')
    },

  ],
});

router.beforeEach((to) => {
  const tenantStore = useTenantStore();

  const publicPages = [
    ROUTE_LANDING.name,
    ROUTE_PRIVACY_POLICY.name,
    ROUTE_404.name,
    ROUTE_QUESTIONNAIRE.name,
    ROUTE_FAQ.name,
    ROUTE_INTELLECTUAL_PROPERTY_POLICY.name,
    ROUTE_TERMS_OF_SERVICE.name,
    ROUTE_PRIVACY_POLICY.name,
    ROUTE_HOW_IT_WORKS.name,
    ROUTE_PERSONALIZED_PRINT_ON_DEMAND_INFO.name,
    ROUTE_DESIGN_MALL_INFO.name,
  ];

  const authRequired = !publicPages.includes(to.name as string);
  if (authRequired && !tenantStore.isLoggedIn) {
    console.log('Path logging -- Not logged in, redirecting to login page:');
    return {path: '/'};
  }

  if (to.path === '/' && tenantStore.isLoggedIn) {
    console.log('Path logging -- Already logged in, redirecting to dashboard page:');
    return {path: ROUTE_DASHBOARD.path};
  }
  console.log('Path logging -- to.path: ' + to.path);

});

export default router;
