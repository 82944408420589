import { IEditorCanvas } from '@/interfaces/editorInterfaces';
import { fabric } from 'fabric';
import {
  ALIGNING_LINE_COLOR,
  ALIGNING_LINE_WIDTH,
  ALIGNING_MARGIN,
  ALIGNING_OFFSET
} from '@/modules/editor/editorConstants';
import { scaleObject } from '@/modules/editor/editorCanvas';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';

/**
 * This is a modified version of the fabricjs aligning_guidelines.js code https://github.com/fabricjs/fabric.js/blob/master/lib/aligning_guidelines.js
 */

export class AligningGuidelines {

  private static ctx: CanvasRenderingContext2D | null = null;
  private static canvas: IEditorCanvas | null;
  private static aligningLineOffset = 5;
  private static aligningLineMargin = 5;
  private static viewportTransform: number[] | undefined;
  private static zoom = 1;
  private static verticalLines: { x: number; y1: number; y2: number; }[] = [];
  private static horizontalLines: { y: number; x1: number; x2: number; }[] = [];

  public static initAligningGuidelines(editorCanvas: IEditorCanvas) {
    AligningGuidelines.canvas = editorCanvas;
    AligningGuidelines.ctx = AligningGuidelines.canvas.getSelectionContext();
    AligningGuidelines.canvas.on('mouse:down', AligningGuidelines.handleMouseDown);
    AligningGuidelines.canvas.on('object:moving', AligningGuidelines.onObjectMoving);
    AligningGuidelines.canvas.on('object:scaling', AligningGuidelines.onObjectScaling);
    AligningGuidelines.canvas.on('before:render', AligningGuidelines.handleBeforeRenderer);
    AligningGuidelines.canvas.on('after:render', AligningGuidelines.handleAfterRenderer);
    AligningGuidelines.canvas.on('mouse:up', AligningGuidelines.handleMouseUp);
  }

  private static drawVerticalLine(coords: { x: number, y1: number, y2: number }) {
    AligningGuidelines.drawLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1);
  }

  private static drawHorizontalLine(coords: { y: number, x1: number, x2: number }) {
    AligningGuidelines.drawLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5);
  }

  private static drawLine(x1: number, y1: number, x2: number, y2: number) {
    if (AligningGuidelines.ctx) {
      AligningGuidelines.ctx.save();
      AligningGuidelines.ctx.lineWidth = ALIGNING_LINE_WIDTH;
      AligningGuidelines.ctx.strokeStyle = ALIGNING_LINE_COLOR;
      AligningGuidelines.ctx.beginPath();
      if (AligningGuidelines.viewportTransform) {
        AligningGuidelines.ctx.moveTo(x1 * AligningGuidelines.zoom + AligningGuidelines.viewportTransform[4], y1 * AligningGuidelines.zoom + AligningGuidelines.viewportTransform[5]);
        AligningGuidelines.ctx.lineTo(x2 * AligningGuidelines.zoom + AligningGuidelines.viewportTransform[4], y2 * AligningGuidelines.zoom + AligningGuidelines.viewportTransform[5]);
        AligningGuidelines.ctx.stroke();
        AligningGuidelines.ctx.restore();
      }
    }
  }

  private static isInRange(value1: number, value2: number) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (let i = value1 - AligningGuidelines.aligningLineMargin, len = value1 + AligningGuidelines.aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }

  private static onObjectMoving(e: fabric.IEvent): void {
    AligningGuidelines.checkAlignment(e, false);
  }

  private static onObjectScaling(e: fabric.IEvent): void {
    AligningGuidelines.checkAlignment(e, true);
  }

  private static checkAlignment(event: fabric.IEvent, isScaling: boolean): void {

    if (AligningGuidelines.canvas) {

      const activeObject = event.target;
      const canvasObjects = AligningGuidelines.canvas.getObjects();
      const snappedByCenterHorizontal: boolean[] = [];
      const snappedByCenterVertical: boolean[] = [];
      const snappedByLeftEdge: boolean[] = [];
      const snappedByRightEdge: boolean[] = [];
      const snappedByTopEdge: boolean[] = [];
      const snappedByBottomEdge: boolean[] = [];
      if (activeObject && AligningGuidelines.viewportTransform) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        const canvasStore = useEditorCanvasStore();
        for (let i = canvasObjects.length; i--;) {

          if (canvasObjects[i] === activeObject && canvasObjects.length > 1) continue;
          const objectCenter = canvasObjects[i].getCenterPoint();
          let objectLeft = 0;
          let objectTop = 0;
          let pageHeight = 0;
          let pageWidth = 0;
          let pageLeft = 0;
          let pageTop = 0;
          const objectBoundingRect = canvasObjects[i].getBoundingRect();
          let objectHeight = 0;
          let objectWidth = 0;
          if (canvasObjects.length > 1) {
            objectLeft = objectCenter.x;
            objectTop = objectCenter.y;
            objectHeight = objectBoundingRect.height / AligningGuidelines.viewportTransform[3];
            objectWidth = objectBoundingRect.width / AligningGuidelines.viewportTransform[0];

            const page = canvasStore.getCurrentPage();
            pageLeft = page ? page.width / 2 : 0;
            pageTop = page ? page.height / 2 : 0;
            pageWidth = page ? page.width : 0;
            pageHeight = page ? page.height : 0;
          } else if (canvasObjects.length === 1) {
            const page = canvasStore.getCurrentPage();
            objectLeft = page ? page.width / 2 : 0;
            objectTop = page ? page.height / 2 : 0;
            objectWidth = page ? page.width : 0;
            objectHeight = page ? page.height : 0;
          }


          const result = this.doSnapAction(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
          snappedByCenterHorizontal.push(result.snappedByCenterHorizontal);
          snappedByCenterVertical.push(result.snappedByCenterVertical);
          snappedByLeftEdge.push(result.snappedByLeftEdge);
          snappedByRightEdge.push(result.snappedByRightEdge);
          snappedByTopEdge.push(result.snappedByTopEdge);
          snappedByBottomEdge.push(result.snappedByBottomEdge);
          if (canvasObjects.length > 1) {
            const result = this.doSnapAction(event, pageLeft, pageTop, pageWidth, pageHeight, isScaling);
            snappedByCenterHorizontal.push(result.snappedByCenterHorizontal);
            snappedByCenterVertical.push(result.snappedByCenterVertical);
            snappedByLeftEdge.push(result.snappedByLeftEdge);
            snappedByRightEdge.push(result.snappedByRightEdge);
            snappedByTopEdge.push(result.snappedByTopEdge);
            snappedByBottomEdge.push(result.snappedByBottomEdge);
          }
        }
      }
      if (snappedByCenterHorizontal.length === 0 && snappedByTopEdge.length === 0 && snappedByBottomEdge.length === 0) {
        AligningGuidelines.horizontalLines.length = 0;
      }

      if (snappedByCenterVertical.length === 0 && snappedByLeftEdge.length === 0 && snappedByRightEdge.length === 0) {
        AligningGuidelines.verticalLines.length = 0;
      }
    }
  }

  private static doSnapAction(event: fabric.IEvent,objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): { snappedByCenterVertical: boolean, snappedByCenterHorizontal: boolean, snappedByLeftEdge: boolean, snappedByRightEdge: boolean, snappedByTopEdge: boolean, snappedByBottomEdge: boolean } {
    const result = this.snapByCenter(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
    const snappedByCenterVertical = result.verticalInTheRange;
    const snappedByCenterHorizontal = result.horizontalInTheRange;
    const snappedByLeftEdge = this.snapByLeftEdge(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
    const snappedByRightEdge = this.snapByRightEdge(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
    const snappedByTopEdge = this.snapByTopEdge(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
    const snappedByBottomEdge = this.snapByBottomEdge(event, objectLeft, objectTop, objectWidth, objectHeight, isScaling);
    return { snappedByCenterVertical, snappedByCenterHorizontal, snappedByLeftEdge, snappedByRightEdge, snappedByTopEdge, snappedByBottomEdge };
  }

  private static snapByCenter(event: fabric.IEvent, objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): { verticalInTheRange: boolean, horizontalInTheRange: boolean } {
    const activeObject = event.target;
    let verticalInTheRange = false;
    let horizontalInTheRange = false;
    let snapByHorizontalCenter = false;
    let snapByVerticalCenter = false;

    if (activeObject && AligningGuidelines.canvas && AligningGuidelines.viewportTransform && AligningGuidelines.canvas._currentTransform) {
      const activeObjectCenter = activeObject.getCenterPoint();
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = activeObject.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / AligningGuidelines.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / AligningGuidelines.viewportTransform[0];
      // snap by both horizontal and vertical center line
      if (AligningGuidelines.isInRange(objectLeft, activeObjectLeft) && AligningGuidelines.isInRange(objectTop, activeObjectTop)) {
        verticalInTheRange = true;
        horizontalInTheRange = true;
        snapByHorizontalCenter = true;
        snapByVerticalCenter = true;
        if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft, objectTop), 'center', 'center');
        }
      }

      // snap by the horizontal center line
      else if (AligningGuidelines.isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true;
        snapByHorizontalCenter = true;

        if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
        }
      }

      // snap by the vertical center line
      else if (AligningGuidelines.isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true;
        snapByVerticalCenter = true;
        if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
        }
      }
      if (snapByHorizontalCenter) {
        AligningGuidelines.verticalLines.push({
          x: objectLeft,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - AligningGuidelines.aligningLineOffset)
            : (objectTop + objectHeight / 2 + AligningGuidelines.aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - AligningGuidelines.aligningLineOffset)
        });
      }
      if (snapByVerticalCenter) {
        AligningGuidelines.horizontalLines.push({
          y: objectTop,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - AligningGuidelines.aligningLineOffset)
            : (objectLeft + objectWidth / 2 + AligningGuidelines.aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - AligningGuidelines.aligningLineOffset)
        });
      }
    }
    return { verticalInTheRange, horizontalInTheRange };
  }

  private static snapByLeftEdge(event: fabric.IEvent, objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): boolean {
    const activeObject = event.target;
    let verticalInTheRange = false;
    if (activeObject && AligningGuidelines.canvas && AligningGuidelines.viewportTransform && AligningGuidelines.canvas._currentTransform) {
      const transform = AligningGuidelines.canvas._currentTransform;
      const activeObjectCenter = activeObject.getCenterPoint();
      const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = activeObject.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / AligningGuidelines.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / AligningGuidelines.viewportTransform[0];
      // snap by the left edge
      if (AligningGuidelines.isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
        verticalInTheRange = true;
        AligningGuidelines.verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - AligningGuidelines.aligningLineOffset)
            : (objectTop + objectHeight / 2 + AligningGuidelines.aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - AligningGuidelines.aligningLineOffset)
        });
        if (isScaling && activeObject.type !== 'sf-text' && AligningGuidelines.isInRange(objectLeft - objectWidth / 2, currentPointer.x)) {
          const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
          scaleObject(event.e, transform, objectLeft - objectWidth / 2, currentPointer.y, { by: 'x' });
        } else if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
        }
      }
    }
    return verticalInTheRange;
  }

  private static snapByRightEdge(event: fabric.IEvent, objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): boolean {
    const activeObject = event.target;
    let verticalInTheRange = false;
    if (activeObject && AligningGuidelines.canvas && AligningGuidelines.viewportTransform && AligningGuidelines.canvas._currentTransform) {
      const transform = AligningGuidelines.canvas._currentTransform;
      const activeObjectCenter = activeObject.getCenterPoint();
      const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = activeObject.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / AligningGuidelines.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / AligningGuidelines.viewportTransform[0];
      if (AligningGuidelines.isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
        verticalInTheRange = true;
        AligningGuidelines.verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1: (objectTop < activeObjectTop)
            ? (objectTop - objectHeight / 2 - AligningGuidelines.aligningLineOffset)
            : (objectTop + objectHeight / 2 + AligningGuidelines.aligningLineOffset),
          y2: (activeObjectTop > objectTop)
            ? (activeObjectTop + activeObjectHeight / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectTop - activeObjectHeight / 2 - AligningGuidelines.aligningLineOffset)
        });
        if (isScaling && activeObject.type !== 'sf-text' && AligningGuidelines.isInRange(objectLeft + objectWidth / 2, currentPointer.x)) {
          const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
          scaleObject(event.e, transform, objectLeft + objectWidth / 2, currentPointer.y, { by: 'x' });
        } else if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
        }
      }
    }
    return verticalInTheRange;
  }

  private static snapByTopEdge(event: fabric.IEvent, objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): boolean {
    const activeObject = event.target;
    let horizontalInTheRange = false;
    if (activeObject && AligningGuidelines.canvas && AligningGuidelines.viewportTransform && AligningGuidelines.canvas._currentTransform) {
      const transform = AligningGuidelines.canvas._currentTransform;
      const activeObjectCenter = activeObject.getCenterPoint();
      const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = activeObject.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / AligningGuidelines.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / AligningGuidelines.viewportTransform[0];
      if (AligningGuidelines.isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        AligningGuidelines.horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - AligningGuidelines.aligningLineOffset)
            : (objectLeft + objectWidth / 2 + AligningGuidelines.aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - AligningGuidelines.aligningLineOffset)
        });
        if (isScaling) {
          if (activeObject.type !== 'sf-text' && AligningGuidelines.isInRange(objectTop - objectHeight / 2, currentPointer.y)) {
            scaleObject(event.e, transform, currentPointer.x, objectTop - objectHeight / 2, { by: 'y' });
          }
        } else if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');

        }
      }
    }
    return horizontalInTheRange;
  }

  private static snapByBottomEdge(event: fabric.IEvent, objectLeft: number, objectTop: number, objectWidth: number, objectHeight: number, isScaling: boolean): boolean {
    const activeObject = event.target;
    let horizontalInTheRange = false;
    if (activeObject && AligningGuidelines.canvas && AligningGuidelines.viewportTransform && AligningGuidelines.canvas._currentTransform) {
      const transform = AligningGuidelines.canvas._currentTransform;
      const activeObjectCenter = activeObject.getCenterPoint();
      const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
      const activeObjectLeft = activeObjectCenter.x;
      const activeObjectTop = activeObjectCenter.y;
      const activeObjectBoundingRect = activeObject.getBoundingRect();
      const activeObjectHeight = activeObjectBoundingRect.height / AligningGuidelines.viewportTransform[3];
      const activeObjectWidth = activeObjectBoundingRect.width / AligningGuidelines.viewportTransform[0];
      if (AligningGuidelines.isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        AligningGuidelines.horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1: (objectLeft < activeObjectLeft)
            ? (objectLeft - objectWidth / 2 - AligningGuidelines.aligningLineOffset)
            : (objectLeft + objectWidth / 2 + AligningGuidelines.aligningLineOffset),
          x2: (activeObjectLeft > objectLeft)
            ? (activeObjectLeft + activeObjectWidth / 2 + AligningGuidelines.aligningLineOffset)
            : (activeObjectLeft - activeObjectWidth / 2 - AligningGuidelines.aligningLineOffset)
        });
        if (isScaling && activeObject.type !== 'sf-text' && AligningGuidelines.isInRange(objectTop + objectHeight / 2, currentPointer.y)) {
          const currentPointer = AligningGuidelines.canvas.getPointer(event.e);
          scaleObject(event.e, transform, currentPointer.x, objectTop + objectHeight / 2, { by: 'y' });
        } else if (!isScaling) {
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
        }
      }
    }
    return horizontalInTheRange;
  }

  public static handleAfterRenderer(): void {
    for (let i = AligningGuidelines.verticalLines.length; i--;) {
      AligningGuidelines.drawVerticalLine(AligningGuidelines.verticalLines[i]);
    }
    for (let i = AligningGuidelines.horizontalLines.length; i--;) {
      AligningGuidelines.drawHorizontalLine(AligningGuidelines.horizontalLines[i]);
    }

    AligningGuidelines.verticalLines.length = AligningGuidelines.horizontalLines.length = 0;
  }

  private static handleBeforeRenderer() {
    if (AligningGuidelines.canvas) {
      AligningGuidelines.canvas.clearContext(AligningGuidelines.canvas.contextTop);
    }
  }

  private static handleMouseUp() {
    if (AligningGuidelines.canvas) {
      AligningGuidelines.verticalLines.length = AligningGuidelines.horizontalLines.length = 0;
      AligningGuidelines.canvas.renderAll();
    }
  }

  private static handleMouseDown() {
    if (AligningGuidelines.canvas) {
      AligningGuidelines.viewportTransform = AligningGuidelines.canvas.viewportTransform;
      AligningGuidelines.zoom = AligningGuidelines.canvas.getZoom();
      AligningGuidelines.aligningLineMargin = Math.round(ALIGNING_MARGIN * (1 / AligningGuidelines.zoom));
      AligningGuidelines.aligningLineOffset = Math.round(ALIGNING_OFFSET * (1 / AligningGuidelines.zoom));
    }
  }

  public static removeEventListeners(): void {
    if (AligningGuidelines.canvas) {
      AligningGuidelines.canvas.off('mouse:down', AligningGuidelines.handleMouseDown);
      AligningGuidelines.canvas.off('object:moving', AligningGuidelines.onObjectMoving);
      AligningGuidelines.canvas.off('object:scaling', AligningGuidelines.onObjectScaling);
      AligningGuidelines.canvas.off('before:render', AligningGuidelines.handleBeforeRenderer);
      AligningGuidelines.canvas.off('after:render', AligningGuidelines.handleAfterRenderer);
      AligningGuidelines.canvas.off('mouse:up', AligningGuidelines.handleMouseUp);
    }
  }

}
