import { isProxy, isReactive, isRef, toRaw } from 'vue';
import { isEqual } from 'lodash';

// copied from vuejs git repo, bug reports: https://github.com/vuejs/core/issues/5303
export function deepToRaw<T>(sourceObj: T): T {
  const objectIterator = (input: unknown): unknown => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item));
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input));
    }
    if (input && typeof input === 'object') {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(
          input[key as keyof typeof input]
        ) as T[keyof T];
        return acc;
      }, {} as T);
    }
    return input;
  };

  return objectIterator(sourceObj) as T;
}

export function areObjectsEqual(obj1: object, obj2: object): boolean {
  return isEqual(obj1, obj2);
}

export function getNumberOfUserViewableColorChips(colorRowElement: HTMLElement, colorChipSize: number): number {
    // 20 pixel for the +xx at the end of the list
    const rowWidth = colorRowElement.offsetWidth - 20;
    // show max of 2 color rows
    return 2 * Math.floor(rowWidth / colorChipSize);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let firstCall = true;

  return function(...args: Parameters<T>) {
    if (firstCall) {
      func.apply(this, args);
      firstCall = false;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;

      if (timeout !== undefined) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce <T extends (...args: any[]) => any> (func: T , wait: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}




