import { defineStore } from 'pinia';
import { PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels } from '@/constants/topicEnums';
import {
  IPreviewResult,
  IPriceDetail,
  IShopProduct,
  IVariant, IVariantImagePreview,
  ShopProductStatus
} from '@/interfaces/shopProductInterface';
import logger from '@/logger/logger';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { computed, ref, toRaw } from 'vue';
import {
  IOptionTranslation,
  IPreviewData,
  IProductInfo,
  IProductTranslations,
  ISkuData
} from '@/interfaces/baseInterface';
import { useSessionStore } from '@/stores/sessionStore';
import { useCatalogStore } from '@/stores/catalogStore';
import {useUndoRedoStore} from '@/stores/undoRedoStore';
import {
  areAllShopProductPreviewsReady,
  findVariantOptionsIntersection,
  getSkuIdFromProductSku,
  getSkuIdFromVariant,
  getUniqueBaseIdsFromShopProduct,
  getVariantByProductSku,
  getVariantsMapForSavingPreviews,
  isShopProductDefaultPreviewReady,
  isSuperSkuShopProduct,
  isTemplateOrDraftShopProduct,
  isTemplateShopProduct,
  shouldSkipVariant,
  updateShopProductPreviewsStatus,
  isABlobVariantImagePreview, getVariantByDefaultSku, isDraftShopProduct
} from '@/helpers/shopProductHelper';
import { ICatalogItem } from '@/interfaces/catalogInterfaces';
import * as catalogHelpers from '@/helpers/catalogHelpers';
import { getCatalogItemName, isSuperSku } from '@/helpers/catalogHelpers';
import { TopicWildCard } from '@/constants/topicConstants';
import { uuid } from '@/helpers/uuidHelpers';
import { Helpers } from '@/modules/editor/helpers';
import { useTenantStore } from '@/stores/tenantStore';
import { useEditorProductStore } from '@/stores/editorProductStore';
import { unFormat } from '@/helpers/currencyHelpers';
import { ICurrency } from '@/interfaces/tenantInterface';
import { IPage, Orientation } from '@/interfaces/editorInterfaces';
import { IProjectInfo } from '@/interfaces/projectInterface';
import { useShopProductProjectStore } from '@/stores/shopProductProjectStore';
import { findSkuData } from '@/helpers/productInfoHelpers';
import { ISolaceClient } from '@/interfaces/iSolaceClient';
import { IItePreviews } from '@/interfaces/iItePreviews';
import { useShopStore } from '@/stores/shopStore';
import { SolaceClientConstants } from '@/constants/solaceClientContstants';

export const useShopProductStore = defineStore('shopProduct', () => {
  const shopProductCurrentSubscriptions: { [key: string]: boolean } = {};

  let solaceClient:ISolaceClient|undefined;
  const solaceReady:Promise<void> = new Promise((resolve,reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, err => {
      reject(err);
    });
  });

  const shopProducts = ref(new Map<string, IShopProduct[]>()); // map of shop id to shop products
  let itePreviews:IItePreviews|undefined;
  const itePreviewReady:Promise<void> = new Promise<void>((resolve,reject) => {
     ServiceLocator.getService<IItePreviews>(ServiceType.ItePreviews).then(ite => {
       itePreviews = ite;
       resolve();
     }, err=> {
       reject(err);
     });
  });

  const allPreviews: {
    [shopProductGuid: string]: {
      resolve: (value: string) => void;
      reject: (reason: string) => void;
    };
  } = {};

  const defaultPreview: {
    [shopProductGuid: string]: {
      resolve: (value: string) => void;
      reject: (reason: string) => void;
    };
  } = {};

  const shopProductProcessed: {
    [shopProductGuid: string]: {
      resolve: (value: string) => void;
      reject: (reason: string) => void;
    }
  } = {};

  const shopProductPreviewsReadyPromises: {[shopProductGuid: string]: Promise<string>} = {};
  const shopProductDefaultPreviewReadyPromises: {[shopProductGuid: string]: Promise<string>} = {};
  const shopProductProcessedPromises: {[shopProductGuid: string]: Promise<string>} = {};

  const superSkuCatalogItem = computed((): ICatalogItem | null => {
    const catalogStore = useCatalogStore();
    const sessionStore = useSessionStore();
    if (sessionStore.currentShopProduct && sessionStore.currentShopProduct.super_sku_id > 0) {
      return catalogStore.getCatalog().find((catalogItem) => catalogItem.id === (sessionStore.currentShopProduct as IShopProduct).super_sku_id && isSuperSku(catalogItem)) || null;
    }
    return null;
  });

  const currentShopProductBases = computed((): IProductInfo[] => {
    const sessionStore = useSessionStore();
    const catalogStore = useCatalogStore();
    const currentShopProduct = sessionStore.currentShopProduct;
    const shopProductBases: IProductInfo[] = [];
    if (currentShopProduct) {
      const baseIds = getUniqueBaseIdsFromShopProduct(currentShopProduct);
      baseIds.forEach((baseId) => {
        catalogStore.requestBasesById([baseId]);
        const base = catalogStore.bases.get(baseId);
        if (base) {
          shopProductBases.push(base);
        }
      });
    }
    return shopProductBases;
  });

  function removeShopProduct(shopId: string, shopProductGuid: string): void {
    const shopProductList = shopProducts.value.get(shopId);
    if (shopProductList) {
      const index = shopProductList.findIndex((shopProduct) => shopProduct.guid === shopProductGuid);
      if (index > -1) {
        shopProductList.splice(index, 1);
      }
    }
  }

  function getShopProducts(shopId: string): IShopProduct[] {
    if (shopProducts.value.has(shopId)) {
      return shopProducts.value.get(shopId) as IShopProduct[];
    }
    return [];
  }

  function findShopProduct(shopId: string, shopProductId: string): IShopProduct | undefined {
    const currentShop_ShopProducts = shopProducts.value.get(shopId);
    return currentShop_ShopProducts ? currentShop_ShopProducts.find((shopProduct) => shopProduct.guid === shopProductId) : undefined;
  }

  // this function always returns the most recent shop product data from the store.
  // use it when you need the most recent shop product data.
  async function retrieveShopProductInfo(shopId: string, shopProductId: string): Promise<IShopProduct> {
    const shopProductGuid = await publishShopProductTrigger(shopId, shopProductId);
    cleanupShopProductPreviewsPromise(shopProductGuid);
    const foundShopProduct = findShopProduct(shopId, shopProductId);
    return foundShopProduct ? Promise.resolve(foundShopProduct) : Promise.reject('No shop product with ID ' + shopProductId + ' found');
  }

  // this function should be called to remove the shop prodyct previews promise that is no longer needed (resolved)
  function cleanupShopProductPreviewsPromise(shopProductGuid: string): void {
    if (allPreviews[shopProductGuid]) {
      delete allPreviews[shopProductGuid];
    }
    delete shopProductPreviewsReadyPromises[shopProductGuid];
  }

  // this function should be called to remove the default preview promise that is resolved
  function cleanupDefaultPreviewPromise(shopProductGuid: string): void {
    if (defaultPreview[shopProductGuid]) {
      delete defaultPreview[shopProductGuid];
    }
    delete shopProductDefaultPreviewReadyPromises[shopProductGuid];
  }

  function cleanupShopProductProcessedPromise(shopProductGuid: string): void {
    if (shopProductProcessed[shopProductGuid]) {
      delete shopProductProcessed[shopProductGuid];
    }
    delete shopProductProcessedPromises[shopProductGuid];
  }

  function subscribeToShopProductCurrent(shopId: string): void {
    if (shopProductCurrentSubscriptions[shopId]) {
      return;
    }
    shopProductCurrentSubscriptions[shopId] = true;
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const shopProductCurrentSubscription = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_SHOP_PRODUCT_CURRENT);
      if (shopProductCurrentSubscription) {
        const replacedSub = shopProductCurrentSubscription.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, TopicWildCard);
        solaceClient.mapSubscriptionJson<IShopProduct | null>(replacedSub, ((shopProduct, topic: string) => {
            let oldShopProductDefaultPreview: string = '';
            if (shopProducts.value.has(shopId)) {
              const products = shopProducts.value.get(shopId) as IShopProduct[];
              if (shopProduct) {
                const index = products.findIndex((product) => shopProduct.guid === product.guid);
                if (index > -1) {
                  oldShopProductDefaultPreview = products[index].default_preview_url || '';
                  products[index] = shopProduct;
                } else {
                  products.push(shopProduct);
                }
              } else {
                const shopProductGuid = topic.split('/')[10];
                const index = products.findIndex((product) => shopProductGuid === product.guid);
                if (index > -1) {
                  products.splice(index, 1);
                }
              }
            } else if (shopProduct) {
              shopProducts.value.set(shopId, [shopProduct]);
            }
            // at this point we know that shop product is processed by DFE... we need to wait for this to be done before proceeding to saving previews. This is needed
            // to prevent some of the DFE errors that occur when we try to save previews before DFE is done processing the shop product
            if (shopProduct && shopProductProcessed[shopProduct.guid]) {
              shopProductProcessed[shopProduct.guid].resolve(shopProduct.guid);
            }
            // else we don't care, it's already resolved by the first current event which is the expected behavior

            // resolve when we have all the preview urls for the shop product
            if (shopProduct && allPreviews[shopProduct.guid]) {
              if (areAllShopProductPreviewsReady(shopProduct)) {
                console.log('All shop product previews ready for shop product guid: ' + shopProduct.guid);
                resolveAllShopProductPreviews(shopProduct.guid);
              } else if (isTemplateOrDraftShopProduct(shopProduct.status)) {
                // draft/templates need only the default preview to be ready
                console.log('Default preview ready for shop product guid: ' + shopProduct.guid);
                resolveAllShopProductPreviews(shopProduct.guid);
              }
            }
            if (shopProduct && defaultPreview[shopProduct.guid] && isShopProductDefaultPreviewReady(shopProduct.default_preview_url || '', oldShopProductDefaultPreview)) {
              console.log('Default preview ready for shop product guid: ' + shopProduct.guid);
              defaultPreview[shopProduct.guid].resolve(shopProduct.guid);
            }
          })
        );
      } else {
        logger.error(
          'Unable to subscribe to shop product current topic - not found'
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function resolveAllShopProductPreviews(shopProductGuid: string): void {
    if (allPreviews[shopProductGuid]) {
      allPreviews[shopProductGuid].resolve(shopProductGuid);
    }
    if (defaultPreview[shopProductGuid]) {
      defaultPreview[shopProductGuid].resolve(shopProductGuid);
    }
  }

  function waitForShopProductPreviewsReady(shopProductGuid: string): Promise<string> {
    return shopProductPreviewsReadyPromises[shopProductGuid];
  }

  function waitForShopProductTransitionToStoreReady(shopProductGuid: string): Promise<string> {
    return shopProductDefaultPreviewReadyPromises[shopProductGuid];
  }

  function waitForShopProductProcessed(shopProductGuid: string): Promise<string> {
    return shopProductProcessedPromises[shopProductGuid];
  }

  function publishShopProductTrigger(shopId: string, shopProductId: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      allPreviews[shopProductId] = { resolve, reject };
    });
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_TRIGGER);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, shopProductId);
        solaceClient.publishMessageString(editedTopic, undefined, err => {
          if (allPreviews[shopProductId]) {
            allPreviews[shopProductId].reject(err.message);
          }
          console.warn('Error publishing shop product trigger', err);
        });
      } else {
        allPreviews[shopProductId].reject(
          'Could not find topic for publish shop product trigger: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_TRIGGER
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
    return promise;
  }

  // this function is for adding a new shop product or updating an exisiting one that is intended to go to the Shopify store
  async function saveAndPublishShopProduct(): Promise<void> {
    const sessionStore = useSessionStore();
    const editorProductStore = useEditorProductStore();
    const shopStore = useShopStore();
    if (sessionStore.currentShopProduct && sessionStore.currentShop) {
      try {
        const projectsToSave: IProjectInfo[] = [];
        sessionStore.currentShopProduct.variants.forEach((variant) => {
          const variantSkuId = getSkuIdFromVariant(variant, (sessionStore.currentShopProduct as IShopProduct).guid);
          const project = editorProductStore.findProject(variantSkuId);
          if (project) {
            const foundProject = projectsToSave.find((projectToSave) => projectToSave.guid === project.guid);
            if (!foundProject) {
              projectsToSave.push(project);
            }
          }
        });
        if (!sessionStore.isInEditingPublishedShopProductMode) {
          setVariantsShippingProfile();
        }
        const foundShopInfo = await shopStore.findShopWithPromise(sessionStore.currentShop.id);
        shopStore.cleanupPromises(foundShopInfo.id);
        const existingShopProduct = foundShopInfo.product_summaries?.find((shopProductSummary) => {
          return shopProductSummary.guid === (sessionStore.currentShopProduct as IShopProduct).guid;
        });
        await saveShopProduct(sessionStore.currentShopProduct, projectsToSave, !!existingShopProduct);
        await waitForShopProductTransitionToStoreReady(sessionStore.currentShopProduct.guid);
        cleanupDefaultPreviewPromise(sessionStore.currentShopProduct.guid);
        cleanupShopProductProcessedPromise(sessionStore.currentShopProduct.guid);
      } catch (error) {
        console.error('error while saving shop product: ', error);
        cleanupDefaultPreviewPromise(sessionStore.currentShopProduct.guid);
        cleanupShopProductProcessedPromise(sessionStore.currentShopProduct.guid);
      }
    }
  }

  function setVariantsShippingProfile(): void {
    const sessionStore = useSessionStore();
    if (sessionStore.currentShopProduct) {
      const allSkus = getAllSkus();
      sessionStore.currentShopProduct.variants.forEach((variant) => {
        const variantSkuId = getSkuIdFromVariant(variant, (sessionStore.currentShopProduct as IShopProduct).guid);
        const sku = allSkus.find((sku: ISkuData) => {
          return sku.product_sku_id === variantSkuId;
        });
        if (sku) {
          variant.shipping_profile = sku.shipping_profile;
        }
      });
    }
  }

  function publishShopProductUpdate(shopProduct: IShopProduct, previewsToBeSaved: number, correlationId: string): void {
    createShopProductPreviewPromises(shopProduct.guid);
    createShopProductProcessedPromises(shopProduct.guid);
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_UPDATE);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopProduct.shop_id).replace(TopicVarLevels.PRODUCT_ID, shopProduct.guid);
        const productData = JSON.stringify(shopProduct);
        solaceClient.publishMessageString(editedTopic, productData, err => {
          allPreviews[shopProduct.guid].reject(err.message);
          shopProductProcessed[shopProduct.guid].reject(err.message);
          console.warn('Error publishing shop product update', err);
        }, SolaceClientConstants.PERSISTENT_MSG, undefined, {preview_count: previewsToBeSaved.toString()}, correlationId);
      } else {
        allPreviews[shopProduct.guid].reject(
          'Could not find topic for publish shop product update: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_UPDATE
        );
        shopProductProcessed[shopProduct.guid].reject('Could not find topic for publish shop product add: ' + PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_UPDATE);
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function publishShopProductAdd(shopProduct: IShopProduct, previewsToBeSaved: number, correlationId: string): void {
    createShopProductPreviewPromises(shopProduct.guid);
    createShopProductProcessedPromises(shopProduct.guid);
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_ADD);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopProduct.shop_id).replace(TopicVarLevels.PRODUCT_ID, shopProduct.guid);
        const productData = JSON.stringify(shopProduct);
        solaceClient.publishMessageString(editedTopic, productData, err => {
          allPreviews[shopProduct.guid].reject(err.message);
          shopProductProcessed[shopProduct.guid].reject(err.message);
          console.warn('Error publishing shop product add', err);
        }, SolaceClientConstants.PERSISTENT_MSG, undefined, {preview_count: previewsToBeSaved.toString()}, correlationId);
      } else {
        allPreviews[shopProduct.guid].reject('Could not find topic for publish shop product add: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_ADD);
        shopProductProcessed[shopProduct.guid].reject('Could not find topic for publish shop product add: ' + PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_ADD);
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function publishShopProductDelete(shopId: string, shopProductGuid: string): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_DELETE);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, shopProductGuid);
        solaceClient.publishMessageString(editedTopic, undefined, err => {
          console.warn('Error publishing shop product delete', err);
        });
      } else {
        console.warn(
          'Could not find topic for publish shop product delete: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_DELETE
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function createShopProductPreviewPromises(shopProductGuid: string): void {
    shopProductPreviewsReadyPromises[shopProductGuid] = new Promise<string>((resolve, reject) => {
      allPreviews[shopProductGuid] = { resolve, reject };
    });
    shopProductDefaultPreviewReadyPromises[shopProductGuid] = new Promise<string>((resolve, reject) => {
      defaultPreview[shopProductGuid] = { resolve, reject };
    });
  }

  function createShopProductProcessedPromises(shopProductGuid: string): void {
    shopProductProcessedPromises[shopProductGuid] = new Promise<string>((resolve, reject) => {
      shopProductProcessed[shopProductGuid] = { resolve, reject };
    });
  }

  const productNameFromCurrentShopProduct = computed(() => {
    const sessionStore = useSessionStore();
    if (sessionStore.currentShopProduct) {
      if (isSuperSkuShopProduct(sessionStore.currentShopProduct)) {
        return superSkuCatalogItem.value ? getCatalogItemName(superSkuCatalogItem.value, sessionStore.currentLanguage) : '';
      } else {
        return sessionStore.currentBase ? catalogHelpers.getProductName(sessionStore.currentBase, sessionStore.currentLanguage) : '';
      }
    }
    return '';
  });

  function initializeShopProduct(previewsPickedBySku: IPreviewData[]): IShopProduct {
    const sessionStore = useSessionStore();
    const tenantStore = useTenantStore();
    const superSkuId = sessionStore.currentCatalogItem && isSuperSku(sessionStore.currentCatalogItem) ? sessionStore.currentCatalogItem.id : 0;
    const originalShopProduct = sessionStore.currentShopProduct;
    const uniquePreviewNames = new Set(previewsPickedBySku.map((previewData) => {
      return previewData.title;
    }));
    const previewsStatus = Array.from(uniquePreviewNames).map((previewName) => {
      return { name: previewName, enabled: false };
    });

    // variants and options are set based on the attribute option selection in design phase
    const shopProduct: IShopProduct = {
      details: { description: '', tags: [], title: '' },
      shop_id: sessionStore.currentShop?.id || tenantStore.user.tenantInfo?.shop_id || '',
      previews_status: previewsStatus,
      main_preview: '',
      options: [],
      product_type: sessionStore.currentBase?.production_type || '',
      variants: [],
      guid: uuid(),
      super_sku_id: superSkuId,
      default_preview_url: '',
      status: ShopProductStatus.pending,
      translations: {},
      last_updated: '',
      default_sku: '',
    };

    if (originalShopProduct) {
      // if this is a template shop product, it's converted to a shop product with real shop id at this point, which means that it needs a new guid.
      // Note: templates are on a private store, so they do not blong to a shop. This means they can't share same guid with an actual shop product when they're converted to a shop product.
      // However, drafts do blong to the shop, so they can share the same guid with an actual shop product.
      if (tenantStore.user.tenantInfo?.shop_id && isTemplateShopProduct(originalShopProduct.status, originalShopProduct.shop_id, tenantStore.user.tenantInfo.shop_id)) {
        originalShopProduct.guid = shopProduct.guid;
      }
      checkShopProductVariantsAndProjectsChanges();
    } else {
      sessionStore.setCurrentShopProduct(shopProduct);
      shopProduct.variants = sessionStore.currentShopProductVariantsAndOptions.variants;
      shopProduct.options = sessionStore.currentShopProductVariantsAndOptions.options;
      shopProduct.previews_status = previewsStatus;
    }

    return shopProduct;
  }

  // attempting to preserve the current shop product variants/options that haven't been changed in the design phase
  function checkShopProductVariantsAndProjectsChanges() {
    const sessionStore = useSessionStore();
    const editorProductStore = useEditorProductStore();
    const tenantStore = useTenantStore();
    const undoRedoStore = useUndoRedoStore();
    const superSkuId = sessionStore.currentCatalogItem && isSuperSku(sessionStore.currentCatalogItem) ? sessionStore.currentCatalogItem.id : 0;
    const originalShopProduct = sessionStore.currentShopProduct;
    // variants and options are set based on the attribute option selection in design phase
    const shopProduct: IShopProduct = {
      details: { description: '', tags: [], title: '' },
      shop_id: sessionStore.currentShop?.id || tenantStore.user.tenantInfo?.shop_id || '',
      previews_status: [],
      main_preview: '',
      options: [],
      product_type: sessionStore.currentBase?.production_type || '',
      variants: [],
      guid: uuid(),
      super_sku_id: superSkuId,
      default_preview_url: '',
      status: ShopProductStatus.pending,
      translations: {},
      last_updated: '',
      default_sku: '',
    };
    if (originalShopProduct) {
      const originalVariants = toRaw(originalShopProduct.variants);
      const newShopProductVariantsAndOptions = toRaw(sessionStore.currentShopProductVariantsAndOptions);
      const sharedVariants = findVariantOptionsIntersection(originalVariants, newShopProductVariantsAndOptions.variants);
      const originalDesignPhaseProjects = undoRedoStore.getOriginalDesignPhaseProjects();
      const originalProjects = originalDesignPhaseProjects.length > 0 ? originalDesignPhaseProjects : undoRedoStore.getInitialEditorProjects();
      const newProjects = editorProductStore.getRawCurrentEditorProjectList();
      let resetAllVariants = true;
      if (originalProjects && sharedVariants.length > 0) {
        const sharedProjectGuids = sharedVariants.map((variant) => variant.project_guid);
        const sharedVariantsHaveSameProjects = originalProjects.filter((originalProject) => {
          return sharedProjectGuids.includes(originalProject.guid);
        }).every((filteredOriginalProject) => {
          const foundNewProject = newProjects.find((newProject) => {
            return filteredOriginalProject.guid === newProject.guid;
          });
          return foundNewProject && Helpers.areProjectPagesEqual(filteredOriginalProject, foundNewProject);
        });
        if (sharedVariantsHaveSameProjects) {
          resetAllVariants = false;
          shopProduct.options = newShopProductVariantsAndOptions.options;
          for (let i = 0; i < newShopProductVariantsAndOptions.variants.length; i++) {
            const newVariant = newShopProductVariantsAndOptions.variants[i];
            const foundSharedVariant = sharedVariants.find((sharedVariant) => sharedVariant.product_sku === newVariant.product_sku);
            if (foundSharedVariant) {
              shopProduct.variants.push(foundSharedVariant);
            } else {
              shopProduct.variants.push(newVariant);
            }
          }
          shopProduct.default_preview_url = originalShopProduct.default_preview_url;
          sessionStore.setCurrentShopProduct(shopProduct);
        }
      }
      if (resetAllVariants) {
        sessionStore.setCurrentShopProduct(shopProduct);
        shopProduct.variants = newShopProductVariantsAndOptions.variants;
        shopProduct.options = newShopProductVariantsAndOptions.options;
      }
      shopProduct.details = originalShopProduct.details;
      shopProduct.previews_status = originalShopProduct.previews_status;
      shopProduct.main_preview = originalShopProduct.main_preview;
      shopProduct.guid = originalShopProduct.guid;
      shopProduct.super_sku_id = originalShopProduct.super_sku_id;
      shopProduct.status = originalShopProduct.status;
      shopProduct.platform_id = originalShopProduct.platform_id;
      shopProduct.platform_status = originalShopProduct.platform_status;
      shopProduct.default_sku = originalShopProduct.default_sku;
      const defaultVariant = getVariantByDefaultSku(shopProduct);
      if (!defaultVariant) {
        shopProduct.default_sku = shopProduct.variants[0].product_sku;
      }
    }
  }

  // this function saves various pieces of a shop product (previews, projects, etc), and decdides whether the product should go to shopify or not. Templates/Drafts are not sent to shopify.
  async function saveShopProduct(shopProduct: IShopProduct, projects: IProjectInfo[], updateExistingProduct = false): Promise<void> {
    const sessionStore = useSessionStore();
    const shouldSavePreviews = isThereAnyPreviewToSave(shopProduct);
    if (shouldSavePreviews) {
      // todo ask Scott if this is still valid, if not I can remove it
      // This is weird, but I have to reset default preview url before publishing to shop product add/update if previews are supposed to be saved. Default preview url is set by dfe.
      shopProduct.default_preview_url = '';
    }
    let currentProducts: IProductInfo[] = [];
    if ((!sessionStore.currentCatalogItem && isSuperSkuShopProduct(shopProduct)) || isSuperSku(sessionStore.currentCatalogItem)) {
      currentProducts = sessionStore.currentSuperSkuProductInfos;
    } else if (sessionStore.currentBase) {
      currentProducts = [sessionStore.currentBase];
    }
    createOptionTranslations(shopProduct);
    try {
      const correlationId = uuid();
      const previewCount = shouldSavePreviews ? getNoOfImagesToBeSaved(shopProduct) : 0;
      if (updateExistingProduct) {
        await saveProjects(false, shopProduct, projects, correlationId);
        publishShopProductUpdate(shopProduct, previewCount, correlationId);
        await waitForShopProductProcessed(shopProduct.guid);
        if (shouldSavePreviews) {
          await saveVariantPreviews(currentProducts, shopProduct, correlationId);
        }
      } else {
        await saveProjects(true, shopProduct, projects, correlationId);
        publishShopProductAdd(shopProduct, previewCount, correlationId);
        await waitForShopProductProcessed(shopProduct.guid);
        if (shouldSavePreviews) {
          await saveVariantPreviews(currentProducts, shopProduct, correlationId);
        }
      }
      sessionStore.setCurrentSavedTemplateOrDraftShopProduct(shopProduct);
      sessionStore.setCurrentSavedTemplateOrDraftProjects(shopProduct, projects);
    } catch (error) {
      console.error('error while saving shop product: ', error);
    }
  }

  function updateShopProductPrices(prices: IPriceDetail[], currency: ICurrency, shopProduct: IShopProduct): void {
    shopProduct.variants.forEach((variant) => {
      const foundPriceDetail = prices.find((priceDetail: IPriceDetail) => {
        const variantSkuId = getSkuIdFromVariant(variant, shopProduct.guid);
        return priceDetail.product.skuId === variantSkuId.toString();
      });
      if (foundPriceDetail) {
        variant.price_f = foundPriceDetail.retail.formatted;
        variant.price = unFormat(foundPriceDetail.retail.formatted, currency);
      }
    });
  }

  async function saveVariantPreviews(currentProducts: IProductInfo[], currentShopProduct: IShopProduct, correlationId: string): Promise<void> {
    await saveVariantPreviewsWithExistingBlob(currentProducts, currentShopProduct, correlationId);
    await saveEmptyVariantPreviews(currentProducts, currentShopProduct, correlationId);
  }

  async function saveVariantPreviewsWithExistingBlob(currentProducts: IProductInfo[], currentShopProduct: IShopProduct, correlationId: string): Promise<void> {
    const sessionStore = useSessionStore();
    const tenantStore = useTenantStore();
    const tenantShopId = tenantStore.tenantInfo()?.shop_id;
    const variantsWithImagePreviews = currentShopProduct.variants.filter((variant) => {
      return variant.image_previews.length > 0;
    });
    let productVariantMap: Map<string, IVariant[]> = new Map();
    const defaultVariant = getVariantByDefaultSku(currentShopProduct);
    // if it's a draft shop product, we only save the default variant main preview -- drafts are not going to the actual shop so we don't need to save all previews
    if (tenantShopId && isDraftShopProduct(currentShopProduct.status, currentShopProduct.shop_id, tenantShopId) && defaultVariant) {
      const defaultVariantWithImagePreviews = variantsWithImagePreviews.filter((variant) => {
        return variant.product_sku === currentShopProduct.default_sku;
      });
      if (defaultVariantWithImagePreviews.length > 0) {
        productVariantMap.set(currentShopProduct.default_sku, defaultVariantWithImagePreviews);
      }
    } else {
      productVariantMap = await getVariantsMapForSavingPreviews(variantsWithImagePreviews, currentProducts, currentShopProduct, sessionStore.currentSuperSkuAttributes);
    }
    for (const [productSku, variants] of productVariantMap) {
      const productSkus = variants.map((variant) => {
        return variant.product_sku;
      });
      const variant = getVariantByProductSku(currentShopProduct, productSku);
      if (variant) {
        await itePreviewReady;
        if (!itePreviews) {
          throw new Error('ItePreview not loadeded');
        }
        let variantImagePreviews: IVariantImagePreview[] = [];
        // if it's a draft shop product, we only save the default variant main preview -- drafts are not going to the actual shop so we don't need to save all previews
        if (tenantShopId && isDraftShopProduct(currentShopProduct.status, currentShopProduct.shop_id, tenantShopId) && defaultVariant) {
          variantImagePreviews = variant.image_previews.filter((preview) => {
            return preview.name === currentShopProduct.main_preview;
          });
        } else {
          variantImagePreviews = variant.image_previews;
        }

        for (let i = 0; i < variantImagePreviews.length; i++) {
          if (isABlobVariantImagePreview(variantImagePreviews[i])) {
            const imageData = await fetchImageDataFromUrl(variantImagePreviews[i].url);
            itePreviews.saveIteBlobPreview(imageData, currentShopProduct.shop_id, currentShopProduct.guid, variantImagePreviews[i].name, productSkus, correlationId);
          }
        }
      }
    }
  }

  function isThereAnyPreviewToSave(currentShopProduct: IShopProduct): boolean {
    return currentShopProduct.variants.some((variant) => {
      if (variant.image_previews.length === 0) {
        return true;
      }
      return variant.image_previews.find((preview) => {
        if (isABlobVariantImagePreview(preview)) {
          return true;
        }
      });
    });
  }

  function getNoOfImagesToBeSaved(currentShopProduct: IShopProduct): number {
    let count = 0;
    const tenantStore = useTenantStore();
    const tenantShopId = tenantStore.tenantInfo()?.shop_id;
    const isDraft = tenantShopId && isDraftShopProduct(currentShopProduct.status, currentShopProduct.shop_id, tenantShopId);
    const defaultVariant = getVariantByDefaultSku(currentShopProduct);
    const variants = isDraft && defaultVariant ? [defaultVariant] : currentShopProduct.variants;

    variants.forEach((variant) => {
      // if it's a draft shop product, we only save the default variant main preview -- drafts are not going to the actual shop so we don't need to save all previews
      const allPreviewNames = isDraft ? [currentShopProduct.main_preview] : currentShopProduct.previews_status.map((preview) => preview.name);
      allPreviewNames.forEach((previewName) => {
        const foundPreview = variant.image_previews.find((preview) => preview.name === previewName);
        if (!foundPreview) {
          count++;
        } else {
          if (isABlobVariantImagePreview(foundPreview) || !foundPreview.url) {
            count++;
          }
        }
      });
    });
    return count;
  }

  async function fetchImageDataFromUrl(url: string): Promise<Uint8Array> {
    const response = await fetch(url);
    const blob = await response.blob();
    const buffer = await blob.arrayBuffer();
    return new Uint8Array(buffer);
  }

  async function saveEmptyVariantPreviews(products: IProductInfo[], shopProduct: IShopProduct, correlationId: string): Promise<void> {
    const sessionStore = useSessionStore();
    const editorProductStore = useEditorProductStore();
    const tenantStore = useTenantStore();
    const tenantShopId = tenantStore.tenantInfo()?.shop_id;
    const variantWithImagePreviews = shopProduct.variants.find((variant) => {
      return variant.image_previews.length > 0;
    });
    // for templates having just one preview which will be used as a default preview url is enough
    if (tenantShopId && isTemplateShopProduct(shopProduct.status, shopProduct.shop_id, tenantShopId) && variantWithImagePreviews) {
      return Promise.resolve();
    }
    const variantsWithNoImagePreviews = shopProduct.variants.filter((variant) => {
      return variant.image_previews.length === 0;
    });

    let productVariantMap: Map<string, IVariant[]> = new Map();
    const defaultVariant = getVariantByDefaultSku(shopProduct);
    if (tenantShopId && isDraftShopProduct(shopProduct.status, shopProduct.shop_id, tenantShopId) && defaultVariant) {
      const defaultVariantWithNoImagePreviews = variantsWithNoImagePreviews.filter((variant) => {
        return variant.product_sku === shopProduct.default_sku;
      });
      if (defaultVariantWithNoImagePreviews.length > 0) {
        productVariantMap.set(shopProduct.default_sku, defaultVariantWithNoImagePreviews);
      }
    } else {
      productVariantMap = await getVariantsMapForSavingPreviews(variantsWithNoImagePreviews, products, shopProduct, sessionStore.currentSuperSkuAttributes);
    }

    for (const [productSku, variants] of productVariantMap) {
      const skuId = parseInt(getSkuIdFromProductSku(shopProduct, productSku), 10);
      const productSkus = variants.map((variant) => {
        return variant.product_sku;
      });
      const product = products.find((product) => {
        return product.sku_data.map(skuData => skuData.product_sku_id).includes(skuId);
      });
      const project = editorProductStore.findProject(skuId);
      if (project && product) {
        await editorProductStore.updateProjectsPagePreviews(skuId, product);
        await itePreviewReady;
        if (!itePreviews) {
          throw new Error('ItePreview not loaded');
        }
        let previewData: IPreviewData[] = [];
        if (tenantShopId && isDraftShopProduct(shopProduct.status, shopProduct.shop_id, tenantShopId) && defaultVariant) {
          previewData = itePreviews.retrievePreviewDataBySkuIds('standard', 'photo', product, [skuId], editorProductStore.pageOrientations, true)
            .filter((previewData) => {
              return previewData.title === shopProduct.main_preview;
            });
        } else {
          previewData = itePreviews.retrievePreviewDataBySkuIds('standard', 'photo', product, [skuId], editorProductStore.pageOrientations, true);
        }
        previewData.forEach((previewData) => {
          let pagesForIteString: IPage[];
          const pages = project.pages;
          if (previewData.flags && previewData.flags.I) {
            const pageIndex = parseInt(previewData.flags.I, 10);
            const foundPage = pages.find((page) => page.page_number - 1 === pageIndex);
            pagesForIteString = foundPage ? [foundPage] : [];
          } else {
            pagesForIteString = pages;
          }
          if (pagesForIteString.length > 0) {
            if (!itePreviews) {
              throw new Error('ItePreview not loaded');
            }
            const replacedITE = itePreviews.replaceIteStringPlaceHolders(previewData, pagesForIteString, product);
            itePreviews.createAndSaveIteImage(replacedITE.iteString, shopProduct.shop_id, shopProduct.guid, previewData.title, productSkus, correlationId);
          }
        });
      }
    }
  }


  async function saveProjects(isNewProject: boolean, shopProduct: IShopProduct, projects: IProjectInfo[], correlationId: string): Promise<void> {
    const shopProductProjectStore = useShopProductProjectStore();
    for (const project of projects) {
      try {
        if (isNewProject) {
          await shopProductProjectStore.publishShopProductProjectAdd(shopProduct.shop_id, shopProduct.guid, project, correlationId).then((projectGuid) => {
            shopProductProjectStore.cleanupGuidPromise(projectGuid);
          }).catch((error) => {
            console.error('error while saving shop product project: ', error);
            shopProductProjectStore.cleanupGuidPromise(project.guid);
          });
        } else {
          await shopProductProjectStore.publishShopProductProjectUpdate(shopProduct.shop_id, shopProduct.guid, project, correlationId).then((projectGuid) => {
            shopProductProjectStore.cleanupGuidPromise(projectGuid);
          }).catch((error) => {
            console.error('error while saving shop product project: ', error);
            shopProductProjectStore.cleanupGuidPromise(project.guid);
          });

        }
      } catch (error) {
        console.error('error while saving shop product project: ', error);
      }
    }
  }

  function createOptionTranslations(shopProduct: IShopProduct): void {
    const sessionStore = useSessionStore();
    // product options are the same among all products in a super sku, so we can use the first product to get the translations
    const productForTranslation = isSuperSkuShopProduct(shopProduct) ? sessionStore.currentSuperSkuProductInfos[0] : sessionStore.currentBase;
    if (productForTranslation?.translations) {
      const translations = productForTranslation.translations as { [key: string]: IProductTranslations };
      Object.keys(productForTranslation.translations).forEach((translation) => {
        if (!shopProduct.translations[translation]) {
          shopProduct.translations[translation] = { option_translations: {} };
        }
        if (translations[translation].option_translations) {
          shopProduct.translations[translation].option_translations = translations[translation].option_translations as {
            [key: string]: IOptionTranslation
          };
        }
      });
    }

    if (isSuperSkuShopProduct(shopProduct)) {
      const catalogItem = sessionStore.currentCatalogItem ? sessionStore.currentCatalogItem : superSkuCatalogItem.value;
      if (catalogItem && catalogItem.attributes) {
        catalogItem.attributes.forEach((superSkuAttribute) => {
          if (superSkuAttribute.translations) {
            const superSkuAttributeTranslations = superSkuAttribute.translations as { [key: string]: string };
            Object.keys(superSkuAttributeTranslations).forEach((translation) => {
              if (!shopProduct.translations[translation]) {
                shopProduct.translations[translation] = { option_translations: {} };
              }
              shopProduct.translations[translation].option_translations[superSkuAttribute.name] = {
                name: superSkuAttributeTranslations[translation],
                values: {}
              };
              superSkuAttribute.values.forEach((value) => {
                if (value.translations) {
                  shopProduct.translations[translation].option_translations[superSkuAttribute.name].values[value.name] = value.translations[translation];
                }
              });
            });
          }
        });
      }
    }

  }

  async function createShopProductOptionPreviews(shopProduct: IShopProduct, previewsPickedBySku: IPreviewData[], shopProductOption: {[key: string]: string}, product: IProductInfo, enabledPreviews: string[]): Promise<IPreviewResult[]> {
    const editorProductStore = useEditorProductStore();
    const iteImagePromises: Promise<void>[] = [];
    const itePreviewImages: IPreviewResult[] = [];
    const selectedAttributeValues = Object.values(shopProductOption);
    let selectedSkus = findSkuData(product, selectedAttributeValues);
    if (selectedSkus.length > 0) {
      let project = editorProductStore.findProject(selectedSkus[0].product_sku_id);
      // switch to landscape orientation option if it's a square size project and orientation is portrait
      if (project && shouldSkipVariant(project, shopProductOption) && selectedAttributeValues.includes(Orientation.portrait)) {
        selectedAttributeValues[selectedAttributeValues.indexOf(Orientation.portrait)] = Orientation.landscape;
        selectedSkus = findSkuData(product, selectedAttributeValues);
        project = editorProductStore.findProject(selectedSkus[0].product_sku_id);
      }
      if (project) {
        await editorProductStore.updateProjectsPagePreviews(selectedSkus[0].product_sku_id, product);
        previewsPickedBySku.forEach((previewData, index) => {
          let pagesForIteString: IPage[];
          const pages = (project as IProjectInfo).pages;
          if (previewData.flags && previewData.flags.I) {
            const pageIndex = parseInt(previewData.flags.I, 10);
            const foundPage = pages.find((page) => page.page_number - 1 === pageIndex);
            pagesForIteString = foundPage ? [foundPage] : [];
          } else {
            pagesForIteString = pages;
          }
          if (pagesForIteString.length > 0) {
            if (!itePreviews) {
              throw new Error('ItePreview not loadeded');
            }
            const replacedITE = itePreviews.replaceIteStringPlaceHolders(previewData, pagesForIteString, product);
            iteImagePromises.push(itePreviews.createLocalIteImage(replacedITE.iteString).then((previewBlobUrl) => {
              const previewResult: IPreviewResult = {
                blobUrl: previewBlobUrl,
                pageIndex: replacedITE.pageIndex,
                previewName: previewData.title,
                basePreviewId: previewData.id,
                projectGuid: (project as IProjectInfo).guid,
                previewIndex: index
              };
              itePreviewImages.push(previewResult);
              const previewIsEnabled = enabledPreviews.some((previewName) => {
                return previewName === previewResult.previewName;
              });
              updateShopProductPreviewsStatus(shopProduct, previewResult.previewName, previewIsEnabled);
            }));
          }
        });
      }
    }
    await Promise.all(iteImagePromises);
    itePreviewImages.sort((a, b) => a.previewIndex - b.previewIndex);
    return itePreviewImages;
  }

  function getAllSkus(): ISkuData[] {
    const sessionStore = useSessionStore();
    let currentProducts: IProductInfo[] = [];
    if (isSuperSkuProduct()) {
      currentProducts = sessionStore.currentSuperSkuProductInfos;
    } else if (sessionStore.currentBase) {
      currentProducts = [sessionStore.currentBase];
    }

    return currentProducts.map((product) => {
      return product.sku_data;
    }).flat();
  }

  function isSuperSkuProduct(): boolean {
    const sessionStore = useSessionStore();
    return isSuperSku(sessionStore.currentCatalogItem) || (!sessionStore.currentCatalogItem && isSuperSkuShopProduct(sessionStore.currentShopProduct));
  }


  return {
    shopProducts,
    findShopProduct,
    getShopProducts,
    superSkuCatalogItem,
    productNameFromCurrentShopProduct,
    publishShopProductDelete,
    retrieveShopProductInfo,
    currentShopProductBases,
    subscribeToShopProductCurrent,
    cleanupShopProductPreviewsPromise,
    cleanupDefaultPreviewPromise,
    cleanupShopProductProcessedPromise,
    waitForShopProductPreviewsReady,
    waitForShopProductTransitionToStoreReady,
    saveShopProduct,
    updateShopProductPrices,
    initializeShopProduct,
    createShopProductOptionPreviews,
    removeShopProduct,
    publishShopProductUpdate,
    saveAndPublishShopProduct,
    checkShopProductVariantsAndProjectsChanges
  };
});
