import {defineStore} from 'pinia';
import {PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels} from '@/constants/topicEnums';
import { computed, ref } from 'vue';
import { IShopInfo, ShopStatus } from '@/interfaces/shopInterface';
import {ServiceLocator, ServiceType} from '@/services/serviceLocator';
import {useShopProductStore} from '@/stores/shopProductStore';
import { TopicWildCard } from '@/constants/topicConstants';
import { useTenantStore } from '@/stores/tenantStore';
import { ISolaceClient } from '@/interfaces/iSolaceClient';

export const useShopStore = defineStore('shop', () => {
  const shopList = ref<IShopInfo[]>([]);
  const publishedRequests: { [key: string]: boolean } = {};
  let solaceClient:ISolaceClient|undefined;
  const solaceReady:Promise<void> = new Promise((resolve, reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, err => {
      reject(err);
    });
  });

  const promises: {
    [shopGuid: string]: {
      resolve: (value: IShopInfo) => void;
      reject: (reason: string) => void;
    };
  } = {};

  // Shops that are not the tenant's shop
  const publicShopList = computed(() => {
    const tenantStore = useTenantStore();
    const tenantShopId = tenantStore.tenantInfo()?.shop_id;
    return shopList.value.filter(shop => shop.id !== tenantShopId);
  });

  function findShop(shopId: string): IShopInfo | null {
    const res = shopList.value?.find((shop) => shop.id === shopId);
    if (!res) {
      requestShopInfo(shopId);
    }
    return res || null;
  }

  function findShopWithPromise(shopId: string): Promise<IShopInfo> {
    const promise = new Promise<IShopInfo>((resolve, reject) => {
      promises[shopId] = { resolve, reject };
    });

    const shop = findShop(shopId);
    if (shop) {
      return Promise.resolve(shop);
    } else {
      requestShopInfo(shopId);
    }
    return promise;

  }

  function requestShopInfo(shopId: string): void {
    getCacheForShopCurrent(shopId).then(() => {
      console.debug('Successfully retrieved shop from cache');
    }).catch(error => {
      console.debug(error);
      publishShopTrigger(shopId);
    });
  }

  function getCacheForShopCurrent(shopId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }
        const shopCurrentSubscription = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_SHOP_CURRENT).replace(TopicVarLevels.SHOP_ID, shopId);
        solaceClient.checkCache(shopCurrentSubscription).then(() => {
          resolve();
        }, err => {
          reject(err);
        });
      }, err => {
        console.error('Getting solace failed', err);
      });
    });
  }

  function subscribeToShopCurrent(): void {
    const shopProductStore = useShopProductStore();
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const shopCurrentSubscription = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_SHOP_CURRENT);
      if (shopCurrentSubscription) {
        const replacedSub = shopCurrentSubscription.replace(TopicVarLevels.SHOP_ID, TopicWildCard);
        solaceClient.mapSubscriptionJson<IShopInfo>(replacedSub, payload => {
            const shopData = payload;
            const shopIndex = shopList.value?.findIndex(
              (shop) => shop.id === shopData.id
            );
            if (shopIndex !== -1) {
              const shopProductList = shopProductStore.shopProducts.get(shopData.id);
              // We need to remove the deleted shop product from shop product store
              // When a shop product gets deleted, shop product current is not returned, instead we get a shop current with new shop product summaries.
              shopProductList?.forEach((shopProduct) => {
                const foundShopProduct = shopData.product_summaries?.find((productSummary) => shopProduct.guid === productSummary.guid);
                if (!foundShopProduct) {
                  shopProductStore.removeShopProduct(shopData.id, shopProduct.guid);
                }
              });
                switch (shopData.status) {
                  case ShopStatus.generic:
                  case ShopStatus.enabled:
                    shopList.value[shopIndex] = shopData;
                    break;
                  case ShopStatus.disabled:
                    shopList.value.splice(shopIndex, 1);
                    break;
                }

            } else {
              shopList.value.push(shopData);
            }
            console.log('shop current data: ', shopList.value);
            shopProductStore.subscribeToShopProductCurrent(shopData.id);
            if (promises[shopData.id]) {
              promises[shopData.id].resolve(shopData);
            }
          }
        );
      } else {
        console.error('Unable to subscribe to shop current topic - not found');
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function publishShopTrigger(shopId: string): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_TRIGGER);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId);

        if (!shopId || publishedRequests[editedTopic]) {
          return;
        }

        publishedRequests[editedTopic] = true;
        solaceClient.publishMessageString(editedTopic, undefined, err => {
          if (promises[shopId]) {
            promises[shopId].reject(err.message);
          }
          // I'm getting a lot of "unimplemented method" errors from dfe.
          // I'm changing this to warn for now, until we can well define dfe subscription topics
          console.warn('Error publishing shop trigger', err);
        });
      } else {
        console.warn(
          `Could not find topic for publish shop trigger: ${PublishTopicKeys.PUB_TENANT_SHOP_TRIGGER}`
        );
        if (promises[shopId]) {
          promises[shopId].reject('Could not find topic for publish shop trigger');
        }
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function publishShopUpdate(updatedShop: IShopInfo): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const pubTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_UPDATE);
      if (pubTopic) {
        const editedTopic = pubTopic.replace(TopicVarLevels.SHOP_ID, updatedShop.id);
        solaceClient.publishMessageString(editedTopic, JSON.stringify(updatedShop), err => {
          console.warn('Error publishing shop update', err);
        });
      } else {
        console.warn(
          'Could not find topic for publish shop update: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_UPDATE
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function publishShopDelete(shopId: string): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const pubTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_DELETE);
      if (pubTopic) {
        const editedTopic = pubTopic.replace(TopicVarLevels.SHOP_ID, shopId);
        solaceClient.publishMessageString(editedTopic, undefined, err => {
          console.warn('Error publishing shop delete', err);
        });
      } else {
        console.warn(
          'Could not find topic for publish shop delete: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_DELETE
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  function cleanupPromises(shopId: string): void {
    if (promises[shopId]) {
      delete promises[shopId];
    }
  }

  return {
    shopList,
    publicShopList,
    findShop,
    requestShopInfo,
    subscribeToShopCurrent,
    publishShopTrigger,
    publishShopUpdate,
    publishShopDelete,
    cleanupPromises,
    findShopWithPromise
  };
});
