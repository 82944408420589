<template>
  <v-app
    id="print-suite"
  >
    <welcome v-if="!isLoggedIn" />
    <dashboard v-else />
  </v-app>
</template>

<script setup lang="ts">


import { defineAsyncComponent, onBeforeMount } from 'vue';
import { useTenantStore } from '@/stores/tenantStore';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { ref } from 'vue';
import Welcome from '@/Welcome.vue';
import PrintSuiteSpinner from '@/components/PrintSuiteSpinner.vue';
import { IKeycloakClient } from '@/interfaces/iKeycloakClient';

const Dashboard = defineAsyncComponent({
  loader: () => import('@/Dashboard.vue'),
  loadingComponent: PrintSuiteSpinner,
});

let keycloakClient:IKeycloakClient|undefined;
const keycloakReady:Promise<void> = new Promise((resolve,reject) => {
  ServiceLocator.getService<IKeycloakClient>(ServiceType.KeyCloak).then(keycloak => {
    keycloakClient = keycloak;
    resolve();
  }, err => {
    reject(err);
  });
});


const isLoggedIn = ref(false);

// initialize Solace setup before the app mounts
onBeforeMount(async () => {
  // check for the registerId if the app is loaded from Shopify service redirect
  await keycloakReady;

  const url = new URL(window.location.href);
  const shopQuery = url.searchParams.get('shop');
  const tenantStore = useTenantStore();
  if (shopQuery) {
    tenantStore.setSessionShopGuid(shopQuery);
  }

  if (!keycloakClient) {
    throw new Error('Keycloak client not initialized');
  }

  if (keycloakClient.isLoggedIn()) {
    isLoggedIn.value = true;
  } else if (tenantStore.hasSessionShopGuid()) {
    tenantStore.doLogIn();
  }
});
</script>

<style lang="scss">

</style>
