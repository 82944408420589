import { defineStore } from 'pinia';
import { computed, ref, shallowRef, toRaw } from 'vue';
import { IEvent } from 'fabric/fabric-impl';
import {
  IEditorEditorPhoto, IEditorElement,
  IEditorImage,
  IEditorPhotoGroup,
  IEditorShape
} from '@/interfaces/editorInterfaces';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
import { Helpers } from '@/modules/editor/helpers';
import { PhotoGroup } from '@/modules/editor/fabricShapes/photoGroup';
import { fabric } from 'fabric';
import { Photo } from '@/modules/editor/photo';
import { PhotoElement } from '@/modules/editor/element';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { Edge } from '@/modules/editor/edge';
import { useImageStore } from '@/stores/imageStore';
export interface IDropPhotoData {
  photo: Photo|undefined;
  draggedImageDimension: {width: number, height: number};
}

export enum EditorImagePanelView {
  transform = 'transform',
  position = 'position',
  properties = 'properties',
  pattern = 'pattern',
  removeBackground = 'removeBackground',
}
export const useEditorImageStore = defineStore('editorImage', () => {
  const selectedImageZoom = ref(0);
  const selectedImageRotation = ref(0);
  const zoomStep = ref(0);
  const currentImagePanelView = ref<EditorImagePanelView | undefined>(
    undefined
  );
  const selectedImageWidth = ref(0);
  const selectedImageHeight = ref(0);
  const selectedImageX = ref(0);
  const selectedImageY = ref(0);
  const dropPhotoData = shallowRef<IDropPhotoData | null>(null);
  const editorCanvasStore = useEditorCanvasStore();

  const photoUsagesCount = computed((): Map<number, number> => {
    const imageStore = useImageStore();
    const photoUsageCountMap = new Map<number, number>(); // photoId -> count
    imageStore.photoList.forEach((photo) => {
      photoUsageCountMap.set(photo.id, 0);
    });

      editorCanvasStore.editorLayerCanvasObjects.forEach((obj) => {
        const photoObject = (obj as IEditorShape).photoObject;
        if (photoObject && photoObject.image && photoObject.image.id !== null) {
          if (photoUsageCountMap.has(photoObject.image.id)) {
            let count = photoUsageCountMap.get(photoObject.image.id) as number;
            count++;
            photoUsageCountMap.set(photoObject.image.id, count);
          }
        }
      });

    return photoUsageCountMap;
  });

  function setDropPhotoData(width: number, height: number, photo: Photo|undefined): void {
    dropPhotoData.value = {
      photo: photo,
      draggedImageDimension: {
        width: width, height: height
      }
    };
  }

  function setCurrentImagePanelView(imagePanelView: EditorImagePanelView|undefined): void {
    currentImagePanelView.value = imagePanelView;
  }

  function closeImageEditPanel(): void {
    currentImagePanelView.value = undefined;
  }

  function handleImageEditsChange(event?: IEvent): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    let selectedObj:IEditorShape|undefined = undefined;

    if (
      event &&
      event.selected &&
      fabricCanvas &&
      event.selected.length === 1 &&
      (event.selected[0] as IEditorShape).photoObject !== undefined
    ) {
      selectedObj = event.selected[0] as IEditorShape;
    } else if (!event) {
      selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    }
    if (selectedObj && selectedObj.photoObject) {
      const zoom = selectedObj.getZoom();
      const zoomMin = selectedObj.photoObject.getMinZoom();
      const zoomMax = editorCanvasStore.errorConfiguration.zoomError;
      selectedImageZoom.value = zoomMax > zoomMin ? Helpers.getImageZoomFromZoomFactor(
        zoom,
        zoomMax,
        zoomMin
      ) : 0;
      zoomStep.value = zoomMax > zoomMin ? (zoomMax - zoomMin) / 20 : 0;
      selectedImageRotation.value = selectedObj.getInnerRotation();
      selectedImageWidth.value = selectedObj.getScaledWidth() as number;
      selectedImageHeight.value = selectedObj.getScaledHeight() as number;
      selectedImageX.value =
        selectedObj.left - selectedObj.getScaledWidth() / 2;

      selectedImageY.value =
        selectedObj.top - selectedObj.getScaledHeight() / 2;
    }
  }

  function setSelectedObjectZoom(zoom: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      const photoObject: IEditorEditorPhoto = (selectedObj as IEditorPhotoGroup)
        .photoObject;
      const zoomBeforeChange = photoObject.imagePosition.zoom;
      const zoomDelta = zoom - zoomBeforeChange;
      (selectedObj as IEditorPhotoGroup).zoom(zoomDelta);
      if (photoObject.group) {
        (photoObject.group as unknown as IEditorShape).userZoom =
          photoObject.imagePosition.zoom;
      }
      photoObject.elementAdded = false;
      fabricCanvas?.renderAll();
    }
  }

  function setSelectedObjectRotation(angle: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      (selectedObj as IEditorPhotoGroup).sliderRotation(angle);
      fabricCanvas?.renderAll();
    }
  }

  function changeImageWidth(width: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup && selectedObj.width) {
      selectedObj.scaleX = width / selectedObj.width;
      selectedObj.setCoords();
      fabricCanvas?.renderAll();
      editorCanvasStore.saveCanvasObjectsState().catch(err => {
        console.error('Unable to save canvas state', err);
      });
    }
  }

  function changeImageHeight(height: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup && selectedObj.height) {
      selectedObj.scaleY = height / selectedObj.height;
      selectedObj.setCoords();
      fabricCanvas?.renderAll();
      editorCanvasStore.saveCanvasObjectsState().catch(err => {
        console.error('Unable to save canvas state', err);
      });
    }
  }

  function changeImageX(left: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      selectedObj.left = left + selectedObj.getScaledWidth() / 2;
      fabricCanvas?.renderAll();
      editorCanvasStore.saveCanvasObjectsState().catch(err => {
        console.error('Unable to save canvas state', err);
      });
    }
  }

  function changeImageY(top: number): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      selectedObj.top = top + selectedObj.getScaledHeight() / 2;
      fabricCanvas?.renderAll();
      editorCanvasStore.saveCanvasObjectsState().catch(err => {
        console.error('Unable to save canvas state', err);
      });
    }
  }

  function rotate90CW(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      selectedObj.animate('innerRotate', '+=90', {
        onChange: fabricCanvas?.renderAll.bind(fabricCanvas),
        easing: fabric.util.ease.easeOutCubic,
        onComplete: editorCanvasStore.saveCanvasObjectsState
      });
    }
  }

  function rotate90CCW(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (selectedObj instanceof PhotoGroup) {
      selectedObj.animate('innerRotate', '-=90', {
        onChange: fabricCanvas?.renderAll.bind(fabricCanvas),
        easing: fabric.util.ease.easeOutCubic,
        onComplete: editorCanvasStore.saveCanvasObjectsState
      });
    }
  }

  function flip(flip: 'flipVertical' | 'flipHorizontal'): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const selectedObj = fabricCanvas?.getActiveObject() as IEditorShape;
    if (flip == 'flipVertical') {
      selectedObj.flipVertical();
    } else {
      selectedObj.flipHorizontal();
    }
    fabricCanvas?.renderAll();
    editorCanvasStore.saveCanvasObjectsState().catch(err => {
      console.error('Unable to save canvas state', err);
    });
  }

  function fitImageToPrintableArea() {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    if (fabricCanvas) {
      const selectedObj = fabricCanvas.getActiveObject() as IEditorShape|null;

      const page = editorCanvasStore.getCurrentPage();
      if (page && selectedObj && selectedObj.photoObject.width && selectedObj.photoObject.height) {
        const snapAngle = Math.round(selectedObj.photoObject.angle / 90);
        // evaluates to true when the orientation of the photo has changed ie 90 / 270
        const isRotated = snapAngle % 2 != 0;

        //rotate to nearest right angle
        selectedObj.photoObject.innerRotate(snapAngle * 90);
        selectedObj.photoObject.placePhoto();
        selectedObj.photoObject.fit();

        let pageWidth = page.width;
        let pageHeight = page.height;
        if (fabricCanvas.wrap && Helpers.validWrap(fabricCanvas.wrap)) {
          pageWidth -= Helpers.getWrapWidth(fabricCanvas);
          pageHeight -= Helpers.getWrapHeight(fabricCanvas);
        }
        //when rotated 90 or 270 we must compare pageWidth to photoObject.height etc....
        if (isRotated) {
          //compare aspect ratio of the printable area to the photo
          if (pageWidth / pageHeight < selectedObj.photoObject.height / selectedObj.photoObject.width) {
            selectedObj.photoObject.width = selectedObj.photoObject.width * (pageWidth / selectedObj.photoObject.height);
            //noinspection JSSuspiciousNameCombination
            selectedObj.photoObject.height = pageWidth;
            selectedObj.photoObject.imagePosition.zoom = pageWidth / selectedObj.photoObject.imagePosition.height;
            selectedObj.photoObject.imagePosition.x = (pageWidth / -2) - ((selectedObj.photoObject.height - selectedObj.photoObject.width) / -2);
            selectedObj.photoObject.imagePosition.y = (selectedObj.photoObject.width / -2) + ((selectedObj.photoObject.height - selectedObj.photoObject.width) / -2);
          } else {
            selectedObj.photoObject.height = selectedObj.photoObject.height * (pageHeight / selectedObj.photoObject.width);
            //noinspection JSSuspiciousNameCombination
            selectedObj.photoObject.width = pageHeight;
            selectedObj.photoObject.imagePosition.zoom = pageHeight / selectedObj.photoObject.imagePosition.width;
            selectedObj.photoObject.imagePosition.x = (selectedObj.photoObject.height / -2) + ((selectedObj.photoObject.width - selectedObj.photoObject.height) / -2);
            selectedObj.photoObject.imagePosition.y = (pageHeight / -2) - ((selectedObj.photoObject.width - selectedObj.photoObject.height) / -2);
          }
          selectedObj.imagePosition = selectedObj.photoObject.imagePosition;
          selectedObj.frameObject.width = selectedObj.photoObject.height;
          selectedObj.frameObject.height = selectedObj.photoObject.width;
          selectedObj.width = selectedObj.photoObject.height;
          selectedObj.height = selectedObj.photoObject.width;
          selectedObj.top = page.height / 2;
          selectedObj.left = page.width / 2;

        } else {
          //compare aspect rations of the zone to the photo
          if (pageWidth / pageHeight < selectedObj.photoObject.width / selectedObj.photoObject.height) {
            selectedObj.photoObject.height = selectedObj.photoObject.height * (pageWidth / selectedObj.photoObject.width);
            selectedObj.photoObject.width = pageWidth;
            selectedObj.photoObject.imagePosition.zoom = pageWidth / selectedObj.photoObject.imagePosition.width;
            selectedObj.photoObject.imagePosition.x = pageWidth / -2;
            selectedObj.photoObject.imagePosition.y = selectedObj.photoObject.height / -2;
          } else {
            selectedObj.photoObject.width = selectedObj.photoObject.width * (pageHeight / selectedObj.photoObject.height);
            selectedObj.photoObject.height = pageHeight;
            selectedObj.photoObject.imagePosition.zoom = pageHeight / selectedObj.photoObject.imagePosition.height;
            selectedObj.photoObject.imagePosition.x = selectedObj.photoObject.width / -2;
            selectedObj.photoObject.imagePosition.y = pageHeight / -2;
          }
          selectedObj.imagePosition = selectedObj.photoObject.imagePosition;
          selectedObj.frameObject.width = selectedObj.photoObject.width;
          selectedObj.frameObject.height = selectedObj.photoObject.height;
          selectedObj.width = selectedObj.photoObject.width;
          selectedObj.height = selectedObj.photoObject.height;
          selectedObj.top = page.height / 2;
          selectedObj.left = page.width / 2;
        }
        selectedObj.photoObject.elementAdded = false;
        fabricCanvas.renderAll();
        handleImageEditsChange();
        editorCanvasStore.saveCanvasObjectsState().catch(err => {
          console.error('Unable to save canvas state', err);
        });
      }
    }

  }


  function fillPrintableArea() {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const page = editorCanvasStore.getCurrentPage();
    if (fabricCanvas && page) {
      const selectedObj = fabricCanvas.getActiveObject() as IEditorShape | null;
      let pageWidth = page.width;
      let pageHeight = page.height;
      if (fabricCanvas.wrap && Helpers.validWrap(fabricCanvas.wrap)) {
        pageWidth -= Helpers.getWrapWidth(fabricCanvas);
        pageHeight -= Helpers.getWrapHeight(fabricCanvas);
      }
      if (selectedObj) {

        const frame = selectedObj.photoObject.getFrame();
        const position = EditorTranslator.Instance.getImagePosition(
          pageWidth,
          pageHeight,
          frame.getMarginLeft(),
          frame.getMarginTop(),
          frame.getMarginRight(),
          frame.getMarginBottom(),
          selectedObj.photoObject.image,
          selectedObj.photoObject.angle // this is the photo objects angle, so inner rotation!
        );

        // set image position
        selectedObj.photoObject.imagePosition = position;

        // set properties -- stolen from constructor
        selectedObj.photoObject.set({
          width: position.width * position.zoom,
          height: position.height * position.zoom,
          left: 0,
          top: 0,
        });
        selectedObj.imagePosition = selectedObj.photoObject.imagePosition;
        selectedObj.frameObject.width = pageWidth;
        selectedObj.frameObject.height = pageHeight;
        selectedObj.width = pageWidth;
        selectedObj.height = pageHeight;
        selectedObj.top = page.height / 2;
        selectedObj.left = page.width / 2;
        selectedObj.photoObject.elementAdded = false;
        fabricCanvas.renderAll();
        editorCanvasStore.saveCanvasObjectsState().catch(err => {
          console.error('Unable to save canvas state', err);
        });
      }
    }

  }

  /** 1- if image is dragged and dropped on top of an existing image, it should always swap the image
   2- if an image is already selected on the canvas, and user clicks on an image in the gallery panel, then it should swap the image
   3- if no image is selected on the canvas, and user clicks on an image in the gallery panel, then it should add the image to the canvas without swapping*/
  function handlePhotoDrop(targetImage: IEditorShape | null): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    // convert to image element
    if (fabricCanvas && dropPhotoData.value) {

      let shape: IEditorShape;
      const zoom = 1 / fabricCanvas.getZoom();
      let pageWidth = 0;
      let pageHeight = 0;
      const page = editorCanvasStore.getCurrentPage();
      if (page) {
        pageWidth = page.width;
        pageHeight = page.height;
      }

      const imageDropPosition: {x: number, y: number} = {x: pageWidth / 2, y: pageHeight / 2};
      if (targetImage) {
          imageDropPosition.x = targetImage.left as number;
          imageDropPosition.y = targetImage.top as number;

      }
      const draggedImageDimension = targetImage ? {width: targetImage.width as number, height: targetImage.height as number} : dropPhotoData.value.draggedImageDimension;
      // calculate width and height
      const width = draggedImageDimension.width;
      const height = draggedImageDimension.height;
      // dropPhotoData.value.photo is undefined when it's being dropped as a customer editable image zone
      // creating a temporary edge. This is used for proper creation of the inner image item, and will be removed before rendering the canvas
      if (dropPhotoData.value.photo) {
        const photo = toRaw(dropPhotoData.value.photo);
        const edge = new Edge(0, photo.urlPrefix, photo.thumbs, 0, 0, 0, 0);
        const photoElement = new PhotoElement(photo, undefined, edge);
        const droppedSize = getDropImageSize({width: photo.width, height: photo.height}, draggedImageDimension, false);

        photoElement.height = targetImage ? height : droppedSize.height * zoom;
        photoElement.width = targetImage ? width : droppedSize.width * zoom;
        if (
          photo.edits &&
          (photo.edits.rotation == 90 || photo.edits.rotation == 270)
        ) {
          const temp = photoElement.width;
          // noinspection JSSuspiciousNameCombination
          photoElement.width = photoElement.height;
          photoElement.height = temp;
        }
        photoElement.x = imageDropPosition.x;
        photoElement.y = imageDropPosition.y;

        // add to canvas
        shape = EditorTranslator.Instance.toShape(photoElement);
        shape.userZoom = shape.imagePosition.zoom;


        applyImageEditsToPhotoShape(shape, photo);
        // removing the temporary edge
        (shape as IEditorPhotoGroup).removeEdge();
      } else {
        // if it's being dropped as a customer editable image zone
        const draggedImageDimension = dropPhotoData.value.draggedImageDimension;
        const imagePosition = {
          width: draggedImageDimension.width,
          height: draggedImageDimension.height,
          x: -draggedImageDimension.width / 2,
          y: -draggedImageDimension.height / 2,
          zoom: 1
        };

        const photoElement = new PhotoElement(undefined, imagePosition, undefined);
        const droppedSize = getDropImageSize(draggedImageDimension, draggedImageDimension, true);

        photoElement.height = targetImage ? height : droppedSize.height * zoom;
        photoElement.width = targetImage ? width : droppedSize.width * zoom;
        photoElement.x = imageDropPosition.x;
        photoElement.y = imageDropPosition.y;
        (photoElement as IEditorElement).customerEditable = dropPhotoData.value.photo === undefined;
        shape = EditorTranslator.Instance.toShape(photoElement);
      }
      shape.customerEditable = dropPhotoData.value.photo === undefined;

      // remove existing selected image before swapping
      if (targetImage) {
        fabricCanvas.insertAt(shape, fabricCanvas.getObjects().indexOf(targetImage), true);
        editorCanvasStore.deleteObject(targetImage);
      } else {
        fabricCanvas.add(shape);
      }

      // mark shape as selected
      fabricCanvas.setActiveObject(shape);
      // initialize the first render
      fabricCanvas.renderAll();
      editorCanvasStore.updateEditorLayerCanvasObjects();
      editorCanvasStore.saveCanvasObjectsState().then().catch(err => {
        console.error('Unable to save canvas state', err);
      });
    }
  }

  function getDropImageSize(dropImageSize: {width: number, height: number}, draggedImageSize: {width: number, height: number}, isCustomerEditableImageZone: boolean): {width: number, height: number} {
    // if it's being dropped as a square, convert it
    if (draggedImageSize.width === draggedImageSize.height && dropImageSize.width !== dropImageSize.height) {
      if (dropImageSize.width > dropImageSize.height) {
        draggedImageSize.height = draggedImageSize.width * (dropImageSize.height / dropImageSize.width);
      } else {
        draggedImageSize.width = draggedImageSize.height * (dropImageSize.width / dropImageSize.height);
      }
    }
    return calculateDropImageDimension({ width: draggedImageSize.width, height: draggedImageSize.height }, isCustomerEditableImageZone);
  }

  function calculateDropImageDimension(draggedImageDimension: {width: number, height: number}, isCustomerEditableImageZone: boolean): {width: number, height: number} {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const page = editorCanvasStore.getCurrentPage();
    const result = {width: draggedImageDimension.width, height: draggedImageDimension.height};
    if (page && fabricCanvas) {
      const zoom = fabricCanvas.getZoom();
      if (isCustomerEditableImageZone) {
        // customer editable image has no aspect ratio
        const scaleX = page.width * zoom / draggedImageDimension.width;
        const scaleY = page.height * zoom / draggedImageDimension.height;
        result.width = draggedImageDimension.width * scaleX;
        result.height = draggedImageDimension.height * scaleY;
      } else {
        const minSize = Math.min(page.width * zoom / draggedImageDimension.width, page.height * zoom / draggedImageDimension.height);
        result.width = draggedImageDimension.width * minSize;
        result.height = draggedImageDimension.height * minSize;
      }
    }
    return result;
  }

  function applyImageEditsToPhotoShape(
    shape: IEditorShape,
    photo: IEditorImage
  ): void {
    const effects = shape.effects;
    if (photo.edits) {
      // re-set initial rotation
      const rotation: number =
        photo.edits.rotation !== undefined ? photo.edits.rotation : 0;
      effects.initialRotation = rotation;
      shape.photoObject.angle = rotation;

      // auto correct
      effects.autoCorrect = !!photo.edits.autocorrect;
    }
    // re-place the photo
    shape.photoObject.placePhoto();
  }

  function isCustomerEditableImageZoneSelected(): boolean {
    const selectedObject = editorCanvasStore.getSelectedObject() as IEditorShape|null;
    return selectedObject !== null && selectedObject.photoObject && !selectedObject.photoObject.image && selectedObject.customerEditable;
  }

  function bringToFront(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      fabricCanvas?.bringToFront(activeObject);
      setObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function sendToBack(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      fabricCanvas?.sendToBack(activeObject);
      setObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function bringForward(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      fabricCanvas?.bringForward(activeObject);
      setObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function sendBackwards(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      fabricCanvas?.sendBackwards(activeObject);
      setObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function setObjectOrders() {
    editorCanvasStore.updateEditorLayerCanvasObjects();
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      editorCanvasStore.setCurrentSelectedObjectIndex(activeObject);
    }
  }

  return {
    selectedImageZoom,
    selectedImageRotation,
    isCustomerEditableImageZoneSelected,
    zoomStep,
    currentImagePanelView,
    photoUsagesCount,
    dropPhotoData,
    closeImageEditPanel,
    handleImageEditsChange,
    setSelectedObjectZoom,
    setSelectedObjectRotation,
    rotate90CCW,
    rotate90CW,
    flip,
    fitImageToPrintableArea,
    fillPrintableArea,
    selectedImageWidth,
    selectedImageHeight,
    selectedImageX,
    selectedImageY,
    changeImageWidth,
    changeImageHeight,
    changeImageX,
    changeImageY,
    handlePhotoDrop,
    setCurrentImagePanelView,
    setDropPhotoData,
    bringToFront,
    sendToBack,
    bringForward,
    sendBackwards,
  };
});
