import {
  CatalogType,
  ICatalogItem,
  ISuperSkuAttribute,
  ISuperSkuProduct,
  ISuperSkuProductAttribute
} from '@/interfaces/catalogInterfaces';
import {IAttributeList, IProductInfo, PresentationType} from '@/interfaces/baseInterface';

export function getCatalogItemName(catalogItem: ICatalogItem, language: string): string {
  return catalogItem.translations[language].title;
}

export function getProductName(product: IProductInfo, language: string): string {
  return product.translations
    ? product.translations[language].display_name
    : '';
}

export function getProductDescription(product: IProductInfo, language: string): string {
  return product.translations
    ? product.translations[language].description
    : '';
}

export function getProductLongDescription(product: IProductInfo, language: string): string {
  return product.translations
    ? product.translations[language].long_description
    : '';
}

export function getBaseIds(catalogItem: ICatalogItem): number[] {
  if (isProduct(catalogItem)) {
    return [catalogItem.id];
  } else if (isSuperSku(catalogItem)) {
    const superSkuProducts = getSuperSkuProducts(catalogItem);
    if (superSkuProducts) {
      return superSkuProducts.map((superSkuProduct: ISuperSkuProduct) => superSkuProduct.id);
    }
  }
  return [];
}

export function getSuperSkuProducts(catalogItem: ICatalogItem): ISuperSkuProduct[] {
  const superSkuProducts: ISuperSkuProduct[] = [];
  if (isSuperSku(catalogItem)) {
    catalogItem.attributes?.forEach((superSkuAttribute: ISuperSkuAttribute) => {
      superSkuAttribute.values.forEach((value: ISuperSkuProductAttribute) => {
        superSkuProducts.push(...value.products);
      });
    });
  }
  return superSkuProducts;
}

export function getSuperSkuAttributes(catalogItem: ICatalogItem): ISuperSkuAttribute[] {
  if (isSuperSku(catalogItem)) {
    return catalogItem.attributes || [];
  }
  return [];
}

export function getSuperSkuAttributeDisplayName(superSkuAttribute: ISuperSkuAttribute, language: string): string {
  return superSkuAttribute.translations
    ? superSkuAttribute.translations[language]
    : '';
}

export function getSuperSkuProductAttributeDisplayValue(superSkuProductAttribute: ISuperSkuProductAttribute, language: string): string {
  return superSkuProductAttribute.translations
    ? superSkuProductAttribute.translations[language]
    : '';
}

export function isCategory(catalogItem: ICatalogItem) : boolean {
  return catalogItem.type === CatalogType.Category;
}

export function isAnyProduct(catalogItem: ICatalogItem) : boolean {
  return isProduct(catalogItem) || isSuperSku(catalogItem);
}

export function isProduct(catalogItem: ICatalogItem | null): boolean {
  if(catalogItem) {
    return catalogItem.type === CatalogType.Product;
  }
  return false;
}

export function isSuperSku(catalogItem: ICatalogItem | null): boolean {
  if(catalogItem) {
    return catalogItem.type === CatalogType.SuperSku;
  }
  return false;
}

// we should be able to make attributes hidden from product explorer but that's not doable right now.
// We don't want to show service level attribute in product explorer in print suite product catalog or editor...
export function isHiddenAttribute(attribute: IAttributeList): boolean {
  return attribute.presentation_type === PresentationType.Hidden;
}


