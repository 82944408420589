import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ICatalogItem, ISuperSkuAttribute } from '@/interfaces/catalogInterfaces';
import { IAttributeValue, IProductInfo, ISkuData } from '@/interfaces/baseInterface';
import * as catalogHelpers from '@/helpers/catalogHelpers';
import { useCatalogStore } from '@/stores/catalogStore';
import {useUIStateStore} from '@/stores/UIStateStore';
import {
  IOption,
  IShopProduct, IShopProductAttribute, IShopProductVariantsAndOptions,
  IVariant, ShopProductStatus
} from '@/interfaces/shopProductInterface';
import { getBaseIds, getCatalogItemName, isAnyProduct, isSuperSku } from '@/helpers/catalogHelpers';
import router from '@/router';
import { useEditorProductStore } from '@/stores/editorProductStore';
import {
  findAttribute,
  findSkuData,
  getAttributesDisplayName,
  getVisibleOptionNames
} from '@/helpers/productInfoHelpers';
import { AppBarBreadCrumbState } from '@/interfaces/uiElementInterfaces';
import { ROUTE_DASHBOARD, ROUTE_PRODUCT_CATALOG } from '@/constants/routeConstants';
import { IShopSummary } from '@/interfaces/tenantInterface';
import { useShopProductStore } from '@/stores/shopProductStore';
import {
  decoupleShopProductProjects,
  extractShopProductGuidFromVariant,
  generateVariantProductSku,
  getSkuIdFromVariant, getVariantByDefaultSku,
  getVariantFromSkuId, isShopProductImportedToShop,
  isSuperSkuShopProduct, isTemplateOrDraftShopProduct, setShopProductMainPreview,
  shouldSkipVariant,
  updateShopProductPreviewImages
} from '@/helpers/shopProductHelper';
import { useTenantStore } from '@/stores/tenantStore';
import { getBrowserLanguage } from '@/helpers/languageHelpers';
import { uuid } from '@/helpers/uuidHelpers';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { deepToRaw } from '@/helpers/vueHelpers';
import { IProjectInfo } from '@/interfaces/projectInterface';
import { isProjectPersonalizable } from '@/helpers/projectHelpers';
import { IItePreviews } from '@/interfaces/iItePreviews';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
import { useUndoRedoStore } from '@/stores/undoRedoStore';

export const useSessionStore = defineStore('session', () => {
  const LocalStorageCurrentCatalogItemKey = 'currentCatalogItem';

  const _currentCatalogItem = ref<ICatalogItem | null>(retrieveLocalStorageCatalogItem());
  const _selectedSuperSkuIndex = ref(0);
  const _activeProductSkuIdList = ref<number[]>([]); // currently selected list of skus in editor
  const _currentActiveProductSkuIdList = ref<number[]>([]); // currently active sku list from which the canvas is rendered. We can have multiple active skus in editor but
  // only subset of those (which is selected by the user) can be used to render the canvas (e.g. red-xs red-large blue-xs can be active but only red-xs and red-large are the ones that are used for rendering the current canvas)
  const _shop = ref<IShopSummary | null>(null);
  const _shopProduct = ref<IShopProduct | null>(null);
  const _draftOrTemplateName = ref('');
  const _currentAppBarBreadCrumbState = ref<string>(AppBarBreadCrumbState.Design);
  const _currentSavedTemplateOrDraftShopProduct = ref<IShopProduct | null>(null);
  const _currentSavedTemplateOrDraftProjects = ref<Map<string, IProjectInfo[]> | null>(null);
  const _originalPublishedShopProduct = ref<IShopProduct|null>(null);

  let itePreviews: IItePreviews | undefined;
  const itePreviewReady = new Promise<void>((resolve,reject) => {
    ServiceLocator.getService<IItePreviews>(ServiceType.ItePreviews).then(ite => {
      itePreviews = ite;
      resolve();
    }, err=> {
      reject(err);
    });
  });


  const editorProductStore = useEditorProductStore();
  const catalogStore = useCatalogStore();
  const shopProductStore = useShopProductStore();

  // This is used later when we can support multiple languages in front end. It'll be the language that front end uses when showing the content to the user
  const currentLanguage = computed((): string => {
    const tenantStore = useTenantStore();
    const tenantInfo = tenantStore.tenantInfo();
    if (tenantInfo && tenantInfo.user_preferences && tenantInfo.user_preferences.lang) {
      return tenantInfo.user_preferences.lang;
    }
    return getBrowserLanguage();
  });

  const currentCatalogItem = computed((): ICatalogItem | null => {
    return _currentCatalogItem.value;
  });

  const currentShop = computed((): IShopSummary | null => {
    return _shop.value;
  });

  const currentActiveProductSkuIdList = computed((): number[] => {
    return _currentActiveProductSkuIdList.value;
  });

  const currentBase = computed((): IProductInfo | null => {
    if (_currentCatalogItem.value === null && currentShopProduct.value === null && shopProductStore.currentShopProductBases.length === 0) {
      return null;
    }
    // if we have a current shop product then we should use the bases from there, otherwise we should use the bases from the catalog item
    else if (currentShopProduct.value && shopProductStore.currentShopProductBases.length > 0) {
      if (isSuperSkuShopProduct(currentShopProduct.value)) {
        return currentSuperSkuProductInfos.value[_selectedSuperSkuIndex.value];
      } else {
        return shopProductStore.currentShopProductBases[0];
      }
    } else if (_currentCatalogItem.value) {
      if (catalogHelpers.isProduct(_currentCatalogItem.value)) {
        return catalogStore.getProductInfo(_currentCatalogItem.value);
      }
      if (catalogHelpers.isSuperSku(_currentCatalogItem.value)) {
        return currentSuperSkuProductInfos.value[_selectedSuperSkuIndex.value] || null;
      }
    }
    return null;
  });

  // when products are created from a previously saved shop product, unselected product list (unSelectedSuperSkuProductInfoIds) is the list of original bases
  // that are not in the existing shop product, but user can potentially add them later when editing
  const currentSuperSkuProductInfos = computed((): IProductInfo[] => {
    let catalogItem: ICatalogItem | null = null;
    if (_currentCatalogItem.value) {
      if (catalogHelpers.isSuperSku(_currentCatalogItem.value)) {
        catalogItem = _currentCatalogItem.value;
      }
    } else if (shopProductStore.currentShopProductBases.length > 0 && isSuperSkuShopProduct(currentShopProduct.value) && shopProductStore.superSkuCatalogItem) {
      catalogItem = shopProductStore.superSkuCatalogItem;
    }
    if (catalogItem) {
      return catalogStore.getSuperSkuProductInfos(catalogItem).filter((productInfo) => {
        return !editorProductStore.unSelectedSuperSkuProductInfoIds.includes(productInfo.id);
      });
    }
    return [];
  });

  const currentSuperSkuAttributes = computed((): ISuperSkuAttribute[] => {
    if (_currentCatalogItem.value) {
      if (catalogHelpers.isSuperSku(_currentCatalogItem.value)) {
        return catalogHelpers.getSuperSkuAttributes(_currentCatalogItem.value);
      }
    } else if (shopProductStore.superSkuCatalogItem) {
      return catalogHelpers.getSuperSkuAttributes(shopProductStore.superSkuCatalogItem);
    }
    return [];
  });

  const activeProductSkuIdList = computed((): number[] => {
    const allSkuData: ISkuData[] = [];
    if (currentSuperSkuProductInfos.value.length > 0) {
      currentSuperSkuProductInfos.value.forEach((productInfo) => {
        allSkuData.push(...productInfo.sku_data);
      });
      const allSkuIds = allSkuData.map(skuData => skuData.product_sku_id);
      return _activeProductSkuIdList.value.filter((skuId) => {
        return allSkuIds.includes(skuId);
      });
    }
    return _activeProductSkuIdList.value;
  });

  const currentShopProductVariantsAndOptions = computed((): IShopProductVariantsAndOptions => {
    const variants: IVariant[] = [];
    const optionList: IOption[] = [];
    let bases: IProductInfo[] = [];
    if (currentShopProduct.value && shopProductStore.currentShopProductBases.length > 0) {
      if (isSuperSkuShopProduct(currentShopProduct.value)) {
        bases = currentSuperSkuProductInfos.value;
      } else {
        bases = currentBase.value ? [currentBase.value] : [];
      }
    } else if (currentCatalogItem.value) {
      if (catalogHelpers.isProduct(currentCatalogItem.value)) {
        bases = currentBase.value ? [currentBase.value] : [];
      }
      if (catalogHelpers.isSuperSku(currentCatalogItem.value)) {
        bases = currentSuperSkuProductInfos.value;
      }
    }

    const shopProductGuid = currentShopProduct.value?.guid || _currentSavedTemplateOrDraftShopProduct.value?.guid || uuid();
    activeProductSkuIdList.value.forEach((skuId) => {
      const variant: IVariant = {
        product_sku: '',
        project_guid: '',
        price: 0,
        image_previews: [],
        options: {},
        title: '',
        price_f: '',
        base_id: 0,
        personalizable: false
      };
      const project = editorProductStore.findProject(skuId);
      bases.forEach((base) => {
        const skuData = base.sku_data.find((skuData: ISkuData) => {
          return skuData.product_sku_id === skuId;
        });
        if (skuData && project) {
          const visibleSkuOptionNames = getVisibleOptionNames(skuData, base);
          const superSkuAttributeNames = [];
          if (currentSuperSkuAttributes.value.length > 0) {
            superSkuAttributeNames.push(...currentSuperSkuAttributes.value.map((superSkuAttribute) => {
              return superSkuAttribute.name;
            }));
          }
          const skuOptions: { [key: string]: string } = {};
          visibleSkuOptionNames.forEach((optionName) => {
            skuOptions[optionName] = skuData.options[optionName as keyof typeof skuData] as string;
          });
          visibleSkuOptionNames.push(...superSkuAttributeNames);
          superSkuAttributeNames.forEach((optionName) => {
            const superSkuAttribute = currentSuperSkuAttributes.value.find((superSkuAttribute) => {
              return superSkuAttribute.name === optionName;
            });
            const foundSuperSkuProductAttribute = superSkuAttribute?.values.find((superSkuProductAttribute) => {
              return superSkuProductAttribute.products.some((product) => {
                return product.id === base.id;
              });
            });
            if (foundSuperSkuProductAttribute) {
              skuOptions[optionName] = foundSuperSkuProductAttribute.name;
            }
          });
          visibleSkuOptionNames.forEach((optionName) => {
            const foundOption = optionList.find((option) => {
              return option.name === optionName;
            });
            if (foundOption) {
              const valueExist = foundOption.values.find((value) => {
                return value === skuOptions[optionName];
              });
              if (!valueExist) {
                foundOption.values.push(skuOptions[optionName]);
              }
            } else {
              optionList.push({ name: optionName, values: [skuOptions[optionName]] });
            }
          });
          if (!shouldSkipVariant(project, skuOptions)) {
            variant.options = skuOptions;
            variant.project_guid = project.guid;
            variant.title = skuData.item_code;
            variant.image_previews = [];
            variant.product_sku = generateVariantProductSku(shopProductGuid, skuData.product_sku_id);
            variant.base_id = base.id;
            variant.personalizable = isProjectPersonalizable(project);
            const skuId = getSkuIdFromVariant(variant, shopProductGuid);
            const foundExistingVariant = currentShopProduct.value ? getVariantFromSkuId(currentShopProduct.value, skuId) : null;
            // do not reset prices or shipping profiles, if shop product already has a variant for this sku with an existing price value or shipping profile
            if (foundExistingVariant) {
              if (foundExistingVariant.price && foundExistingVariant.price_f) {
                variant.price = foundExistingVariant.price;
                variant.price_f = foundExistingVariant.price_f;
              }
              if (foundExistingVariant.shipping_profile) {
                variant.shipping_profile = foundExistingVariant.shipping_profile;
              }
            } else {
              variant.price = 0;
              variant.price_f = '';
              variant.shipping_profile = skuData.shipping_profile;
            }
            variants.push(variant);
          }
        }

      });

    });

    if (currentShopProduct.value && currentShopProduct.value.variants.length > 0) {
      const matchingVariant = getVariantByDefaultSku(currentShopProduct.value);
      if (!matchingVariant) {
        // If no variant is found by the default_sku, it means that defaukt_sku is not set yet in the shop product.
        // In this case Just set it to the product_sku of the first variant...
        currentShopProduct.value.default_sku = currentShopProduct.value.variants[0].product_sku;
      }
    }
    return { variants: variants, options: optionList };
  });

  const currentAppBarBreadCrumbState = computed((): string => {
    return _currentAppBarBreadCrumbState.value;
  });

  const currentShopProduct = computed((): IShopProduct | null => {
    return _shopProduct.value;
  });

  const currentTemplateOrDraftName = computed(() => {
    return _draftOrTemplateName.value;
  });

  const currentProductName = computed((): string => {
    if (!currentCatalogItem.value && currentShopProduct.value) {
      return currentShopProduct.value.details.title;
    }
    if (currentBase.value && currentCatalogItem.value) {
      if (isSuperSku(currentCatalogItem.value)) {
        return getCatalogItemName(currentCatalogItem.value, currentLanguage.value);
      } else {
        return catalogHelpers.getProductName(currentBase.value, currentLanguage.value);
      }
    }
    return '';
  });

  const isInEditingPublishedShopProductMode = computed((): boolean => {
    return currentCatalogItem.value === null && currentShopProduct.value !== null && isShopProductImportedToShop(currentShopProduct.value.status);
  });

  function setCurrentSavedTemplateOrDraftShopProduct(shopProduct: IShopProduct): void {
    if (isTemplateOrDraftShopProduct(shopProduct.status)) {
      _currentSavedTemplateOrDraftShopProduct.value = structuredClone(deepToRaw(shopProduct));
    }
  }

  function setCurrentSavedTemplateOrDraftProjects(shopProduct: IShopProduct, projects: IProjectInfo[]): void {
    if (isTemplateOrDraftShopProduct(shopProduct.status)) {
      if (!_currentSavedTemplateOrDraftProjects.value) {
        _currentSavedTemplateOrDraftProjects.value = new Map<string, IProjectInfo[]>();
      }
      const clonedProjects = structuredClone(deepToRaw(projects));
      _currentSavedTemplateOrDraftProjects.value.set(shopProduct.guid, clonedProjects);
    }
  }

  // this function keeps a copy of the original shop product that is already published to a store
  function setOriginalShopProduct(shopProduct: IShopProduct): void {
    if (isShopProductImportedToShop(shopProduct.status)) {
      _originalPublishedShopProduct.value = structuredClone(deepToRaw(shopProduct));
    }
  }

  function setCurrentCatalogItem(catalogItem: ICatalogItem | null): void {
    _selectedSuperSkuIndex.value = 0;
    _currentCatalogItem.value = catalogItem;
    setLocalStorageCatalogItem(catalogItem);
  }

  function setCurrentSuperSkuBase(superSkuProductInfo: IProductInfo): void {
    const index = currentSuperSkuProductInfos.value.findIndex((productInfo) => {
      return productInfo.id === superSkuProductInfo.id;
    });
    if (index >= 0) {
      _selectedSuperSkuIndex.value = index;
    }
  }

  function setActiveProductSkuIdList(activeProductSkuIdList: number[]): void {
    _activeProductSkuIdList.value = activeProductSkuIdList;
  }

  function removeFromActiveProductSkuIdList(skuIdsToRemove: number[]): void {
    _activeProductSkuIdList.value = _activeProductSkuIdList.value.filter((skuId) => {
      return !skuIdsToRemove.includes(skuId);
    });
    _currentActiveProductSkuIdList.value = _currentActiveProductSkuIdList.value.filter((skuId) => {
      return !skuIdsToRemove.includes(skuId);
    });
    editorProductStore.removeFromEditorProjects(skuIdsToRemove);
  }


  function setCurrentActiveProductSkuIdList(selectedProductSkuIds: number[]): void {
    _currentActiveProductSkuIdList.value = selectedProductSkuIds;

  }

  function addActiveProductSkuId(activeProductSkuId: number): void {
    if (!_activeProductSkuIdList.value.includes(activeProductSkuId)) {
      _activeProductSkuIdList.value.push(activeProductSkuId);
    }
  }

  function setCurrentShop(shop: IShopSummary|null): void {
    _shop.value = shop;
  }

  function setCurrentAppBarBreadCrumbState(state: string): void {
    _currentAppBarBreadCrumbState.value = state;
  }

  function setCurrentShopProduct(shopProduct: IShopProduct | null): void {
    _shopProduct.value = shopProduct;
  }

  function setCurrentTemplateOrDraftName(name: string) {
    _draftOrTemplateName.value = name;
  }

  function getCurrentSavedTemplateOrDraftShopProduct(): IShopProduct | null {
    return _currentSavedTemplateOrDraftShopProduct.value;
  }

  function getCurrentSavedTemplateOrDraftProjects(shopProductGuid: string): IProjectInfo[] | null {
    if (_currentSavedTemplateOrDraftProjects.value) {
      return _currentSavedTemplateOrDraftProjects.value.get(shopProductGuid) || null;
    }
    return null;
  }

  function getOriginalPublishedShopProduct(): IShopProduct | null {
    return _originalPublishedShopProduct.value;
  }

  function clearSession(): void {
    const undoRedoStore = useUndoRedoStore();
    _activeProductSkuIdList.value = [];
    _currentActiveProductSkuIdList.value = [];
    _selectedSuperSkuIndex.value = 0;
    _currentCatalogItem.value = null;
    setLocalStorageCatalogItem(null);
    _shop.value = null;
    _shopProduct.value = null;
    _draftOrTemplateName.value = '';
    _currentAppBarBreadCrumbState.value = AppBarBreadCrumbState.Design;
    _currentSavedTemplateOrDraftProjects.value = null;
    _currentSavedTemplateOrDraftShopProduct.value = null;
    undoRedoStore.clearUndoRedoStates();
  }

  function clearCurrentStateData(): void {
    const uiStateStore = useUIStateStore();
    const editorCanvasStore = useEditorCanvasStore();
    const undoRedoStore = useUndoRedoStore();
    editorProductStore.clearEditorProductStoreValues();
    uiStateStore.setSpecificDesignSwitch(false);
    uiStateStore.setIsSavingShopProductAsDraft(false);
    setCurrentShopProduct(null);
    setActiveProductSkuIdList([]);
    setCurrentActiveProductSkuIdList([]);
    setCurrentCatalogItem(null);
    editorCanvasStore.clearEditorStoreValues();
    setCurrentAppBarBreadCrumbState(AppBarBreadCrumbState.Design);
    setCurrentTemplateOrDraftName('');
    undoRedoStore.clearUndoRedoStates();
  }

  function retrieveLocalStorageCatalogItem(): ICatalogItem | null {
    const localStorageCatalogItem = localStorage.getItem(LocalStorageCurrentCatalogItemKey);
    // the catalog item in local storage should be used only when the page is refreshed in editor. We are not requesting for bases only when we are navigating to a product catalog, which means
    // that we won't get the base data if we refresh on the product catalog page...
    if (router.currentRoute.value.name === ROUTE_PRODUCT_CATALOG.name || router.currentRoute.value.name === ROUTE_DASHBOARD.name) {
      setLocalStorageCatalogItem(null);
      return null;
    }
    if (localStorageCatalogItem) {
      try {
        const catalogItem: ICatalogItem = JSON.parse(localStorageCatalogItem);
        if (catalogItem && isAnyProduct(catalogItem)) {
          const catStore = useCatalogStore(); //this happens on refresh so store isn't up yet
          catStore.requestBasesById(getBaseIds(catalogItem));
        }
        return catalogItem;
      } catch (err) {
        return null;
      }
    }
    return null;
  }

  function setLocalStorageCatalogItem(catalogItem: ICatalogItem | null): void {
    if (catalogItem) {
      localStorage.setItem(LocalStorageCurrentCatalogItemKey, JSON.stringify(catalogItem));
    } else {
      localStorage.removeItem(LocalStorageCurrentCatalogItemKey);
    }

  }

  // Returns a shop product that has a generic/private shop ID from tenantInfo
  async function createTemplateShopProduct(shouldDecoupleProjects: boolean, needDefaultPreview: boolean = true): Promise<IShopProduct | null> {
    await itePreviewReady;
    if (!itePreviews) {
      throw new Error('ItePreviews not ready');
    }
    itePreviews.subscribe();
    const tenantStore = useTenantStore();
    const shopId = tenantStore.user.tenantInfo?.shop_id;
    editorProductStore.fillSuperSkuEmptyProjects(activeProductSkuIdList.value);
    let shopProductGuid = '';
    const clonedShopProductVariantsAndOptions = structuredClone(deepToRaw(currentShopProductVariantsAndOptions.value));

    // extract shop produt guid from currentShopProductVariantsAndOptions
    // shop product guid is generated in currentShopProductVariantsAndOptions depending on whether the shop product exists in current session or not
    for (let i = 0; i < clonedShopProductVariantsAndOptions.variants.length; i++) {
      for (let j = 0; j < activeProductSkuIdList.value.length; j++) {
        shopProductGuid = extractShopProductGuidFromVariant(clonedShopProductVariantsAndOptions.variants[i], activeProductSkuIdList.value[j]);
        if (shopProductGuid) {
          break;
        }
      }
      if (shopProductGuid) {
        break;
      }
    }

    if (shopId && currentBase.value && shopProductGuid) {
      const templateProduct: IShopProduct = {
        details: { description: '', tags: [], title: '' },
        shop_id: shopId,
        previews_status: [],
        main_preview: '',
        options: clonedShopProductVariantsAndOptions.options,
        product_type: currentBase.value?.production_type || '',
        variants: clonedShopProductVariantsAndOptions.variants,
        guid: shopProductGuid,
        super_sku_id: currentCatalogItem.value && isSuperSku(currentCatalogItem.value) ? currentCatalogItem.value.id : shopProductStore.superSkuCatalogItem?.id || 0,
        default_preview_url: '',
        status: ShopProductStatus.draft,
        translations: {},
        last_updated: '',
        default_sku: ''
      };
      if (needDefaultPreview) {
        const shopProductAttributeWithPreviews: IShopProductAttribute | null = await getTemplateShopProductAttributeWithPreview(clonedShopProductVariantsAndOptions);

        // Creating preview for just one variant should be enough to get back the default preview url from DFE.
        // The default preview url is then used to show the thumb images in templates list
        if (shopProductAttributeWithPreviews) {
          const selectedShopProductOption: { [key: string]: string } = {};
          selectedShopProductOption[shopProductAttributeWithPreviews.name] = shopProductAttributeWithPreviews.values[0].name;
          const selectedAttributeValues = Object.values(selectedShopProductOption);
          if (currentBase.value) {
            const selectedSkuIds = findSkuData(currentBase.value, selectedAttributeValues).map((skuData) => {
              return skuData.product_sku_id;
            });
            const previewsPickedBySku = itePreviews.retrievePreviewDataBySkuIds('standard', 'photo', currentBase.value, selectedSkuIds, editorProductStore.pageOrientations, true);
            const previewPickedBySku = previewsPickedBySku[0];
            if (previewPickedBySku) {
              const itePreviewImages = await shopProductStore.createShopProductOptionPreviews(templateProduct, [previewPickedBySku], selectedShopProductOption, currentBase.value, []);
              if (itePreviewImages.length > 0) {
                const foundPreviewData = itePreviews.findPreviewById(itePreviewImages[0].basePreviewId, previewsPickedBySku);
                if (foundPreviewData) {
                  updateShopProductPreviewImages(templateProduct, {
                    projectGuid: itePreviewImages[0].projectGuid,
                    previewName: itePreviewImages[0].previewName,
                    imageUrl: itePreviewImages[0].blobUrl
                  }, foundPreviewData);
                }
                setShopProductMainPreview(templateProduct, itePreviewImages[0].previewName, '');
              }
            }
          }
        }
      }
      if (shouldDecoupleProjects) {
        decoupleShopProductProjects(templateProduct);
      }
      return templateProduct;
    }
    return null;
  }

  async  function getTemplateShopProductAttributeWithPreview(clonedShopProductVariantsAndOptions: IShopProductVariantsAndOptions): Promise<IShopProductAttribute | null> {
    await itePreviewReady;
    if (!itePreviews) {
      throw new Error('ITEPreview is not defined -- Error');
    }
    for (let i = 0; i < clonedShopProductVariantsAndOptions.options.length; i++) {
      const option = clonedShopProductVariantsAndOptions.options[i];
      if (isSuperSku(currentCatalogItem.value) || itePreviews.doesAttributeHavePreviews(option.name, (currentBase.value as IProductInfo).previews)) {
        const attribute = findAttribute(currentBase.value as IProductInfo, option.name);
        if (attribute && attribute.values) {
          const values = attribute.values.filter((value: IAttributeValue) => {
            return option.values.includes(value.name);
          });
          return {
            name: option.name,
            presentation_type: attribute.presentation_type,
            values: values,
            displayName: getAttributesDisplayName(currentBase.value as IProductInfo, attribute, currentLanguage.value)
          };
        }
      }
    }
    return null;
  }

  return {
    currentCatalogItem,
    currentBase,
    currentSuperSkuProductInfos,
    currentSuperSkuAttributes,
    activeProductSkuIdList,
    currentActiveProductSkuIdList,
    currentShop,
    currentShopProduct,
    currentTemplateOrDraftName,
    currentShopProductVariantsAndOptions,
    currentAppBarBreadCrumbState,
    currentLanguage,
    isInEditingPublishedShopProductMode,
    setCurrentCatalogItem,
    setCurrentSuperSkuBase,
    setActiveProductSkuIdList,
    removeFromActiveProductSkuIdList,
    addActiveProductSkuId,
    setCurrentShop,
    setCurrentShopProduct,
    setCurrentTemplateOrDraftName,
    setCurrentActiveProductSkuIdList,
    setCurrentAppBarBreadCrumbState,
    clearSession,
    createTemplateShopProduct,
    currentProductName,
    setCurrentSavedTemplateOrDraftShopProduct,
    setCurrentSavedTemplateOrDraftProjects,
    setOriginalShopProduct,
    getCurrentSavedTemplateOrDraftShopProduct,
    getCurrentSavedTemplateOrDraftProjects,
    getOriginalPublishedShopProduct,
    clearCurrentStateData,
  };
});

