import {
  IEditorCanvas,
  IEditorEdge,
  IEditorElement,
  IEditorImage,
  IEditorImageElement,
  IEditorShape,
  IEditorText,
  IEditorTextElement,
  IEffects,
  IFreeObject,
  IImageEdits,
  IImagePosition,
  IEditorRedEye,
  IShapeOptions, IWebsiteImage, IThumbDictionary, IWebsiteThumb, IEditorPage
} from '@/interfaces/editorInterfaces';
import { fabric } from 'fabric';
import { Helpers } from '@/modules/editor/helpers';
import { ElementCollection } from '@/modules/editor/elementCollection';
import {
  filters,
  Masks,
  ThumbSize,
  WrapOptions,
} from '@/modules/editor/editorConstants';
import { Text } from '@/modules/editor/fabricShapes/text';
import {
  BackgroundElement,
  ClipartElement,
  PhotoElement,
} from '@/modules/editor/element';
import { ForegroundElement } from '@/modules/editor/element';
import {
  Background,
  Foreground,
  PhotoGroup,
} from '@/modules/editor/fabricShapes/photoGroup';
import { Group } from '@/modules/editor/fabricShapes/group';
import { GroupElement, TextElement } from '@/modules/editor/element';
import { ITextEdit } from '@/interfaces/projectInterface';
import { GraphicAsset } from '@/modules/editor/graphicAsset';
import { Photo } from '@/modules/editor/photo';
import logger from '@/logger/logger';
import { Thumb } from '@/modules/editor/image';
export class EditorTranslator {
  public extraPropertiesToInclude: string[] = ['freeObjectId'];
  public saveTextPreview = false;
  private static singleton: EditorTranslator;

  public static generateUid(len = 7): string {
    return Math.random()
      .toString(35)
      .substring(2, len + 2);
  }

  public static get Instance() {
    return this.singleton || (this.singleton = new this());
  }

  public boardSetter(
    board: IEditorCanvas,
    elementCollection: ElementCollection,
  ): void {

    // board.clear() removes overlayImage. Keep it here to add later...
    const overlayImage = board.overlayImage;

    // remove everything currently on the board
    board.clear();
    if (overlayImage) {
      board.overlayImage = overlayImage;
    }
    board.objectsToLoad = 0;

    // convert element to shapes
    let shapes: IEditorShape[] = [];
    const unEditableShapes: IEditorShape[] = [];
    let xOffset = 0;

    for (let i = 0; i < elementCollection.pages.length; i++) {
      const page = elementCollection.pages[i];

      // if the page is un-editable, we add a white rectangle to cover any content that is spanning
      if (!page.canEdit) {
        unEditableShapes.push(
          new fabric.Rect({
            fill: '#ffffff',
            left: xOffset + page.width / 2,
            top: page.height / 2,
            width: page.width,
            height: page.height,
            selectable: false,
          }) as unknown as IEditorShape
        );
      }

      for (let j = 0; j < page.elements.length; j++) {
        const shape = this.toShape(page.elements[j]);

        shape.left += xOffset;

        // if page is un-editable, force selection to false
        if (!page.canEdit) {
          shape.selectable = false;
        }

        if (page.canEdit) {
          // add to array at specific index
          // index: at page 0, its equivalent to j
          //        on other pages, it offsets as such:
          //          - on second page, first element is the second element, etc.
          //          - on third page, first element is the third element, etc.

          if (shape instanceof Background) {
            shapes.unshift(shape);
          } else {
            shapes.splice(this.calculateDepth(i, j), 0, shape);
          }
        } else {
          unEditableShapes.push(shape);
        }
      }

      // deal with any bleed data
      if (
        page.bleed &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (page.bleed as any)[WrapOptions.EDGE_STYLE] &&
        elementCollection.pages.length === 1
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        board.wrap = page.bleed as any;
      } else {
        board.wrap = null;
      }

      // increment offset
      xOffset += page.width;
    }

    // we ensure that any content from un-editable pages has the highest z-index. This way, editable
    // content will fall underneath when it spans.
    shapes = shapes.concat(unEditableShapes);

    // add shapes to board
    shapes.forEach((shape: IEditorShape): void => {
      if (shape.type == 'sf-text') {
        if (shape.isEditing) {
          (shape as IEditorText).exitEditing();
        }
      }

      board.add(shape);
    });
  }

  public getElementImagePosition(
    element: IEditorShape | IEditorElement,
    forceRecalculate?: boolean
  ): IImagePosition {
    if (element.imagePosition && !forceRecalculate) {
      return fabric.util.object.clone(element.imagePosition);
    }
    // Mobile cannot properly resolve IEditorShape -- TODO: FIX in Mobile
    return this.getImagePosition(
      element.width as number,
      element.height as number,
      (element as IEditorShape).getMarginLeft
        ? (element as IEditorShape).getMarginLeft()
        : 0,
      (element as IEditorShape).getMarginTop
        ? (element as IEditorShape).getMarginTop()
        : 0, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      (element as IEditorShape).getMarginRight
        ? (element as IEditorShape).getMarginRight()
        : 0,
      (element as IEditorShape).getMarginBottom
        ? (element as IEditorShape).getMarginBottom()
        : 0,
      element.image as IEditorImage,
      element.effects ? element.effects.rotation : 0
    );
  }

  public getImagePosition(
    width: number,
    height: number,
    marginLeft: number,
    marginTop: number,
    marginRight: number,
    marginBottom: number,
    image: IEditorImage,
    rotation: number
  ): IImagePosition {
    if (!image) {
      return this.generateImagePosition(
        width / -2,
        height / -2,
        width,
        height,
        1
      );
    }

    let frameWidth = width - marginLeft - marginRight;
    let frameHeight = height - marginTop - marginBottom;

    // if there is an image, figure out the placement
    if (rotation) {
      const box = Helpers.boundingBox(
        rotation,
        width,
        height,
        0,
        0,
        marginLeft,
        marginTop,
        marginRight,
        marginBottom
      );

      frameWidth = box[1] - box[0];
      frameHeight = box[3] - box[2];
    }

    // calculate zoom
    const zoom =
      1 / Helpers.zoomToFit(frameWidth, frameHeight, image.width, image.height);

    // calculate crop rect (only if there is a rotation
    const scaledWidth = image.width * zoom;
    const scaledHeight = image.height * zoom;

    return this.generateImagePosition(
      scaledWidth / -2,
      scaledHeight / -2,
      image.width,
      image.height,
      zoom
    );
  }

  private generateImagePosition(
    x: number,
    y: number,
    width: number,
    height: number,
    zoom: number
  ): IImagePosition {
    return { x: x, y: y, width: width, height: height, zoom: zoom };
  }

  public toShape(element: IEditorElement): IEditorShape {
    // we clone the element here
    // note that all the instanceof checks are against element, because the prototype isn't carried through

    // TODO: Create type for options
    const options: IShapeOptions = {
      uid: element.uid,
      left: element.x,
      top: element.y,
      width: element.width,
      height: element.height,
      angle: element.rotation,
      opacity: element.opacity,
      effects: element.effects ? structuredClone(element.effects) : {} as IEffects,
      locked: element.locked,
      fixed: element.fixed,
      editableSave: element.editableSave,
      isLetterBoxed: element.isLetterBoxed,
      customerEditable: element.customerEditable || false,

      // data for flash pass-through
      order: element.order,
      uniquePhotoId: element.uniquePhotoId ? element.uniquePhotoId : undefined,
    };

    this.extraPropertiesToInclude.forEach((extraPropertyToInclude: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (element[extraPropertyToInclude] !== undefined) {
        Object.defineProperty(options, extraPropertyToInclude, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: element[extraPropertyToInclude],
          writable: true,
          enumerable: true,
          configurable: true,
        });
      }
    });
    const effects = options.effects;

    if (effects) {
      if (effects.shadowAngle || effects.shadowOpacity) {
        options.shadow = EditorTranslator.buildShadow(
          effects.shadowColor || '',
          effects.shadowOpacity || 0,
          effects.shadowBlur || 0,
          effects.shadowDistance || 0,
          effects.shadowAngle || 0,
          true
        );
      } else if (effects.glowOpacity || effects.glowBlur) {
        options.shadow = EditorTranslator.buildGlow(
          effects.glowColor || '',
          effects.glowOpacity || 0,
          effects.glowBlur || 0,
          true
        );
      }
    }

    // if it's a text object, convert and return
    if (element instanceof TextElement) {
      options.styles = element.styles;
      options.preview_image = element.preview_image;
      options.preview_file = element.preview_file;
      options.edited = element.edited;
      options.editable = true;
      options.textBeforeEdit = element.textBeforeEdit;
      options.placeHolderTranslations = element.placeHolderTranslations;
      options.originalHeight = element.originalHeight;
      options.textZoneId = element.textZoneId;
      options.linkedZoneId = element.linkedZoneId;
      return new Text(
        element.text || '',
        fabric.util.object.extend(options, element.rootStyle)
      );
    }

    if (element instanceof GroupElement) {
      const innerObjects: IEditorElement[] = [];
      for (let i = 0; i < element._objects.length; i++) {
        const innerObject = element._objects[i] as IEditorElement;
        if (!(innerObject as unknown as IEditorShape).type) {
          innerObjects.push(
            this.toShape(innerObject) as unknown as IEditorElement
          );
        } else {
          innerObjects.push(innerObject);
        }
      }
      const groupShape = new Group(innerObjects, options);
      groupShape.subTargetCheck = true;
      return groupShape;
    }

    // photos and backgrounds require image position and frame position
    options.imagePosition = this.getElementImagePosition(element);
    if (element.userZoom) {
      options.userZoom = element.userZoom || 0;
    }

    if (element instanceof PhotoElement) {
      return new PhotoGroup(element.image, element.frame, options);
    }

    if (element instanceof ForegroundElement) {
      return new Foreground(element.image, options);
    }
    return new Background(element.image, options);
  }

  public cloneShape(shape: fabric.Object): IEditorShape {
    const element = this.toElement(shape as IEditorShape);

    // since we are cloning, we need a new uid
    element.uid = element.generateUid();

    return this.toShape(element);
  }

  public toElement(shape: IEditorShape): IEditorElement {
    const propertiesToInclude = ['fixed', 'order', 'customerEditable'];
    if (this.extraPropertiesToInclude.length > 0) {
      propertiesToInclude.push(...this.extraPropertiesToInclude);
    }
    const object = (shape as fabric.Object).toObject(propertiesToInclude);

    let element: IEditorElement | undefined;

    // if (shape instanceof backGroundInstance)
    if (shape instanceof Text) {
      if (!shape.uid) {
        shape.uid = EditorTranslator.generateUid();
      }
      const styles = object.styles;

      // generate a root styles object
      const rootStyles = {
        fill: object.fill,
        fontFamily: object.fontFamily,
        fontSize: object.fontSize,
        fontStyle: object.fontStyle,
        fontWeight: object.fontWeight,
        textAlign: object.textAlign,
        backgroundColor: object.backgroundColor,
        underline: object.underline,
        overline: object.overline,
        linethrough: object.linethrough,
        maxCharacters: (shape as IEditorText).maxCharacters,
        textTransform: (shape as IEditorText).textTransform,
      };

      element = new TextElement(object.text, rootStyles, styles);
      if (this.saveTextPreview) {
        (element as IEditorTextElement).preview_image =
          this.getPreviewImageFromTextAsBase64(shape);
      }
      (element as IEditorTextElement).edited = (shape as IEditorText).edited;
      element.editable = true;
      element.customerEditable = (shape as IEditorText).customerEditable || false;
      (element as IEditorTextElement).textBeforeEdit = (
        shape as IEditorText
      ).textBeforeEdit;
      (element as IEditorTextElement).placeHolderTranslations = (
        shape as IEditorText
      ).placeHolderTranslations;
      (element as IEditorTextElement).originalHeight = (
        shape as IEditorText
      ).originalHeight;
    } else if (shape instanceof PhotoGroup) {
      const image = object.photo || null;
      const imagePosition = (fabric.util.object as fabric.IUtilObject).clone(
        object.imagePosition
      );

      // noinspection SuspiciousInstanceOfGuard
      if (shape instanceof Background) {
        element = new BackgroundElement(image, imagePosition);
      } else if (shape instanceof Foreground) {
        element = new ForegroundElement(image, imagePosition);
      } else {
        element = new PhotoElement(image, imagePosition, object.frame || null);
      }
    } else if (shape.type == 'group') {
      // if it is a group
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      element = new GroupElement(object, (shape as any)._objects);
      if (shape.uid) {
        element.uid = shape.uid;
      }
      return element;
    }

    if (element) {
      // default options
      element.uid = shape.uid;
      element.x = object.left;
      element.y = object.top;
      element.width = object.width;
      element.height = object.height;
      element.editable = true;
      element.customerEditable = object.customerEditable || false;
      element.editableSave = shape.editableSave;
      element.locked = object.locked;
      element.fixed = object.fixed;
      element.isLetterBoxed = object.isLetterBoxed;

      element.rotation = object.angle;
      element.opacity = object.opacity;
      element.effects = object.effects ? structuredClone(object.effects) : {};

      // data for flash pass-through
      element.order = object.order;

      this.extraPropertiesToInclude.forEach(
        (extraPropertyToInclude: string) => {
          if (object[extraPropertyToInclude] !== undefined) {
            Object.defineProperty(element, extraPropertyToInclude, {
              value: object[extraPropertyToInclude],
              writable: true,
              enumerable: true,
              configurable: true,
            });
          }
        }
      );

      if (shape.uniquePhotoId) {
        element.uniquePhotoId = shape.uniquePhotoId;
      }
      if (shape.userZoom) {
        element.userZoom = shape.userZoom;
      }
    }
    return element as IEditorElement;
  }

  public fromElement(element: IEditorElement): IFreeObject {
    const freeObject = {
      x: element.x,
      y: element.y,
      width: element.width,
      height: element.height,
      rotation: element.rotation,
      alpha: element.opacity,
      locked: element.locked,
      fixed: element.fixed,
      isLetterBoxed: element.isLetterBoxed,
      order: element.order,
      uniquePhotoId: element.uniquePhotoId,
    } as IFreeObject;

    if (element.editable !== undefined) {
      freeObject.canEdit = element.editable;
    }

    if (element.freeObjectId !== undefined) {
      freeObject.freeObjectId = element.freeObjectId;
    }

    if (element.fillColor) {
      freeObject.fillColor = element.fillColor;
    }
    if (element.context) {
      freeObject.context = element.context;
    }

    if (element.customerEditable !== undefined) {
      freeObject.customerEditable = element.customerEditable;
    }

    const effects: IEffects = element.effects;

    // if we have a text element
    if (element instanceof TextElement) {
      const textEdits = {} as ITextEdit;
      /* We can remove tlftext from edits as we are not using it to create fabricjs text. The required styles are saved in styles property which is used by fabric to create text object.*/
      // textEdits.TLFText = SF.Util.TLF.toTLF(element);
      textEdits.rootStyle = element.rootStyle;
      // Get rid of empty objects in element.styles when saving to edits
      textEdits.styles = this.convertToDatabaseTextStyles(element.styles);
      textEdits.text = element.text;
      textEdits.preview_image = element.preview_image;
      textEdits.preview_file = element.preview_file;
      textEdits.edited = element.edited;
      textEdits.originalHeight = element.originalHeight;
      if ((element as TextElement).placeHolderTranslations) {
        textEdits.placeHolderTranslations = (
          element as TextElement
        ).placeHolderTranslations;
      }
      freeObject.textEdits = textEdits;
      // freeObject.canEdit = element.editable;
    } else {
      // otherwise we have an image
      const imageEdits = {} as IImageEdits;

      // if the image is actually set
      if (element.image) {
        // retain asset id's
        if (element.image instanceof GraphicAsset) {
          freeObject.graphicAssetId = element.image.id ?? undefined;
        } else if (element.image instanceof Photo) {
          freeObject.photoId = element.image.id;
        }

        // copy the data over
        // if it's a clipart, it belongs in the frame data
        // noinspection SuspiciousInstanceOfGuard
        if (element instanceof ClipartElement) {
          const clipArtElement = element as IEditorImageElement;
          if (clipArtElement.image) {
            freeObject.urlPrefix = clipArtElement.image.urlPrefix;
            freeObject.pathPrefix = clipArtElement.image.pathPrefix;

            this.copyImageData(
              clipArtElement.image,
              ThumbSize.FULL,
              freeObject,
              'fullFrame'
            );
            this.copyImageData(
              clipArtElement.image,
              ThumbSize.LARGE,
              freeObject,
              'largeFrame'
            );
            this.copyImageData(
              clipArtElement.image,
              ThumbSize.MEDIUM,
              freeObject,
              'mediumFrame'
            );
            this.copyImageData(
              clipArtElement.image,
              ThumbSize.SMALL,
              freeObject,
              'smallFrame'
            );
          } else {
            logger.error(
              'Missing image in clipart, element looks like: ' +
                JSON.stringify(clipArtElement)
            );
          }
        } else {
          // since it is not a clipart, we also have positioning data
          // that said, we need to counter rotate the data first
          if (
            element.effects &&
            element.effects.rotation &&
            element.imagePosition
          ) {
            Helpers.rotateImagePosition(
              element.imagePosition,
              -element.effects.rotation
            );
          }
          if ((element.image as IEditorImage).exifRotation) {
            imageEdits.exifRotation = (element.image as IEditorImage)
              .exifRotation as number;
          }
          if (element.imagePosition) {
            imageEdits.picturePosX = element.imagePosition.x;
            imageEdits.picturePosY = element.imagePosition.y;
            imageEdits.zoom = element.imagePosition.zoom * 100;
          }
          imageEdits.urlPrefix = element.image.urlPrefix;
          imageEdits.pathPrefix = element.image.pathPrefix as string;

          this.copyImageData(
            element.image,
            ThumbSize.FULL,
            imageEdits,
            'fullImage'
          );
          this.copyImageData(
            element.image,
            ThumbSize.XLARGE,
            imageEdits,
            'xlargeImage'
          );
          this.copyImageData(
            element.image,
            ThumbSize.LARGE,
            imageEdits,
            'largeImage'
          );
          this.copyImageData(
            element.image,
            ThumbSize.MEDIUM,
            imageEdits,
            'mediumImage'
          );
          this.copyImageData(
            element.image,
            ThumbSize.SMALL,
            imageEdits,
            'smallImage'
          );
        }
      }

      // if we have a photo, it has an edge and potentially different frame information
      if (element instanceof PhotoElement && element.frame) {
        // add frame details -- in the flash editor, the clipart information is in the frame..
        const frame = element.frame;
        const frameWindowLeft = (frame.marginLeft / 100.0) * element.width;
        const frameWindowTop = (frame.marginTop / 100.0) * element.height;
        const frameMarginRight = (frame.marginRight / 100.0) * element.width;
        const frameMarginBottom = (frame.marginBottom / 100.0) * element.height;

        // set frame window information
        freeObject.frameWindowX = frameWindowLeft;
        freeObject.frameWindowY = frameWindowTop;
        freeObject.frameWindowWidth =
          element.width - (frameWindowLeft + frameMarginRight);
        freeObject.frameWindowHeight =
          element.height - (frameWindowTop + frameMarginBottom);

        // set other data
        freeObject.edgeId = (frame as IEditorEdge).id as number;
        freeObject.urlPrefix = (frame as IEditorEdge).urlPrefix;
        freeObject.pathPrefix = (frame as IEditorEdge).pathPrefix;

        this.copyImageData(
          frame as IEditorEdge,
          ThumbSize.FULL,
          freeObject,
          'fullFrame'
        );
        this.copyImageData(
          frame as IEditorEdge,
          ThumbSize.LARGE,
          freeObject,
          'largeFrame'
        );
        this.copyImageData(
          frame as IEditorEdge,
          ThumbSize.MEDIUM,
          freeObject,
          'mediumFrame'
        );
        this.copyImageData(
          frame as IEditorEdge,
          ThumbSize.SMALL,
          freeObject,
          'smallFrame'
        );
      } else {
        freeObject.frameWindowX = 0;
        freeObject.frameWindowY = 0;
        freeObject.frameWindowWidth = element.width;
        freeObject.frameWindowHeight = element.height;
      }
      // if we have a group
      if (
        (element as GroupElement).type &&
        (element as GroupElement).type == 'group'
      ) {
        freeObject.type = 'group';
        const groupElement = element as GroupElement;
        const innerObjects = (element as GroupElement)._objects;
        const innerFreeObjects: IFreeObject[] = [];
        for (let i = 0; i < innerObjects.length; i++) {
          let innerShape;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!innerObjects[i].type) {
            innerShape = this.toShape(innerObjects[i]);
          } else {
            innerShape = innerObjects[i];
          }

          const innerElement = this.toElement(innerShape as IEditorShape);
          const innerFreeObject = this.fromElement(innerElement);
          innerFreeObjects.push(innerFreeObject);
        }

        for (const prop in groupElement) {
          if (
            Object.prototype.hasOwnProperty.call(groupElement, prop) &&
            !Object.prototype.hasOwnProperty.call(freeObject, prop)
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            freeObject[prop] = groupElement[prop];
          }
        }
        freeObject._objects = innerFreeObjects;
      }

      if ((element as GroupElement).type != 'group') {
        if (effects) {
          imageEdits.brightness = effects.brightness;
          imageEdits.contrast = effects.contrast;
          imageEdits.saturation = effects.saturation;
          imageEdits.luminance = effects.luminance;
          imageEdits.hue = effects.hue;
          imageEdits.red = effects.red;
          imageEdits.green = effects.green;
          imageEdits.blue = effects.blue;
          imageEdits.sharpness = effects.sharpness;
          imageEdits.flipHorizontal = effects.flipHorizontal as boolean;
          imageEdits.flipVertical = effects.flipVertical as boolean;
          imageEdits.rotation = imageEdits.exifRotation
            ? effects.rotation - imageEdits.exifRotation
            : effects.rotation || 0;
          imageEdits.initialRotation = effects.initialRotation || 0;
          imageEdits.colorMode = this.getColorMode(effects.filter);
          const masks: string[] = Object.keys(Masks);
          // deal with mask and border
          if (effects.borderSize || effects.mask) {
            freeObject.maskBorder = {
              type: effects.mask
                ? masks.indexOf(
                    masks.filter((key: string): boolean => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      return Masks[key] === effects.mask;
                    })[0]
                  )
                : 0,
              color: this.convertToDatabaseColor(effects.borderColor),
              thickness: effects.borderSize as number,
            };
          }

          // vignette
          if (effects.vignetteOpacity) {
            // if color is in rgba format convert it to hex
            if (
              effects.vignetteColor &&
              effects.vignetteColor.indexOf('rgb') >= 0
            ) {
              freeObject.vignette = {
                color: this.convertToDatabaseColor(
                  EditorTranslator.rgb2hex(effects.vignetteColor)
                ),
                alpha: EditorTranslator.getOpacityFromRGBA(
                  effects.vignetteColor
                ),
                blur: effects.vignetteBlur,
              };
            } else {
              freeObject.vignette = {
                color: this.convertToDatabaseColor(effects.vignetteColor),
                alpha: effects.vignetteOpacity / 100,
                blur: effects.vignetteBlur,
              };
            }
          }

          imageEdits.autoCorrect = effects.autoCorrect as boolean;
          imageEdits.autoRedEye = effects.autoRedEye as boolean;
          imageEdits.duotoneColor = effects.duotoneColor as string;
          this.changeRedEyeOriginToTopLeft(imageEdits, element);
        }
      }
      // markers
      freeObject.isBackground = element instanceof BackgroundElement;
      freeObject.isForeground = element instanceof ForegroundElement;
      freeObject.isClipArt = element instanceof ClipartElement;
      if ((element as GroupElement).type != 'group') {
        freeObject.imageEdits = imageEdits;
      }
    }

    // deal with effects that can be applied on any element
    if (effects) {
      // glow, shadow, reflection
      if (effects.reflectionSize) {
        freeObject.glowShadowReflection = {
          type: 3,
          distance: effects.reflectionDistance as number,
          falloff: effects.reflectionSize,
          alpha: !effects.reflectionOpacity
            ? 0
            : effects.reflectionOpacity / 100,
          blur: effects.reflectionBlur as number,
        };
      } else if (effects.shadowColor) {
        if (effects.shadowColor.indexOf('rgb') >= 0) {
          freeObject.glowShadowReflection = {
            type: 2,
            color: this.convertToDatabaseColor(
              EditorTranslator.rgb2hex(effects.shadowColor)
            ),
            alpha: EditorTranslator.getOpacityFromRGBA(effects.shadowColor),
            blur: effects.shadowBlur as number,
            distance: effects.shadowDistance as number,
            angle: effects.shadowAngle ? effects.shadowAngle - 180 : 0,
          };
        } else {
          freeObject.glowShadowReflection = {
            type: 2,
            color: this.convertToDatabaseColor(effects.shadowColor),
            alpha: effects.shadowOpacity ? effects.shadowOpacity / 100 : 0,
            blur: effects.shadowBlur as number,
            distance: effects.shadowDistance as number,
            angle: effects.shadowAngle ? effects.shadowAngle - 180 : 0,
          };
        }
      } else if (effects.glowColor) {
        if (effects.glowColor.indexOf('rgb') >= 0) {
          freeObject.glowShadowReflection = {
            type: 1,
            color: this.convertToDatabaseColor(
              EditorTranslator.rgb2hex(effects.glowColor)
            ),
            alpha: EditorTranslator.getOpacityFromRGBA(effects.glowColor),
            blur: effects.glowBlur as number,
            distance: 0,
            angle: 0,
          };
        } else {
          freeObject.glowShadowReflection = {
            type: 1,
            color: this.convertToDatabaseColor(effects.glowColor),
            alpha: effects.glowOpacity ? effects.glowOpacity / 100 : 0,
            blur: effects.glowBlur as number,
            distance: 0,
            angle: 0,
          };
        }
      }
    }

    return freeObject;
  }

  public changeRedEyeOriginToTopLeft(
    imageEdits: IImageEdits,
    element: IEditorElement
  ): void {
    const effects: IEffects = element.effects;
    if (element.effects) {
      imageEdits.editorRedEyes = effects.redEyes
        ? effects.redEyes.map((e: IEditorRedEye): IEditorRedEye => {
            if (element.image) {
              e.x += element.image.width / 2;
              e.y += element.image.height / 2;
            }
            return e;
          })
        : [];
    }
  }

  private getColorMode(filter: string | undefined): number {
    const idx = filter ? filters.indexOf(filter) : -1;
    return idx > 0 ? idx : 0;
  }

  private convertToDatabaseTextStyles(styles: never[]): never[] {
    const transformedStyles = structuredClone(styles);
    const lineKeys = Object.keys(transformedStyles);
    lineKeys.forEach((lineKey: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (transformedStyles[lineKey as any]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lineStyle = transformedStyles[lineKey as any];
        const keys = Object.keys(lineStyle);
        keys.forEach((key: string) => {
          if (lineStyle[key]) {
            const characterStyle = lineStyle[key];
            if (Object.keys(characterStyle).length == 0) {
              delete lineStyle[key];
            }
          }
        });
      }
    });

    return transformedStyles as never[];
  }

  private convertToDatabaseColor(color: string | undefined): number {
    // if color is rgba convert it to hex
    if (color && color.indexOf('rgb') >= 0) {
      // convert from rgb to hex and keep opacity value
      color = EditorTranslator.rgb2hex(color);
    }
    return color ? parseInt(color.substring(1), 16) : 0;
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  protected copyImageData(
    image: IEditorImage,
    size: string,
    object: any,
    fieldName: string
  ): void {
    let thumb = image.getThumb(size);
    if (!thumb) {
      if (size === ThumbSize.XLARGE) {
        return;
      }
      thumb = image.getThumb(ThumbSize.MEDIUM);
      if (!thumb) {
        thumb = image.getThumb(ThumbSize.FULL);
        if (!thumb) {
          // if we are missing the full size, we are in big trouble...  but try the small?
          thumb = image.getThumb(ThumbSize.SMALL);
          if (!thumb) {
            // ok.  that's enough of that.  give up.  we tried.
            return;
          }
        }
      }
    }

    object[fieldName + 'Filename'] = thumb.name;
    object[fieldName + 'Width'] = thumb.width;
    object[fieldName + 'Height'] = thumb.height;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
  public getPreviewImageFromTextAsBase64(textObject: fabric.Object): string {
    if (textObject.canvas) {
      const previewImage = this.createPreviewImageFromText(textObject);
      const result = previewImage.replace(
        /^data:image\/(png|jpe?g);base64,/,
        ''
      );
      if (result.length < 10) {
        throw new Error('Error creating text preview.');
      }
      return result;
    } else {
      return '';
    }
  }

  private createPreviewImageFromText(textObject: fabric.Object): string {
    const virtual = document.getElementById('virtual');
    if (virtual) {
      virtual.remove();
    }
    const virtualCanvas = document.createElement('canvas');
    virtualCanvas.id = 'virtual';
    virtualCanvas.style.display = 'none';
    document.body.appendChild(virtualCanvas);
    const fabricCanvas = new fabric.Canvas('virtual');
    const clonedText = (fabric.util.object as fabric.IUtilObject).clone(
      textObject
    );

    clonedText.scaleX = textObject.scaleX || 0;
    clonedText.scaleY = textObject.scaleY || 0;
    if (textObject.canvas) {
      const zoom = textObject.canvas.getZoom();
      fabricCanvas.width = textObject.canvas.getWidth() / zoom;
      fabricCanvas.height = textObject.canvas.getHeight() / zoom;
    }
    //    Next line was originally added to fix PS-59403, however if we have carriage returns between lines
    //    it results in incorrect line height calculation and texts on jrs will show up with extra spaces between lines.
    //    We should find a different solution for ps-59403
    //    clonedText._fontSizeMult = 1.35;

    fabricCanvas.add(clonedText);
    fabricCanvas.renderAll();
    const shadowOffset = { x: 0, y: 0 };
    const blurOffset = { x: 0, y: 0 };
    if (clonedText.shadow) {
      shadowOffset.x = clonedText.shadow.offsetX;
      shadowOffset.y = clonedText.shadow.offsetY;
      if (
        shadowOffset.x === 0 &&
        shadowOffset.y === 0 &&
        clonedText.shadow.blur
      ) {
        blurOffset.x = clonedText.shadow.blur * clonedText.scaleX;
        blurOffset.y = clonedText.shadow.blur * clonedText.scaleY;
      }
    }

    const extraSpaceAroundText = clonedText.getBoundingRect().height; //adding extra space as big as the bounding size height around the text box screenshot to capture the full text height
    const offsetTop = Math.abs(shadowOffset.y * clonedText.scaleY);
    const offsetLeft = Math.abs(shadowOffset.x * clonedText.scaleX);
    const boundingBox = clonedText.getBoundingRect(undefined, true); // recalculate bounding box
    const boundingBoxWidth = boundingBox.width;
    const boundingBoxHeight = boundingBox.height;
    const boundingBoxLeft = boundingBox.left;
    const boundingBoxTop = boundingBox.top;

    const dataurl = fabricCanvas.toDataURL({
      format: 'png',
      left: boundingBoxLeft - offsetLeft - blurOffset.x,
      top: boundingBoxTop - offsetTop - blurOffset.y - extraSpaceAroundText,
      width: boundingBoxWidth + 2 * offsetLeft + 2 * blurOffset.x,
      height:
        boundingBoxHeight +
        2 * offsetTop +
        2 * blurOffset.y +
        2 * extraSpaceAroundText,
    });
    fabricCanvas.dispose();
    return dataurl;
  }

  public static rgb2hex(rgbValue: string): string {
    const rgb = rgbValue.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4
      ? '#' +
          ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : '';
  }

  public static getOpacityFromRGBA(rgba: string): number {
    return +rgba.replace(/ /g, '').replace(/^.*,(.+)\)/, '$1');
  }

  public static buildRGBA(color: string, opacity: number): string {
    return (
      'rgba(' +
      this.hexToR(color) +
      ',' +
      this.hexToG(color) +
      ',' +
      this.hexToB(color) +
      ',' +
      opacity / 100 +
      ')'
    );
  }

  private static hexToR(h: string): number {
    return parseInt(this.cutHex(h).substring(0, 2), 16);
  }

  private static hexToG(h: string): number {
    return parseInt(this.cutHex(h).substring(2, 4), 16);
  }

  private static hexToB(h: string): number {
    return parseInt(this.cutHex(h).substring(4, 6), 16);
  }

  private static cutHex(h: string): string {
    return h.charAt(0) == '#' ? h.substring(1, 7) : h;
  }

  public static buildShadow(
    color: string,
    opacity: number,
    blur: number,
    distance: number,
    angle: number,
    strict?: boolean
  ): fabric.Shadow | null {
    if (strict && !angle && !blur && !distance) {
      return null;
    }
    color = color || 'rgba(0,0,0,0)';
    opacity = opacity || 0;
    if (!angle) {
      angle = 180;
    }
    angle = -(((180 - angle) * Math.PI) / 180);
    distance = distance || 0;
    if (color.indexOf('rgb') >= 0) {
      // convert from rgb to hex and keep opacity value
      const rgbaColor = color;
      color = this.rgb2hex(rgbaColor);
      opacity = this.getOpacityFromRGBA(rgbaColor) * 100;
    }

    return new fabric.Shadow({
      color: this.buildRGBA(color, opacity),
      blur: blur,
      offsetX: Math.round(Math.cos(angle) * distance),
      offsetY: Math.round(Math.sin(angle) * distance),
    });
  }

  public static buildGlow(
    color: string,
    opacity: number,
    blur: number,
    strict?: boolean
  ): fabric.Shadow | null {
    if (strict && !opacity && !blur) {
      return null;
    }

    // If colour is specified, ensure blur and opacity are specified as in applyGlow01
    if (color) {
      blur = blur || 30;
      if (opacity != 0) {
        opacity = opacity || 100;
      }
    }

    color = color || 'rgba(0,0,0,0)';
    opacity = opacity || 0;
    if (color.indexOf('rgb') >= 0) {
      // convert from rgb to hex and keep opacity value
      const rgbaColor = color;
      color = this.rgb2hex(rgbaColor);
      opacity = this.getOpacityFromRGBA(rgbaColor) * 100;
    }
    return new fabric.Shadow({
      color: this.buildRGBA(color, opacity),
      // color: color,
      blur: blur,
      offsetX: 0,
      offsetY: 0,
    });
  }

  public toImageModel(photo: IWebsiteImage, type?: string): IEditorImage {
    type = type || 'photo';

    if (type === 'photo') {
      return new Photo(parseInt(photo.id || '0', 10), this.getUrlPrefix(photo.full_url as string),
        this.generateThumbsObject(photo as IWebsiteImage), photo.image_data, photo.edits);

    }

    return new GraphicAsset(parseInt(photo.id || '0', 10), type, this.getUrlPrefix(photo.full_url as string), this.generateThumbsObject(photo as IWebsiteImage));
  }

  public calculateOffset(edits: { pages: IEditorPage[] }, pageIndex: number): number {
    let offset = 0;

    for (let i = 0; i < pageIndex; i++) {
      offset += edits.pages[i].width;
    }

    return offset;
  }

  private getUrlPrefix(path: string): string {
    return this.isDataUrl(path) ? '' : path.substring(0, path.lastIndexOf('/') + 1);
  }

  private isDataUrl(path: string):boolean {
    return path.substring(0, 4) === 'data';
  }

  private generateThumbsObject(photo: IWebsiteImage): IThumbDictionary {
    const thumbsDictionary: IThumbDictionary = {};
    const thumbs: IWebsiteThumb[] = photo.thumb_list;

    const flipped = photo.edits.rotation !== undefined ? Math.abs(photo.edits.rotation) % 180 === 90 : false;
    const fullWidth = flipped ? photo.height : photo.width;
    const fullHeight = flipped ? photo.width : photo.height;
    thumbsDictionary[ThumbSize.FULL] = new Thumb(this.getImageName(photo.full_url), fullWidth, fullHeight);

    // now, iterate through thumb list and get the rest of them
    for (let i = 0; i < thumbs.length; i++) {
      thumbsDictionary[ThumbSize.mapFromPhp(thumbs[i].thumb_type)] = new Thumb(this.getImageName(thumbs[i].url), thumbs[i].width, thumbs[i].height);
    }

    return thumbsDictionary;
  }

  private getImageName(path: string): string {
    return this.isDataUrl(path) ? path : path.substring(path.lastIndexOf('/') + 1);
  }

  private calculateDepth(pageIndex: number, elementIndex: number): number {
    return (elementIndex + 1) * pageIndex + elementIndex;
  }
}
