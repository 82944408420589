import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { PublishTopicKeys, SubscribeTopicKeys } from '@/constants/topicEnums';
import { IPreparedProduct, ITenantInfo, IUser } from '@/interfaces/tenantInterface';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { deepToRaw } from '@/helpers/vueHelpers';
import { useShopProductStore } from '@/stores/shopProductStore';
import { useShopProductProjectStore } from '@/stores/shopProductProjectStore';
import { ISolaceClient } from '@/interfaces/iSolaceClient';
import { IKeycloakClient, VoidCallbackFunc } from '@/interfaces/iKeycloakClient';

export const useTenantStore = defineStore('tenant', () => {
  const user = ref<IUser>({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    emailVerified: false
  });

  let solaceClient: ISolaceClient | undefined;
  const solaceReady: Promise<void> = new Promise((resolve, reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, err => {
      reject(err);
    });
  });

  let keycloakClient: IKeycloakClient | undefined;
  const keycloakReady: Promise<void> = new Promise((resolve, reject) => {
    ServiceLocator.getService<IKeycloakClient>(ServiceType.KeyCloak).then(keycloak => {
      keycloakClient = keycloak;
      resolve();
    }, err => {
      reject(err);
    });
  });


  let sessionShopRegisterId = '';


  const isLoggedIn = computed<boolean>((): boolean => {
    return !!(keycloakClient && keycloakClient.isLoggedIn());
  });

  const userInitials = computed<string>(() => {
    return (
      (user.value.firstName ? user.value.firstName.substring(0, 1) : '') +
      (user.value.lastName ? user.value.lastName.substring(0, 1) : '')
    );
  });

  const aiImageGenerationEnabled = computed<boolean>(() => {
    // For some reason the ai_image_generation_enabled flag is returning true even though the API key is not set - hardcode to false until this can be fixed.
    return false; //  user.value.tenantInfo?.flags.ai_image_generation_enabled || false;
  });

  function initialize(callback: VoidCallbackFunc): void {
    keycloakReady.then(() => {
      if (!keycloakClient) {
        throw new Error('Keycloak client is not defined -- Error');
      }
      keycloakClient.initKeycloak(callback);
    }, err => {
      console.error('Error getting keycloak', err);
    });
  }

  function doLogIn(redirectUri: null | string = null): void {
    keycloakReady.then(() => {
      if (!keycloakClient) {
        throw new Error('Keycloak client is not defined -- Error');
      }
      void keycloakClient.doLogIn(sessionShopRegisterId, redirectUri);
    }, err => {
      console.error('Error getting keycloak', err);
    });
  }

  function doSignUp(): void {
    keycloakReady.then(() => {
      if (!keycloakClient) {
        throw new Error('Keycloak client is not defined -- Error');
      }
      void keycloakClient.doSignUp();
    }, err => {
      console.error('Error getting keycloak', err);
    });
  }

  function doLogOut(): void {
    keycloakReady.then(() => {
      if (!keycloakClient) {
        throw new Error('Keycloak client is not defined -- Error');
      }
      void keycloakClient.doLogOut();
    }, err => {
      console.error('Error getting keycloak', err);
    });
  }

  function setSessionShopGuid(shopRegisterId: string): void {
    sessionShopRegisterId = shopRegisterId;
  }

  function hasSessionShopGuid(): boolean {
    return sessionShopRegisterId !== '';
  }

  function setUserBasicInfoFromKeycloakToken(): void {
    keycloakReady.then(() => {
      if (!keycloakClient) {
        throw new Error('Keycloak client is not defined -- Error');
      }
      const token = keycloakClient.getParsedToken();
      if (token) {
        user.value.firstName = token.given_name;
        user.value.lastName = token.family_name;
        user.value.username = token.preferred_username;
        user.value.email = token.email;
        user.value.emailVerified = token.email_verified;
      }
    }, err => {
      console.error('Error getting keycloak', err);
    });
  }

  function setTenantContactInfoFromKeycloakToken() {
    if (user.value.tenantInfo?.contact_info
      && (user.value.email !== user.value.tenantInfo.contact_info.email
        || user.value.firstName !== user.value.tenantInfo.contact_info.first_name
        || user.value.lastName !== user.value.tenantInfo.contact_info.last_name)) {
      user.value.tenantInfo.contact_info.first_name = user.value.firstName;
      user.value.tenantInfo.contact_info.last_name = user.value.lastName;
      user.value.tenantInfo.contact_info.email = user.value.email;
      publishClientTenantUpdate(true, false);
    }
  }

  function setUserPreference(key: string, value: string): void {
    if (user.value.tenantInfo) {
      user.value.tenantInfo.user_preferences = user.value.tenantInfo.user_preferences || {};
      user.value.tenantInfo.user_preferences[key] = value;
      publishClientTenantUpdate(false, true);
    }
  }

  function setUserPreferences(preferences: { [key: string]: string }): void {
    if (user.value.tenantInfo) {
      user.value.tenantInfo.user_preferences = user.value.tenantInfo.user_preferences || {};
      for (const key in preferences) {
        user.value.tenantInfo.user_preferences[key] = preferences[key];
      }
      publishClientTenantUpdate(false, true);
    }
  }

  function getUserPreference(key: string): string | undefined {
    return user.value.tenantInfo?.user_preferences?.[key];
  }

  function setCurrentTenant(): void {
    subscribeToTenantCurrent();
    getCacheForTenantResults().then(() => {
      console.debug('Successfully retrieved tenant info from cache');
    }).catch(error => {
      console.debug(error);
      publishClientTenantTrigger();
    });
  }

  function getCacheForTenantResults(): Promise<void> {
    return new Promise((resolve, reject) => {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }
        const tenantCurrentSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_CURRENT);
        solaceClient.checkCache(tenantCurrentSub).then(() => {
          resolve();
        }, err => {
          reject(err);
        });
      }, err => {
        reject(err);
      });
    });
  }

  function subscribeToGenericShopProducts(genericShopId: string): void {
    const shopProductStore = useShopProductStore();
    const shopProductProjectStore = useShopProductProjectStore();
    shopProductStore.subscribeToShopProductCurrent(genericShopId);
    shopProductProjectStore.subscribeToShopProductProjectCurrent(genericShopId);
  }

  function subscribeToTenantCurrent(): void {
    solaceReady.then(() => {
      const shopProductStore = useShopProductStore();
      const shopProductProjectStore = useShopProductProjectStore();
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const tenantCurrentSubscription = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_CURRENT);
      if (tenantCurrentSubscription) {
        solaceClient.mapSubscriptionJson<ITenantInfo>(tenantCurrentSubscription, (payload) => {
            user.value.tenantInfo = payload;

            setUserBasicInfoFromKeycloakToken();
            console.log(
              'Tenant result: user.value',
              deepToRaw<ITenantInfo>(user.value.tenantInfo)
            );
            setTenantContactInfoFromKeycloakToken();
            // subscribing to private/generic shop products... shops array on the payload doesn't contain private/generic shop
            subscribeToGenericShopProducts(payload.shop_id);
            // subscribing to shop products...
            payload.shops?.forEach(shop => {
              shopProductStore.subscribeToShopProductCurrent(shop.id);
              shopProductProjectStore.subscribeToShopProductProjectCurrent(shop.id);
            });
            if (sessionShopRegisterId) {
              if (user.value.tenantInfo) {
                const shopsWithRegisterId = user.value.tenantInfo.shops?.filter(shop => shop.register_id === sessionShopRegisterId);
                if (shopsWithRegisterId) {
                  if (shopsWithRegisterId.length === 0) {
                    publishShopRegister(sessionShopRegisterId);
                    console.log('published register event for new shop');
                  } else {
                    console.log('shop is already registered');
                  }
                }
              }
            }
          }
        );
      }
    }, err => {
      console.error('Solace ready error', err);
    });
  }

  // todo:: add Shop Register event - send keycloakId and ShopGuid
  function publishShopRegister(shopGuid: string): void {
    Promise.all([keycloakReady, solaceReady]).then(() => {
      if (!keycloakClient || !solaceClient) {
        throw new Error('Keycloak or Solace client is not defined -- Error');
      }
      const token = keycloakClient.getParsedToken();
      if (token && token.sub) {
        const shopRegisterInfo = {
          keycloakId: token.sub,
          registerId: shopGuid,
          platformType: 'shopify'
        };

        const registerShopData = JSON.stringify(shopRegisterInfo);

        const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_REGISTER);
        if (reqTopic) {
          solaceClient.publishMessageString(reqTopic, registerShopData, err => {
            console.error('Error publishing shop register', err);
          });
        } else {
          console.warn(
            `Could not find topic for publish shop register: ${PublishTopicKeys.PUB_TENANT_SHOP_REGISTER}`
          );
        }
      } else {
        console.error('No token or subject found');
      }
    }, err => {
      console.error('Error getting keycloak or solace', err);
    });
  }

  function publishClientTenantTrigger(): void {
    Promise.all([keycloakReady, solaceReady]).then(() => {
      if (!keycloakClient || !solaceClient) {
        throw new Error('Keycloak or Solace client is not defined -- Error');
      }
      const token = keycloakClient.getParsedToken();
      if (token && token.sub) {
        const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_TRIGGER);
        if (reqTopic) {
          solaceClient.publishMessageString(reqTopic, undefined, err => {
            // I'm getting a lot of unimplemented method errors from dfe.
            // I'm changing this to warn for now, until we can well define dfe subscription topics
            console.warn('Error publishing tenant trigger', err);
          });
        } else {
          console.warn(
            `Could not find topic for publish tenant: ${PublishTopicKeys.PUB_TENANT_TRIGGER}`
          );
        }
      } else {
        console.error('No token or subject found');
      }
    }, err => {
      console.error('Error getting keycloak or solace', err);
    });
  }

  function publishClientTenantUpdate(updateContactInfo: boolean, updateUserPreference: boolean): void {
    Promise.all([keycloakReady, solaceReady]).then(() => {
      if (!keycloakClient || !solaceClient) {
        throw new Error('Keycloak or Solace client is not defined -- Error');
      }
      const token = keycloakClient.getParsedToken();
      if (token && token.sub && user.value.tenantInfo) {
        const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_UPDATE);
        if (reqTopic) {
          const data: {
            contact_info?: { [key: string]: string },
            user_preferences?: { [key: string]: string }
          } = {};
          if (updateContactInfo) {
            data.contact_info = user.value.tenantInfo?.contact_info;
          }
          if (updateUserPreference) {
            data.user_preferences = user.value.tenantInfo?.user_preferences;
          }
          solaceClient.publishMessageString(reqTopic, JSON.stringify(data), err => {
            console.error('Error publishing tenant update', err);
          });
        } else {
          console.warn(
            `Could not find topic for publish tenant: ${PublishTopicKeys.PUB_TENANT_UPDATE}`
          );
        }
      } else {
        console.error('No token or subject found');
      }
    }, err => {
      console.error('Error getting keycloak or solace', err);
    });
  }

  function publishTenantPreparedProductsUpdate(preparedProducts: IPreparedProduct[]): void {
    Promise.all([keycloakReady, solaceReady]).then(() => {
      if (!keycloakClient || !solaceClient) {
        throw new Error('Keycloak or Solace client is not defined -- Error');
      }
      const token = keycloakClient.getParsedToken();
      if (token && token.sub && user.value.tenantInfo) {
        const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_UPDATE);
        if (reqTopic) {
          solaceClient.publishMessageString(reqTopic, JSON.stringify({ prepared_products: preparedProducts }));
        } else {
          console.warn(
            `Could not find topic for publish tenant: ${PublishTopicKeys.PUB_TENANT_UPDATE}`
          );
        }
      } else {
        console.error('No token or subject found');
      }
    }, err => {
      console.error('Error getting keycloak or solace', err);
    });
  }

  function tenantInfo(): ITenantInfo | undefined {
    return user.value.tenantInfo;
  }

  function updateTenantContactInfo(name: string, address: string, city: string, countryIso: string, provinceAbbr: string, zipCode: string): void {
    if (user.value.tenantInfo) {
      user.value.tenantInfo.contact_info.name = name;
      user.value.tenantInfo.contact_info.address = address;
      user.value.tenantInfo.contact_info.city = city;
      user.value.tenantInfo.contact_info.country_iso = countryIso;
      user.value.tenantInfo.contact_info.province_abbr = provinceAbbr;
      user.value.tenantInfo.contact_info.postal = zipCode;
      publishClientTenantUpdate(true, false);
    }
  }



  return {
    user,
    tenantInfo,
    aiImageGenerationEnabled,
    userInitials,
    isLoggedIn,
    setSessionShopGuid,
    doLogIn,
    doLogOut,
    doSignUp,
    setUserBasicInfoFromKeycloakToken,
    setCurrentTenant,
    hasSessionShopGuid,
    updateTenantContactInfo,
    initialize,
    setUserPreference,
    setUserPreferences,
    getUserPreference,
    publishTenantPreparedProductsUpdate
  };
});
