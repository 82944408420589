/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAllFilters } from 'fabric/fabric-impl';
import { fabric } from 'fabric';

interface IEditorAllFileters extends IAllFilters {
  ColorFilter?: any;
  UnsharpMask?: any;
  BlurFilter?: any;
  SepiaWithConversionAmount?: any;
}
export const FabricImageFilters: IEditorAllFileters = fabric.Image.filters;

FabricImageFilters.ColorFilter = fabric.util.createClass(
  fabric.Image.filters.BaseFilter,
  {
    type: 'ColorFilter',
    color: '#F95C63',
    alpha: 1,
    fragmentSource:
      'gl_FragColor.r = max(0.0, min(1.0, gl_FragColor.r - (gl_FragColor.r * uAlpha - uColor.r)));\n' +
      'gl_FragColor.g = max(0.0, min(1.0, gl_FragColor.g - (gl_FragColor.g * uAlpha - uColor.g)));\n' +
      'gl_FragColor.b = max(0.0, min(1.0, gl_FragColor.b - (gl_FragColor.b * uAlpha - uColor.b)));\n',

    // webGlPrecision exists on fabric but the type definition is missing
    buildSource: function (): string {
      return (
        'precision' +
        ' ' +
        (fabric as any).webGlPrecision +
        ' ' +
        'float;\n' +
        'uniform sampler2D uTexture;\n' +
        'uniform vec4 uColor;\n' +
        'uniform float uAlpha;\n' +
        'varying vec2 vTexCoord;\n' +
        'void main() {\n' +
        'vec4 color = texture2D(uTexture, vTexCoord);\n' +
        'gl_FragColor = color;\n' +
        'if (color.a > 0.0) {\n' +
        this.fragmentSource +
        '}\n' +
        '}'
      );
    },

    retrieveShader: function (options: any): any {
      const cacheKey = this.type;
      if (
        !Object.prototype.hasOwnProperty.call(options.programCache, cacheKey)
      ) {
        const shaderSource = this.buildSource();
        options.programCache[cacheKey] = this.createProgram(
          options.context,
          shaderSource
        );
      }
      return options.programCache[cacheKey];
    },

    applyTo2d: function (options: any): void {
      const imageData = options.imageData;
      const data = imageData.data;
      const iLen = data.length;
      const source = new fabric.Color(this.color).getSource();
      source[0] = source[0] / 255;
      source[1] = source[1] / 255;
      source[2] = source[2] / 255;
      for (let i = 0; i < iLen; i += 4) {
        data[i] =
          Math.max(
            0,
            Math.min(
              1,
              data[i] / 255 - (data[i] / 255 - source[0]) * this.alpha
            )
          ) * 255;
        data[i + 1] =
          Math.max(
            0,
            Math.min(
              1,
              data[i + 1] / 255 - (data[i + 1] / 255 - source[1]) * this.alpha
            )
          ) * 255;
        data[i + 2] =
          Math.max(
            0,
            Math.min(
              1,
              data[i + 2] / 255 - (data[i + 2] / 255 - source[2]) * this.alpha
            )
          ) * 255;
      }
    },

    getUniformLocations: function (
      gl: WebGLRenderingContext,
      program: WebGLProgram
    ): {
      uColor: WebGLUniformLocation | null;
      uAlpha: WebGLUniformLocation | null;
    } {
      return {
        uColor: gl.getUniformLocation(program, 'uColor'),
        uAlpha: gl.getUniformLocation(program, 'uAlpha'),
      };
    },

    sendUniformData: function (
      gl: WebGLRenderingContext,
      uniformLocations: {
        uColor: WebGLUniformLocation;
        uAlpha: WebGLUniformLocation;
      }
    ): void {
      const source = new fabric.Color(this.color).getSource();
      source[0] = (this.alpha * source[0]) / 255;
      source[1] = (this.alpha * source[1]) / 255;
      source[2] = (this.alpha * source[2]) / 255;
      source[3] = this.alpha;
      gl.uniform4fv(uniformLocations.uColor, source);
      gl.uniform1f(uniformLocations.uAlpha, this.alpha);
    },

    toObject: function (): { type: string; color: string; alpha: number } {
      return {
        type: this.type,
        color: this.color,
        alpha: this.alpha,
      };
    },
  }
);

FabricImageFilters.UnsharpMask = fabric.util.createClass(
  fabric.Image.filters.BaseFilter,
  {
    type: 'UnsharpMask',
    strength: 0,
    radius: 0,
    fragmentSource:
      '\n            ' +
      'uniform sampler2D uBlurredTexture;\n            ' +
      'uniform sampler2D uTexture;\n            ' +
      'uniform float uStrength;\n         ' +
      'varying vec2 vTexCoord;\n\n            ' +
      'void main() {\n                ' +
      'vec4 blurred = texture2D(uBlurredTexture, vTexCoord);\n                ' +
      'vec4 original = texture2D(uTexture, vTexCoord);\n                ' +
      'gl_FragColor = mix(blurred, original, 1.0 + uStrength);\n            }\n        ',

    buildSource: function (): string {
      return (
        'precision' +
        ' ' +
        (fabric as any).webGlPrecision +
        ' ' +
        'float;\n' +
        this.fragmentSource
      );
    },

    retrieveShader: function (options: any): any {
      const cacheKey = this.type;
      if (
        !Object.prototype.hasOwnProperty.call(options.programCache, cacheKey)
      ) {
        const shaderSource = this.buildSource();
        options.programCache[cacheKey] = this.createProgram(
          options.context,
          shaderSource
        );
      }
      return options.programCache[cacheKey];
    },

    applyTo2d: function (options: {
      imageData: {
        data: number[];
      };
    }): void {
      const original = new Uint8ClampedArray(options.imageData.data);
      const blur = new FabricImageFilters.BlurFilter({
        blur: Math.abs(this.radius),
      });
      blur.applyTo2d(options);
      const blurredImageData = options.imageData;
      const pixels = blurredImageData.data;
      const strength = this.strength + 1;
      for (let i = 0; i < pixels.length; i += 4) {
        pixels[i] = this.mix(pixels[i], original[i], strength);
        pixels[i + 1] = this.mix(pixels[i + 1], original[i + 1], strength);
        pixels[i + 2] = this.mix(pixels[i + 2], original[i + 2], strength);
        pixels[i + 3] = this.mix(pixels[i + 3], original[i + 3], strength);
      }

      options.imageData = blurredImageData;
    },

    mix: function (x: number, y: number, a: number): number {
      return x * (1 - a) + y * a;
    },

    applyTo: function (options: any): void {
      if (options.webgl) {
        options.passes = 2;
        const blur = new FabricImageFilters.BlurFilter({
          blur: Math.abs(this.radius),
        });
        blur.applyTo(options);
        this.bindAdditionalTexture(
          options.context,
          options.sourceTexture,
          options.context.TEXTURE1
        );
        options.pass = 0;
        options.passes = 1;
        this._setupFrameBuffer(options);
        this.applyToWebGL(options);
        this.unbindAdditionalTexture(options.context, options.context.TEXTURE1);
        this._swapTextures(options);
      } else {
        this.applyTo2d(options);
      }
    },
    getUniformLocations: function (
      gl: WebGLRenderingContext,
      program: WebGLUniformLocation
    ): {
      uStrength: WebGLUniformLocation | null;
      uBlurredTexture: WebGLUniformLocation | null;
    } {
      return {
        uStrength: gl.getUniformLocation(program, 'uStrength'),
        uBlurredTexture: gl.getUniformLocation(program, 'uBlurredTexture'),
      };
    },

    sendUniformData: function (
      gl: WebGLRenderingContext,
      uniformLocations: {
        uStrength: WebGLUniformLocation;
        uBlurredTexture: WebGLUniformLocation;
      }
    ): void {
      gl.uniform1f(uniformLocations.uStrength, this.strength);
      gl.uniform1i(uniformLocations.uBlurredTexture, 1);
    },

    toObject: function (): { type: string; strength: number; radius: number } {
      return {
        type: this.type,
        strength: this.strength,
        radius: this.radius,
      };
    },
  }
);

class BlurStack {
  public r = 0;
  public g = 0;
  public b = 0;
  public a = 0;
  public next: BlurStack | null = null;
}

FabricImageFilters.BlurFilter = fabric.util.createClass(
  fabric.Image.filters.Blur,
  {
    type: 'BlurFilter',
    mul_table: [
      512, 512, 456, 512, 328, 456, 335, 512, 405, 328, 271, 456, 388, 335, 292,
      512, 454, 405, 364, 328, 298, 271, 496, 456, 420, 388, 360, 335, 312, 292,
      273, 512, 482, 454, 428, 405, 383, 364, 345, 328, 312, 298, 284, 271, 259,
      496, 475, 456, 437, 420, 404, 388, 374, 360, 347, 335, 323, 312, 302, 292,
      282, 273, 265, 512, 497, 482, 468, 454, 441, 428, 417, 405, 394, 383, 373,
      364, 354, 345, 337, 328, 320, 312, 305, 298, 291, 284, 278, 271, 265, 259,
      507, 496, 485, 475, 465, 456, 446, 437, 428, 420, 412, 404, 396, 388, 381,
      374, 367, 360, 354, 347, 341, 335, 329, 323, 318, 312, 307, 302, 297, 292,
      287, 282, 278, 273, 269, 265, 261, 512, 505, 497, 489, 482, 475, 468, 461,
      454, 447, 441, 435, 428, 422, 417, 411, 405, 399, 394, 389, 383, 378, 373,
      368, 364, 359, 354, 350, 345, 341, 337, 332, 328, 324, 320, 316, 312, 309,
      305, 301, 298, 294, 291, 287, 284, 281, 278, 274, 271, 268, 265, 262, 259,
      257, 507, 501, 496, 491, 485, 480, 475, 470, 465, 460, 456, 451, 446, 442,
      437, 433, 428, 424, 420, 416, 412, 408, 404, 400, 396, 392, 388, 385, 381,
      377, 374, 370, 367, 363, 360, 357, 354, 350, 347, 344, 341, 338, 335, 332,
      329, 326, 323, 320, 318, 315, 312, 310, 307, 304, 302, 299, 297, 294, 292,
      289, 287, 285, 282, 280, 278, 275, 273, 271, 269, 267, 265, 263, 261, 259,
    ],
    shg_table: [
      9, 11, 12, 13, 13, 14, 14, 15, 15, 15, 15, 16, 16, 16, 16, 17, 17, 17, 17,
      17, 17, 17, 18, 18, 18, 18, 18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 19,
      19, 19, 19, 19, 19, 19, 19, 19, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
      20, 20, 20, 20, 20, 20, 20, 20, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21,
      21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 22,
      22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22,
      22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22,
      23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
      23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
      23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
      24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24,
    ],

    fragmentSource:
      '\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform vec2 uDelta;\n' +
      'varying vec2 vTexCoord;\n' +
      'const float nSamples = 30.0;\n' +
      'vec3 v3offset = vec3(12.9898, 78.233, 151.7182);\n' +
      'float random(vec3 scale) {\n' +
      /* use the fragment position for a different seed per-pixel */
      'return fract(sin(dot(gl_FragCoord.xyz, scale)) * 43758.5453);\n' +
      '}\n' +
      'void main() {\n' +
      'vec4 color = vec4(0.0);\n' +
      'float total = 0.0;\n' +
      'float offset = random(v3offset);\n' +
      'for (float t = -nSamples; t <= nSamples; t++) {\n' +
      'float percent = (t + offset - 0.5) / nSamples;\n' +
      'float weight = 1.0 - abs(percent);\n' +
      'color += texture2D(uTexture, vTexCoord + uDelta * percent) * weight;\n' +
      'total += weight;\n' +
      '}\n' +
      'gl_FragColor = color / total;\n' +
      'gl_FragColor.rgb /= gl_FragColor.a + 0.00001;\n' +
      '}',

    buildSource: function (): string {
      return (
        'precision' +
        ' ' +
        (fabric as any).webGlPrecision +
        ' ' +
        'float;\n' +
        this.fragmentSource
      );
    },

    // TODO: Get rid of any, figure out what options is
    retrieveShader: function (options: any): any {
      const cacheKey = this.type;
      if (
        !Object.prototype.hasOwnProperty.call(options.programCache, cacheKey)
      ) {
        const shaderSource = this.buildSource();
        options.programCache[cacheKey] = this.createProgram(
          options.context,
          shaderSource
        );
      }
      return options.programCache[cacheKey];
    },

    // TODO: Get rid of any, figure out what options is
    applyToWebGL: function (options: any): void {
      const gl = options.context;
      const shader = this.retrieveShader(options);
      if (options.pass === 0 && options.originalTexture) {
        gl.bindTexture(gl.TEXTURE_2D, options.originalTexture);
      } else {
        gl.bindTexture(gl.TEXTURE_2D, options.sourceTexture);
      }
      gl.useProgram(shader.program);
      this.sendAttributeData(gl, shader.attributeLocations, options.aPosition);

      gl.uniform1f(shader.uniformLocations.uStepW, 1 / options.sourceWidth);
      gl.uniform1f(shader.uniformLocations.uStepH, 1 / options.sourceHeight);

      this.sendUniformData(gl, shader.uniformLocations, options);
      gl.viewport(0, 0, options.destinationWidth, options.destinationHeight);
      gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    },

    // TODO: Get rid of any, figure out what gl, uniformLocations and options is
    sendUniformData: function (
      gl: any,
      uniformLocations: any,
      options: any
    ): void {
      const delta = this.chooseRightDelta(options);
      gl.uniform2fv(uniformLocations.delta, delta);
    },

    chooseRightDelta: function (options: any): number[] {
      const delta = [0, 0];
      if (this.horizontal) {
        delta[0] = this.blur / options.sourceWidth;
      } else {
        delta[1] = this.blur / options.sourceHeight;
      }
      return delta;
    },

    applyTo2d: function (options: {
      imageData: {
        data: number[];
        width: number;
        height: number;
      };
    }): void {
      const imageData = options.imageData;
      const pixels = imageData.data;
      const radius = this.blur;
      const width = imageData.width;
      const height = imageData.height;
      let x;
      let y;
      let i;
      let p;
      let yp;
      let yi;
      let yw;
      let rSum;
      let gSum;
      let bSum;
      let aSum;
      let rOutSum;
      let gOutSum;
      let bOutSum;
      let aOutSum;
      let rInSum;
      let gInSum;
      let bInSum;
      let aInSum;
      let pr;
      let pg;
      let pb;
      let pa;
      let rbs;
      let stackEnd;
      const div = radius + radius + 1;
      const widthMinus1 = width - 1;
      const heightMinus1 = height - 1;
      const radiusPlus1 = radius + 1;
      const sumFactor = (radiusPlus1 * (radiusPlus1 + 1)) / 2;
      const stackStart = new BlurStack();
      let stack = stackStart;
      for (i = 1; i < div; i++) {
        stack = stack.next = new BlurStack();
        if (i == radiusPlus1) {
          stackEnd = stack;
        }
      }
      stack.next = stackStart;
      let stackIn:BlurStack|null = null;
      let stackOut:BlurStack|null = null;
      yw = yi = 0;
      const mulSum = this.mul_table[radius];
      const shgSum = this.shg_table[radius];
      for (y = 0; y < height; y++) {
        rInSum = gInSum = bInSum = aInSum = rSum = gSum = bSum = aSum = 0;
        rOutSum = radiusPlus1 * (pr = pixels[yi]);
        gOutSum = radiusPlus1 * (pg = pixels[yi + 1]);
        bOutSum = radiusPlus1 * (pb = pixels[yi + 2]);
        aOutSum = radiusPlus1 * (pa = pixels[yi + 3]);
        rSum += sumFactor * pr;
        gSum += sumFactor * pg;
        bSum += sumFactor * pb;
        aSum += sumFactor * pa;
        stack = stackStart;
        for (i = 0; i < radiusPlus1; i++) {
          stack.r = pr;
          stack.g = pg;
          stack.b = pb;
          stack.a = pa;
          stack = stack.next as BlurStack;
        }
        for (i = 1; i < radiusPlus1; i++) {
          p = yi + ((widthMinus1 < i ? widthMinus1 : i) << 2);
          rSum += (stack.r = pr = pixels[p]) * (rbs = radiusPlus1 - i);
          gSum += (stack.g = pg = pixels[p + 1]) * rbs;
          bSum += (stack.b = pb = pixels[p + 2]) * rbs;
          aSum += (stack.a = pa = pixels[p + 3]) * rbs;
          rInSum += pr;
          gInSum += pg;
          bInSum += pb;
          aInSum += pa;
          stack = stack.next as BlurStack;
        }
        stackIn = stackStart as BlurStack;
        stackOut = stackEnd as BlurStack;
        for (x = 0; x < width; x++) {
          pixels[yi + 3] = pa = (aSum * mulSum) >> shgSum;
          if (pa != 0) {
            pa = 255 / pa;
            pixels[yi] = ((rSum * mulSum) >> shgSum) * pa;
            pixels[yi + 1] = ((gSum * mulSum) >> shgSum) * pa;
            pixels[yi + 2] = ((bSum * mulSum) >> shgSum) * pa;
          } else {
            pixels[yi] = pixels[yi + 1] = pixels[yi + 2] = 0;
          }
          rSum -= rOutSum;
          gSum -= gOutSum;
          bSum -= bOutSum;
          aSum -= aOutSum;
          if (stackIn && stackOut) {
            rOutSum -= stackIn.r;
            gOutSum -= stackIn.g;
            bOutSum -= stackIn.b;
            aOutSum -= stackIn.a;

            p =
              (yw + ((p = x + radius + 1) < widthMinus1 ? p : widthMinus1)) <<
              2;
            rInSum += stackIn.r = pixels[p];
            gInSum += stackIn.g = pixels[p + 1];
            bInSum += stackIn.b = pixels[p + 2];
            aInSum += stackIn.a = pixels[p + 3];
            rSum += rInSum;
            gSum += gInSum;
            bSum += bInSum;
            aSum += aInSum;
            stackIn = stackIn.next;
            rOutSum += pr = stackOut.r;
            gOutSum += pg = stackOut.g;
            bOutSum += pb = stackOut.b;
            aOutSum += pa = stackOut.a;
            rInSum -= pr;
            gInSum -= pg;
            bInSum -= pb;
            aInSum -= pa;
            stackOut = stackOut.next;
            yi += 4;
          }
        }
        yw += width;
      }
      for (x = 0; x < width; x++) {
        gInSum = bInSum = aInSum = rInSum = gSum = bSum = aSum = rSum = 0;
        yi = x << 2;
        rOutSum = radiusPlus1 * (pr = pixels[yi]);
        gOutSum = radiusPlus1 * (pg = pixels[yi + 1]);
        bOutSum = radiusPlus1 * (pb = pixels[yi + 2]);
        aOutSum = radiusPlus1 * (pa = pixels[yi + 3]);
        rSum += sumFactor * pr;
        gSum += sumFactor * pg;
        bSum += sumFactor * pb;
        aSum += sumFactor * pa;
        stack = stackStart;
        for (i = 0; i < radiusPlus1; i++) {
          stack.r = pr;
          stack.g = pg;
          stack.b = pb;
          stack.a = pa;
          stack = stack.next as BlurStack;
        }
        yp = width;
        for (i = 1; i <= radius; i++) {
          yi = (yp + x) << 2;
          rSum += (stack.r = pr = pixels[yi]) * (rbs = radiusPlus1 - i);
          gSum += (stack.g = pg = pixels[yi + 1]) * rbs;
          bSum += (stack.b = pb = pixels[yi + 2]) * rbs;
          aSum += (stack.a = pa = pixels[yi + 3]) * rbs;
          rInSum += pr;
          gInSum += pg;
          bInSum += pb;
          aInSum += pa;
          stack = stack.next as BlurStack;
          if (i < heightMinus1) {
            yp += width;
          }
        }
        yi = x;
        stackIn = stackStart;
        stackOut = stackEnd as BlurStack;
        for (y = 0; y < height; y++) {
          p = yi << 2;
          pixels[p + 3] = pa = (aSum * mulSum) >> shgSum;
          if (pa > 0) {
            pa = 255 / pa;
            pixels[p] = ((rSum * mulSum) >> shgSum) * pa;
            pixels[p + 1] = ((gSum * mulSum) >> shgSum) * pa;
            pixels[p + 2] = ((bSum * mulSum) >> shgSum) * pa;
          } else {
            pixels[p] = pixels[p + 1] = pixels[p + 2] = 0;
          }
          rSum -= rOutSum;
          gSum -= gOutSum;
          bSum -= bOutSum;
          aSum -= aOutSum;
          if (stackIn && stackOut) {
            rOutSum -= stackIn.r;
            gOutSum -= stackIn.g;
            bOutSum -= stackIn.b;
            aOutSum -= stackIn.a;
            p =
              (x +
                ((p = y + radiusPlus1) < heightMinus1 ? p : heightMinus1) *
                  width) <<
              2;
            rSum += rInSum += stackIn.r = pixels[p];
            gSum += gInSum += stackIn.g = pixels[p + 1];
            bSum += bInSum += stackIn.b = pixels[p + 2];
            aSum += aInSum += stackIn.a = pixels[p + 3];
            stackIn = stackIn.next;
            rOutSum += pr = stackOut.r;
            gOutSum += pg = stackOut.g;
            bOutSum += pb = stackOut.b;
            aOutSum += pa = stackOut.a;
            rInSum -= pr;
            gInSum -= pg;
            bInSum -= pb;
            aInSum -= pa;
            stackOut = stackOut.next;
            yi += width;
          }
        }
      }
    },
  }
);

FabricImageFilters.SepiaWithConversionAmount = fabric.util.createClass(
  fabric.Image.filters.ColorMatrix,
  {
    type: 'SepiaWithConversionAmount',
    amount: 0,
    mainParameter: 'amount',

    calculateMatrix: function (): void {
      this.matrix = [
        1.0 - 0.607 * this.amount,
        0.769 * this.amount,
        0.189 * this.amount,
        0,
        0,
        0.349 * this.amount,
        1.0 - 0.314 * this.amount,
        0.168 * this.amount,
        0,
        0,
        0.272 * this.amount,
        0.534 * this.amount,
        1.0 - 0.869 * this.amount,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
      ];
    },

    isNeutralState: function (options: any): any {
      this.calculateMatrix();
      return fabric.Image.filters.BaseFilter.prototype.isNeutralState.call(
        this,
        options
      );
    },

    applyTo: function (options: any): any {
      this.calculateMatrix();
      fabric.Image.filters.BaseFilter.prototype.applyTo.call(this, options);
    },
  }
);
