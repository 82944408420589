export interface IConfig {
  keycloak: {
    realm: string;
    'auth-server-url': string;
    resource: string;
  };
  'redirect': {
    'shopify': string;
  };
  'services': {
    'signup': string;
    'klaviyo': {
      'url': string;
      'key':  string;
    };
    'sfa': {
      'url': string;
      'id': string;
    }
  };
}

let config: IConfig | null = null;

export async function loadConfig(): Promise<IConfig> {
  const response = await fetch('/config.json');
  if (!response.ok) {
    throw new Error('Failed to load configuration');
  }
  config = await response.json();
  return config as IConfig;
}

export function getConfig(): IConfig {
  if (!config) {
    throw new Error('Configuration not loaded yet. Call loadConfig first.');
  }
  return config;
}


