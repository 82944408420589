import { IEditorCanvas, IEditorShape } from '@/interfaces/editorInterfaces';
import { fabric } from 'fabric';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
const distance = 50;
let canvas: IEditorCanvas|null = null;
let clipboardObject: fabric.Object | null = null;
let isMouseOnCanvas = false;
export class KeyboardInteraction {
  public static initKeyboardInteraction(fabricCanvas: IEditorCanvas) {
    canvas = fabricCanvas;
    document.addEventListener('keydown', KeyboardInteraction.handleKeyDown);
    if (canvas) {
      canvas.on('mouse:over', KeyboardInteraction.handleMouseOverCanvas);
      canvas.on('mouse:out', KeyboardInteraction.handleMouseLeaveCanvas);
    }
  }

  public static removeEventListeners() {
    document.removeEventListener('keydown', KeyboardInteraction.handleKeyDown);
    if (canvas) {
      canvas.off('mouse:over', KeyboardInteraction.handleMouseOverCanvas);
      canvas.off('mouse:out', KeyboardInteraction.handleMouseLeaveCanvas);
    }
  }

  private static handleMouseOverCanvas() {
    isMouseOnCanvas = true;
  }

  private static handleMouseLeaveCanvas() {
    isMouseOnCanvas = false;
  }

  private static handleKeyDown(event: KeyboardEvent) {
    if (isMouseOnCanvas) {
      if (KeyboardInteraction.isCtrlPressed(event) && event.key === 'c') {
        KeyboardInteraction.copyObject();
      }

      if (KeyboardInteraction.isCtrlPressed(event) && event.key === 'x') {
        KeyboardInteraction.cutObject();
      }

      if (KeyboardInteraction.isCtrlPressed(event) && event.key === 'v') {
        KeyboardInteraction.pasteObject();
      }

      if (KeyboardInteraction.isCtrlPressed(event) && event.key === 'z') {
        KeyboardInteraction.undo();
      }

      if (event.key === 'Delete') {
        KeyboardInteraction.deleteObject();
      }
    }
  }

  private static copyObject() {
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        clipboardObject = EditorTranslator.Instance.cloneShape(activeObject);
      }
    }
  }

  private static isCtrlPressed(event: KeyboardEvent) {
    return event.ctrlKey || event.metaKey;
  }

  private static undo() {
    const canvasStore = useEditorCanvasStore();
    canvasStore.undo();
  }

  private static cutObject() {
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        KeyboardInteraction.copyObject();
        KeyboardInteraction.deleteObject();
      }
    }
  }

  private static pasteObject() {
    if (clipboardObject && canvas) {
      // make a new clone to be able to paste multiple times
      const clonedObject = EditorTranslator.Instance.cloneShape(clipboardObject);
      const object = clonedObject as fabric.Object;
      const objectLeft = (object.left as number);
      const objectTop = (object.top as number);
      let newLeft = objectLeft + distance;
      let newTop = objectTop + distance;
      const objectWidth = object.width as number;
      const objectHeight = object.height as number;
      const canvasWidth = canvas.width as number;
      const canvasHeight = canvas.height as number;
      const containingWidth = (canvasWidth - canvas.marginLeft * 2) / canvas.getZoom();
      const containingHeight = (canvasHeight - canvas.marginTop * 2) / canvas.getZoom();
      if (newLeft + objectWidth / 2 > containingWidth) {
        newLeft = containingWidth - objectWidth / 2 - distance;
      }
      if (newTop + objectHeight / 2 > containingHeight) {
        newTop = canvasHeight - objectHeight / 2 - distance;
      }
      clonedObject.set({
        left: newLeft,
        top: newTop,
      });

      (canvas as IEditorCanvas).add(clonedObject);
      (canvas as IEditorCanvas).setActiveObject(clonedObject);
      (canvas as IEditorCanvas).requestRenderAll();
    }
  }

  private static deleteObject() {
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        if (activeObject.type === 'sf-text' && (activeObject as IEditorShape).isEditing) {
          return; // do not delete text when editing

        }
        const canvasStore = useEditorCanvasStore();
        canvasStore.deleteSelectedObject();
      }
    }
  }
}