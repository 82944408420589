import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { IEvent } from 'fabric/fabric-impl';
import { IEditorText } from '@/interfaces/editorInterfaces';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
import { fabric } from 'fabric';
import { IFontFile } from '@/interfaces/fontsInterface';
import { useFontStore } from '@/stores/fontStore';
import { DEFAULT_TEXT_COLOR } from '@/modules/editor/editorConstants';
export interface ITextsViewTextObject {
  text: string;
  objectIndexOnCanvas: number;
}
export enum EditorTextPanelView {
  fonts = 'Fonts',
  colorPicker = 'ColorPicker',
  alignment = 'Alignment',
  position = 'Position',
}
export const useEditorTextStore = defineStore('editorText', () => {
  const currentTextObjects = ref<ITextsViewTextObject[]>([]);
  const editorCanvasStore = useEditorCanvasStore();
  const swatchColor = ref(DEFAULT_TEXT_COLOR);
  const fontStore = useFontStore();
  const selectedTextZoneFont = ref<IFontFile | undefined>(undefined);
  const selectedTextZoneLineHeight = ref(0);
  const selectedTextZoneLetterSpacing = ref(0);
  const selectedTextZoneAlignment = ref('left');
  const selectedTextZoneFontWeight = ref('');
  const selectedTextZoneSize = ref(0);
  const selectedTextZoneFontStyle = ref('');
  const selectedTextZoneUnderline = ref(false);
  const selectedTextZoneLineThrough = ref(false);
  const currentTextPanelView = ref<EditorTextPanelView | undefined>(undefined);

  watch(swatchColor, () => {
    setActiveTextColor();
  });

  function resetCurrentTextObjects(): void {
    currentTextObjects.value = [];
  }

  function setCurrentTextObjects() {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    if (fabricCanvas) {
      resetCurrentTextObjects();
      fabricCanvas
        .getObjects()
        .forEach((textObject: fabric.Object, index: number) => {
          if (textObject.type === 'sf-text') {
            currentTextObjects.value.push({
              text: (textObject as IEditorText).text,
              objectIndexOnCanvas: index,
            });
          }
        });
    }
  }

  function setCurrentTextPanelView(view: EditorTextPanelView|undefined): void {
    currentTextPanelView.value = view;
  }

  function bringToFront(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      fabricCanvas?.bringToFront(activeObject);
      setTextObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function sendToBack(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      fabricCanvas?.sendToBack(activeObject);
      setTextObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function bringForward(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      fabricCanvas?.bringForward(activeObject);
      setTextObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function sendBackwards(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      fabricCanvas?.sendBackwards(activeObject);
      setTextObjectOrders();
      editorCanvasStore.saveCanvasObjectsState().catch((error) => {
        console.error('Error saving canvas objects state', error);
      });
    }
  }

  function setTextObjectOrders() {
    setCurrentTextObjects();
    editorCanvasStore.updateEditorLayerCanvasObjects();
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject) {
      editorCanvasStore.setCurrentSelectedObjectIndex(activeObject);
    }
  }

  function closeTextEditPanel(): void {
    currentTextPanelView.value = undefined;
  }

  function setTextZoneProperties(
    type:
      | 'size'
      | 'color'
      | 'align'
      | 'font'
      | 'bold'
      | 'italic'
      | 'underline'
      |'linethrough'
      |'lineHeight'
      |'charSpacing',
    value: string | number | boolean
  ): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      const textZone = activeObject as IEditorText;
      const isBold = textZone.getStyle('fontWeight') === 'bold';
      const isItalic = textZone.getStyle('fontStyle') === 'italic';

      switch (type) {
        case 'font':
          textZone.setStyle('fontFamily', value as string);
          selectedTextZoneFont.value = fontStore.fontFiles.find(
            (fontFile) =>
              fontFile.name.toLowerCase() === textZone.fontFamily.toLowerCase()
          );
          break;
        case 'bold':
          textZone.setStyle('fontWeight', isBold ? '' : 'bold');
          selectedTextZoneFontWeight.value = isBold ? '' : 'bold';
          break;
        case 'italic':
          textZone.setStyle('fontStyle', isItalic ? '' : 'italic');
          selectedTextZoneFontStyle.value = isItalic ? '' : 'italic';
          break;
        case 'underline':
          /*fabric cache should be cleared before applying underline otherwise it uses the character width in its cache which may not be correct*/
          fabric.util.clearFabricFontCache(textZone.fontFamily);
          textZone.setStyle('underline', !textZone.getStyle('underline'));
          selectedTextZoneUnderline.value = textZone.getStyle('underline') as boolean;
          break;
        case 'linethrough':
          /*fabric cache should be cleared before applying overline otherwise it uses the character width in its cache which may not be correct*/
          fabric.util.clearFabricFontCache(textZone.fontFamily);
          textZone.setStyle('linethrough', !textZone.getStyle('linethrough'));
          selectedTextZoneLineThrough.value = textZone.getStyle('linethrough') as boolean;
          break;
        case 'size':
          textZone.setStyle('fontSize', value as number);
          selectedTextZoneSize.value = value as number;
          break;
        case 'color':
          textZone.setStyle('fill', value as string);
          break;
        case 'align':
          textZone.setStyle('textAlign', value as string);
          selectedTextZoneAlignment.value = value as string;
          break;
        case 'lineHeight':
          textZone.setStyle('lineHeight', value as number);
          selectedTextZoneLineHeight.value = value as number;
          break;
        case 'charSpacing':
          textZone.setStyle('charSpacing', value as number);
          selectedTextZoneLetterSpacing.value = value as number;
          break;
        default:
          console.warn('Unknown type: ' + type);
      }

      fabricCanvas?.renderAll();
    }
  }

  function setActiveTextColor(): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    const activeObject = editorCanvasStore.getSelectedObject();
    if (activeObject && activeObject.type === 'sf-text') {
      (activeObject as IEditorText).setStyle('fill', swatchColor.value);
      fabricCanvas?.renderAll();
    }
  }

  function handleTextChange(event: IEvent): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    if (event.target && fabricCanvas) {
      const index = fabricCanvas.getObjects().indexOf(event.target);
      const currentEditingTextObjIndex = currentTextObjects.value.findIndex(
        (currentTextObj) => {
          return currentTextObj.objectIndexOnCanvas === index;
        }
      );
      if (currentEditingTextObjIndex > -1) {
        currentTextObjects.value[currentEditingTextObjIndex].text = (
          event.target as IEditorText
        ).text;
      }
    }
  }

  function handleSelectionChange(event: IEvent): void {
    const fabricCanvas = editorCanvasStore.fabricCanvas;
    if (
      event.selected &&
      fabricCanvas &&
      event.selected.length === 1 &&
      event.selected[0].type === 'sf-text'
    ) {
      const editorText = event.selected[0] as IEditorText;
      swatchColor.value = editorText.fill as string;
      selectedTextZoneFont.value = fontStore.fontFiles.find(
        (fontFile) =>
          fontFile.name.toLowerCase() === editorText.fontFamily.toLowerCase()
      );
      selectedTextZoneLineHeight.value = editorText.lineHeight;
      selectedTextZoneLetterSpacing.value = editorText.charSpacing;
      selectedTextZoneAlignment.value = editorText.textAlign;
      selectedTextZoneSize.value = editorText.fontSize;
    }
  }

  function setSelectedTextZoneSize(size: number): void {
    selectedTextZoneSize.value = size;
  }

  function resetSwatchColor(): void {
    swatchColor.value = DEFAULT_TEXT_COLOR;
  }


  return {
    currentTextObjects,
    swatchColor,
    selectedTextZoneFont,
    selectedTextZoneLineHeight,
    selectedTextZoneLetterSpacing,
    selectedTextZoneAlignment,
    selectedTextZoneSize,
    selectedTextZoneFontWeight,
    selectedTextZoneFontStyle,
    selectedTextZoneUnderline,
    selectedTextZoneLineThrough,
    currentTextPanelView,
    setTextZoneProperties,
    closeTextEditPanel,
    handleTextChange,
    handleSelectionChange,
    bringToFront,
    sendToBack,
    bringForward,
    sendBackwards,
    setCurrentTextPanelView,
    resetCurrentTextObjects,
    setSelectedTextZoneSize,
    resetSwatchColor,
    setCurrentTextObjects
  };
});
