export interface ICatalogItem {
  attributes?: ISuperSkuAttribute[];
  category_type?: string;
  id: number;
  name: string;
  parent_id: number;
  position: number;
  preview: string;
  preview_key: string;
  price_f: string;
  translations: { [key: string]: ICategoryTranslations };
  type: CatalogType;
  updated: number;
  visible: boolean;
}

export enum CatalogType {
  Category = 'category',
  Product = 'product',
  SuperSku = 'super_sku',
}

export interface ISuperSkuAttribute {
  id: number;
  name: string;
  position: number;
  presentation_type: string;
  type: AttributeType;
  values: ISuperSkuProductAttribute[];
  visible: boolean;
  translations?: { [key: string]: string };
}

export enum AttributeType {
  SuperSkuAttribute = 'super_sku_attribute',
}

export interface ISuperSkuProductAttribute {
  id: number;
  name: string;
  position: number;
  products: ISuperSkuProduct[];
  type: ValueType;
  visible: boolean;
  translations?: { [key: string]: string };
  swatch_file?: string;
}

export interface ISuperSkuProduct {
  id: number;
  name: string;
  preview: string;
  preview_key: string;
  price_f: string;
  translations?: { [key: string]: ICategoryTranslations };
  type: ProductType;
  updated: number;
}

export enum ProductType {
  SuperSkuProduct = 'super_sku_product',
}

export enum ValueType {
  SuperSkuProductAttribute = 'super_sku_product_attribute',
}

export interface ICategoryTranslations {
  description: string;
  long_description: string;
  short_description: string;
  title: string;
}
