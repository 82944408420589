import { IPoint, Transform } from 'fabric/fabric-impl';
import {
  IBleed,
  IWrap,
  IMaskBorder,
  IRootStyle,
  ITextEdit
} from '@/interfaces/projectInterface';
import { fabric } from 'fabric';
import { IAttributeValue } from '@/interfaces/baseInterface';

export interface IEditorCanvas extends fabric.Canvas {
  _currentTransform: ITransform;
  contextContainer: CanvasRenderingContext2D;
  contextTop: CanvasRenderingContext2D;
  clipTo: (ctx: CanvasRenderingContext2D) => void;
  marginTop: number;
  marginLeft: number;
  objectsToLoad: number;
  threeDpreview: boolean;
  _previousMovePointer: IPoint;
  errorConfiguration: ICanvasErrorConfig;
  wrap: IWrap | null; // TODO: Convert to a proper type with all the wrap attributes..
  wrapperEl: HTMLElement | null;
  lowerCanvas: HTMLCanvasElement;
  mask: ICanvasMask | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _fire(eventName: string, options?: any): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  __eventListeners: { [key: string]: any } | null;

  _renderOverlay(ctx: CanvasRenderingContext2D): void;

  _renderBackgroundOrOverlay(upperContext: CanvasRenderingContext2D, property: string): void;
  toCanvasElement(): HTMLCanvasElement;
  _setBackstoreDimension(field: string, value: number): void;
}

export interface ICanvasMask {
  maskImage: fabric.Image;
  marginLeft: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
}

export interface ICanvasErrorConfig {
  zoomWarning: number;
  zoomError: number;
}

export interface IEditorFont {
  styles: ('r' | 'b' | 'i' | 'bi')[];
  name: string;
  public: boolean;
  getImage: () => string;
  getCSS: () => string;
  getTTF: (style: 'r' | 'b' | 'i' | 'bi') => string;
  canItalicize: (isBold: boolean) => boolean;
  canBold: (isItalic: boolean) => boolean;
  hasStyle: (style: string) => boolean;
}

export interface IEditorPage {
  id: number | null;
  number: number;
  type: string;
  width: number;
  height: number;
  elements: IEditorElement[];
  mode: string;
  index: number;
  groupIndex: number;
  canEdit: boolean;
  canReorder: boolean;
  canDelete: boolean;
  joined: boolean;
  spanning: boolean;
  isPreviewPage: boolean;
  errors: IEditorElementError[];
  bleed?: IBleed;
  isInsideFrontCover: boolean;
  isInsideBackCover: boolean;
  preview: string | HTMLCanvasElement | null;
  duplicateId: number | null;
  pageName?: string;
}

export interface IEditorElementError {
  type: number;
  page: number;
  label: string;
  extra: {
    severity: number;
    count: number;
  };
  project_id?: number;
  change_project?: boolean;
}

export interface IShapeOptions {
  userZoom?: number;
  imagePosition?: IImagePosition;
  canvas?: fabric.Canvas;
  linkedZoneId?: number;
  textZoneId?: number;
  originalHeight?: number;
  placeHolderTranslations?: { [p: string]: string };
  textBeforeEdit?: string;
  addedByUser?: boolean;
  editable?: boolean;
  left: number;
  top: number;
  width: number;
  height: number;
  angle: number;
  opacity: number;
  locked: boolean;
  fixed: boolean;
  editableSave?: boolean;
  isLetterBoxed: boolean;
  selectable?: boolean;
  order: number;
  uniquePhotoId?: number;

  edited?: boolean;
  preview_file?: string;
  preview_image?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: any;
  effects?: IEffects;
  shadow?: fabric.Shadow | null;
  uid: string;
  customerEditable?: boolean;
}

export interface IEditorElement {
  editableSave?: boolean;
  imagePosition?: IEditorImagePosition;
  image?: IEditorImage;
  uid: string;
  freeObjectId?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  opacity: number;
  effects: IEffects;
  editable?: boolean;
  locked: boolean;
  fixed: boolean;
  isLetterBoxed: boolean;
  order: number;
  shadow?: string | fabric.Shadow;
  uniquePhotoId?: number;
  generateUid: () => string;
  userZoom?: number;
  textZoneId?: number;
  linkedZoneId?: number;
  context?: string;
  fillColor?: string; // empty fill color for empty images
  customerEditable?: boolean;
}

export interface IEditorImagePosition {
  x: number;
  y: number;
  width: number;
  height: number;
  zoom: number;
}

export interface IEditorImage extends IEditorThumb {
  id: number | null;
  name: string;
  urlPrefix: string;
  pathPrefix?: string;
  thumbs: IThumbDictionary;
  edits: { rotation?: number; autocorrect?: boolean };
  exifRotation?: number;

  getThumb(thumb: string): IEditorThumb;

  getUrl(
    thumbName: string,
    hasAutoCorrect?: boolean,
    hasAutoRedEye?: boolean
  ): string;
}

export interface IEditorThumb {
  width: number;
  height: number;
  name: string;
}

export interface IThumbDictionary {
  [key: string]: IEditorThumb;
}

export interface IEffects {
  border?: boolean;
  glowBlur?: number;
  glowOpacity?: number;
  glowColor?: string;
  shadowBlur?: number;
  shadowDistance?: number;
  shadowAngle?: number;
  shadowOpacity?: number;
  shadowColor?: string;
  reflectionBlur?: number;
  reflectionOpacity?: number;
  reflectionDistance?: number;
  reflectionSize?: number;
  duotoneColor?: string;
  autoCorrect?: boolean;
  autoRedEye?: boolean;
  vignetteBlur?: number;
  vignetteColor?: string;
  vignetteOpacity?: number;
  filter?: string;
  initialRotation: number;
  flipVertical?: boolean;
  flipHorizontal?: boolean;
  sharpness?: number;
  blue?: number;
  green?: number;
  red?: number;
  hue?: number;
  luminance?: number;
  saturation?: number;
  contrast?: number;
  brightness?: number;
  rotation: number;
  redEyes?: IEditorRedEye[];
  borderSize?: number;
  borderColor?: string;
  mask?: {
    scaleX: number;
    scaleY: number;
    left: number;
    top: number;
    stroke?: string;
    strokeWidth?: number;
  };
}

export interface IEditorRedEye extends IPoint {
  r: number;
}

export interface IEditorTextElement extends IEditorElement {
  addedByUser?: boolean;
  text: string;
  styles: {
    fontWeight: string;
    fontStyle: string;
    underline: boolean;
  }[];
  tlf: string | null;
  rootStyle: IRootStyle;
  preview_image: string;
  preview_file: string;
  edited?: boolean;
  originalHeight?: number;
  placeHolderTranslations?: { [key: string]: string };
  textBeforeEdit?: string;
}

export interface IEditorFullPageElement extends IEditorImageElement {
  imagePosition: IEditorImagePosition;
}

export interface IEditorImageElement extends IEditorElement {
  image?: IEditorImage;
}

export interface IEditorEditorPhoto extends IEditorEditorImage {
  elementAdded: boolean;
  angle: number;

  placePhoto(): void;
  fit(): boolean;

  getMinZoom(): number;

  _getFrameBox(
    width: number,
    height: number,
    frame: IEditorEditorFrame
  ): { left: number; top: number; right: number; bottom: number };

  getFrame(): IEditorEditorFrame;

  getMinimumBoundingRectangle(
    angle: number,
    left: number,
    top: number
  ): number[];
}

export interface IEditorEditorFrame extends IEditorEditorImage {
  marginLeft: number;
  marginRight: number;
  marginTop: number;
  marginBottom: number;

  getFrameWidth(): number;

  getFrameHeight(): number;
}

export interface IEditorEditorImage extends IEditorShape {
  _element: HTMLImageElement;
  _originalElement?: HTMLImageElement;
  optimalThumbLoaded: boolean;
  imagePosition: IEditorImagePosition;
  image: IEditorImage;

  getEffects(): IEffects;

  getWidth(): number;

  getHeight(): number;

  setWidth(val: number): void;

  setHeight(val: number): void;

  hasAutoCorrect?(): boolean;

  hasAutoRedEye?(): boolean;

  setReady(ready: boolean, isOptimal: boolean): void;

  removeImage(): void;

  setAutoCorrect(autoCorrect: boolean, callback: () => void): void;

  setAutoRedEye(autoRedEye: boolean, callback: () => void): void;

  _loadImage?(
    image: IEditorImage,
    useElement: boolean,
    loadThumbIfCached: boolean,
    complete: (selfImage: HTMLImageElement) => void
  ): void;
}

export interface IEditorShape extends IFabricClass {
  ready?: boolean;
  freeObjectId?: number;
  userZoom?: number;
  uniquePhotoId?: number;
  imagePosition: IEditorImagePosition;
  photoObject: IEditorEditorPhoto;
  frameObject: IEditorEditorFrame;
  isEditing: boolean;
  elementAdded?: boolean;
  skipSelectEvent?: boolean;
  pinchZoomValue?: number;
  clipTo: (ctx: CanvasRenderingContext2D) => void;
  flipVertical(): void;
  flipHorizontal(): void;

  innerRotate(angle: number): void;

  styles: {
    fontWeight: string;
    fontStyle: string;
    underline: boolean;
  }[];
  top: number;
  left: number;
  uid: string;
  effects: IEffects;
  preview_image?: string;
  preview_file?: string;
  x: number;
  y: number;
  selectable: boolean;
  type: string;
  text: string;
  textBeforeEdit: string;
  editableSave: boolean;
  customerEditable: boolean; // defines whether end user who is buying the product can edit the object or not
  locked: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toObject: () => any;

  getZoom(): number;

  getInnerRotation(): number;

  getStyle(style: string): string|boolean;

  setStyle(style: string, value: string | number | boolean): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fire(eventName: string, options?: any): fabric.Object;

  isGhosted(): boolean;

  applyEffects(): void;

  ghost(): void;

  unGhost(): void;

  canvas: IEditorCanvas;
  angle: number;

  zoom(zoom: number, bestFit?: boolean): void;

  getMarginLeft: () => number;

  getMarginRight: () => number;

  getMarginTop: () => number;

  getMarginBottom: () => number;

  image: IEditorImage;

  replaceImage: (
    image: IEditorImage,
    reset: boolean,
    callback: () => void
  ) => void;

  dispose?: () => void;

}

export interface IFabricClass extends fabric.Object {
  util?: IFabricUtil;
}

export interface IFabricUtil extends fabric.IUtil {
  cleanUpJsdomNode: (node: HTMLElement) => void;
  hasStyleChanged: (prevStyle: object, thisStyle: object, forTextSpans: boolean) => boolean;
}

export interface IImagePosition {
  x: number;
  y: number;
  width: number;
  height: number;
  zoom: number;
}

export interface IToData {
  text: string;
  rootStyle: IRootStyle;
  styles: object[];
}

export interface IEditorThumbSize {
  FULL: string;
  LARGE: string;
  XLARGE: string;
  MEDIUM: string;
  SMALL: string;
  mapFromPhp: (thumbType: string) => string;
}

export interface IEditorGraphicAsset extends IEditorImage {
  type: string;
}

export interface IFreeObject {
  _objects: IFreeObject[];
  type?: string;
  order: number;
  freeObjectId: number;
  uniquePhotoId?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  alpha: number;
  frameWindowX: number;
  frameWindowY: number;
  frameWindowWidth: number;
  frameWindowHeight: number;
  urlPrefix?: string;
  pathPrefix?: string;
  fullFrameFilename: string;
  fullFrameWidth: number;
  fullFrameHeight: number;
  xlargeFrameFilename: string;
  xlargeFrameWidth: number;
  xlargeFrameHeight: number;
  largeFrameFilename: string;
  largeFrameWidth: number;
  largeFrameHeight: number;
  mediumFrameFilename: string;
  mediumFrameWidth: number;
  mediumFrameHeight: number;
  smallFrameFilename: string;
  smallFrameWidth: number;
  smallFrameHeight: number;
  canEdit: boolean;
  locked: boolean;
  fixed: boolean;
  isLetterBoxed?: boolean;
  textEdits?: ITextEdit;
  imageEdits?: IImageEdits;
  textZoneId: number | null;
  linkedZoneId: number | null;
  glowShadowReflection?: IGlowShadowReflection;
  maskBorder?: IMaskBorder;

  photoId: number;
  graphicAssetId?: number;
  edgeId?: number;
  isClipArt: boolean;
  isBackground: boolean;
  isForeground: boolean;
  onSpine?: boolean;
  context?: string;
  fillColor?: string; // empty fill color for empty images
  skipSelectEvent?: boolean; // true if object selection should be ignored upon clicking
  customerEditable?: boolean; // true if object is editable by customer
  vignette?: {
    color?: number;
    blur?: number;
    alpha?: number;
  };
}

export interface IImageEdits {
  exifRotation: number;
  autoRedEye: boolean;
  editorRedEyes: IEditorRedEye[];
  initialRotation?: number;
  urlPrefix: string;
  pathPrefix: string;
  smallImageFilename: string;
  smallImageWidth: number;
  smallImageHeight: number;
  mediumImageFilename: string;
  mediumImageWidth: number;
  mediumImageHeight: number;
  largeImageFilename: string;
  largeImageWidth: number;
  largeImageHeight: number;
  xlargeImageFilename?: null | string;
  xlargeImageWidth?: number | null;
  xlargeImageHeight?: number | null;
  fullImageFilename: string;
  fullImageWidth: number;
  fullImageHeight: number;
  pictureBackColor: number;
  red?: number;
  green?: number;
  blue?: number;
  brightness?: number;
  contrast?: number;
  sharpness?: number;
  saturation?: number;
  luminance?: number;
  hue?: number;
  picturePosX: number;
  picturePosY: number;
  zoom: number;
  zoomStep: number;
  rotation: number;
  autoCorrect: boolean;
  letterBox: boolean;
  flipHorizontal: boolean;
  flipVertical: boolean;
  colorMode: number;
  duotoneColor: string;
}

export interface IPage {
  edits: IPageEdits;
  ice?: string;
  alignment?: number;
  page_id: number;
  page_number: number;
  preview_image: string;
}

export interface IPageEdits {
  bleed?: IBleed | IEditorBleed;
  pageName?: string;
  canEdit?: boolean;
  canReorder?: boolean;
  canDelete?: boolean;
  mode: string;
  type: string;
  width: number;
  height: number;
  keepInSafetyZone?: boolean;
  spanningPage?: boolean;
  joinedPage?: boolean;
  freeObject: IFreeObject[];
}

export interface IEditorBleed {
  bleed_side_l?: number;
  bleed_side_r?: number;
  bleed_side_t?: number;
  bleed_side_b?: number;
  bleed_back_l?: number;
  bleed_back_r?: number;
  bleed_back_t?: number;
  bleed_back_b?: number;
  coverPhotoMarginTop?: number;
  coverPhotoMarginBottom?: number;
  coverPhotoMarginLeft?: number;
  coverPhotoMarginRight?: number;
  topSideDepth?: number;
  bottomSideDepth?: number;
  leftSideDepth?: number;
  rightSideDepth?: number;
  topBackOverPrint?: number;
  bottomBackOverPrint?: number;
  leftBackOverPrint?: number;
  rightBackOverPrint?: number;
  edge_style?: null | string;
  edge_color?: null | string;
  corner_type?: null | string;
  back_presentation?: null | string;
  width: number;
  height: number;
}

export interface IEditorElementCollection {
  width: number;
  height: number;
  pages: IEditorPage[];
  visiblePages: IEditorPage[];
  elements: IEditorElement[];
}

export interface ITextOptions {
  width?: number;
  height?: number;
  effects?: {
    autoCorrect?: boolean;
  };
  edited?: boolean;
  fontSize: number;
  originalFontSize?: number;
  fontFamily: string;
  top?: number;
  left?: number;
  fontWeight?: 'bold' | '';
  fontStyle?: 'italic' | '';
  linkedZoneId?: number;
  textZoneId?: number;
  placeHolderTranslations?: { [language: string]: string };
  originalHeight?: number;
  autoFontSize?: boolean;
}

export interface IEditorFontStyle {
  fontWeight: string;
  fontStyle: string;
  fontFamily: string;
}

export interface ITextZoneProperties {
  maxWidth: number;
  lineHeight: number;
  zoneHeight: number;
  measureText: (test: string, val: number) => number;
}

export interface IEditorFrame extends IEditorShape {
  marginLeft: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;

  resetPhoto(): void;
}

export interface IEditorPhotoGroup extends IEditorShape {
  isImageTooLarge(): boolean;
  isImageWarning(): boolean;
  sliderRotation(angle: number): void;
  removeEdge(): void;

  photoObject: IEditorEditorPhoto;
  frameObject: IEditorEditorFrame;

  getInitialRotationAngle(): number;
  resetOrientation(initialRotation?: number): void;
  removePhoto(): void;
}

export interface IEditorText extends IEditorShape {
  textAlign: string;
  fontStyle: string;
  fixTextFromOptions: (value: string) => string;
  lineHeight: number;
  charSpacing: number;

  _textLines: string[];
  autoFontSize: boolean;

  fontFamily: string;
  addedByUser?: boolean;
  placeHolderTranslations: { [language: string]: string };
  textZoneId?: number;
  linkedZoneId?: number;
  underline: boolean;
  fontSize: number;
  originalFontSize?: number;

  originalWidth?: number;
  edited: boolean;
  linkEdited?: boolean;
  maxCharacters?: number;
  textTransform?: string;
  originalHeight?: number;
  editable: boolean;

  enterEditing(): void;

  exitEditing(skipEmptyToDefault?: boolean): void;

  findBackgroundColor(): { r: number; g: number; b: number };

  setText(text: string): void;

  getTextAlign(): string;

  setTextAlign(value: string): void;

  getFontWeight(): string;

  setFontWeight(value: string): void;

  getUnderline(): boolean;

  setUnderline(value: boolean): boolean;

  getFontStyle(): string;

  setFontStyle(value: string): void;

  getFill(): string | fabric.Pattern;

  setFill(value: string | fabric.Pattern): fabric.Object;

  setColor(value: string | fabric.Pattern): fabric.Object; // Argh, no getColor
  getFontSize(): number;

  setFontSize(value: number): void;

  getFontFamily(): string;

  setFontFamily(value: string): void;

  _measureLine(line: number): { width: number; numOfSpaces: number };
}

export interface IEditorEdge extends IEditorImage {
  marginBottom: number;
  marginRight: number;
  marginLeft: number;
  marginTop: number;
}

export interface IEditorOCoords {
  tl: fabric.Point;
  mt: fabric.Point;
  tr: fabric.Point;
  ml: fabric.Point;
  mr: fabric.Point;
  bl: fabric.Point;
  mb: fabric.Point;
  br: fabric.Point;
  mtr: fabric.Point;
  pan: {
    x: number;
    y: number;
    corner?: {
      tl: { x: number; y: number };
      tr: { x: number; y: number };
      bl: { x: number; y: number };
      br: { x: number; y: number };
    };
  };
  ir: {
    x: number;
    y: number;
    corner?: {
      tl: { x: number; y: number };
      tr: { x: number; y: number };
      bl: { x: number; y: number };
      br: { x: number; y: number };
    };
  };
}

export interface ITransform extends Transform {
  gestureScale: number;
  signY: number;
  signX: number;
  innerTheta: number;
  actionPerformed: boolean;
}

export interface IWebsiteImage {
  id: string;
  full_url: string;
  image_data: object;
  edits: { rotation?: number; autocorrect?: boolean };
  width: number;
  height: number;
  thumb_list: IWebsiteThumb[];
  placeHolder: boolean;
}

export interface IWebsiteThumb {
  url: string;
  width: number;
  height: number;
  thumb_type: string;
  file_path: string;
}

export interface IGlowShadowReflection {
  type: number;
  distance: number;
  angle?: number;
  color?: number;
  alpha: number;
  blur: number;
  strength?: number;
  falloff?: number;
}

export interface ISelectedProductAttribute {
  default_value: string;
  name: string;
  presentation_type: string;
  schema_type: string;
  values: IAttributeValue[];
}

export interface IActivatedForDesignProductAttribute {
  default_value: string;
  name: string;
  presentation_type: string;
  schema_type: string;
  value: IAttributeValue | undefined;
}

export enum EditorPanelView {
  productAttributes = 'Product',
  layers = 'Layers',
  texts = 'Texts',
  images = 'Images',
}

export interface ISelectedObject {
  objectIndexOnCanvas: number;
  // do I need to add anything else?
}

export const ProductTypes = {
  MultiplePageProductType: 'multiple_page'
};

export interface ICrop extends IPoint {
  width: number;
  height: number;
}

export enum Orientation {
  landscape = 'landscape',
  portrait = 'portrait',
  square = 'square'
}

export interface IPageState {
  page: IEditorPage;
  selectedObjectUid: string;
}

export interface IPageStateStack {
  pageStates: IPageState[];
  index: number;
}

export enum ShopProductCreationPhase {
  Design,
  Mockups,
  Details,
  Pricing
}

export enum EditorLayerType {
  BackLayer = 'backlayer',
  Overlay = 'mask', // Overlay option in Utilities>Previews tab is a "mask" type in data
  Mask = 'mask_bw', // Mask option in Utilities>Previews tab is a "mask_bw" type in data
}

export enum Layering {
  bringToFront = 'bringToFront',
  sendToBack = 'sendToBack',
  bringForward = 'bringForward',
  sendBackwards = 'sendBackwards',
}

