import {defineStore} from 'pinia';
import {PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels} from '@/constants/topicEnums';
import {ref} from 'vue';
import {Photo} from '@/modules/editor/photo';
import {ThumbSize} from '@/modules/editor/editorConstants';
import {IThumbDictionary} from '@/interfaces/editorInterfaces';
import {uuid} from '@/helpers/uuidHelpers';
import {getBaseDirectoryFromPath, getFileNameFromPath} from '@/helpers/pathHelpers';
import {ServiceLocator, ServiceType} from '@/services/serviceLocator';
import { TopicWildCard } from '@/constants/topicConstants';
import { ISolaceClient } from '@/interfaces/iSolaceClient';
import { SolaceClientConstants } from '@/constants/solaceClientContstants';

interface IPhotoData {
  id: number;
  height: number;
  width: number;
  url: string;
  caption: string;
  filename: string;
  guid: string;
  upload_date: string;
  expiry_date: string;
  thumbnails: {
    height: number;
    width: number;
    thumb_type:
      | 'CI_SIZE_XXLARGE'
      | 'CI_SIZE_XLARGE'
      | 'CI_SIZE_LARGE'
      | 'CI_SIZE_MEDIUM';
    url: string;
  }[];
  edits: {
    rotation?: number;
  };
}

export const useImageStore = defineStore('image', () => {
  const photoList = ref<Photo[]>([]);
  const uploadGuids: {
    [guid: string]: {
      resolve: (value: {photo: Photo, guid: string}) => void;
      reject: (reason: string) => void;
    };
  } = {};

  const noImageToLoad = ref(false);
  const generatedUrls = ref<string[]>([]);
  const failedToLoadPhotoIds = ref<number[]>([]);
  let subscribed = false;
  let solaceClient:ISolaceClient|undefined;
  const solaceReady:Promise<void> = new Promise((resolve, reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, (err:Error):void => {
      reject(err);
    });
  });

  function addImageToPhoto(image: IPhotoData) {
    let photo = photoList.value.find((photo) => photo.id === image.id);
    const urlPrefix = getBaseDirectoryFromPath(image.url);
    const imageData = {
      width: image.width,
      height: image.height,
    };
    const thumbs: IThumbDictionary = {};
    image.thumbnails.forEach((thumb) => {
      const type = ThumbSize.mapFromPhp(thumb.thumb_type);
      thumbs[type] = {
        width: thumb.width,
        height: thumb.height,
        name: getFileNameFromPath(thumb.url),
      };
    });
    thumbs[ThumbSize.FULL] = {
      width: image.width,
      height: image.height,
      name: getFileNameFromPath(image.url),
    };

    if (!photo) {
      photo = new Photo(image.id, urlPrefix, thumbs, imageData, image.edits);
      photoList.value.push(photo);
      const failedIndex = failedToLoadPhotoIds.value.indexOf(image.id);
      if (failedIndex > -1) {
        failedToLoadPhotoIds.value.splice(failedIndex, 1);
      }
    } else {
      photo.urlPrefix = urlPrefix;
      photo.thumbs = thumbs;
      photo.imageData = imageData;
      photo.edits = image.edits;
    }
    if (uploadGuids[image.guid]) {
      uploadGuids[image.guid].resolve({photo: photo, guid: image.guid});
    }
  }

  function deleteImage(photo: Photo): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const deleteImageTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_IMAGE_DELETE);
      if (deleteImageTopic) {
        console.log('#current photos: ' + photoList.value.length);

        const changedImageDeleteTopic = deleteImageTopic.replace(
          TopicVarLevels.IMAGE_ID,
          photo.id.toString()
        );
        solaceClient.publishMessageString(changedImageDeleteTopic, undefined, err => {
          console.error('Error publishing delete image', err);
        });
      } else {
        console.warn(
          `Could not find topic for image delete: ${PublishTopicKeys.PUB_IMAGE_DELETE}`
        );
      }
    }, (err:Error):void => {
      console.error('Solace ready error', err);
    });
  }

  function uploadImage(imageData: Uint8Array, fileName: string): Promise<{photo: Photo, guid: string}> {
    const guid = uuid();
    const promise = new Promise<{photo: Photo, guid: string}>((resolve, reject) => {
      uploadGuids[guid] = { resolve, reject }; // Don't think reject will ever be used, but...
    });

    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const uploadImageTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_IMAGE_ADD);
      if (uploadImageTopic) {
        solaceClient.publishMessageBinary(
          uploadImageTopic,
          imageData,
          undefined,
          SolaceClientConstants.PERSISTENT_MSG,
          undefined,
          {
            image_guid: guid,
            file_name: fileName,
          }
        );
      } else {
        console.warn(
          `Could not find topic for image upload: ${PublishTopicKeys.PUB_IMAGE_ADD}`
        );
      }
    }, (err:Error):void => {
      console.error('Solace ready error', err);
    });

    return promise;
  }

  function subscribe(): void {
    if (!subscribed) {
      subscribeToImages();
      subscribeToImage();
      subscribeToGeneratedImage();
      subscribed = true;
    }
  }

  // If cache is available, use it, otherwise request images directly
  function requestPhotos(): void {
    getCachedImages().then(() => {
      console.debug('Successfully retrieved images from cache');
    }).catch(error => {
      console.debug(error);
      queryImages();
    });
  }

  function subscribeToImages(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const imagesSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_IMAGES_CURRENT);
      if (imagesSub) {
        solaceClient.mapSubscriptionJson<IPhotoData[]>(imagesSub, payload => {
          console.log('#received photos: ' + payload.length);
          noImageToLoad.value = payload.length === 0;
          payload.forEach((image: IPhotoData) => {
            addImageToPhoto(image);
          });
        });
      }
    }, (err:Error):void => {
      console.error('Solace ready error', err);
    });
  }

  function subscribeToGeneratedImage():void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const imagesSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_IMAGES_AI_GENERATED);
      if (imagesSub) {
        solaceClient.mapSubscriptionJson<string[]>(imagesSub, payload => {
          console.log('#received urls ' + payload.length);
          generatedUrls.value = payload;
        });
      } else {
        console.warn(
          `Could not find topic for subscribe generated images: ${SubscribeTopicKeys.SUB_IMAGES_AI_GENERATED}`
        );
      }
    }, (err:Error):void => {
      console.error('Solace ready error', err);
    });
  }

  function subscribeToImage(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const imageSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_IMAGE_CURRENT);
      if (imageSub) {
        const replacedSub = imageSub.replace(TopicVarLevels.IMAGE_ID, TopicWildCard);
        solaceClient.mapSubscriptionJson<IPhotoData>(replacedSub, payload => {
          console.log('#received photo: ' + payload);
          addImageToPhoto(payload);
        });
      }
    }, (err:Error):void => {
      console.error('Solace ready error', err);
    });
  }

  function getCachedImages(): Promise<void> {
    return new Promise((resolve, reject) => {
      solaceReady.then(() => {
        if (!solaceClient) {
          throw new Error('Solace client is not defined -- Error');
        }
        const imagesSub = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_IMAGES_CURRENT);
        solaceClient.checkCache(imagesSub).then(() => {
          resolve();
        }, err=> {
          reject(err);
        });
      }).catch(err => {
        console.error('Solace ready error', err);
      });
    });
  }

  function queryImages(): void {
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_IMAGES_TRIGGER);
      if (reqTopic) {
        solaceClient.publishMessageString(reqTopic, undefined, err => {
          console.error('Error publishing query images', err);
        });
      } else {
        console.warn(
          `Could not find topic for query images: ${PublishTopicKeys.PUB_IMAGES_TRIGGER}`
        );
      }
    }).catch(err => {
      console.error('Solace ready error', err);
    });
  }

  function addToFailedToLoad(photoId: number): void {
    if (!failedToLoadPhotoIds.value.includes(photoId)) {
      failedToLoadPhotoIds.value.push(photoId);
    }
  }

  function isFailedToLoad(photoId: number): boolean {
    return failedToLoadPhotoIds.value.includes(photoId);
  }

  // this function should be called to remove the guid promises that are no longer needed (resolved)
  function cleanupGuidPromise(guid: string): void {
    if (uploadGuids[guid]) {
      delete uploadGuids[guid];
    }
  }

  function imageGenerate(prompt:string):void {
    if (!solaceClient) {
      throw new Error('Solace client is not defined -- Error');
    }
    const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_IMAGES_AI_TRIGGER);
    if (reqTopic) {
      generatedUrls.value = [];
      solaceClient.publishMessageString(reqTopic, JSON.stringify({ prompt }), err => {
        console.error('Error publishing image generate', err);
      });
    } else {
      console.warn(
        `Could not find topic for publish tenant: ${PublishTopicKeys.PUB_IMAGES_AI_TRIGGER}`
      );
    }
  }

  function clearGeneratedImages():void {
    generatedUrls.value = [];
  }

  function clearPhotoList():void {
    photoList.value = [];
  }


  return {
    photoList,
    generatedUrls,
    noImageToLoad,
    failedToLoadPhotoIds,
    subscribe,
    uploadImage,
    deleteImage,
    addToFailedToLoad,
    isFailedToLoad,
    cleanupGuidPromise,
    imageGenerate,
    clearGeneratedImages,
    requestPhotos,
    clearPhotoList
  };
});
