import { IEditorImage, IThumbDictionary } from '@/interfaces/editorInterfaces';
import { Image } from '@/modules/editor/image';

export class Photo extends Image implements IEditorImage {
  public override pathPrefix = '';

  constructor(
    public override id: number,
    urlPrefix: string,
    thumbs: IThumbDictionary,
    public imageData: {
      data?: number;
      width?: number;
      height?: number;
    } | null = null,
    public override  edits: {
      rotation?: number;
      autocorrect?: boolean;
    } = {}
  ) {
    super(id, urlPrefix, thumbs);
  }
}
