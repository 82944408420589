import { createLogger, LogEvent, LoggerHook } from 'vue-logger-plugin';
import {PublishTopicKeys} from '@/constants/topicEnums';
import {ServiceLocator, ServiceType} from '@/services/serviceLocator';
import { ISolaceClient } from '@/interfaces/iSolaceClient';

const SolacePubHook: LoggerHook = {
  run(event: LogEvent) {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then( solaceClient => {
      const logTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_LOG);
      if (logTopic) {
        solaceClient.publishLogMessage(logTopic, event.level, event.argumentArray.toString());
      } else {
        // Note: DO NOT PUBLISH TO LOGGER, this will cause an infinite loop
        console.error(`Unable to publish solace log message as topic ${PublishTopicKeys.PUB_LOG} was not found`);
      }
    }, err=> {
      console.error('Unable to publish solace log message as solace client was not found', err);
    });
  },
};

// create logger with options
const logger = createLogger({
  enabled: true,
  consoleEnabled: true,
  level: 'debug',
  // beforeHooks: [],
  afterHooks: [SolacePubHook],
});

// Tests run from node, so window is not defined
if (typeof window !== 'undefined') {
  window.onerror = (message, url, lineNo, columnNo, error) => {
    logger.error(`${message} url: ${url} line: ${lineNo}:${columnNo}`, error);
  };

  window.addEventListener('unhandledrejection', function(event) {
    logger.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
  });
}

export default logger;
