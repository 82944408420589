import { IBleed, IMaskBorder } from '@/interfaces/projectInterface';
import {
  IEditorBleed,
  IEditorEdge,
  IEditorElement,
  IEditorFullPageElement,
  IEditorGraphicAsset,
  IEditorImage,
  IEditorPage,
  IEditorRedEye,
  IEditorTextElement,
  IEffects,
  IFreeObject,
  IGlowShadowReflection,
  IImageEdits,
  IPage,
  IThumbDictionary,
  Orientation
} from '@/interfaces/editorInterfaces';

import {
  BackgroundElement,
  ForegroundElement,
  GroupElement,
  PhotoElement,
  TextElement
} from '@/modules/editor/element';
import { TLF } from '@/modules/editor/tlf';
import { Image, Thumb } from '@/modules/editor/image';
import { EdgeStyles, filters, Masks, ProductAttribute, ThumbSize, WrapOptions } from '@/modules/editor/editorConstants';
import { Photo } from '@/modules/editor/photo';
import { GraphicAsset } from '@/modules/editor/graphicAsset';
import { Edge } from '@/modules/editor/edge';
import { Helpers } from '@/modules/editor/helpers';
import { EditorTranslator } from '@/modules/editor/editorTranslator';

export class EditorPages {
  public pages: IEditorPage[] = [];

  constructor(pages: IPage[]) {
    this.pages = this.createEditorPages(pages);
  }

  public createEditorPages(
    data: IPage[]
  ): IEditorPage[] {
    this.pages = [];
    data.forEach((page: IPage) => {
      this.pages.push(EditorPages.toPage(page as IPage));
    });
    return this.pages;
  }

  public static toPage(data: IPage): IEditorPage {
    const page: IEditorPage = {} as IEditorPage;
    page.id = data.page_id;
    page.number = data.page_number;
    page.type = data.edits.type;
    page.pageName = data.edits.pageName;
    page.width = data.edits.width;
    page.height = data.edits.height;
    page.canEdit = !!data.edits.canEdit;
    page.canReorder = !!data.edits.canReorder;
    page.canDelete = !!data.edits.canDelete;
    page.joined = !!data.edits.joinedPage;
    page.elements = this.toElements(data.edits.freeObject);
    page.preview = data.preview_image;
    page.bleed = this.convertToBleed(data.edits.bleed, page);
    page.duplicateId = null;

    return page;
  }

  private static convertToBleed(
    data: IBleed | IEditorBleed | undefined,
    page: IEditorPage
  ): IBleed | undefined {
    if (!data) {
      return undefined;
    }

    // TODO: Please re-evaluate this.  Bleed|IEditorBleed are not equivalent and none of them have width or height.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bleed: any = {};

    bleed[ProductAttribute.BLEED_SIDE_LEFT] =
      data.leftSideDepth || (data as IEditorBleed).bleed_side_l
        ? parseInt(
            String(data.leftSideDepth || (data as IEditorBleed).bleed_side_l),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_BACK_LEFT] =
      data.leftBackOverPrint || (data as IEditorBleed).bleed_back_l
        ? parseInt(
            String(
              data.leftBackOverPrint || (data as IEditorBleed).bleed_back_l
            ),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_SIDE_RIGHT] =
      data.rightSideDepth || (data as IEditorBleed).bleed_side_r
        ? parseInt(
            String(data.rightSideDepth || (data as IEditorBleed).bleed_side_r),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_BACK_RIGHT] =
      data.rightBackOverPrint || (data as IEditorBleed).bleed_back_r
        ? parseInt(
            String(
              data.rightBackOverPrint || (data as IEditorBleed).bleed_back_r
            ),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_SIDE_TOP] =
      data.topSideDepth || (data as IEditorBleed).bleed_side_t
        ? parseInt(
            String(data.topSideDepth || (data as IEditorBleed).bleed_side_t),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_BACK_TOP] =
      data.topBackOverPrint || (data as IEditorBleed).bleed_back_t
        ? parseInt(
            String(
              data.topBackOverPrint || (data as IEditorBleed).bleed_back_t
            ),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_SIDE_BOTTOM] =
      data.bottomSideDepth || (data as IEditorBleed).bleed_side_b
        ? parseInt(
            String(data.bottomSideDepth || (data as IEditorBleed).bleed_side_b),
            10
          )
        : 0;
    bleed[ProductAttribute.BLEED_BACK_BOTTOM] =
      data.bottomBackOverPrint || (data as IEditorBleed).bleed_back_b
        ? parseInt(
            String(
              data.bottomBackOverPrint || (data as IEditorBleed).bleed_back_b
            ),
            10
          )
        : 0;

    bleed[WrapOptions.EDGE_STYLE] = (data as IBleed).edgeStyle
      ? (data as IBleed).edgeStyle
      : (data as IEditorBleed).edge_style
      ? (data as IEditorBleed).edge_style
      : null;
    bleed[WrapOptions.EDGE_COLOR] = (data as IBleed).edgeColor
      ? (data as IBleed).edgeColor
      : (data as IEditorBleed).edge_color
      ? (data as IEditorBleed).edge_color
      : null;
    bleed[WrapOptions.CORNER_TYPE] = (data as IBleed).cornerType
      ? (data as IBleed).cornerType
      : (data as IEditorBleed).corner_type
      ? (data as IEditorBleed).corner_type
      : null;
    bleed[WrapOptions.BACK_PRESENTATION] = (data as IBleed).backPresentation
      ? (data as IBleed).backPresentation
      : (data as IEditorBleed).back_presentation
      ? (data as IEditorBleed).back_presentation
      : null;

    bleed.width = page.width;
    bleed.height = page.height;

    //XXX : THIS NOT equivalent to a Bleed object...  FIX
    return bleed;
  }

  public getLabel(
    type: string,
    number: number,
    pageNames: string[] | undefined,
    isPager: boolean
  ): string {
    console.log(
      `get label, type: ${type} number: ${number} pageNames: [${pageNames?.join(
        ', '
      )}] isPage: ${isPager}`
    );
    return '';
  }

  public static toElements(freeObjects: IFreeObject[]): IEditorElement[] {
    const elements: IEditorElement[] = [];
    for (let i = 0; i < freeObjects.length; i++) {
      const el: IEditorElement | null = this.toElement(freeObjects[i]);

      // for some reason, some backgrounds are not the first element
      if (freeObjects[i].isBackground && i > 0 && el) {
        elements.unshift(el);
      } else if (el) {
        // add element
        elements.push(el);
      } else {
        console.warn('Unable to convert freeObejct to element', freeObjects[i]);
      }
    }

    return elements;
  }

  public static toElement(freeObject: IFreeObject): IEditorElement | null {
    let element: IEditorElement | null;
    let effects: IEffects = {
      initialRotation: 0,
      rotation: 0,
    };

    // convert shared effects here

    // convert to element
    if (freeObject.textEdits) {
      element = this.getTextEditsElement(freeObject);
      element.textZoneId = freeObject.textZoneId || undefined;
      element.linkedZoneId = freeObject.linkedZoneId || undefined;
      element.customerEditable = freeObject.customerEditable || undefined;
    } else {
      // it's an image
      const image: IEditorImage | null =
        this.createImageModelFromFreeObject(freeObject);

      // image edits
      const imageEdits = freeObject.imageEdits;

      // image effects
      if (imageEdits && image) {
        effects = this.getEffects(imageEdits, image);
      }

      element = this.getNonClipArtImage(
        imageEdits,
        freeObject,
        effects.rotation,
        image
      );
    }

    if (!element) {
      return null;
    }

    // setup basic element data

    if (element.freeObjectId !== undefined) {
      freeObject.freeObjectId = element.freeObjectId;
    }

    if (element.fillColor) {
      freeObject.fillColor = element.fillColor;
    }

    if (freeObject.context) {
      element.context = freeObject.context;
    }

    if (freeObject.customerEditable) {
      element.customerEditable = freeObject.customerEditable;
    }
    element.x = parseFloat(freeObject.x.toString());
    element.y = parseFloat(freeObject.y.toString());
    element.width = parseFloat(freeObject.width.toString());
    element.height = parseFloat(freeObject.height.toString());
    element.rotation = freeObject.rotation;
    element.opacity = freeObject.alpha !== undefined ? freeObject.alpha : 1; // freeObject.alpha can be zero
    element.effects = effects;

    // Allow those creating art to edit uneditable elements, but then restore them when saving back to the database (see fromElement)

    element.editable = true;
    element.editableSave = freeObject.canEdit;

    element.locked = freeObject.locked;
    element.fixed = freeObject.fixed;
    element.isLetterBoxed = !!freeObject.isLetterBoxed;
    element.order = freeObject.order;
    if ((element as IEditorFullPageElement).imagePosition) {
      element.userZoom = (element as IEditorFullPageElement).imagePosition.zoom;
    }
    if (freeObject.uniquePhotoId) {
      element.uniquePhotoId = freeObject.uniquePhotoId;
    }

    // copy over effects that we don't support yet
    if (freeObject.glowShadowReflection) {
      this.parseGlowShadowReflection(element, freeObject.glowShadowReflection);
    }

    // deal with mask and borders
    if (freeObject.maskBorder) {
      this.parseMaskBorder(element, freeObject.maskBorder);
    }

    // deal with vignette
    if (freeObject.vignette) {
      effects.vignetteColor = this.parseColor(freeObject.vignette.color || 0);
      effects.vignetteBlur = freeObject.vignette.blur;
      effects.vignetteOpacity = (freeObject.vignette.alpha || 0) * 100;
    }

    return element;
  }

  private static getNonClipArtImage(
    imageEdits: IImageEdits | undefined,
    freeObject: IFreeObject,
    rotation: number | undefined,
    image: IEditorImage | null
  ): IEditorElement | null {
    // backgrounds and photos require imagePosition data
    let imagePosition;
    let element: IEditorElement | null = null;

    // sometimes we have image edits, but without an actual image.
    if (imageEdits && imageEdits.fullImageWidth) {
      const zoom = imageEdits.zoom / 100;
      imagePosition = {
        x: imageEdits.picturePosX,
        y: imageEdits.picturePosY,
        width: imageEdits.fullImageWidth,
        height: imageEdits.fullImageHeight,
        zoom: zoom,
      };
    } else {
      imagePosition = {
        x: freeObject.width / -2,
        y: freeObject.height / -2,
        width: freeObject.width,
        height: freeObject.height,
        zoom: 1,
      };
    }

    // if we have a rotation, we need to rotate the imagePosition
    if (rotation) {
      Helpers.rotateImagePosition(imagePosition, rotation);
    }

    if (freeObject.type && freeObject.type == 'group') {
      const freeObjects = freeObject._objects;
      const innerObject: IEditorElement[] = [];
      for (let i = 0; i < freeObjects.length; i++) {
        const item = this.toElement(freeObjects[i]);
        if (item) {
          innerObject.push(item);
        } else {
          console.warn(
            'Unable to convert to element: ' + JSON.stringify(freeObjects[i])
          );
        }
      }

      element = new GroupElement(freeObject, innerObject);
    }

    if (freeObject.isBackground && image) {
      element = new BackgroundElement(image, imagePosition);
    } else if (freeObject.isForeground && image) {
      element = new ForegroundElement(image, imagePosition);
    } else if (freeObject.type != 'group') {
      element = new PhotoElement(
        image as IEditorImage,
        imagePosition,
        this.getEdgeAsImageModel(freeObject.edgeId || 0, freeObject) ||
          undefined
      );
    }
    if (!element) {
      throw new Error('Unable to create for non-clip-art image');
    }
    return element;
  }

  private static getEdgeAsImageModel(
    id: number,
    freeObject: IFreeObject
  ): IEditorEdge | null {
    if (!freeObject || !freeObject.fullFrameFilename) {
      return null;
    }
    const res = new Edge(
      id,
      freeObject.urlPrefix || '',
      this.getFrameThumbs(freeObject),
      Math.round((freeObject.frameWindowY / freeObject.height) * 100),
      Math.round(
        ((freeObject.width -
          (freeObject.frameWindowWidth + freeObject.frameWindowX)) /
          freeObject.width) *
          100
      ),
      Math.round(
        ((freeObject.height -
          (freeObject.frameWindowHeight + freeObject.frameWindowY)) /
          freeObject.height) *
          100
      ),
      Math.round((freeObject.frameWindowX / freeObject.width) * 100)
    );

    res.pathPrefix = freeObject.pathPrefix || '';
    return res;
  }

  private static getFrameThumbs(freeObject: IFreeObject): IThumbDictionary {
    const thumbs: IThumbDictionary = {};

    thumbs[ThumbSize.FULL] = new Thumb(
      freeObject.fullFrameFilename,
      freeObject.fullFrameWidth,
      freeObject.fullFrameHeight
    );
    if (
      freeObject.xlargeFrameFilename &&
      freeObject.xlargeFrameWidth &&
      freeObject.xlargeFrameHeight
    ) {
      thumbs[ThumbSize.XLARGE] = new Thumb(
        freeObject.xlargeFrameFilename,
        freeObject.xlargeFrameWidth,
        freeObject.xlargeFrameHeight
      );
    }
    thumbs[ThumbSize.LARGE] = new Thumb(
      freeObject.largeFrameFilename,
      freeObject.largeFrameWidth,
      freeObject.largeFrameHeight
    );
    thumbs[ThumbSize.MEDIUM] = new Thumb(
      freeObject.mediumFrameFilename,
      freeObject.mediumFrameWidth,
      freeObject.mediumFrameHeight
    );
    thumbs[ThumbSize.SMALL] = new Thumb(
      freeObject.smallFrameFilename,
      freeObject.smallFrameWidth,
      freeObject.smallFrameHeight
    );

    return thumbs;
  }

  protected static getTextEditsElement(freeObject: IFreeObject): IEditorTextElement {
    let element: IEditorTextElement;
    if (!freeObject.textEdits) {
      throw new Error('text element without freeObject.textEdits');
    }
    if (
      freeObject.textEdits.text &&
      freeObject.textEdits.rootStyle &&
      freeObject.textEdits.styles
    ) {
      element = new TextElement(
        freeObject.textEdits.text,
        freeObject.textEdits.rootStyle,
        freeObject.textEdits.styles,
        freeObject.textEdits
      );
    } else {
      const textData = freeObject.textEdits.TLFText
        ? TLF.Instance.toData(freeObject.textEdits.TLFText)
        : freeObject.textEdits;

      if (!textData) {
        throw new Error('cannot get textData from textEdits');
      }

      // some old projects had fontSizes in strings, cleanup here
      if (
        textData.rootStyle &&
        textData.rootStyle.fontSize &&
        typeof textData.rootStyle.fontSize === 'string'
      ) {
        textData.rootStyle.fontSize = parseInt(textData.rootStyle.fontSize, 10);
      }

      // create element
      element = new TextElement(
        textData.text as string,
        textData.rootStyle,
        textData.styles,
        freeObject.textEdits
      );
      element.tlf = freeObject.textEdits.TLFText;
    }
    element.preview_image = freeObject.textEdits.preview_image;
    element.preview_file = freeObject.textEdits.preview_file;
    element.edited = freeObject.textEdits.edited;
    element.editable = freeObject.canEdit;
    element.originalHeight = freeObject.textEdits.originalHeight;
    if (freeObject.textEdits.placeHolderTranslations) {
      element.placeHolderTranslations =
        freeObject.textEdits.placeHolderTranslations;
    }
    return element;
  }

  protected static createImageModelFromFreeObject(
    freeObject: IFreeObject
  ): IEditorImage | null {
    let image: IEditorImage | null;
    if (freeObject.photoId && freeObject.photoId != -1) {
      image = this.getPhotoModel(freeObject.photoId, freeObject.imageEdits);
    } else if (freeObject.isBackground && freeObject.graphicAssetId) {
      image = this.getBackgroundModel(
        freeObject.graphicAssetId,
        freeObject.imageEdits
      );
    } else {
      // @note: covers and spine don't have an ID, figure it out later.
      image = this.getImageModel(null, freeObject.imageEdits);
    }

    return image;
  }

  protected static getImageModel(
    id: number | null,
    imageEdits: IImageEdits | undefined
  ): IEditorImage | null {
    if (!imageEdits || !imageEdits.fullImageFilename) {
      return null;
    }

    const res = new Image(
      id,
      imageEdits.urlPrefix,
      this.getImageThumbs(imageEdits)
    );
    res.pathPrefix = imageEdits.pathPrefix;
    return res;
  }

  protected static getImageThumbs(imageEdits: IImageEdits): IThumbDictionary {
    const thumbs: IThumbDictionary = {};

    thumbs[ThumbSize.FULL] = new Thumb(
      imageEdits.fullImageFilename,
      imageEdits.fullImageWidth,
      imageEdits.fullImageHeight
    );
    if (
      imageEdits.xlargeImageFilename &&
      imageEdits.xlargeImageWidth &&
      imageEdits.xlargeImageHeight
    ) {
      thumbs[ThumbSize.XLARGE] = new Thumb(
        imageEdits.xlargeImageFilename,
        imageEdits.xlargeImageWidth,
        imageEdits.xlargeImageHeight
      );
    }
    thumbs[ThumbSize.LARGE] = new Thumb(
      imageEdits.largeImageFilename,
      imageEdits.largeImageWidth,
      imageEdits.largeImageHeight
    );
    thumbs[ThumbSize.MEDIUM] = new Thumb(
      imageEdits.mediumImageFilename,
      imageEdits.mediumImageWidth,
      imageEdits.mediumImageHeight
    );
    thumbs[ThumbSize.SMALL] = new Thumb(
      imageEdits.smallImageFilename,
      imageEdits.smallImageWidth,
      imageEdits.smallImageHeight
    );

    return thumbs;
  }

  protected static getPhotoModel(
    id: number,
    imageEdits: IImageEdits | undefined
  ): IEditorImage | null {
    if (!imageEdits || !imageEdits.fullImageFilename) {
      return null;
    }

    const res = new Photo(
      id,
      imageEdits.urlPrefix,
      this.getImageThumbs(imageEdits)
    );
    res.pathPrefix = imageEdits.pathPrefix;
    return res;
  }

  protected static getBackgroundModel(
    id: number,
    imageEdits: IImageEdits | undefined
  ): IEditorGraphicAsset | null {
    if (!imageEdits || !imageEdits.fullImageFilename) {
      return null;
    }

    const res = new GraphicAsset(
      id,
      'background',
      imageEdits.urlPrefix,
      this.getImageThumbs(imageEdits)
    );
    res.pathPrefix = imageEdits.pathPrefix;
    return res;
  }

  private static getEffects(imageEdits: IImageEdits, image: IEditorImage): IEffects {
    return {
      brightness: imageEdits.brightness,
      contrast: imageEdits.contrast,
      saturation: imageEdits.saturation,
      luminance: imageEdits.luminance,
      hue: imageEdits.hue,
      red: imageEdits.red,
      green: imageEdits.green,
      blue: imageEdits.blue,
      sharpness: imageEdits.sharpness,
      flipHorizontal: imageEdits.flipHorizontal,
      flipVertical: imageEdits.flipVertical,
      initialRotation: imageEdits.initialRotation || 0,
      rotation: imageEdits.rotation + (imageEdits.exifRotation || 0) || 0,
      filter: this.getFilter(imageEdits.colorMode) || undefined,
      autoCorrect: imageEdits.autoCorrect,
      autoRedEye: imageEdits.autoRedEye,
      duotoneColor: imageEdits.duotoneColor,
      redEyes: this.changeRedEyeOriginToCenter(imageEdits, image),
    };
  }

  private static getFilter(colorMode: string | number): string | null {
    colorMode = parseInt(colorMode as string, 10);

    return colorMode && filters.length > colorMode ? filters[colorMode] : null;
  }

  public static changeRedEyeOriginToCenter(
    imageEdits: IImageEdits,
    image: IEditorImage
  ): IEditorRedEye[] {
    return imageEdits.editorRedEyes
      ? imageEdits.editorRedEyes.map((e: IEditorRedEye): IEditorRedEye => {
          if (image) {
            e.x -= image.width / 2;
            e.y -= image.height / 2;
          }
          return e;
        })
      : [];
  }

  private static parseGlowShadowReflection(
    element: IEditorElement,
    glowShadowReflection: IGlowShadowReflection
  ): void {
    // type = {1 = glow, 2 = shadow, 3 = reflection)
    const effects: IEffects = element.effects;

    if (glowShadowReflection.type == 1) {
      effects.glowColor = this.parseColor(glowShadowReflection.color as number);
      effects.glowOpacity = glowShadowReflection.alpha * 100;
      effects.glowBlur = glowShadowReflection.blur;
    } else if (glowShadowReflection.type == 2) {
      effects.shadowColor = this.parseColor(
        glowShadowReflection.color as number
      );
      effects.shadowOpacity = glowShadowReflection.alpha * 100;
      effects.shadowBlur = glowShadowReflection.blur;
      effects.shadowDistance = glowShadowReflection.distance;
      effects.shadowAngle = (glowShadowReflection.angle as number) + 180;
    } else if (glowShadowReflection.type == 3) {
      effects.reflectionDistance = glowShadowReflection.distance;
      effects.reflectionOpacity = glowShadowReflection.alpha * 100;
      effects.reflectionSize = glowShadowReflection.falloff;
      effects.reflectionBlur = glowShadowReflection.blur;
    }
  }

  private static parseColor(color: number): string {
    //noinspection NonShortCircuitBooleanExpressionJS
    const hex = '00000' + (color || 0).toString(16);
    return '#' + hex.substring(hex.length - 6);
  }

  private static parseMaskBorder(
    element: IEditorElement,
    maskBorder: IMaskBorder
  ): void {
    const effects = element.effects;
    const masks: string[] = Object.keys(Masks);
    if (maskBorder.type !== 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      effects.mask = Masks[masks[maskBorder.type]];
    }

    effects.borderColor = this.parseColor(maskBorder.color);
    effects.borderSize = maskBorder.thickness;
  }

  public static setPageOrientation(
    editorPage: IEditorPage,
    orientation: Orientation
  ): void {
    const isCurrentlyLandscape: boolean = Helpers.getOrientation(editorPage.width, editorPage.height) === Orientation.landscape;
    if (
      (orientation === Orientation.portrait && isCurrentlyLandscape) ||
      (orientation === Orientation.landscape && !isCurrentlyLandscape)
    ) {
      const width = editorPage.height;
      const height = editorPage.width;
      editorPage.width = width;
      editorPage.height = height;
      const editorPageBleed = editorPage.bleed as IEditorBleed;
      if (editorPageBleed) {
        const old = editorPageBleed.width;
        //noinspection JSSuspiciousNameCombination
        editorPageBleed.width = editorPageBleed.height;
        editorPageBleed.height = old;
      }
      editorPage.elements = editorPage.elements.map(
        (element: IEditorElement): IEditorElement => {
          if (element instanceof BackgroundElement) {
            //because orientation of all the elements are maintained. It doesn't make sense to stay letterBoxed
            element.isLetterBoxed = false;

            let lSide = 0;
            let rSide = 0;
            let tSide = 0;
            let bSide = 0;
            let rBack = 0;
            let lBack = 0;
            let tBack = 0;
            let bBack = 0;

            if (
              editorPageBleed &&
              (editorPageBleed.edge_style == EdgeStyles.MIRROR_WRAP ||
                editorPageBleed.edge_style ==
                EdgeStyles.MIRROR_WRAP_WITH_BLUR ||
                editorPageBleed.edge_style == EdgeStyles.IMAGE_WRAP_WITH_BLUR ||
                editorPageBleed.edge_style == EdgeStyles.EXTEND ||
                editorPageBleed.edge_style == EdgeStyles.IMAGE_WRAP ||
                editorPageBleed.edge_style == EdgeStyles.BORDER ||
                editorPageBleed.edge_style == EdgeStyles.BORDER_WHITE)
            ) {
              rBack = editorPageBleed[
                ProductAttribute.BLEED_BACK_RIGHT as keyof typeof editorPageBleed
                ] as number;
              lBack = editorPageBleed[
                ProductAttribute.BLEED_BACK_LEFT as keyof typeof editorPageBleed
                ] as number;
              tBack = editorPageBleed[
                ProductAttribute.BLEED_BACK_TOP as keyof typeof editorPageBleed
                ] as number;
              bBack = editorPageBleed[
                ProductAttribute.BLEED_BACK_BOTTOM as keyof typeof editorPageBleed
                ] as number;

              rSide = editorPageBleed[
                ProductAttribute.BLEED_SIDE_RIGHT as keyof typeof editorPageBleed
                ] as number;
              lSide = editorPageBleed[
                ProductAttribute.BLEED_SIDE_LEFT as keyof typeof editorPageBleed
                ] as number;
              tSide = editorPageBleed[
                ProductAttribute.BLEED_SIDE_TOP as keyof typeof editorPageBleed
                ] as number;
              bSide = editorPageBleed[
                ProductAttribute.BLEED_SIDE_BOTTOM as keyof typeof editorPageBleed
                ] as number;

              if (isCurrentlyLandscape) {
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_TOP as keyof typeof editorPageBleed
                  ] as number) = rBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_BOTTOM as keyof typeof editorPageBleed
                  ] as number) = lBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_RIGHT as keyof typeof editorPageBleed
                  ] as number) = bBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_LEFT as keyof typeof editorPageBleed
                  ] as number) = tBack;

                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_TOP as keyof typeof editorPageBleed
                  ] as number) = rSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_BOTTOM as keyof typeof editorPageBleed
                  ] as number) = lSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_RIGHT as keyof typeof editorPageBleed
                  ] as number) = bSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_LEFT as keyof typeof editorPageBleed
                  ] as number) = tSide;
              } else {
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_TOP as keyof typeof editorPageBleed
                  ] as number) = lBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_BOTTOM as keyof typeof editorPageBleed
                  ] as number) = rBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_RIGHT as keyof typeof editorPageBleed
                  ] as number) = tBack;
                (editorPageBleed[
                  ProductAttribute.BLEED_BACK_LEFT as keyof typeof editorPageBleed
                  ] as number) = bBack;

                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_TOP as keyof typeof editorPageBleed
                  ] as number) = lSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_BOTTOM as keyof typeof editorPageBleed
                  ] as number) = rSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_RIGHT as keyof typeof editorPageBleed
                  ] as number) = tSide;
                (editorPageBleed[
                  ProductAttribute.BLEED_SIDE_LEFT as keyof typeof editorPageBleed
                  ] as number) = bSide;
              }
            }

            element.width = width;
            element.height = height;

            element.x = width / 2;
            element.y = height / 2;
            element.imagePosition =
              EditorTranslator.Instance.getElementImagePosition(element, true);
          } else {
            // ensure all other elements are still on screen
            if (element.x < 0 || element.x > width) {
              element.x = width / 2;
            }
            if (element.y < 0 || element.y > height) {
              element.y = height / 2;
            }
          }
          return element;
        }
      );
    }
  }
}
