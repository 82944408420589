// constants for each route

export const ROUTE_DASHBOARD = {
  path: '/dashboard',
  name: 'dashboard',
};

export const ROUTE_HOW_IT_WORKS = {
  path: '/howItWorks',
  name: 'howItWorks',
};

export const ROUTE_PERSONALIZED_PRINT_ON_DEMAND_INFO = {
  path: '/personalizedPrintOnDemand',
  name: 'personalizedPrintOnDemand',
};

export const ROUTE_DESIGN_MALL_INFO = {
  path: '/designMallInfo',
  name: 'designMallInfo',
};

export const ROUTE_PRODUCT_CATALOG = {
  path: '/productCatalog',
  name: 'productCatalog',
};

export const ROUTE_LANDING = {
  path: '/',
  name: 'landing',
};

export const ROUTE_EDITOR = {
  path: '/editor/:templateAddToStore?',
  name: 'editor',
};

export const ROUTE_INTELLECTUAL_PROPERTY_POLICY = {
  path: '/intellectualPropertyPolicy',
  name: 'intellectualPropertyPolicy',
};

export const ROUTE_PRIVACY_POLICY = {
  path: '/privacyPolicy',
  name: 'privacyPolicy',
};

export const ROUTE_TERMS_OF_SERVICE = {
  path: '/termsOfService',
  name: 'termsOfService',
};

export const ROUTE_FAQ = {
  path: '/faq',
  name: 'faq',
};

export const ROUTE_QUESTIONNAIRE = {
  path: '/questionnaire',
  name: 'questionnaire',
};

export const ROUTE_STORES = {
  path: '/stores',
  name: 'stores',
};

export const ROUTE_TEMPLATES = {
  path: '/templates',
  name: 'templates',
};


export const CHILD_ROUTE_STORES_SHOP_LIST = {
  path: '',
  name: 'shopList',
};

export const CHILD_ROUTE_STORES_SHOP_PRODUCTS = {
  path: ':id/products',
  name: 'shopProducts',
};

export const CHILD_ROUTE_STORES_SHOP_PRODUCT_SUMMARY_DETAILS = {
  path: ':id/products/:productId',
  name: 'shopProductSummaryDetails',
};

export const CHILD_ROUTE_STORES_SHOP_SETTINGS = {
  path: ':id/settings',
  name: 'shopSettings',
};

export const ROUTE_SETTINGS = {
  path: '/settings',
  name: 'settings',
};

export const ROUTE_ORDERS = {
  path: '/orders',
  name: 'orders',
};

export const ROUTE_BILLING = {
  path: '/billing',
  name: 'billing',
};

export const ROUTE_HELP_SUPPORT = {
  path: '/helpSupport',
  name: 'helpSupport',
};

export const ROUTE_EMAIL_SUPPORT = {
  path: '/emailSupport',
  name: 'emailSupport',
};

export const ROUTE_404 = {
  path: '/:pathMatch(.*)*',
  name: '404',
};



export const TemplateAddToStore = 'templateAddToStore';


