import { GraphicAsset } from '@/modules/editor/graphicAsset';
import { IEditorEdge, IThumbDictionary } from '@/interfaces/editorInterfaces';

export class Edge extends GraphicAsset implements IEditorEdge {
  /**
   * @param id
   * @param urlPrefix
   * @param thumbs
   * @param marginTop
   * @param marginRight
   * @param marginBottom
   * @param marginLeft
   */
  constructor(
    id: number,
    urlPrefix: string,
    thumbs: IThumbDictionary,
    public marginTop: number,
    public marginRight: number,
    public marginBottom: number,
    public marginLeft: number
  ) {
    super(id, 'edge', urlPrefix, thumbs);
  }
}
