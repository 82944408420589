import { IToData } from '@/interfaces/editorInterfaces';
import { IRootStyle } from '@/interfaces/projectInterface';
import logger from '@/logger/logger';

export class TLF {
  private readonly tlfTextAttributes: string[] = [
    'color',
    'fontFamily',
    'fontWeight',
    'fontSize',
    'fontStyle', // bold, italic, etc
    'underline',
    'linethrough',
    'overline',
    'textAlign',
    'verticalAlign',
    'lineHeight',
    'backgroundColor',
  ];

  private readonly tlfAttributesMap: { [key: string]: string } = {
    color: 'fill',
  };

  private static singleton: TLF;

  public static get Instance() {
    return this.singleton || (this.singleton = new this());
  }

  public toData(tlf: string): IToData | null {
    const properties: IRootStyle = {};
    const parseXML = this.parseXML(tlf);
    if (!parseXML) {
      logger.error('Unable to parseXml in Font TLF');
      return null;
    }
    const xml = parseXML.documentElement;

    // start by copying over root properties
    // @note: maybe we can optimize TLF first, for some reason, all of the root properties from flash are all
    // garbage, because styles seem to be defined individually inside <span>'s. A pre-processor to find
    // command styles would be best.
    this.copyProperties(xml, properties);

    const paddingTop = xml.getAttribute('paddingTop');
    if (paddingTop) {
      properties.margin = parseInt(paddingTop, 10);
    } else {
      properties.margin = 0;
    }

    // init vars
    let text = '';
    let currentText: string;
    const styles: object[] = [];
    let currentStyle: object;

    // get blocks of text (in paragraphs for TLF)
    const paragraphs = xml.getElementsByTagName('p');
    let spans: HTMLCollectionOf<HTMLSpanElement>;
    let span: HTMLSpanElement;

    // from flash, each paragraph is a new line of text (\n, not a line wrap)
    for (let i = 0; i < paragraphs.length; i++) {
      // each paragraph can have one or more span's
      spans = xml.getElementsByTagName('span');
      currentText = '';
      currentStyle = {};

      // if there was already some text previously, indicate a new line
      if (text) {
        text += '\n';
      }

      // loop over each span and take the text and style definition (per character)
      for (let j = 0; j < spans.length; j++) {
        span = spans[j];

        // ignore empty spans
        if (span.childNodes.length === 0) {
          continue;
        }

        if (paragraphs.length === 1 && spans.length === 1) {
          // optimization: there is only one paragraph with one span, merge with properties
          this.copyProperties(span, properties);
        } else {
          // otherwise, we need the style property for each character
          // we go one step further by only returning styles not defined in root
          const tmpStyles = this.getDifferentProperties(
            properties,
            this.copyProperties(span, {})
          );

          // if there are differences between root and this span, we need to include the data, per character
          if (Object.keys(tmpStyles).length !== 0) {
            if (
              span &&
              span.childNodes.length > 0 &&
              span.childNodes[0].nodeValue
            ) {
              for (let k = 0; k < span.childNodes[0].nodeValue.length; k++) {
                // This looks fishy, investigate (what are styles suposed to look like?)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (currentStyle as any)[currentText.length + k] = tmpStyles;
              }
            }
          }
        }

        // append span's text to the current line's text
        currentText += span.childNodes[0].nodeValue;
      }

      // append paragraph's text to final text object
      text += currentText;

      // if there were style definitions for this paragraph, add it to styles object
      if (Object.keys(currentStyle).length !== 0) {
        styles[i] = currentStyle;
      }
    }

    return {
      text: text,
      rootStyle: properties,
      styles: styles,
    };
  }

  private parseXML(data: string): Document | undefined {
    let xml: Document | undefined;
    let tmp: DOMParser;

    try {
      if (window.DOMParser) {
        // Standard
        tmp = new window.DOMParser();
        xml = tmp.parseFromString(data, 'text/xml');
      } else {
        // IE
        logger.error('Unable to parse XML, no XML Parser, ancient browser?');
      }
    } catch (e) {
      xml = undefined;
    }

    if (
      !xml ||
      !xml.documentElement ||
      xml.getElementsByTagName('parsererror').length
    ) {
      throw new Error('Invalid XML: ' + data);
    }

    return xml;
  }

  private copyProperties(elem: HTMLElement, obj: IRootStyle): IRootStyle {
    if (!elem.attributes) {
      return obj;
    }

    for (let i = 0; i < elem.attributes.length; i++) {
      const attr = elem.attributes[i];

      if (attr.specified && this.tlfTextAttributes.indexOf(attr.name) >= 0) {
        const name = this.tlfAttributesMap[attr.name] || attr.name;
        let value: string | number = attr.value;

        if (name === 'lineHeight') {
          value = parseInt(value as string, 10) / 100;
        }

        if (
          [
            'version',
            'fill',
            'fontFamily',
            'fontSize',
            'fontStyle',
            'fontWeight',
            'textAlign',
            'backgroundColor',
            'underline',
            'overline',
            'linethrough',
            'margin',
          ].indexOf(name) > -1
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (obj as any)[name] = value;
        }
      }
    }

    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getDifferentProperties(a: any, b: any): IRootStyle {
    const result: { [key: string]: string | number } = {};

    for (const key in b) {
      if (
        [
          'version',
          'fill',
          'fontFamily',
          'fontSize',
          'fontStyle',
          'fontWeight',
          'textAlign',
          'backgroundColor',
          'underline',
          'overline',
          'linethrough',
          'margin',
        ].indexOf(key) > -1
      )
        if (b[key] && (!a[key] || a[key] !== b[key])) {
          result[key] = b[key];
        }
    }

    return result as IRootStyle;
  }
}
