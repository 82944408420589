export function uuid(): string {
  if (typeof crypto !== 'undefined' && crypto.getRandomValues) {
    return (([1e7] as unknown as number) + -1e3 + -4e3 + -8e3 + -1e11)
      .toString()
      .replace(/[018]/g, (c: string) =>
        (
          (c as unknown as number) ^
          (crypto.getRandomValues(new Uint8Array(1))[0] &
            (15 >> ((c as unknown as number) / 4)))
        ).toString(16)
      );
  } else {
    return URL.createObjectURL(new Blob([])).slice(-36);
  }
}

export function generateGlobalTopicID() {
  const randomNumber = Math.floor(Math.random() * 1000000000) + 1;
  return btoa(randomNumber.toString());
}

