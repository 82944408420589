import {
  IEditorImage,
  IEditorThumb,
  IThumbDictionary,
} from '@/interfaces/editorInterfaces';
import { ThumbSize } from '@/modules/editor/editorConstants';

export class Thumb implements IEditorThumb {
  constructor(
    public name: string,
    public width: number,
    public height: number
  ) {}
}

export class Image extends Thumb implements IEditorImage {
  public pathPrefix = '';
  public edits: { rotation?: number; autocorrect?: boolean } = {};
  public override width = 0;
  public override height = 0;


  constructor(
    public id: number | null,
    public urlPrefix: string = '',
    public thumbs: IThumbDictionary = {}
  ) {
    super('', 0, 0);
    if (thumbs && thumbs[ThumbSize.FULL]) {
      const thumb = thumbs[ThumbSize.FULL];
      this.name = thumb.name;
      this.width = thumb.width;
      this.height = thumb.height;
    }
  }

  /**
   * @param {string} size
   * @return {SF.Model.Thumb}
   */
  public getThumb(size: string): IEditorThumb {
    return this.thumbs[size];
  }

  /**
   * @param {string} name
   * @param {boolean} [hasAutoCorrect]
   * @param {boolean} [hasAutoRedEye]
   * @returns {string}
   */
  public getUrl(
    name: string,
    hasAutoCorrect?: boolean,
    hasAutoRedEye?: boolean
  ): string {
    if (
      [
        ThumbSize.FULL,
        ThumbSize.XLARGE,
        ThumbSize.LARGE,
        ThumbSize.MEDIUM,
        ThumbSize.SMALL,
      ].includes(name)
    ) {
      name = this.getThumb(name).name;
    }
    let extension = '';
    if (hasAutoRedEye && hasAutoCorrect) {
      extension = '.ar';
    } else if (hasAutoRedEye) {
      extension = '.r';
    } else if (hasAutoCorrect) {
      extension = '.a';
    }
    return this.urlPrefix + name + extension;
  }
}
