import {
  IEditorEdge,
  IEditorElement,
  IEditorFrame,
  IEditorFullPageElement,
  IEditorImage,
  IEditorImageElement,
  IEditorImagePosition,
  IEditorTextElement,
  IEffects,
  IFreeObject,
} from '@/interfaces/editorInterfaces';
import { fabric } from 'fabric';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { IRootStyle, ITextEdit } from '@/interfaces/projectInterface';
import { useSessionStore } from '@/stores/sessionStore';

export class Element implements IEditorElement {
  public x = 0;
  public y = 0;
  public width = 0;
  public height = 0;
  public effects: IEffects = {
    initialRotation: 0,
    rotation: 0,
  };
  public rotation = 0;
  public opacity = 1;

  public editableSave?: boolean = undefined;
  public editable = true;
  public locked = false;
  public fixed = false;
  //@note this is currently only used on the background. Consider moving it there
  public isLetterBoxed = false;
  public order = 0;
  public uid: string;
  public uniquePhotoId!: number;
  public imagePosition!: IEditorImagePosition | undefined;

  constructor() {
    this.uid = this.generateUid();

    // data that is just passed through for flash
  }

  public generateUid(): string {
    return EditorTranslator.generateUid();
  }

  public _clone(e: IEditorElement): void {
    e.uid = this.uid;
    e.x = this.x;
    e.y = this.y;
    e.width = this.width;
    e.height = this.height;
    e.effects = fabric.util.object.clone(this.effects);
    e.rotation = this.rotation;
    e.opacity = this.opacity;
    e.editable = this.editable;
    e.locked = this.locked;
    e.fixed = this.fixed;
    e.editableSave = this.editableSave;
    e.isLetterBoxed = this.isLetterBoxed;
    if (this.uniquePhotoId) {
      e.uniquePhotoId = this.uniquePhotoId;
    }
    const extraPropertiesToInclude =
      EditorTranslator.Instance.extraPropertiesToInclude;
    if (extraPropertiesToInclude) {
      extraPropertiesToInclude.forEach((extraPropertyToInclude: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this[extraPropertyToInclude] !== undefined) {
          Object.defineProperty(e, extraPropertyToInclude, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value: this[extraPropertyToInclude],
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
      });
    }
  }
}

export class TextElement extends Element implements IEditorTextElement {
  public addedByUser!: boolean;
  public placeHolderTranslations?: { [key: string]: string };
  public preview_image = '';
  public preview_file = '';
  public tlf = '';
  public originalHeight!: number;
  public edited = false;
  public text = '';
  public textBeforeEdit = '';
  public textZoneId? = 0;
  public linkedZoneId? = 0;

  constructor(
    text: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public rootStyle: IRootStyle = {} as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public styles: any = {},
    public textEdits?: ITextEdit
  ) {
    super();
    if (text && (!textEdits || textEdits.edited || !textEdits.canEdit)) {
      this.text = text;
    } else if (textEdits && textEdits.placeHolderTranslations) {
      this.setText(textEdits);
    }
    this.styles = styles || {};
  }
  protected setText(textEdits: ITextEdit): void {
    const sessionStore = useSessionStore();
    this.text = textEdits.placeHolderTranslations
      ? textEdits.placeHolderTranslations[sessionStore.currentLanguage]
      : '';
  }

  public clone(): IEditorTextElement {
    const textElement = new TextElement(this.text, this.rootStyle, this.styles);
    this._clone(textElement);
    textElement.edited = this.edited;
    textElement.editable = this.editable;
    if (this.placeHolderTranslations) {
      textElement.placeHolderTranslations = this.placeHolderTranslations;
    }
    return textElement;
  }
}

export class ImageElement extends Element implements IEditorImageElement {
  constructor(public image: IEditorImage|undefined) {
    super();
  }

  public clone(): ImageElement {
    const imageElement = new ImageElement(this.image);
    this._clone(imageElement);
    return imageElement;
  }
}

export class GroupElement extends Element {
  public _objects: IEditorElement[];
  public type: string;

  constructor(
    object: IFreeObject,
    shapeObjects: IEditorElement[],
  ) {
    super();
    this.width = object.width || 0;
    this.height = object.height || 0;
    // TODO: This is suspicious.  Either the object isn't a FreeObject or FreeObject contains x and y instead of left and top.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.x = (object as any).left || 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.y = (object as any).top || 0;
    this._objects = shapeObjects;
    this.type = 'group';
  }
}

export class PhotoElement extends ImageElement {
  constructor(
    image: IEditorImage|undefined,
    public override  imagePosition: IEditorImagePosition | undefined,
    public frame?: IEditorFrame | IEditorEdge
  ) {
    super(image);
  }

  public override clone(): PhotoElement {
    const e = new PhotoElement(
      this.image,
      this.imagePosition
        ? fabric.util.object.clone(this.imagePosition)
        : undefined,
      this.frame
    );
    this._clone(e);

    return e;
  }

  public getFrameWidth(): number {
    return this.width - this.getMarginLeft() - this.getMarginRight();
  }

  public getFrameHeight(): number {
    return this.height - this.getMarginTop() - this.getMarginBottom();
  }

  public getMarginLeft(): number {
    if (this.frame) {
      return this.width * (this.frame.marginLeft / 100);
    }

    if (this.effects && this.effects.borderSize) {
      return this.effects.borderSize;
    }

    return 0;
  }

  public getMarginRight(): number {
    if (this.frame) {
      return this.width * (this.frame.marginRight / 100);
    }

    if (this.effects && this.effects.borderSize) {
      return this.effects.borderSize;
    }

    return 0;
  }

  public getMarginTop(): number {
    if (this.frame) {
      return this.height * (this.frame.marginTop / 100);
    }

    if (this.effects && this.effects.borderSize) {
      return this.effects.borderSize;
    }

    return 0;
  }

  public getMarginBottom(): number {
    if (this.frame) {
      return this.height * (this.frame.marginBottom / 100);
    }

    if (this.effects && this.effects.borderSize) {
      return this.effects.borderSize;
    }

    return 0;
  }

  public replaceImage(image: IEditorImage, effectsRotation = 0): void {
    this.image = image;
    // set rotation from edits
    const rotation = image.edits.rotation || 0;
    this.effects.initialRotation = rotation;
    this.effects.rotation = effectsRotation || rotation;

    // re-place photo
    this.imagePosition = EditorTranslator.Instance.getElementImagePosition(
      this,
      true
    );
  }
}

export class FullPageElement
  extends ImageElement
  implements IEditorFullPageElement
{
  constructor(
    image: IEditorImage,
    public override imagePosition: IEditorImagePosition
  ) {
    super(image);
    this.fixed = true;
  }

  public override clone(): FullPageElement {
    const e = new FullPageElement(
      this.image as IEditorImage,
      fabric.util.object.clone(this.imagePosition)
    );
    this._clone(e);

    return e;
  }

  public getFrameWidth(): number {
    return this.width;
  }

  public getFrameHeight(): number {
    return this.height;
  }

  public getMarginLeft(): number {
    return 0;
  }

  public getMarginRight(): number {
    return 0;
  }

  public getMarginTop(): number {
    return 0;
  }

  public getMarginBottom(): number {
    return 0;
  }

  public replaceImage(image: IEditorImage): void {
    this.image = image;

    // set rotation from edits
    const rotation =
      image.edits && image.edits.rotation ? image.edits.rotation : 0;
    this.effects.initialRotation = rotation;
    this.effects.rotation = rotation;

    // re-place photo
    this.imagePosition = EditorTranslator.Instance.getElementImagePosition(
      this,
      true
    );
  }
}

export class ForegroundElement extends FullPageElement {
  public override clone(): ForegroundElement {
    const e = new ForegroundElement(
      this.image as IEditorImage,
      fabric.util.object.clone(this.imagePosition)
    );
    this._clone(e);

    return e;
  }

  public override replaceImage(image: IEditorImage): void {
    this.image = image;

    // set rotation from edits
    const rotation = image.edits.rotation || 0;
    this.effects.initialRotation = rotation;
    this.effects.rotation = rotation;

    // re-place photo
    this.imagePosition = EditorTranslator.Instance.getElementImagePosition(
      this,
      true
    );
  }
}

export class BackgroundElement extends FullPageElement {
  public onSpine!: boolean;

  public override clone(): BackgroundElement {
    const backgroundElement = new BackgroundElement(
      this.image as IEditorImage,
      fabric.util.object.clone(this.imagePosition)
    );
    this._clone(backgroundElement);
    return backgroundElement;
  }

  public override replaceImage(image: IEditorImage): void {
    this.image = image;

    // set rotation from edits
    const rotation = image.edits.rotation || 0;
    this.effects.initialRotation = rotation;
    this.effects.rotation = rotation;

    // re-place photo
    this.imagePosition = EditorTranslator.Instance.getElementImagePosition(
      this,
      true
    );
  }
}

export class ClipartElement extends ImageElement {
  public override clone(): ClipartElement {
    const imageElement = new ClipartElement(this.image);
    this._clone(imageElement);
    return imageElement;
  }
}
