/* eslint-disable no-prototype-builtins */
import { fabric } from 'fabric';
import {
  IEditorCanvas,
  IEditorFrame,
  IEditorOCoords,
  IEditorShape, IFabricUtil,
  ITransform
} from '@/interfaces/editorInterfaces';
import {
  CornerTypes,
  EdgeStyles,
  ProductAttribute,
  WrapOptions
} from '@/modules/editor/editorConstants';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
import { ControlMouseEventHandler, IBaseFilter, IPoint, TextOptions } from 'fabric/fabric-impl';
import { IPosition, IWrap } from '@/interfaces/projectInterface';
import controlRotateUrl from '@/assets/images/editor/rotate.svg';
import cornerControlUrl from '@/assets/images/editor/corner.svg';
import controlPanUrl from '@/assets/images/editor/icon_hand_open.png';
import { PhotoGroup } from '@/modules/editor/fabricShapes/photoGroup';
import { useEditorImageStore } from '@/stores/editorImageStore';
import { useSessionStore } from '@/stores/sessionStore';
import { doesProductHaveBackLayer } from '@/helpers/itePreviewHelper';
import { AligningGuidelines } from '@/modules/editor/aligningGuidelines';

interface ITarget {
  type: string;
  off: (name: string, imageData: (data: { target: ITarget }) => void) => void;
  group?: object;
}

interface IStyleOverride {
  [key: string]: string | number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const devicePixelRatio = (fabric as any).devicePixelRatio;
const rotateControlImg = fabric.util.createImage();
const cornerControlImg = fabric.util.createImage();
const panControlImg = fabric.util.createImage();

rotateControlImg.src = controlRotateUrl;
cornerControlImg.src = cornerControlUrl;
panControlImg.src = controlPanUrl;

const rotateCursor = encodeURIComponent(
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>'
);
const rotationIconAbsoluteOffset = 20;
const innerRotationIconAbsoluteOffset = 20;

export class EditorCanvas {

  CreateEditorCanvas = fabric.util.createClass(fabric.Canvas, {
    _previousMovePointer: null,
    objectsToLoad: 0,

    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,

    initialize: function(
      el: HTMLElement,
      options: {
        preserveObjectStacking: boolean;
        backgroundColor: string;
        selection: boolean;
      }
    ): void {
      // define a proxy'd render all
      const renderAll = this._renderAll.bind(this);
      this.renderAll = function(): void {
        requestAnimationFrame(renderAll);
      };

      // default options
      options.preserveObjectStacking = true;
      options.backgroundColor = options.backgroundColor || '#FFFFFF';
      options.selection = options.selection || false;

      this.objectsToLoad = 0;
      this.callSuper('initialize', el, options);
      this.on('after:render', PostRenderer.render.bind(this));
    },

    _renderAll: function(): fabric.StaticCanvas {
      const lowerContext = this.contextContainer;
      const upperContext = this.contextTop || this.contextContainer;

      if (!upperContext) {
        return this;
      }
      // clear both context's
      this.clearContext(upperContext);
      this.clearContext(lowerContext);

      // fire before render
      this.fire('before:render');
      const sessionStore = useSessionStore();
      const base = sessionStore.currentBase;
      if (base) {
        const hasBackLayer = doesProductHaveBackLayer(base);
        if (this.backgroundImage) {
          if (hasBackLayer) {
            this._renderBackground(lowerContext);
          }
        }

        // apply clip
        if (this.clipTo) {
          lowerContext.save();
          lowerContext.beginPath();
          this.clipTo(lowerContext);
          lowerContext.clip();
        }
        // render background
        if (!hasBackLayer) {
          this._renderBackground(lowerContext);
        }
      }
      this.renderCanvasBackgroundColor(lowerContext);

      // render objects
      lowerContext.save();
      const objsToRender = this._chooseObjectsToRender();

      //apply viewport transform once for all rendering process
      // eslint-disable-next-line prefer-spread
      lowerContext.transform.apply(lowerContext, this.viewportTransform);
      for (let i = 0; i < objsToRender.length; i++) {
        if (
          objsToRender[i].type == 'group' &&
          this.getObjects().indexOf(objsToRender[i]) >= 0
        ) {
          for (let j = 0; j < objsToRender[i]._objects.length; j++) {
            objsToRender[i]._objects[j]._currentViewportTransform =
              this.viewportTransform;
          }
        }
      }

      this._renderObjects(lowerContext, objsToRender);

      if (!this.preserveObjectStacking) {
        this._renderObjects(lowerContext, [this.getActiveGroup()]);
      }
      lowerContext.restore();

      // undo clip
      if (this.clipTo) {
        lowerContext.restore();
      }
      if (
        this.__eventListeners['after:render'] &&
        this.__eventListeners['after:render'].length === 0
      ) {
        PostRenderer.render.apply(this);
        AligningGuidelines.handleAfterRenderer();
      }

      // fire after-render
      this.fire('after:render');

      PostRenderer.renderCanvasCorner.apply(this);
      PostRenderer.renderMask.apply(this);

      this._renderOverlay(upperContext);
      // draw controls on upper layer
      this.drawControls(upperContext);
      return this;
    },

    _onMouseWheel: function(e: Event) {
      this._cacheTransformEventData(e);
      const target = this.getActiveObject();

      if (!target || target.isLetterBoxed) {
        e.preventDefault();
        return;
      }

      if (target instanceof PhotoGroup) {
        const zoom = target.getZoom();
        let zoomDelta = -(e as WheelEvent).deltaY / 2400;
        if (zoomDelta > 0 && target.isImageTooLarge()) {
          e.preventDefault();
          return;
        }
        const newZoom = zoom + zoomDelta;
        const minZoom = target.photoObject.getMinZoom();
        const editorCanvasStore = useEditorCanvasStore();
        const editorImageStore = useEditorImageStore();
        const errorConfiguration = editorCanvasStore.errorConfiguration;
        const maxZoom = errorConfiguration.zoomError;

        if (minZoom > newZoom || maxZoom < newZoom) {
          if (zoom === minZoom || zoom === maxZoom) {
            e.preventDefault();
            return;
          }

          if (minZoom > newZoom) {
            zoomDelta = minZoom - zoom;
          } else if (minZoom < newZoom) {
            zoomDelta = maxZoom - zoom;
          }
        }
        const zoomBeforeChange = target.photoObject.imagePosition.zoom;
        target.zoom(zoomDelta);
        if (
          target.photoObject.imagePosition.zoom == zoomBeforeChange &&
          target.photoObject.group
        ) {
          (target.photoObject.group as IEditorFrame).userZoom = minZoom;
        } else if (target.photoObject.group) {
          target.photoObject.group.userZoom =
            target.photoObject.imagePosition.zoom;
        }
        target.photoObject.elementAdded = false;
        this.renderAll();
        this._resetTransformEventData();
        editorImageStore.handleImageEditsChange();
        editorCanvasStore.saveCanvasObjectsState().catch(err => {
          console.error('Unable to save canvas state', err);
        });
      }
      e.preventDefault();
    },


    /**
     * @private
     * @param obj Object that was added
     */
    _onObjectAdded: function(obj: fabric.Object): void {
      this.dirty = true;
      if (obj.type == 'group') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const innerObjects = (obj as any).getObjects();
        for (let i = 0; i < innerObjects.length; i++) {
          if (innerObjects[i].hasOwnProperty('ready') && !innerObjects[i].ready) {
            // increment counter
            this.objectsToLoad++;

            // listen for ready
            innerObjects[i].on('ready', this._onObjectReady.bind(this));
          }
        }
      }
      if (obj.hasOwnProperty('ready') && !(obj as IEditorShape).ready) {
        // increment counter
        this.objectsToLoad++;

        // listen for ready
        obj.on('ready', this._onObjectReady.bind(this));
      }

      this.callSuper('_onObjectAdded', obj);
    },

    /**
     * @private
     * @param obj Object that was removed
     */
    _onObjectRemoved: function(obj: fabric.Object): void {
      this.dirty = true;
      if (obj.hasOwnProperty('ready') && ((obj as IEditorShape).ready || (obj as IEditorShape).image === null)) {
        obj.off('ready', this._onObjectReady);
      }

      this.toolTipZones = [];

      this.callSuper('_onObjectRemoved', obj);
    },

    _onObjectReady: function(data: {
      target: ITarget;
    }): void {
      // do not decrease objectsToLoad if onObjectReady is called by a text object
      // Note: text objects are not counted by objectsToLoad, but they still need to be ready before we can fire ready on canvas
      if (this.objectsToLoad > 0 && data.target.type !== 'sf-text') {
        this.objectsToLoad--;
      }
      if (this.objectsToLoad === 0) {
        let fontsLoaded = true;
        const textObjects = this.getObjects().filter((object: IEditorShape) => {
          return object.type === 'sf-text';
        });

        if (textObjects.length > 0) {
          fontsLoaded = textObjects.every((textObj: { ready: boolean }) => {
            return textObj.ready;
          });
        }

        if (fontsLoaded) {
          fabric.charWidthsCache = {};
          this.renderAll();
          this.fire('ready');
        }
      }

      data.target.off('ready', this._onObjectReady);
      if (data.target.group) {
        this._fire('modified', {
          target: data.target.group
        });
      }
    },

    renderCanvasBackgroundColor: function(
      ctx: CanvasRenderingContext2D
    ): void {
      const zoom = this.getZoom();
      const objects = this.getObjects();
      const editorCanvasStore = useEditorCanvasStore();
      if (
        !editorCanvasStore.getCanvasBackgroundColor ||
        (objects.length == 1 && objects[0].width * zoom >= this.width)
      ) {
        return;
      }
      ctx.save();
      const originalFillStyle = ctx.fillStyle;
      ctx.fillStyle = editorCanvasStore.getCanvasBackgroundColor;
      ctx.fillRect(0, 0, this.width, this.height);
      ctx.fillStyle = originalFillStyle;
      ctx.restore();
    },

    _setupCurrentTransform: function(e: Event, target: IEditorShape): void {
      this._previousMovePointer = null;
      const alreadySelected: boolean = target === this._activeObject;
      this.callSuper('_setupCurrentTransform', e, target, alreadySelected);

      if (this._currentTransform) {
        if (target.photoObject) {
          this._currentTransform.zoom = target.getZoom();
          this._currentTransform.innerTheta = fabric.util.degreesToRadians(
            target.getInnerRotation()
          );
          this._currentTransform.innerLeft = target.photoObject.left;
          this._currentTransform.innerTop = target.photoObject.top;
        }

        this._currentTransform.height = target.height;
      }
    },

    _performTransformAction: function(
      e: Event,
      transform: ITransform,
      pointer: IPoint
    ): void {
      const imageStore = useEditorImageStore();
      let actionPerformed = false;
      const action = transform.action;
      if (action === 'pan') {
        actionPerformed = this._pan(pointer.x, pointer.y);
        if (actionPerformed) {
          this._fire('panning', {
            e: e,
            transform: transform,
            pointer: {
              x: pointer.x,
              y: pointer.y
            }
          });
        }
        this.setCursor('grabbing');
        transform.actionPerformed =
          transform.actionPerformed || actionPerformed;
        this._previousMovePointer = pointer;
      } else if (action === 'scale' || action === 'scaleX' || action === 'scaleY') {
        if (action==='scale' && (e as MouseEvent).shiftKey) {
          // the naming in fabric is weird. They mentioned in the library comments that this name will be changed in their future releases
          // uniformScaling should be false for the shift key to do the proportional scaling, but default value is true.
          const originalUniformScaling = this.uniformScaling;
          this.uniformScaling = false;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actionPerformed = (fabric as any).controlsUtils.scalingEqually(e, transform, pointer.x, pointer.y);
          this.uniformScaling = originalUniformScaling;
        } else {
          actionPerformed = action === 'scale' ? scaleObject(e, transform, pointer.x, pointer.y) : action === 'scaleX' ?
            scaleObject(e, transform, pointer.x, pointer.y, { by: 'x' }) :
            scaleObject(e, transform, pointer.x, pointer.y, { by: 'y' });
        }

        if (actionPerformed) {
          this._fire('scaling', {
            e: e,
            transform: transform,
            pointer: {
              x: pointer.x,
              y: pointer.y
            }
          });
        }

        transform.actionPerformed = transform.actionPerformed || actionPerformed;
      } else {
        this.callSuper('_performTransformAction', e, transform, pointer);
      }
      if (transform.actionPerformed) {
        if (action === 'drag' || action === 'pan' || action === 'scale' || action === 'scaleX' || action === 'scaleY') {
          imageStore.handleImageEditsChange();
        }
      }
    },

    setCursor: function(value: string): void {
      if (this._currentTransform && this._currentTransform.action === 'pan') {
        value = 'grabbing';
      }
      this.upperCanvasEl.style.cursor = value;
    },

    _pan: function(x: number, y: number): boolean {
      const t = this._currentTransform;
      const target = t.target;

      if (target.photoObject) {
        // calculate delta
        let dX;
        let dY;
        if (!this._previousMovePointer) {
          dX = 0;
          dY = 0;
        } else {
          dX = x - this._previousMovePointer.x;
          dY = y - this._previousMovePointer.y;
        }

        if (target.angle) {
          const radians = fabric.util.degreesToRadians(-target.angle);
          const sin = Math.sin(radians);
          const cos = Math.cos(radians);
          const rx = dX * cos - dY * sin;
          const ry = dX * sin + dY * cos;

          dX = rx;
          dY = ry;
        }

        if (target.flipX) {
          dX = -dX;
        }

        if (target.flipY) {
          dY = -dY;
        }

        target.pan(dX, dY);
        return true;
      }

      return false;
    },

    dispose: function(): void {
      this.off('after:render');
      this.forEachObject(
        (object: {
          photoObject: { dispose: () => void };
          frameObject: { dispose: () => void };
        }): void => {
          if (object.photoObject) {
            object.photoObject.dispose();
          }
          if (object.frameObject) {
            object.frameObject.dispose();
          }
        }
      );
      if (
        this.wrapperEl.children &&
        this.wrapperEl.children[0] instanceof HTMLCanvasElement
      ) {
        this.wrapperEl.children[0].width = 0;
        this.wrapperEl.children[0].height = 0;
      }
      this.callSuper('dispose');
      if (fabric.filterBackend) {
        if (fabric.filterBackend.resources) {
          if (
            fabric.filterBackend.resources.blurLayer1 &&
            fabric.filterBackend.resources.blurLayer1 instanceof
            HTMLCanvasElement
          ) {
            fabric.filterBackend.resources.blurLayer1.width = 0;
            fabric.filterBackend.resources.blurLayer1.height = 0;
          }
          if (
            fabric.filterBackend.resources.blurLayer2 &&
            fabric.filterBackend.resources.blurLayer2 instanceof
            HTMLCanvasElement
          ) {
            fabric.filterBackend.resources.blurLayer2.width = 0;
            fabric.filterBackend.resources.blurLayer2.height = 0;
          }
        }
        fabric.filterBackend.dispose();
        fabric.filterBackend = undefined;
      }
    }
  });


  CreateStaticCanvas = fabric.util.createClass(fabric.StaticCanvas, {
    objectsToLoad: 0,

    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,

    initialize: function(el: HTMLElement, options?: {
      preserveObjectStacking: boolean;
      backgroundColor: string;
      selection: boolean;
    }): void {
      this.objectsToLoad = 0;
      this.renderOnAddRemove = false;
      const editorCanvasStore = useEditorCanvasStore();
      if (!options) {
        options = { preserveObjectStacking: true, backgroundColor: editorCanvasStore.getCanvasBackgroundColor, selection: false};
      }

      this.callSuper('initialize', el, options);
      this.on('after:render', PostRenderer.render.bind(this));
    },

    /**
     * @private
     * @param obj Object that was added
     */
    _onObjectAdded: function(obj: fabric.Object): void {
      if (obj.type == 'group') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const innerObjects = (obj as any).getObjects();
        for (let i = 0; i < innerObjects.length; i++) {
          if (innerObjects[i].hasOwnProperty('ready') && !innerObjects[i].ready) {
            // increment counter
            this.objectsToLoad++;

            // listen for ready
            innerObjects[i].on('ready', this._onObjectReady.bind(this));
          }
        }
      }
      if (obj.hasOwnProperty('ready') && !(obj as IEditorShape).ready) {
        // increment counter
        this.objectsToLoad++;

        // listen for ready
        obj.on('ready', this._onObjectReady.bind(this));
      }

      this.callSuper('_onObjectAdded', obj);
    },

    /**
     * @private
     * @param obj Object that was removed
     */
    _onObjectRemoved: function(obj: fabric.Object): void {
      if (obj.hasOwnProperty('ready') && ((obj as IEditorShape).ready || (obj as IEditorShape).image === null)) {
        obj.off('ready', this._onObjectReady);
      }
      this.callSuper('_onObjectRemoved', obj);
    },

    _onObjectReady: function(data: {
      target: ITarget;
    }): void {
      let fontsLoaded = true;
      if (!fabric.isLikelyNode) {
        const textObjects = this.getObjects().filter((object: IEditorShape) => {
          return object.type === 'sf-text';
        });

        if (textObjects.length > 0) {
          fontsLoaded = !textObjects.find((textObj: IEditorShape) => {
            return !textObj.ready;
          });
        }
      }
      // do not decrease objectsToLoad if onObjectReady is called by a text object
      // Note: text objects are not counted by objectsToLoad but they still need to be ready before we can fire ready on canvas
      if (fabric.isLikelyNode || (this.objectsToLoad > 0 && data.target.type !== 'sf-text')) {
        this.objectsToLoad--;
      }
      if (this.objectsToLoad === 0 && fontsLoaded) {
        if (!fabric.isLikelyNode) {
          fabric.charWidthsCache = {};
        }
        this.renderAll();
        this.fire('ready');

      }

      data.target.off('ready', this._onObjectReady);
    },

    dispose: function(): void {
      // cancel eventually ongoing renders
      if (this.isRendering) {
        fabric.util.cancelAnimFrame(this.isRendering);
        this.isRendering = 0;
      }
      this.forEachObject(function(disposeObject: IEditorShape): void {
        if (disposeObject.dispose) {
          disposeObject.dispose();
        }
        if (disposeObject.photoObject && disposeObject.photoObject.dispose) {
          disposeObject.photoObject.dispose();
        }
        if (disposeObject.frameObject && disposeObject.frameObject.dispose) {
          disposeObject.frameObject.dispose();
        }
      });
      this._objects = [];
      if (this.backgroundImage && this.backgroundImage.dispose) {
        this.backgroundImage.dispose();
      }
      this.backgroundImage = null;
      if (this.overlayImage && this.overlayImage.dispose) {
        this.overlayImage.dispose();
      }
      this.overlayImage = null;
      this._iTextInstances = null;
      (fabric.util as IFabricUtil).cleanUpJsdomNode(this.lowerCanvasEl);
      return this;
    }
  });
}

const defaultSettings = {
  originX: 'center',
  originY: 'center',
  transparentCorners: false,
  cornerColor: 'rgb(255,102,0)',
  controlSize: 15,
  rotateControlSize: 15,
  panControlSize: 25,
  cornerStyle: 'rect' as 'rect' | 'circle', // fabric types do not match with the fabric.js code...
  rotatingPointOffset: 30,
  innerRotateOffset: -25,
  borderColor: '#549ACF',
  order: 0,
  padding: 0,
  maxTextureSize: 4096,
  borderScaleFactor: 2,
};

const parentDrawBorders = fabric.Object.prototype.drawBorders;
fabric.Object.prototype.drawBorders = function(
  ctx: CanvasRenderingContext2D,
  styleOverride: IStyleOverride
): fabric.Object {
  // this checks if the mtr control can be rendered within the canvas area and if the mt control falls above the canvas, then it changes the offsetY to draw it downwards
  if (
    this.type === 'background' &&
    this.controls.mtr &&
    this.controls.mtr.visible &&
    this.controls.mt &&
    this.oCoords &&
    this.oCoords.mt.y - rotationIconAbsoluteOffset < 0
  ) {
    this.controls.mtr.offsetY = rotationIconAbsoluteOffset;
  } else {
    this.controls.mtr.offsetY = -rotationIconAbsoluteOffset;
  }
  this.borderScaleFactor = defaultSettings.borderScaleFactor;
  styleOverride.borderColor = defaultSettings.borderColor;
  return parentDrawBorders.call(this, ctx, styleOverride);
};

fabric.Object.prototype.controls.innerRotateControl = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetY: innerRotationIconAbsoluteOffset,
  offsetX: 0,
  cursorStyle: `url("data:image/svg+xml;charset=utf-8,${rotateCursor}") 12 12, crosshair`,
  actionHandler: innerRotateObject,
  render: renderRotateIcon,
  withConnection: true,
  actionName: 'innerRotate'
});

fabric.Object.prototype.controls.panControl = new fabric.Control({
  x: 0,
  y: 0,
  offsetY: 0,
  offsetX: 0,
  cursorStyleHandler: panCursorStyleHandler,
  render: renderPanIcon,
  withConnection: true,
  getActionName: getPanActionName,
  getActionHandler: getPanActionHandler

});

fabric.Object.prototype.controls.mtr = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetX: 0,
  offsetY: -rotationIconAbsoluteOffset,
  cursorStyle: `url("data:image/svg+xml;charset=utf-8,${rotateCursor}") 12 12, crosshair`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionHandler: (fabric as any).controlsUtils.rotationWithSnapping,
  actionName: 'rotate',
  render: renderRotateIcon,
  withConnection: true
});

fabric.Object.prototype.controls.ml.render = renderCornerIcon;
fabric.Object.prototype.controls.mr.render = renderCornerIcon;
fabric.Object.prototype.controls.mb.render = renderCornerIcon;
fabric.Object.prototype.controls.mt.render = renderCornerIcon;
fabric.Object.prototype.controls.tl.render = renderCornerIcon;
fabric.Object.prototype.controls.tr.render = renderCornerIcon;
fabric.Object.prototype.controls.bl.render = renderCornerIcon;
fabric.Object.prototype.controls.br.render = renderCornerIcon;

fabric.Textbox.prototype.controls.mtr = fabric.Object.prototype.controls.mtr;
fabric.Textbox.prototype.controls.mr.render = renderCornerIcon;
fabric.Textbox.prototype.controls.ml.render = renderCornerIcon;

fabric.Textbox.prototype.setControlsVisibility({
  mb: false,
  bl: false,
  br: false,
  mt: false,
  tl: false,
  tr: false
});

// commented out for now... maybe needed later
/*function getRotateTopPosition(iconCurrentTopPosition: number): number {
  return iconCurrentTopPosition < 0
    ? -iconCurrentTopPosition
    : iconCurrentTopPosition;
}*/

function panCursorStyleHandler(): string {
  return 'move';
}

function getPanActionName(
  _: Event,
  __: fabric.Control,
  fabricObject: IEditorShape
): string {
  return fabricObject.photoObject && !fabricObject.photoObject.image ? 'drag' : 'pan';
}

function getPanActionHandler(
  _: Event,
  fabricObject: IEditorShape
): ControlMouseEventHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return fabricObject.photoObject && !fabricObject.photoObject.image ? (fabric as any).controlsUtils.dragHandler : () => {
    // do nothing
  };
}

function renderRotateIcon(
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  _: object,
  fabricObject: fabric.Object
) {
  const size = defaultSettings.rotateControlSize;
  // reposition the rotation icon for background object if it is above the canvas

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as number));
  ctx.drawImage(rotateControlImg, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function getLocalPoint(transform: ITransform, originX: string, originY: string, x: number, y: number) {
  const target = transform.target,
    control = target.controls[transform.corner],
    zoom = target.canvas?.getZoom(),
    padding = target.padding && zoom ? target.padding / zoom : 0,
    localPoint = target.toLocalPoint(new fabric.Point(x, y), originX, originY);
  if (localPoint.x >= padding) {
    localPoint.x -= padding;
  }
  if (localPoint.x <= -padding) {
    localPoint.x += padding;
  }
  if (localPoint.y >= padding) {
    localPoint.y -= padding;
  }
  if (localPoint.y <= padding) {
    localPoint.y += padding;
  }
  localPoint.x -= control.offsetX;
  localPoint.y -= control.offsetY;
  return localPoint;
}

export function scaleObject(eventData: Event, transform: ITransform, x: number, y: number, option?: { by: string }) {
  const target = transform.target;
  const centerPoint = target.getCenterPoint();
  const constraint = target.translateToOriginPoint(centerPoint, transform.originX, transform.originY);
  const lockScalingX: boolean | undefined = target.lockScalingX;
  const lockScalingY: boolean | undefined = target.lockScalingY;
  const by = option ? option.by : undefined;
  const scaleProportionally = scaleIsProportional(eventData, target);
  const forbidScaling = scalingIsForbidden(target, by, scaleProportionally);
  if (forbidScaling) {
    return false;
  }

  let newPoint;
  let scaleX;
  let scaleY;
  let dim;
  let signX;
  let signY;

  const gestureScale = transform.gestureScale;
  const opposite = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    center: 'center'
  };

  if (gestureScale) {
    scaleX = transform.scaleX * gestureScale;
    scaleY = transform.scaleY * gestureScale;
  } else {
    newPoint = getLocalPoint(transform, transform.originX, transform.originY, x, y);
    // use of sign: We use sign to detect change of direction of an action. sign usually change when
    // we cross the origin point with the mouse. So a scale flip for example. There is an issue when scaling
    // by center and scaling using one middle control ( default: mr, mt, ml, mb), the mouse movement can easily
    // cross many time the origin point and flip the object. so we need a way to filter out the noise.
    // This ternary here should be ok to filter out X scaling when we want Y only and vice versa.
    signX = by !== 'y' ? Math.sign(newPoint.x) : 1;
    signY = by !== 'x' ? Math.sign(newPoint.y) : 1;
    if (!transform.signX) {
      transform.signX = signX;
    }
    if (!transform.signY) {
      transform.signY = signY;
    }

    if (target.lockScalingFlip &&
      (transform.signX !== signX || transform.signY !== signY)
    ) {
      return false;
    }

    dim = target._getTransformedDimensions();
    // missing detection of flip and logic to switch the origin
    if (scaleProportionally && !by && target.scaleY && target.scaleX) {
      // uniform scaling
      const distance = Math.abs(newPoint.x) + Math.abs(newPoint.y),
        original = transform.original,
        originalDistance = Math.abs(dim.x * original.scaleX / target.scaleX) +
          Math.abs(dim.y * original.scaleY / target.scaleY),
        scale = distance / originalDistance;
      scaleX = original.scaleX * scale;
      scaleY = original.scaleY * scale;
    } else if (target.scaleX && target.scaleY) {
      scaleX = Math.abs(newPoint.x * target.scaleX / dim.x);
      scaleY = Math.abs(newPoint.y * target.scaleY / dim.y);
    }
    // if we are scaling by center, we need to double the scale
    if (scaleX !== undefined && scaleY !== undefined) {
      if (isTransformCentered(transform)) {
        scaleX *= 2;
        scaleY *= 2;
      }
      if (transform.signX !== signX && by !== 'y') {
        transform.originX = opposite[transform.originX] as 'right' | 'left';
        scaleX *= -1;
        transform.signX = signX;
      }
      if (transform.signY !== signY && by !== 'x') {
        transform.originY = opposite[transform.originY] as 'top' | 'bottom';
        scaleY *= -1;
        transform.signY = signY;
      }
    }
  }

  let scaled = false;
  if (!by && target.width && scaleX) {
    if (!lockScalingX) {
      scaled = true;
      target.set('width', target.width * scaleX);
    }
    if (!lockScalingY && target.height && scaleY) {
      scaled = true;
      target.set('height', target.height * scaleY);
    }
    if (target instanceof PhotoGroup) {
      (target as IEditorShape).photoObject.elementAdded = false;
    }


  } else {
    // forbidden cases already handled on top here.

    if (by === 'x' && target.width && scaleX) {
      scaled = true;
      target.set('width', target.width * scaleX);
    }
    if (by === 'y' && target.height && scaleY) {
      scaled = true;
      target.set('height', target.height * scaleY);
    }
    if (target instanceof PhotoGroup) {
      (target as IEditorShape).photoObject.elementAdded = false;
    }
  }
  if (scaled) {
    target.setPositionByOrigin(constraint, transform.originX, transform.originY);
  }
  return scaled;
}

function isTransformCentered(transform: ITransform) {
  const originX = transform.originX as string, originY = transform.originY as string;
  return originX === 'center' && originY === 'center';
}

function scaleIsProportional(eventData: Event, fabricObject: fabric.Object): boolean {

  const canvas = fabricObject.canvas as IEditorCanvas;
  const uniScaleKey = canvas.uniScaleKey;
  const uniformIsToggled = eventData[uniScaleKey as unknown as keyof typeof eventData];

  return !!((canvas.uniformScaling && !uniformIsToggled) ||
    (!canvas.uniformScaling && uniformIsToggled));
}

function scalingIsForbidden(fabricObject: fabric.Object, by: string | undefined, scaleProportionally: boolean) {
  const lockX = fabricObject.lockScalingX;
  const lockY = fabricObject.lockScalingY;
  return !!(
    (lockX && lockY)
    || (!by && (lockX || lockY) && scaleProportionally)
    || (lockX && by === 'x')
    || (lockY && by === 'y')
  );

}

function innerRotateObject(
  _: Event,
  transform: ITransform,
  x: number,
  y: number
): boolean {
  if ((transform.target as IEditorShape).photoObject) {
    const lastAngle = Math.atan2(
      transform.ey - transform.original.top,
      transform.ex - transform.original.left
    );
    const curAngle = Math.atan2(
      y - transform.original.top,
      x - transform.original.left
    );
    const radians = curAngle - lastAngle + transform.innerTheta;
    let angle = fabric.util.radiansToDegrees(radians);

    // normalize angle to -180 180 value
    if (angle > 180) {
      angle -= 360;
    } else if (angle < -180) {
      angle += 360;
    }

    (transform.target as IEditorShape).innerRotate(angle);
    return true;
  }

  return false;
}

function renderCornerIcon(
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  _: object,
  fabricObject: fabric.Object
) {
  const size = defaultSettings.controlSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as number));
  ctx.drawImage(cornerControlImg, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function renderPanIcon(
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  _: object,
  fabricObject: IEditorShape
) {
  if (fabricObject.photoObject && fabricObject.photoObject.image) {
    const size = defaultSettings.panControlSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle as number));
    ctx.drawImage(panControlImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }
}
const objectSet = fabric.Object.prototype._set;
fabric.Object.prototype.objectCaching = false;
fabric.Object.prototype.set(defaultSettings);
const setCoords = fabric.Object.prototype.setCoords;
fabric.Object.prototype.setCoords = function(
  skipCorners?: boolean
): fabric.Object {
  // call parent setCoords, which also calls setCornerCoords
  setCoords.call(this, skipCorners);
  // set coordinates for inner rotate and pan icon
  const coords = this.oCoords as IEditorOCoords;
  const theta = fabric.util.degreesToRadians(this.angle as number);
  const sinTh = Math.sin(theta);
  const cosTh = Math.cos(theta);

  if (coords) {
    if (!skipCorners) {
      coords.ir = new fabric.Point(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        coords.mt.x + sinTh * (this as any).innerRotateOffset,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        coords.mt.y - cosTh * (this as any).innerRotateOffset
      );
    }
    coords.pan = new fabric.Point(
      coords.tl.x + (coords.br.x - coords.tl.x) / 2,
      coords.tl.y + (coords.br.y - coords.tl.y) / 2
    );
  }
  // set corner coords
  if (this.angle) {
    const newTheta = fabric.util.degreesToRadians((45 - this.angle) as number);
    let cornerHypotenuse =
      Math.sqrt(2 * Math.pow(this.cornerSize as number, 2)) / 2;
    let cosHalfOffset = cornerHypotenuse * Math.cos(newTheta);
    let sinHalfOffset = cornerHypotenuse * Math.sin(newTheta);

    if (!skipCorners && coords) {
      coords.ir.corner = {
        tl: {
          x: coords.ir.x - sinHalfOffset,
          y: coords.ir.y - cosHalfOffset
        },
        tr: {
          x: coords.ir.x + cosHalfOffset,
          y: coords.ir.y - sinHalfOffset
        },
        bl: {
          x: coords.ir.x - cosHalfOffset,
          y: coords.ir.y + sinHalfOffset
        },
        br: {
          x: coords.ir.x + sinHalfOffset,
          y: coords.ir.y + cosHalfOffset
        }
      };
    }

    // the pan icon has a different size
    // also add some padding 30 for circle 10 padding = 40
    cornerHypotenuse = Math.sqrt(2 * Math.pow(40, 2)) / 2;
    cosHalfOffset = cornerHypotenuse * Math.cos(newTheta);
    sinHalfOffset = cornerHypotenuse * Math.sin(newTheta);

    if (coords) {
      coords.pan.corner = {
        tl: {
          x: coords.pan.x - sinHalfOffset,
          y: coords.pan.y - cosHalfOffset
        },
        tr: {
          x: coords.pan.x + cosHalfOffset,
          y: coords.pan.y - sinHalfOffset
        },
        bl: {
          x: coords.pan.x - cosHalfOffset,
          y: coords.pan.y + sinHalfOffset
        },
        br: {
          x: coords.pan.x + sinHalfOffset,
          y: coords.pan.y + cosHalfOffset
        }
      };
    }
  }

  return this;
};

fabric.Object.prototype._set = function (key: string, value: unknown): fabric.Object {
  if (key === 'locked' || key === 'fixed') {
    this.set({
      _originalHoverCursor: this.hoverCursor,
      lockMovementX: value,
      lockMovementY: value,
      lockScalingX: value,
      lockScalingY: value,
      hoverCursor: value ? 'initial' : (this._originalHoverCursor || this.hoverCursor)
    });
  }

  return objectSet.call(this, key, value);
};

const PostRenderer = {
  renderMask: function(): void {
    const editorCanvas = this as IEditorCanvas;
    const ctx = editorCanvas.getContext();
    const zoom = editorCanvas.getZoom();
    const canvasWidth = editorCanvas.width as number;
    const canvasHeight = editorCanvas.height as number;
    const width = canvasWidth - 2 * editorCanvas.marginLeft;
    const height = canvasHeight - 2 * editorCanvas.marginTop;
    const mask = editorCanvas.mask;
    const defaultCompositeOperation = ctx.globalCompositeOperation;
    if (mask) {
      ctx.save();
      ctx.globalCompositeOperation = 'destination-out';
      ctx.drawImage(mask.maskImage.getElement(),
        editorCanvas.marginLeft - mask.marginLeft * zoom,
        editorCanvas.marginTop - mask.marginTop * zoom,
        width + (mask.marginLeft + mask.marginRight) * zoom,
        height + (mask.marginTop + mask.marginBottom) * zoom);
      ctx.globalCompositeOperation = defaultCompositeOperation;
      ctx.restore();
    }
  },
  renderCanvasCorner: function (): void {
    const canvas = this as unknown as IEditorCanvas;
    const wrap = canvas.wrap;
    const ctx = canvas.getSelectionContext();
    ctx.save();
    const zoom = canvas.getZoom();
    const editorContainer = document.querySelector('.editor-canvas-container');
    const color = editorContainer
      ? getComputedStyle(editorContainer).backgroundColor
      : 'white'; // This is bad! find a way to pass the background color!
    // draw wrap
    if (!wrap || !wrap[WrapOptions.CORNER_TYPE]) {
      return;
    }

    const position = getPosition(wrap, zoom);
    const height = Math.round((wrap.height as number) * zoom);
    const width = Math.round((wrap.width as number) * zoom);
    let edges: number[][] = [];

    const originalFillStyle = ctx.fillStyle;
    // first, white out area
    ctx.fillStyle = color;
    fillRect(-1, -1, position.left + 1, height + 2, ctx, canvas);
    fillRect(
      width - position.right,
      -1,
      position.right + 1,
      height + 2,
      ctx,
      canvas
    );
    fillRect(
      position.left,
      -1,
      width - position.left - position.right,
      position.top + 1,
      ctx,
      canvas
    );
    fillRect(
      position.left,
      height - position.bottom,
      width - position.left - position.right,
      position.bottom + 1,
      ctx,
      canvas
    );

    // any further drawing should be subtractive
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = color;
    ctx.globalAlpha = 0.6;

    // draw wrap
    fillRect(
      position.lBack,
      position.top,
      position.lSide - 1,
      height - position.top - position.bottom,
      ctx,
      canvas
    );
    fillRect(
      width - position.right + 1,
      position.top,
      position.rSide - 1,
      height - position.top - position.bottom,
      ctx,
      canvas
    );
    fillRect(
      position.left,
      position.tBack,
      width - position.left - position.right,
      position.tSide - 1,
      ctx,
      canvas
    );
    fillRect(
      position.left,
      height - position.bottom + 1,
      width - position.left - position.right,
      position.bSide - 1,
      ctx,
      canvas
    );

    // draw bleed
    switch (wrap[WrapOptions.CORNER_TYPE]) {
      case CornerTypes.HORIZONTAL_FOLD:
        edges = [
          // left
          [position.left, height - position.bottom],
          [0, height - position.bottom],
          [0, position.top],
          [position.left, position.top],

          // top
          [position.left, position.tBack],
          [position.left + position.lBack, position.tBack],
          [position.left + position.lBack, 0],
          [width - position.right - position.rBack, 0],
          [width - position.right - position.rBack, position.tBack],
          [width - position.right, position.tBack],
          [width - position.right, position.top],

          // right
          [width, position.top],
          [width, height - position.bottom],
          [width - position.right, height - position.bottom],

          // bottom
          [width - position.right, height - position.bBack],
          [width - position.right - position.rBack, height - position.bBack],
          [width - position.right - position.rBack, height],
          [position.left + position.lBack, height],
          [position.left + position.lBack, height - position.bBack],
          [position.left, height - position.bBack],
          [position.left, height - position.bottom]
        ];

        fillRect(
          0,
          position.top,
          position.lBack - 1,
          height - position.top - position.bottom,
          ctx,
          canvas
        );
        fillRect(
          width - position.rBack + 1,
          position.top,
          position.rBack - 1,
          height - position.top - position.bottom,
          ctx,
          canvas
        );
        fillRect(
          position.right + position.lBack,
          0,
          width -
          position.left -
          position.right -
          position.lBack -
          position.rBack,
          position.tBack - 1,
          ctx,
          canvas
        );
        fillRect(
          position.right + position.lBack,
          height - position.bBack + 1,
          width -
          position.left -
          position.right -
          position.lBack -
          position.rBack,
          position.tBack - 1,
          ctx,
          canvas
        );
        break;

      case CornerTypes.VERTICAL_FOLD:
        edges = [
          // left
          [position.left, height - position.bottom],
          [position.lBack, height - position.bottom],
          [position.lBack, height - position.bottom - position.bBack],
          [0, height - position.bottom - position.bBack],
          [0, position.top + position.tBack],
          [position.lBack, position.top + position.tBack],
          [position.lBack, position.top],
          [position.left, position.top],

          // top
          [position.left, 0],
          [width - position.right, 0],
          [width - position.right, position.top],

          // right
          [width - position.rBack, position.top],
          [width - position.rBack, position.top + position.tBack],
          [width, position.top + position.tBack],
          [width, height - position.bottom - position.bBack],
          [width - position.rBack, height - position.bottom - position.bBack],
          [width - position.rBack, height - position.bottom],
          [width - position.right, height - position.bottom],

          // bottom
          [width - position.right, height],
          [position.left, height],
          [position.left, height - position.bottom]
        ];

        fillRect(
          0,
          position.top + position.tBack,
          position.lBack - 1,
          height -
          position.top -
          position.bottom -
          position.tBack -
          position.bBack,
          ctx,
          canvas
        );
        fillRect(
          width - position.rBack + 1,
          position.top + position.tBack,
          position.rBack - 1,
          height -
          position.top -
          position.bottom -
          position.tBack -
          position.bBack,
          ctx,
          canvas
        );
        fillRect(
          position.right,
          0,
          width - position.left - position.right,
          position.tBack - 1,
          ctx,
          canvas
        );
        fillRect(
          position.right,
          height - position.bBack + 1,
          width - position.left - position.right,
          position.tBack - 1,
          ctx,
          canvas
        );
        break;

      case CornerTypes.DIAGONAL_FOLD:
        edges = [
          [position.lBack, height - position.bottom],
          [0, height - position.bottom - position.bBack],
          [0, position.top + position.tBack], // top left
          [position.lBack, position.top], // top right

          // top
          [position.left, position.top],
          [position.left, position.tBack],
          [position.left + position.lBack, 0],
          [width - position.right - position.rBack, 0],
          [width - position.right, position.tBack],
          [width - position.right, position.top],

          // right
          [width - position.rBack, position.top],
          [width, position.top + position.tBack],
          [width, height - position.bottom - position.bBack],
          [width - position.rBack, height - position.bottom],
          [width - position.right, height - position.bottom],

          // bottom
          [width - position.right, height - position.bBack],
          [width - position.right - position.rBack, height],
          [position.left + position.lBack, height],
          [position.left, height - position.bBack],
          [position.left, height - position.bottom]
        ];

        // left
        ctx.beginPath();
        moveTo(edges[2][0], edges[2][1], ctx, canvas);
        lineTo(edges[3][0] - 1, edges[3][1], ctx, canvas);
        lineTo(edges[0][0] - 1, edges[0][1], ctx, canvas);
        lineTo(edges[1][0], edges[1][1], ctx, canvas);
        ctx.closePath();
        ctx.fill();

        // right
        ctx.beginPath();
        moveTo(width - 1, position.top + position.tBack, ctx, canvas);
        lineTo(width - position.rBack + 1, position.top, ctx, canvas);
        lineTo(
          width - position.rBack + 1,
          height - position.bottom,
          ctx,
          canvas
        );
        lineTo(
          width - 1,
          height - position.bottom - position.bBack,
          ctx,
          canvas
        );
        ctx.closePath();
        ctx.fill();

        // top
        ctx.beginPath();
        moveTo(position.left, position.tBack - 1, ctx, canvas);
        lineTo(position.left + position.lBack, 0, ctx, canvas);
        lineTo(width - position.right - position.rBack, 0, ctx, canvas);
        lineTo(width - position.right, position.tBack - 1, ctx, canvas);
        ctx.closePath();
        ctx.fill();

        // bottom
        ctx.beginPath();
        moveTo(position.left, height - position.bBack + 1, ctx, canvas);
        lineTo(position.left + position.lBack, height - 1, ctx, canvas);
        lineTo(
          width - position.right - position.rBack,
          height - 1,
          ctx,
          canvas
        );
        lineTo(
          width - position.right,
          height - position.bBack + 1,
          ctx,
          canvas
        );
        ctx.closePath();
        ctx.fill();

        break;
      default:
        return;
    }

    // finally, draw the border
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.globalCompositeOperation = 'source-over';

    ctx.beginPath();
    moveTo(edges[0][0], edges[0][1], ctx, canvas);

    for (let i = 1; i < edges.length; i++) {
      lineTo(edges[i][0], edges[i][1], ctx, canvas);
    }

    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = originalFillStyle;
    ctx.restore();
  },

  render: function(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const editorCanvas = this as IEditorCanvas;
    const wrap = editorCanvas.wrap;

    if (editorCanvas.overlayImage) {
      const upperContext = editorCanvas.contextTop || editorCanvas.contextContainer;
      editorCanvas._renderOverlay(upperContext);
    }
    if (wrap) {
      const ctx = editorCanvas.getContext();
      const zoom = editorCanvas.getZoom();
      const marginTop = editorCanvas.marginTop;
      const marginLeft = editorCanvas.marginLeft;

      const position = getPosition(wrap, zoom);

      const height = Math.round((wrap.height as number) * zoom);
      const width = Math.round((wrap.width as number) * zoom);

      const threeDpreviewBoard = editorCanvas.threeDpreview;

      ctx.save();

      if (
        wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.BORDER ||
        wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.BORDER_WHITE
      ) {
        drawBorder(
          ctx,
          wrap[WrapOptions.EDGE_COLOR] as string,
          marginLeft,
          marginTop,
          position.left,
          position.top,
          position.bottom,
          position.right,
          width,
          height
        );
      } else if (wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.MIRROR_WRAP) {
        drawMirror(
          ctx,
          marginLeft,
          marginTop,
          position.left,
          position.top,
          position.bottom,
          position.right,
          width,
          height,
          threeDpreviewBoard
        );
      } else if (
        wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.MIRROR_WRAP_WITH_BLUR
      ) {
        if (editorCanvas.objectsToLoad == 0) {
          drawMirrorWithBlur(
            ctx,
            marginLeft,
            marginTop,
            position.left,
            position.top,
            position.bottom,
            position.right,
            width,
            height,
            threeDpreviewBoard
          );
        }
      } else if (
        wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.IMAGE_WRAP_WITH_BLUR
      ) {
        drawImageWrapWithBlur(
          ctx,
          marginLeft,
          marginTop,
          position.left,
          position.top,
          position.bottom,
          position.right,
          width,
          height,
          threeDpreviewBoard
        );
      } else if (wrap[WrapOptions.EDGE_STYLE] === EdgeStyles.EXTEND) {
        if (editorCanvas.objectsToLoad == 0) {
          drawExtend(
            ctx,
            marginLeft,
            marginTop,
            position.left,
            position.top,
            position.bottom,
            position.right,
            width,
            height,
            threeDpreviewBoard
          );
        }
      }
      ctx.restore();
    }
  }
};

function getPosition(wrap: IWrap, zoom: number): IPosition {
  const lSide = Math.round(
    (wrap[ProductAttribute.BLEED_SIDE_LEFT] as number) * zoom
  );
  const rSide = Math.round(
    (wrap[ProductAttribute.BLEED_SIDE_RIGHT] as number) * zoom
  );
  const tSide = Math.round(
    (wrap[ProductAttribute.BLEED_SIDE_TOP] as number) * zoom
  );
  const bSide = Math.round(
    (wrap[ProductAttribute.BLEED_SIDE_BOTTOM] as number) * zoom
  );

  // back side (bleed)
  const lBack = Math.round(
    (wrap[ProductAttribute.BLEED_BACK_LEFT] as number) * zoom
  );
  const rBack = Math.round(
    (wrap[ProductAttribute.BLEED_BACK_RIGHT] as number) * zoom
  );
  const tBack = Math.round(
    (wrap[ProductAttribute.BLEED_BACK_TOP] as number) * zoom
  );
  const bBack = Math.round(
    (wrap[ProductAttribute.BLEED_BACK_BOTTOM] as number) * zoom
  );

  // aggregate
  return {
    left: lSide + lBack,
    top: tSide + tBack,
    right: rSide + rBack,
    bottom: bSide + bBack,
    lSide: lSide,
    lBack: lBack,
    tSide: tSide,
    tBack: tBack,
    bSide: bSide,
    rBack: rBack,
    bBack: bBack,
    rSide: rSide
  };
}

function copyCanvas(canvas: HTMLCanvasElement): HTMLCanvasElement {
  const copiedCanvas = fabric.util.createCanvasElement();
  copiedCanvas.width = canvas.width;
  copiedCanvas.height = canvas.height;
  const ctx = copiedCanvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);
  }
  return copiedCanvas;
}

function disposeCopiedCanvas(canvas: HTMLCanvasElement): void {
  canvas.width = 0;
  canvas.height = 0;
  // (canvas as any) = null; -- Setting the local canvas variable to null does nothing...
}

function drawBorder(
  ctx: CanvasRenderingContext2D,
  color: string,
  marginLeft: number,
  marginTop: number,
  left: number,
  top: number,
  bottom: number,
  right: number,
  width: number,
  height: number
): void {
  ctx.fillStyle = color;
  ctx.fillRect(marginLeft, marginTop, left, height);
  ctx.fillRect(marginLeft + width - right, marginTop, right, height);
  ctx.fillRect(marginLeft + left, marginTop, width - left - right, top);
  ctx.fillRect(
    marginLeft + left,
    marginTop + height - bottom,
    width - left - right,
    bottom
  );
}

function drawMirror(
  ctx: CanvasRenderingContext2D,
  marginLeft: number,
  marginTop: number,
  left: number,
  top: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
  threeDpreview: boolean
): void {
  // setTransform resets all the previous transformations. We need to restore device pixel ratio after setTransform.
  const copiedCanvas = copyCanvas(ctx.canvas);

  // if threeDpreview is true it means that we are called from the renderer class (used for drawing 3D preview).
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ratio = threeDpreview ? 1 : devicePixelRatio;

  ctx.setTransform(-1, 0, 0, 1, 0, 0);
  // left and right
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + left) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio,
    (-marginLeft - left) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + width - right - left) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio,
    (-marginLeft - width) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio
  );

  // top and bottom
  ctx.setTransform(1, 0, 0, -1, 0, 0);
  ctx.drawImage(
    copiedCanvas,
    marginLeft * ratio,
    (marginTop + top) * ratio,
    width * ratio,
    top * ratio,
    marginLeft * ratio,
    (-marginTop - top) * ratio,
    width * ratio,
    top * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    marginLeft * ratio,
    (marginTop + height - top - bottom) * ratio,
    width * ratio,
    top * ratio,
    marginLeft * ratio,
    (-marginTop - height) * ratio,
    width * ratio,
    top * ratio
  );
  disposeCopiedCanvas(copiedCanvas);
}

function drawMirrorWithBlur(
  ctx: CanvasRenderingContext2D,
  marginLeft: number,
  marginTop: number,
  left: number,
  top: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
  threeDpreview: boolean
): void {
  const filters: IBaseFilter[] = [];
  filters.push(new fabric.Image.filters.Blur({blur: 0.05}));
  const copiedCanvas: HTMLCanvasElement = copyCanvas(ctx.canvas);
  const originalFabricTextureSize = fabric.textureSize;
  let textureSizeChanged = false;
  if (fabric.textureSize < Math.max(copiedCanvas.width, copiedCanvas.height)) {
    fabric.textureSize = defaultSettings.maxTextureSize;
    textureSizeChanged = true;
    if (fabric.filterBackend) {
      fabric.filterBackend.dispose();
      fabric.filterBackend = undefined;
    }
  }
  if (!fabric.filterBackend) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fabric.filterBackend = (fabric as any).initFilterBackend();
  }
  if (fabric.filterBackend) {
    fabric.filterBackend.applyFilters(
      filters,
      copiedCanvas,
      copiedCanvas.width,
      copiedCanvas.height,
      copiedCanvas
    );
  }
  // setTransform resets all the previous transformations. We need to restore device pixel ratio after setTransform.
  let ratio;
  // if threeDpreview is true it means that we are called from the renderer class (used for drawing 3D preview).
  if (threeDpreview) {
    ratio = 1;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ratio = devicePixelRatio;
  }

  const padding = left * 0.05;
  ctx.save();
  // left and right
  ctx.setTransform(-1, 0, 0, 1, 0, 0);
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + left) * ratio + padding,
    marginTop * ratio + padding,
    left * ratio - padding * 2,
    height * ratio - padding * 2,
    (-marginLeft - left) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + width - right - left) * ratio + padding,
    marginTop * ratio + padding,
    left * ratio - padding * 2,
    height * ratio - padding * 2,
    (-marginLeft - width) * ratio,
    marginTop * ratio,
    left * ratio,
    height * ratio
  );

  // top and bottom
  ctx.setTransform(1, 0, 0, -1, 0, 0);
  ctx.drawImage(
    copiedCanvas,
    marginLeft * ratio + padding,
    (marginTop + top) * ratio + padding,
    width * ratio - padding * 2,
    top * ratio - padding * 2,
    marginLeft * ratio,
    (-marginTop - top) * ratio,
    width * ratio,
    top * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    marginLeft * ratio + padding,
    (marginTop + height - top - bottom) * ratio + padding,
    width * ratio - padding * 2,
    top * ratio - padding * 2,
    marginLeft * ratio,
    (-marginTop - height) * ratio,
    width * ratio,
    top * ratio
  );
  ctx.restore();
  if (textureSizeChanged) {
    fabric.textureSize = originalFabricTextureSize;
    if (fabric.filterBackend) {
      fabric.filterBackend.dispose();
    }
    fabric.filterBackend = undefined;
  }
}

function drawImageWrapWithBlur(
  ctx: CanvasRenderingContext2D,
  marginLeft: number,
  marginTop: number,
  left: number,
  top: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
  threeDpreview: boolean
): void {
  const filters: IBaseFilter[] = [];
  filters.push(new fabric.Image.filters.Blur({blur: 0.5}));
  const copiedCanvas: HTMLCanvasElement = copyCanvas(ctx.canvas);
  const originalFabricTextureSize = fabric.textureSize;
  let textureSizeChanged = false;
  if (fabric.textureSize < Math.max(copiedCanvas.width, copiedCanvas.height)) {
    fabric.textureSize = defaultSettings.maxTextureSize;
    textureSizeChanged = true;
    if (fabric.filterBackend) {
      fabric.filterBackend.dispose();
      fabric.filterBackend = undefined;
    }
  }
  if (!fabric.filterBackend) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fabric.filterBackend = (fabric as any).initFilterBackend();
  }
  if (fabric.filterBackend) {
    fabric.filterBackend.applyFilters(
      filters,
      copiedCanvas,
      copiedCanvas.width,
      copiedCanvas.height,
      copiedCanvas
    );
  }
  let ratio;
  // if threeDpreview it means that we are called from the renderer class (used for drawing 3D preview).
  if (threeDpreview) {
    ratio = 1;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ratio = devicePixelRatio;
  }
  // left and right
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.drawImage(
    copiedCanvas,
    marginLeft * ratio,
    (top + marginTop) * ratio,
    left * ratio,
    (height - bottom - top) * ratio,
    marginLeft * ratio,
    (top + marginTop) * ratio,
    left * ratio,
    (height - bottom - top) * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    (width + marginLeft - right) * ratio,
    (top + marginTop) * ratio,
    right * ratio,
    (height - bottom - top) * ratio,
    (width + marginLeft - right) * ratio,
    (top + marginTop) * ratio,
    right * ratio,
    (height - bottom - top) * ratio
  );

  // top and bottom
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + left) * ratio,
    marginTop * ratio,
    (width - left - right) * ratio,
    top * ratio,
    (marginLeft + left) * ratio,
    marginTop * ratio,
    (width - left - right) * ratio,
    top * ratio
  );
  ctx.drawImage(
    copiedCanvas,
    (marginLeft + left) * ratio,
    (marginTop + height - bottom) * ratio,
    (width - left - right) * ratio,
    bottom * ratio,
    (marginLeft + left) * ratio,
    (marginTop + height - bottom) * ratio,
    (width - left - right) * ratio,
    bottom * ratio
  );
  if (textureSizeChanged) {
    fabric.textureSize = originalFabricTextureSize;
    if (fabric.filterBackend) {
      fabric.filterBackend.dispose();
    }
    fabric.filterBackend = undefined;
  }
}

// We are picking 3 pixels for the extend
function drawExtend(
  ctx: CanvasRenderingContext2D,
  marginLeft: number,
  marginTop: number,
  left: number,
  top: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
  threeDpreview: boolean
): void {
  let ratio;
  // if threeDpreview it means that we are called from the renderer class (used for drawing 3D preview).
  if (threeDpreview) {
    ratio = 1;
  } else {
    ratio = devicePixelRatio;
  }
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  // left and right
  ctx.drawImage(
    ctx.canvas,
    (marginLeft + left + 1) * ratio,
    (top + marginTop) * ratio,
    3 * ratio,
    (height - bottom - top) * ratio,
    marginLeft * ratio,
    (top + marginTop) * ratio,
    left * ratio,
    (height - bottom - top) * ratio
  );
  ctx.drawImage(
    ctx.canvas,
    (width + marginLeft - right - 4) * ratio,
    (top + marginTop) * ratio,
    3 * ratio,
    (height - bottom - top) * ratio,
    (width + marginLeft - right) * ratio,
    (top + marginTop) * ratio,
    right * ratio,
    (height - bottom - top) * ratio
  );

  // top and bottom
  ctx.drawImage(
    ctx.canvas,
    (marginLeft + left) * ratio,
    (marginTop + top + 1) * ratio,
    (width - left - right) * ratio,
    3 * ratio,
    (marginLeft + left) * ratio,
    marginTop * ratio,
    (width - left - right) * ratio,
    top * ratio
  );
  ctx.drawImage(
    ctx.canvas,
    (marginLeft + left) * ratio,
    (marginTop + height - bottom - 4) * ratio,
    (width - left - right) * ratio,
    3 * ratio,
    (marginLeft + left) * ratio,
    (marginTop + height - bottom) * ratio,
    (width - left - right) * ratio,
    bottom * ratio
  );
}

function fillRect(
  x: number,
  y: number,
  width: number,
  height: number,
  ctx: CanvasRenderingContext2D,
  canvas: IEditorCanvas
): void {
  const mTop = canvas.marginTop;
  const mLeft = canvas.marginLeft;
  ctx.fillRect(mLeft + x, mTop + y, width, height);
}

function lineTo(
  x: number,
  y: number,
  ctx: CanvasRenderingContext2D,
  canvas: IEditorCanvas
): void {
  const mTop = canvas.marginTop;
  const mLeft = canvas.marginLeft;
  ctx.lineTo(mLeft + x, mTop + y);
}

function moveTo(
  x: number,
  y: number,
  ctx: CanvasRenderingContext2D,
  canvas: IEditorCanvas
): void {
  const mTop = canvas.marginTop;
  const mLeft = canvas.marginLeft;
  ctx.moveTo(mLeft + x, mTop + y);
}

(fabric.util as IFabricUtil).hasStyleChanged = function(prevStyle: TextOptions, thisStyle: TextOptions, forTextSpans: boolean) {
  forTextSpans = forTextSpans || false;
  return (prevStyle.fill !== thisStyle.fill ||
      prevStyle.stroke !== thisStyle.stroke ||
      prevStyle.strokeWidth !== thisStyle.strokeWidth ||
      prevStyle.fontSize !== thisStyle.fontSize ||
      prevStyle.fontFamily !== thisStyle.fontFamily ||
      prevStyle.fontWeight !== thisStyle.fontWeight ||
      prevStyle.fontStyle !== thisStyle.fontStyle ||
      prevStyle.textBackgroundColor !== thisStyle.textBackgroundColor ||
      prevStyle.textAlign !== thisStyle.textAlign ||
      prevStyle.deltaY !== thisStyle.deltaY ||
      prevStyle.lineHeight !== thisStyle.lineHeight ||
      prevStyle.charSpacing !== thisStyle.charSpacing) ||
    (forTextSpans &&
      (prevStyle.overline !== thisStyle.overline ||
        prevStyle.underline !== thisStyle.underline ||
        prevStyle.linethrough !== thisStyle.linethrough));
};
