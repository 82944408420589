import { fabric } from 'fabric';
import {
  IEditorCanvas,
  IEditorElement,
  IEditorImagePosition, IEditorPage,
  IEditorShape,
  IFreeObject, IPage, IPageEdits, Orientation
} from '@/interfaces/editorInterfaces';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { IBleed, IProjectInfo } from '@/interfaces/projectInterface';
import { EdgeStyles, WrapOptions } from '@/modules/editor/editorConstants';
import { IProductInfo } from '@/interfaces/baseInterface';

export class Helpers {
  public static boundingBox(
    angle: number,
    width: number,
    height: number,
    left: number,
    top: number,
    marginLeft: number,
    marginTop: number,
    marginRight: number,
    marginBottom: number
  ): number[] {
    left = left || 0;
    top = top || 0;
    marginLeft = marginLeft || 0;
    marginTop = marginTop || 0;
    marginRight = marginRight || 0;
    marginBottom = marginBottom || 0;

    const innerX = -(width / 2) - left;
    const innerY = -(height / 2) - top;

    // calculate angle (counter rotate inner window)
    const t = fabric.util.degreesToRadians(-angle);
    const cos = Math.cos(t);
    const sin = Math.sin(t);

    // calculate each of the points of the inner window
    const sInnerY = -sin * (innerY + marginTop);
    const cInnerY = cos * (innerY + marginTop);
    const sOuterY = -sin * (innerY + height - marginBottom);
    const cOuterY = cos * (innerY + height - marginBottom);

    const cInnerX = cos * (innerX + marginLeft);
    const sInnerX = sin * (innerX + marginLeft);
    const cOuterX = cos * (innerX + width - marginRight);
    const sOuterX = sin * (innerX + width - marginRight);

    const x = [
      sInnerY + cInnerX,
      sInnerY + cOuterX,
      sOuterY + cInnerX,
      sOuterY + cOuterX,
    ];
    const y = [
      cInnerY + sInnerX,
      cInnerY + sOuterX,
      cOuterY + sInnerX,
      cOuterY + sOuterX,
    ];

    return [
      this.round(Math.min(...x), -2),
      this.round(Math.max(...x), -2),
      this.round(Math.min(...y), -2),
      this.round(Math.max(...y), -2),
    ];
  }

  public static round(value: number, exp?: number): number {
    return Helpers.decimalAdjust('round', value, exp);
  }

  public static zoomToFit(
    innerWidth: number,
    innerHeight: number,
    outerWidth: number,
    outerHeight: number
  ): number {
    return innerWidth / innerHeight > outerWidth / outerHeight
      ? outerWidth / innerWidth
      : outerHeight / innerHeight;
  }

  public static resize (
    newWidth: number,
    newHeight: number,
    existingWidth: number,
    existingHeight: number
    ): { width: number; height: number } {
    const returnSize = { width: newWidth, height: newHeight };

    let multiplier = newWidth / existingWidth;

    if (existingHeight * multiplier >= newHeight) {
      returnSize.height = Math.round(existingHeight * multiplier);
    } else {
      multiplier = newHeight / existingHeight;
      returnSize.width = Math.round(existingWidth * multiplier);
    }

    return returnSize;
  }

  public static clamp(low: number, value: number, high: number): number {
    return Math.max(low, Math.min(value, high));
  }

  private static decimalAdjust(
    type: 'round',
    value: number,
    exp?: number | string
  ): number {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;

    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    let arrValue = value.toString().split('e');
    value = Math[type](
      +(arrValue[0] + 'e' + (arrValue[1] ? +arrValue[1] - exp : -exp))
    );
    // Shift back
    arrValue = value.toString().split('e');
    return +(arrValue[0] + 'e' + (arrValue[1] ? +arrValue[1] + exp : exp));
  }

  public static rotateImagePosition(
    imagePosition: IEditorImagePosition,
    rotation: number
  ): void {
    const widthOffset = (imagePosition.width * imagePosition.zoom) / 2;
    const heightOffset = (imagePosition.height * imagePosition.zoom) / 2;

    const rPosition = this.rotatePoint(
      imagePosition.x + widthOffset,
      imagePosition.y + heightOffset,
      rotation
    );

    // update x and y with difference
    imagePosition.x = rPosition[0] - widthOffset;
    imagePosition.y = rPosition[1] - heightOffset;
  }

  public static rotatePoint(x: number, y: number, rotation: number): number[] {
    if (rotation) {
      const radians = fabric.util.degreesToRadians(rotation);
      const sin = Math.sin(radians);
      const cos = Math.cos(radians);

      return [x * cos - y * sin, x * sin + y * cos];
    }

    return [x, y];
  }

  public static calculateEditableAreaMarginsFromLayerPreview(layerPreviewDataWidth: number, pageWidth: number, layerPreviewDataMargins: {topMargin: number, bottomMargin: number, leftMargin: number, rightMargin: number}): {extraSpaceTop: number, extraSpaceBottom: number, extraSpaceLeft: number, extraSpaceRight: number} {
    const widthOfEditableAreaInPreviewImage = layerPreviewDataWidth - layerPreviewDataMargins.leftMargin - layerPreviewDataMargins.rightMargin;
    const scale = widthOfEditableAreaInPreviewImage ? pageWidth / widthOfEditableAreaInPreviewImage : 0;
    return {
      extraSpaceBottom: layerPreviewDataMargins.bottomMargin * scale,
      extraSpaceTop: layerPreviewDataMargins.topMargin * scale,
      extraSpaceLeft: layerPreviewDataMargins.leftMargin * scale,
      extraSpaceRight: layerPreviewDataMargins.rightMargin * scale
    };
  }

  public static resizeAndClipCanvas(
    editorCanvas: IEditorCanvas,
    canvasContainerWidth: number,
    canvasContainerHeight: number,
    width: number,
    height: number,
    padding: number,
    extraSpace: {extraSpaceTop: number, extraSpaceBottom: number, extraSpaceLeft: number, extraSpaceRight: number},
    drawCanvasBorder = false,
  ): void {
    padding *= 2;

    editorCanvas.setDimensions({
      width: canvasContainerWidth,
      height: canvasContainerHeight,
    });
    const scale = Helpers.zoomToFit(
      width + extraSpace.extraSpaceLeft + extraSpace.extraSpaceRight,
      height + extraSpace.extraSpaceTop + extraSpace.extraSpaceBottom,
      canvasContainerWidth - padding,
      canvasContainerHeight - padding
    );
    editorCanvas.setZoom(scale);
    const marginTop = Math.round((canvasContainerHeight - scale * (extraSpace.extraSpaceTop + extraSpace.extraSpaceBottom + height)) / 2 +  scale * extraSpace.extraSpaceTop);
    const marginLeft = Math.round((canvasContainerWidth - scale * (extraSpace.extraSpaceLeft + extraSpace.extraSpaceRight + width)) / 2 +  scale * extraSpace.extraSpaceLeft);

    if (editorCanvas.viewportTransform) {
      editorCanvas.viewportTransform[4] = marginLeft;
      editorCanvas.viewportTransform[5] = marginTop;
    }
    editorCanvas.marginLeft = marginLeft;
    editorCanvas.marginTop = marginTop;
    editorCanvas.calcViewportBoundaries();
    // couldn't get the new clipPath in fabricjs to work so using this instead. They also mentioned that clipPath is not efficient compared to their old clipTo
    editorCanvas.clipTo = function (ctx: CanvasRenderingContext2D): void {
      if (drawCanvasBorder) {
        const dashPattern = [4, 1];
        const strokeStyles = ['black', 'white'];
        Helpers.drawDashedRectWithColorDashes(ctx, marginLeft, marginTop, scale * width, scale * height, dashPattern, strokeStyles);
      }
      ctx.rect(marginLeft, marginTop, scale * width, scale * height);
    };
  }

  private static drawDashedRectWithColorDashes(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height:number, dashPattern: number[], dashColors: string[]) {
    let colorIndex = 0;
    // Save the current drawing state
    ctx.save();
    const flooredWidth = Math.floor(width);
    const flooredHeight = Math.floor(height);

    // Loop through each dash along the perimeter of the rectangle
    ctx.setLineDash(dashPattern);
    for (let i = x; i < x + flooredWidth; i += dashPattern[0] + dashPattern[1]) {
      // Set the stroke color to the current color index
      ctx.strokeStyle = dashColors[colorIndex];
      colorIndex = (colorIndex + 1) % dashColors.length;

      // Draw a horizontal dash along the top side of the rectangle
      ctx.beginPath();
      ctx.moveTo(i, y);
      ctx.lineTo(Math.min(i + dashPattern[0], x + flooredWidth), y);
      ctx.stroke();

      // Draw a horizontal dash along the bottom side of the rectangle
      ctx.beginPath();
      ctx.moveTo(x + flooredWidth - (i - x), y + flooredHeight);
      ctx.lineTo(Math.max(x + flooredWidth - (i - x) - dashPattern[0], x), y + flooredHeight);
      ctx.stroke();
    }

    for (let i = y; i < y + flooredHeight; i += dashPattern[0] + dashPattern[1]) {
      // Set the stroke color to the current color index
      ctx.strokeStyle = dashColors[colorIndex];
      colorIndex = (colorIndex + 1) % dashColors.length;

      // Draw a vertical dash along the right side of the rectangle
      ctx.beginPath();
      ctx.moveTo(x + flooredWidth, y + (i - y));
      ctx.lineTo(x + flooredWidth, Math.min(y + (i - y) + dashPattern[0], y + flooredHeight));
      ctx.stroke();

      // Draw a vertical dash along the left side of the rectangle
      ctx.beginPath();
      ctx.moveTo(x, y + flooredHeight - (i - y));
      ctx.lineTo(x, Math.max(y + flooredHeight - (i - y) - dashPattern[0], y));
      ctx.stroke();
    }
    ctx.restore();
    // Restore the original drawing state
  }


  public static toFreeObjects(fabricCanvas: IEditorCanvas): IFreeObject[] {
    const elements = fabricCanvas
      .getObjects()
      .map((shape) =>
        EditorTranslator.Instance.toElement(shape as IEditorShape)
      );
    return elements.map((element: IEditorElement) =>
      EditorTranslator.Instance.fromElement(element)
    );
  }

  public static convertToSameAspectRatioProject(
    currentEdits: IPageEdits,
    newEdits: IPageEdits,
  ): void {
    const freeObjectsOnCurrentCanvas = currentEdits.freeObject;
    const currentBleed = currentEdits.bleed as IBleed;
    const newBleed = newEdits.bleed as IBleed;


    let previousLeftRightWrap = 0;
    let previousTopBottomWrap = 0;
    let currentLeftRightWrap = 0;
    let currentTopBottomWrap = 0;
    if (currentEdits.bleed &&
      (currentBleed.edgeStyle == EdgeStyles.MIRROR_WRAP ||
        currentBleed.edgeStyle == EdgeStyles.MIRROR_WRAP_WITH_BLUR ||
        currentBleed.edgeStyle == EdgeStyles.EXTEND ||
        currentBleed.edgeStyle == EdgeStyles.BORDER ||
        currentBleed.edgeStyle == EdgeStyles.BORDER_WHITE)) {
      previousLeftRightWrap = (currentEdits.bleed.leftSideDepth || 0) + (currentEdits.bleed.leftBackOverPrint || 0) + (currentEdits.bleed.rightSideDepth || 0) + (currentEdits.bleed.rightBackOverPrint || 0);
      previousTopBottomWrap = (currentEdits.bleed.topSideDepth || 0) + (currentEdits.bleed.topBackOverPrint || 0) + (currentEdits.bleed.bottomSideDepth || 0) + (currentEdits.bleed.bottomBackOverPrint || 0);
    }

    if (newEdits.bleed &&
      (newBleed.edgeStyle == EdgeStyles.MIRROR_WRAP ||
        newBleed.edgeStyle == EdgeStyles.MIRROR_WRAP_WITH_BLUR ||
        newBleed.edgeStyle == EdgeStyles.EXTEND ||
        newBleed.edgeStyle == EdgeStyles.BORDER ||
        newBleed.edgeStyle == EdgeStyles.BORDER_WHITE)) {
      currentLeftRightWrap = (newEdits.bleed.leftSideDepth || 0) + (newEdits.bleed.leftBackOverPrint || 0) + (newEdits.bleed.rightSideDepth || 0) + (newEdits.bleed.rightBackOverPrint || 0);
      currentTopBottomWrap = (newEdits.bleed.topSideDepth || 0) + (newEdits.bleed.topBackOverPrint || 0) + (newEdits.bleed.bottomSideDepth || 0) + (newEdits.bleed.bottomBackOverPrint || 0);
    }

    const previouslySelectedProjectWidth = currentEdits.width;
    const previouslySelectedProjectHeight = currentEdits.height;
    const currentSelectedProjectWidth = newEdits.width;
    const currentSelectedProjectHeight = newEdits.height;    // when there is a wrap, wrap size is included in the total page width/height but image edits may not include the wrap size
    // depending on the wrap type (for regular image wrap the image is extended to the total page width/height but for the rest of the wrap types the image is not extended)
    const pageScaleX = currentSelectedProjectWidth / previouslySelectedProjectWidth;
    const pageScaleY = currentSelectedProjectHeight / previouslySelectedProjectHeight;

    const backgroundScaleX = (currentSelectedProjectWidth - currentLeftRightWrap) / (previouslySelectedProjectWidth - previousLeftRightWrap);
    const backgroundScaleY = (currentSelectedProjectHeight - currentTopBottomWrap) / (previouslySelectedProjectHeight - previousTopBottomWrap);

    const newBackgroundWidth = currentSelectedProjectWidth - currentLeftRightWrap;
    const newBackgroundHeight = currentSelectedProjectHeight - currentTopBottomWrap;
    const previousBackgroundWidth = previouslySelectedProjectWidth - previousLeftRightWrap;
    const previousBackgroundHeight = previouslySelectedProjectHeight - previousTopBottomWrap;

    newEdits.freeObject = [];
    for (let i = 0; i < freeObjectsOnCurrentCanvas.length; i++) {
      newEdits.freeObject.push(freeObjectsOnCurrentCanvas[i]);
    }

    newEdits.freeObject.forEach((freeObject: IFreeObject) => {
      freeObject.width *= backgroundScaleX;
      freeObject.height *= backgroundScaleY;
      freeObject.x *= pageScaleX;
      freeObject.y *= pageScaleY;
      freeObject.frameWindowWidth *= backgroundScaleX;
      freeObject.frameWindowHeight *= backgroundScaleY;
      freeObject.frameWindowX *= pageScaleX;
      freeObject.frameWindowY *= pageScaleY;
      if (freeObject.imageEdits) {
        const previousZoom = freeObject.imageEdits.zoom;
        const maxRatio = Math.max(newBackgroundWidth / previousBackgroundWidth, newBackgroundHeight / previousBackgroundHeight);
        const newZoom = maxRatio * previousZoom;
        freeObject.imageEdits.picturePosY *= newZoom / previousZoom;
        freeObject.imageEdits.picturePosX *= newZoom / previousZoom;
        freeObject.imageEdits.zoom = newZoom;

      }
      if (
        freeObject.textEdits &&
        freeObject.textEdits.rootStyle.fontSize !== undefined
      ) {
        freeObject.textEdits.rootStyle.fontSize = Math.round(freeObject.textEdits.rootStyle.fontSize * backgroundScaleX);
      }
    });
  }

  public static getImageZoomFromZoomFactor(
    zoom: number,
    zoomMax: number,
    zoomMin: number
  ): number {
    const minMaxDifference = zoomMax - zoomMin;
    const zoomIncrement = minMaxDifference / 100;
    const easePercent = (zoom - zoomMin) / zoomIncrement;
    const value = 1 - Math.pow(1 - easePercent / 100, 2);
    return value < 0 ? 0 : Math.sqrt(value) * 100;
  }

  public static getPagePreviewDOMElement(): HTMLElement|undefined {
    return document.querySelector('.page-preview') as HTMLElement|undefined;
  }

  public static createPagePreviewCanvasElement(pageIndex: number): HTMLCanvasElement {
    const id = 'printSuitePreviewCanvas' + pageIndex;
    let previewCanvas = document.getElementById(id) as HTMLCanvasElement|undefined;
    if (!previewCanvas) {
      previewCanvas = document.createElement('canvas');
      previewCanvas.style.display = 'none';
      previewCanvas.id = id;
      document.body.appendChild(previewCanvas);
    }

    return previewCanvas;
  }

  public static removePagePreviewCanvasElement(pageIndex: number): void {
    const id = 'printSuitePreviewCanvas' + pageIndex;
    const tempPreviewCanvas = document.getElementById(id) as HTMLCanvasElement|undefined;
    if (tempPreviewCanvas) {
      tempPreviewCanvas.remove();
    }
  }

  public static getBase64ImageFromDataUrl(dataUrl: string): string {
    const splitPosition = dataUrl.indexOf(',');
    if (splitPosition >= 0) {
      return dataUrl.substring(splitPosition + 1);
    } else {
      return '';
    }
  }

  public static getOrientation(width: number, height: number): Orientation {
    if (width > height) {
      return Orientation.landscape;
    } else if (width < height) {
      return Orientation.portrait;
    } else {
      return Orientation.square;
    }
  }

  public static setActiveObjectByUid(fabricCanvas: IEditorCanvas, objectUid: string): void {
      const objectToSelect = fabricCanvas.getObjects().find((object) => {
        return (object as IEditorShape).uid === objectUid;
      });
      if (objectToSelect) {
        fabricCanvas.setActiveObject(objectToSelect);
      }

  }

  // This is to compare two pages to see if they are exactly equal. Properties with undefined value should be ignored too
  public static arePagesEqual(page1: IEditorPage, page2: IEditorPage): boolean {
    return JSON.stringify(page1) === JSON.stringify(page2);
  }

  public static areProjectPagesEqual(project1: IProjectInfo, project2: IProjectInfo): boolean {
    return project1.pages.length === project2.pages.length && project1.pages.every((page, index) => {
      return JSON.stringify(page, Helpers.JSONStringifyReplacer) === JSON.stringify(project2.pages[index], Helpers.JSONStringifyReplacer);
    });
  }

  private static JSONStringifyReplacer(key: string, value: object ): object|undefined {
    // Filtering out preview_image string from the page object in project
    if (key === 'preview_image') {
      return undefined;
    }
    return value;
  }

  public static isEmptyProject(project: IProjectInfo): boolean {
    return project.pages.every((page: IPage) => {
      return page.edits.freeObject.length === 0;
    });
  }

  public static getSameAspectRatioProductsForABase(base: IProductInfo, basesToCompare: IProductInfo[]): IProductInfo[] {
    if (
      base.dynamic_data.optimal_x
      && base.dynamic_data.optimal_y
    ) {
      const currentSelectedProductAspectRatio =
        base.dynamic_data.optimal_x /
        base.dynamic_data.optimal_y;

      return basesToCompare.filter((productInfo: IProductInfo) => {
        if (
          productInfo.dynamic_data.optimal_x &&
          productInfo.dynamic_data.optimal_y
        ) {
          const aspectRatio =
            productInfo.dynamic_data.optimal_x /
            productInfo.dynamic_data.optimal_y;
          return aspectRatio === currentSelectedProductAspectRatio;
        }
      });
    }

    return [];
  }

  public static validWrap(wrap: { [key: string]: string | number }): boolean {
    return (
      [
        EdgeStyles.MIRROR_WRAP,
        EdgeStyles.MIRROR_WRAP_WITH_BLUR,
        EdgeStyles.EXTEND,
        EdgeStyles.BORDER,
        EdgeStyles.BORDER_WHITE,
      ].indexOf(wrap[WrapOptions.EDGE_STYLE] as string) > -1
    );
  }

  public static getWrapWidth(fabricCanvas: IEditorCanvas): number {
    if (fabricCanvas.wrap) {
      return (fabricCanvas.wrap.bleed_back_l as number) + (fabricCanvas.wrap.bleed_side_l as number) + (fabricCanvas.wrap.bleed_back_r as number) + (fabricCanvas.wrap.bleed_side_r as number);
    } else {
      return 0;
    }
  }

  public static getWrapHeight(fabricCanvas: IEditorCanvas): number {
    if (fabricCanvas.wrap) {
      return (fabricCanvas.wrap.bleed_back_t as number) + (fabricCanvas.wrap.bleed_side_t as number) + (fabricCanvas.wrap.bleed_back_b as number) + (fabricCanvas.wrap.bleed_side_b as number);
    } else {
      return 0;
    }
  }

}
