import { IAttributeValue, IOptionTranslation } from '@/interfaces/baseInterface';

export interface IShopProduct {
  details: IDetails;
  shop_id: string;
  previews_status: IShopProductPreviewStatus[]; // This is a list of all the previews that should be ready before sending this shop product to shopify. Check this list against the variant previews list to see if all the previews are ready
  main_preview: string;
  options: IOption[];
  product_type: string;
  variants: IVariant[];
  guid: string;
  super_sku_id: number; // if this shop product belongs to a super sku, use the catalog item id here, otherwise set it to 0
  default_preview_url: string|null; // this is the default preview url for the shop product -- this is set by dfe, front end send it just as an empty string to dfe.
  status: ShopProductStatus; // this is our internal state of the imported product in the store (e.g; if import fails)
  translations: { [key: string]: IShopProductTranslations };
  platform_id?: string;
  platform_status?: string;
  last_updated: string;
  // default_sku is product_sku of the variant that main preview image belongs to.
  // This is used by ecomm service to know which image should be sent as the main preview image (called featured media by shopify) to the shop
  default_sku: string;
}

export interface IZone {
  zone_name: string;
  zone_label: string;
  countries: string[];
  rates: IRate[];
}

export interface IRate {
  name: string;
  display_name: string;
  price: number;
  price_f: string;
  currency: string;
}

export interface IShopProductTranslations {
  option_translations: { [key: string]: IOptionTranslation };
}

export interface IDetails {
  description: string;
  tags:        string[];
  title:       string;
}

export interface IShopProductPreviewStatus {
  enabled: boolean;
  name: string;
}

export interface IOption {
  name:   string;
  values: string[];
}

export interface IVariant {
  image_previews:   IVariantImagePreview[];
  options:          { [key: string]: string };
  price_f:          string;
  price:            number;
  project_guid:       string; // this is a guid generated by the front end, not the initial project id
  product_sku: string; // product_sku is a combination of shop product guid and sku id
  title:            string; // Scott will send a field called "name" in sku data that I can use for the title, and it needs to be editable in UI as well (which is not right now)
  base_id:         number; // catalog base id (that this shop product variant belongs to)
  personalizable: boolean;
  platform_id?: string;
  shipping_profile?: string;
}

export interface IRange {
  min: number;
  max: number;
}

export interface IVariantImagePreview {
  name: string;
  url:  string;
}

export interface IPreviewResult {
  projectGuid: string;
  blobUrl: string;
  pageIndex: number;
  previewName: string;
  basePreviewId: number;
  previewIndex: number;
}

export interface IShopProductVariantsAndOptions {
  variants: IVariant[];
  options: IOption[];
}

export interface IPriceDetail {
  product: {name: string, skuId: string};
  wholesale: {formatted: string, unformatted: number};
  markup: string;
  retail: {formatted: string, unformatted: number};
  estimatedProfit: string;
  expenseShipping: {formatted: string, unformatted: number};
  revenueShipping: {formatted: string, unformatted: number};
}

export enum ShopProductStatus {
  draft, // Note: This draft is different from the shopify draft status. It tells DFE that this product is not intended to be published to a store (it's a printsuite template/draft)
  pending, // Tells DFE that the product should be published to the store
  inactive, // product has been successfully imported to the shop, but user needs to activate it in the shop
  active, // product has been successfully imported to the shop and is active
  queuedToPublish // product is in the queue to be published to the shop
}

export interface IShopProductAttribute {
  name: string;
  presentation_type: string;
  values: IAttributeValue[];
  displayName: string;
}
