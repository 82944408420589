import { defineStore } from 'pinia';
import { IProjectInfo } from '@/interfaces/projectInterface';
import { ref } from 'vue';
import { PublishTopicKeys, SubscribeTopicKeys, TopicVarLevels } from '@/constants/topicEnums';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import logger from '@/logger/logger';
import { IShopProduct } from '@/interfaces/shopProductInterface';
import {
  getUniqueProjectGuidsFromBase,
} from '@/helpers/shopProductHelper';
import { TopicWildCard } from '@/constants/topicConstants';
import { ISolaceClient } from '@/interfaces/iSolaceClient';
import { MessageDeliveryModeType } from 'solclientjs';

export const useShopProductProjectStore = defineStore('shopProductProject', () => {
  const shopProductsProjectsMap = ref(new Map<string, IProjectInfo[]>()); // map of shop product id and projects
  let solaceClient:ISolaceClient|undefined;
  const solaceReady:Promise<void> = new Promise((resolve, reject) => {
    ServiceLocator.getService<ISolaceClient>(ServiceType.SolaceClient).then(solace => {
      solaceClient = solace;
      resolve();
    }, err => {
      reject(err);
    });
  });
  const shopProductProjectCurrentSubscriptions: { [key: string]: boolean } = {};
  const guids: {
    [guid: string]: {
      resolve: (value: string) => void;
      reject: (reason: string) => void;
    };
  } = {};

  function getProjectByGuid(projectGuid: string): IProjectInfo|null {
    for (const projectList of shopProductsProjectsMap.value.values()) {
      const project = projectList.find((project) => project.guid === projectGuid);
      if (project) {
        return project;
      }
    }
    return null;
  }

  function getProjectsByShopProductGuid(shopProductGuid: string): IProjectInfo[] {
    return shopProductsProjectsMap.value.get(shopProductGuid) || [];
  }

  function doesShopProductProjectExist(shopProductId: string, projectGuid: string): boolean {
    const projects = shopProductsProjectsMap.value.get(shopProductId);
    if (projects) {
      return projects.findIndex((project) => project.guid === projectGuid) > -1;
    }
    return false;
  }

  function publishShopProductProjectTrigger(shopId: string, shopProductGuid: string, projectGuid: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      guids[projectGuid] = { resolve, reject };
    });
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_TRIGGER);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, shopProductGuid).replace(TopicVarLevels.PROJECT_ID, projectGuid);
        solaceClient.publishMessageString(editedTopic, undefined, err => {
          if (guids[projectGuid]) {
            guids[projectGuid].reject(err.message);
          }
          console.warn('Error publishing shop product project trigger', err);
        });
      } else {
        guids[projectGuid].reject(
          'Could not find topic for publish shop product project trigger: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_TRIGGER
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
    return promise;
  }

  // this function should be called to clean up the guid promises that are no longer needed (resolved)
  function cleanupGuidPromise(projectGuid: string): void {
    if (guids[projectGuid]) {
      delete guids[projectGuid];
    }
  }

  function publishShopProductProjectAdd(shopId: string, shopProductGuid: string, project: IProjectInfo, correlationId: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      guids[project.guid] = { resolve, reject };
    });
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_ADD);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, shopProductGuid).replace(TopicVarLevels.PROJECT_ID, project.guid);
        solaceClient.publishMessageString(editedTopic, JSON.stringify(project), err => {
          guids[project.guid].reject(err.message);
          console.warn('Error publishing shop product project add', err);
        }, MessageDeliveryModeType.PERSISTENT, undefined, undefined, correlationId);
      } else {
        guids[project.guid].reject('Could not find topic for publish shop product project add: ' + PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_ADD);
        console.warn(
          'Could not find topic for publish shop product project add: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_ADD
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
    return promise;
  }

  function publishShopProductProjectUpdate(shopId: string, shopProductGuid: string, project: IProjectInfo, correlationId: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      guids[project.guid] = { resolve, reject };
    });
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      const reqTopic = solaceClient.getPubTopic(PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_UPDATE);
      if (reqTopic) {
        const editedTopic = reqTopic.replace(TopicVarLevels.SHOP_ID, shopId).replace(TopicVarLevels.PRODUCT_ID, shopProductGuid).replace(TopicVarLevels.PROJECT_ID, project.guid);
        solaceClient.publishMessageString(editedTopic, JSON.stringify(project), err => {
          guids[project.guid].reject(err.message);
          console.warn('Error publishing shop product project update', err);
        }, MessageDeliveryModeType.PERSISTENT, undefined, undefined, correlationId);
      } else {
        guids[project.guid].reject(
          'Could not find topic for publish shop product project update: ' +
          PublishTopicKeys.PUB_TENANT_SHOP_PRODUCT_PROJECT_UPDATE
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
    return promise;
  }

  function subscribeToShopProductProjectCurrent(shopId: string): void {
    if (shopProductProjectCurrentSubscriptions[shopId]) {
      return;
    }
    solaceReady.then(() => {
      if (!solaceClient) {
        throw new Error('Solace client is not defined -- Error');
      }
      shopProductProjectCurrentSubscriptions[shopId] = true;
      const shopProductProjectCurrentSubscription = solaceClient.getSubTopic(SubscribeTopicKeys.SUB_TENANT_SHOP_PRODUCT_PROJECT_CURRENT);
      if (shopProductProjectCurrentSubscription) {
        const replacedSub = shopProductProjectCurrentSubscription.replace(TopicVarLevels.SHOP_ID, TopicWildCard).replace(TopicVarLevels.PRODUCT_ID, TopicWildCard).replace(TopicVarLevels.PROJECT_ID, TopicWildCard);
        solaceClient.mapSubscriptionJson<IProjectInfo>(replacedSub, (projectInfo, topic: string) => {
            const shopProductGuid = topic.split('/')[10];
            const projects = shopProductsProjectsMap.value.get(shopProductGuid);
            if (projects) {
              const index = projects.findIndex((project) => project.guid === projectInfo.guid);
              if (index > -1) {
                projects[index] = projectInfo;
              } else {
                projects.push(projectInfo);
              }
            } else {
              shopProductsProjectsMap.value.set(shopProductGuid, [projectInfo]);
            }
            if (guids[projectInfo.guid]) {
              guids[projectInfo.guid].resolve(projectInfo.guid);
            } else {
              console.log('No promise found for shop product project with ID ' + projectInfo.guid);
            }
          }
        );
      } else {
        logger.error(
          'Unable to subscribe to shop product project current topic - not found'
        );
      }
    }, err => {
      console.error('Getting solace failed', err);
    });
  }

  async function triggerShopProductProjectsFromBase(baseId: number, shopProduct: IShopProduct): Promise<void> {
    const uniqueProjectGuids = getUniqueProjectGuidsFromBase(shopProduct, baseId);
    for (const projectGuid of uniqueProjectGuids) {
      if (!doesShopProductProjectExist(shopProduct.guid, projectGuid)) {
        const id = await publishShopProductProjectTrigger(shopProduct.shop_id, shopProduct.guid, projectGuid);
        cleanupGuidPromise(id);
      }
    }
  }

  return {
    shopProductsProjectsMap,
    subscribeToShopProductProjectCurrent,
    publishShopProductProjectAdd,
    publishShopProductProjectUpdate,
    triggerShopProductProjectsFromBase,
    getProjectByGuid,
    cleanupGuidPromise,
    getProjectsByShopProductGuid
  };
});
