import { IPreviewData } from '@/interfaces/baseInterface';
import {
  EditorLayerType,
  IEditorCanvas,
  IEditorImage,
  IWebsiteImage,
  IWebsiteThumb,
  Orientation
} from '@/interfaces/editorInterfaces';
import { EditorTranslator } from '@/modules/editor/editorTranslator';
import { Image } from '@/modules/editor/fabricShapes/image';
import { Helpers } from '@/modules/editor/helpers';
import { fabric } from 'fabric';
import { ThumbSize } from '@/modules/editor/editorConstants';
export interface IEditorLayer {
  imageLandscape?: ILayerImage;
  imagePortrait?: ILayerImage;
}

export interface ILayerImage {
  image: IEditorImage | null;
  margin_left?: number;
  margin_right?: number;
  margin_top?: number;
  margin_bottom?: number;
}

export type IEditorLayerTypes = 'imageLandscape' | 'imagePortrait';

export const LandscapeRotation = 'L';
export const PortraitRotation = 'P';

export class LayerOnCanvas {
  public static renderLayer(previewData: IPreviewData, pageWidth: number, pageHeight: number, fabricCanvas: IEditorCanvas): void {
    let marginLeft = 0;
    let marginRight = 0;
    let marginTop = 0;
    let marginBottom = 0;
    const layerPreview: IEditorLayer = {
      imageLandscape: {
        image: null,
        margin_bottom: 0,
        margin_top: 0,
        margin_left: 0,
        margin_right: 0
      },
      imagePortrait: {
        image: null,
        margin_bottom: 0,
        margin_top: 0,
        margin_left: 0,
        margin_right: 0
      }
    };

    const isLandscape = Helpers.getOrientation(pageWidth, pageHeight) === Orientation.landscape;

      const layerLandscape = previewData.flags && previewData.flags.R === LandscapeRotation;
      const layerPortrait = previewData.flags && previewData.flags.R === PortraitRotation;
      if (layerLandscape) {
        const layerImageLandscape: IWebsiteImage = this.buildImage(previewData);
        this.setupPreview(layerPreview, EditorTranslator.Instance.toImageModel(layerImageLandscape, ''), previewData, 'imageLandscape');
      }
      if (layerPortrait) {
        const layerImagePortrait: IWebsiteImage = this.buildImage(previewData);
        this.setupPreview(layerPreview, EditorTranslator.Instance.toImageModel(layerImagePortrait, ''), previewData, 'imagePortrait');
      }

      if (layerPreview.imageLandscape && layerPreview.imageLandscape.image && layerPreview.imagePortrait && !layerPreview.imagePortrait.image) {
        this.setupPreview(layerPreview, layerPreview.imageLandscape.image, previewData, 'imagePortrait');
      } else if (layerPreview.imagePortrait && layerPreview.imagePortrait.image && layerPreview.imageLandscape && !layerPreview.imageLandscape.image) {
        this.setupPreview(layerPreview, layerPreview.imagePortrait.image, previewData, 'imageLandscape');

      }
      let layerImage;

      if (!isLandscape) {
        if (!layerPreview.imagePortrait || !layerPreview.imagePortrait.image) {
          console.error('No layer image for portrait');
          return;
        }
        layerImage = layerPreview.imagePortrait.image;
        const imagePortrait = layerPreview.imagePortrait;
        marginBottom = imagePortrait.margin_bottom || 0;
        marginTop = imagePortrait.margin_top || 0;
        marginLeft = imagePortrait.margin_left || 0;
        marginRight = imagePortrait.margin_right || 0;
      } else {
        if (!layerPreview.imageLandscape || !layerPreview.imageLandscape.image) {
          console.error('No layer image for landscape');
          return;
        }
        layerImage = layerPreview.imageLandscape.image;
        const imageLandscape = layerPreview.imageLandscape;
        marginBottom = imageLandscape.margin_bottom || 0;
        marginTop = imageLandscape.margin_top || 0;
        marginLeft = imageLandscape.margin_left || 0;
        marginRight = imageLandscape.margin_right || 0;
      }
      const recalculatedMarginValues = Helpers.calculateEditableAreaMarginsFromLayerPreview(previewData.width, pageWidth, {topMargin: marginTop, bottomMargin: marginBottom, leftMargin: marginLeft, rightMargin: marginRight});
      marginLeft = recalculatedMarginValues.extraSpaceLeft;
      marginRight = recalculatedMarginValues.extraSpaceRight;
      marginTop = recalculatedMarginValues.extraSpaceTop;
      marginBottom = recalculatedMarginValues.extraSpaceBottom;
      const image = new Image(layerImage, {});
      image.canvas = fabricCanvas;
      image.image.width = pageWidth + marginLeft + marginRight;
      image.image.height = pageHeight + marginTop + marginBottom;
      fabricCanvas.objectsToLoad ++;
      image._loadImage(image.image, true, true, (selfImage: fabric.Image) => {
        if (fabricCanvas.objectsToLoad > 0) {
          fabricCanvas.objectsToLoad --;
        }
        if (previewData.media_context === EditorLayerType.BackLayer) {
          this.setBackLayerImageOnCanvas(selfImage, pageHeight, pageWidth, marginTop, marginBottom, marginLeft, marginRight, fabricCanvas);
        } else if (previewData.media_context === EditorLayerType.Overlay) {
          this.setOverlayImageOnCanvas(selfImage, pageHeight, pageWidth, marginTop, marginBottom, marginLeft, marginRight, fabricCanvas);
        } else if (previewData.media_context === EditorLayerType.Mask) {
          this.setMaskImageOnCanvas(selfImage, pageHeight, pageWidth, marginTop, marginBottom, marginLeft, marginRight, fabricCanvas);
        }
        fabricCanvas.requestRenderAll();
      });



  }

  private static setBackLayerImageOnCanvas(layerImage: fabric.Image, height: number, width: number, marginTop: number, marginBottom: number, marginLeft: number, marginRight: number, fabricCanvas: IEditorCanvas): void {
    this.setLayerImagePosition(layerImage, height, width, marginTop, marginBottom, marginLeft, marginRight);
    if (fabricCanvas) {
      fabricCanvas.setBackgroundImage(layerImage, fabricCanvas.requestRenderAll.bind(fabricCanvas), {});
    }
  }

  private static setOverlayImageOnCanvas(layerImage: fabric.Image, height: number, width: number, marginTop: number, marginBottom: number, marginLeft: number, marginRight: number, fabricCanvas: IEditorCanvas): void {
    this.setLayerImagePosition(layerImage, height, width, marginTop, marginBottom, marginLeft, marginRight);
    if (fabricCanvas) {
      fabricCanvas.setOverlayImage(layerImage, fabricCanvas.requestRenderAll.bind(fabricCanvas), {});
    }
  }

  private static setMaskImageOnCanvas(layerImage: fabric.Image, height: number, width: number, marginTop: number, marginBottom: number, marginLeft: number, marginRight: number, fabricCanvas: IEditorCanvas): void {
    this.setLayerImagePosition(layerImage, height, width, marginTop, marginBottom, marginLeft, marginRight);
    if (fabricCanvas) {
      fabricCanvas.mask = {maskImage: layerImage, marginLeft: marginLeft, marginTop: marginTop, marginBottom: marginBottom, marginRight: marginRight};
    }
  }

  private static setLayerImagePosition(layerImage: fabric.Image, height: number, width: number, marginTop: number, marginBottom: number, marginLeft: number, marginRight: number): void {
    layerImage.set({
      height: height + marginTop + marginBottom,
      width: width + marginLeft + marginRight,
      originX: 'center',
      originY: 'center',

    });
    layerImage.top = (height + marginTop + marginBottom) / 2 - marginTop;
    layerImage.left = (width + marginLeft + marginRight) / 2 - marginLeft;
  }


  private static setupPreview(layerPreview: IEditorLayer, image: IEditorImage, previewData: IPreviewData, imageType: IEditorLayerTypes): void {
    const layerPreviewElement = layerPreview[imageType];
    if (layerPreviewElement) {
      layerPreviewElement.image = image;
      layerPreviewElement.margin_right = previewData.attributes && previewData.attributes.margin_right ? parseInt(previewData.attributes.margin_right, 10) : 0;
      layerPreviewElement.margin_left = previewData.attributes && previewData.attributes.margin_left ? parseInt(previewData.attributes.margin_left, 10) : 0;
      layerPreviewElement.margin_top = previewData.attributes && previewData.attributes.margin_top ? parseInt(previewData.attributes.margin_top, 10) : 0;
      layerPreviewElement.margin_bottom = previewData.attributes && previewData.attributes.margin_bottom ? parseInt(previewData.attributes.margin_bottom, 10) : 0;
    } else {
      console.error('Unable to find layer preview element ' + imageType);
    }
  }

  // Asked by Jeremy: always go with full size for overlay/mask/backlayer images
  private static buildImage(previewData: IPreviewData): IWebsiteImage {
    const fullSizeImage: IWebsiteThumb = {
      url: previewData.url,
      width: previewData.width,
      height: previewData.height,
      thumb_type: ThumbSize.FULL,
      file_path: previewData.file_path,
    };
    return {
      id: '',
      full_url: previewData.url,
      image_data: {},
      edits: {},
      width: previewData.width,
      height: previewData.height,
      thumb_list: [fullSizeImage],
      placeHolder: false,

    };
  }
}
