import { fabric } from 'fabric';
import { FontHelper } from '@/modules/editor/fontHelper';
import {
  ITextOptions,
  ITextZoneProperties,
} from '@/interfaces/editorInterfaces';
import { useFontStore } from '@/stores/fontStore';
import { DEFAULT_FONT, DEFAULT_TEXT_COLOR } from '@/modules/editor/editorConstants';
import { useSessionStore } from '@/stores/sessionStore';
export const Text = fabric.util.createClass(fabric.Textbox, {
  type: 'sf-text',

  // the flash editor scales text by a factor of 5, for no reason, so we do it to..
  // @note: changing the font size and scaling are not equivalent, as scaling is different on large font sizes.
  scaleX: 5,
  scaleY: 5,
  fill: 'rgba(0,0,0,1)',

  ready: false, // if font isn't loaded yet, don't render
  loadingFont: 0, // if a font is loading, also don't render
  failedFire: 0,
  minFontSize: 6,
  maxFontSize: 500,
  initialize: function (
    text: string,
    options: ITextOptions,
    keepEmptyText = false
  ): void {
    const sessionStore = useSessionStore();
    const language = sessionStore.currentLanguage;
    // adjust the width and height, so that we scale it down by 5
    if (options.width) {
      options.width /= this.scaleX;
    }

    if (options.height) {
      options.height /= this.scaleY;
    }

    if (!options.effects) {
      options.effects = {};
    }

    // Handles case-sensitive differences in DefaultText among all environments

    if (keepEmptyText) {
      text = '';
    }

    text = this.fixTextFromOptions(text);
    options.originalFontSize = options.fontSize;

    this.callSuper('initialize', text, options);
    const messageOptions: string[] = [];
    if (
      options.placeHolderTranslations &&
      options.placeHolderTranslations[language]
    ) {
      messageOptions.push(options.placeHolderTranslations[language]);
    }
    if (!options.originalHeight) {
      this.originalHeight = options.height;
    }
    this.textZoneId = options.textZoneId;
    this.linkedZoneId = options.linkedZoneId;
    this.autoFontSize = !!options.autoFontSize;
    this.setAdjustedTextSize(text, options, messageOptions);
    this.forceClearCache();
  },

  /**
   * Aborts cursor animation and clears all timeouts
   */
  abortCursorAnimation: function (): void {
    this._currentTickState && this._currentTickState.abort();
    this._currentTickCompleteState && this._currentTickCompleteState.abort();
    clearTimeout(this._cursorTimeout1);
    clearTimeout(this._cursorTimeout2);
    this._currentCursorOpacity = 0;
    // fabricjs removes contextTop at this point which causes canvas to flicker when typing, and it also removes the image wraps upon mouse down/up (PS-62551)
  },

  setAdjustedTextSize: function (
    text: string,
    options: ITextOptions,
    messageOptions: string[]
  ): void {
    const sessionStore = useSessionStore();
    if (
      options.placeHolderTranslations &&
      text == options.placeHolderTranslations[sessionStore.currentLanguage] &&
      options.edited === false
    ) {
      this.text = this._truncateText(options, messageOptions);
    }
  },

  forceClearCache: function (): void {
    // do nothing -- override if needed
  },

  _truncateText: function (
    options: ITextOptions,
    messageOptions: string[]
  ): string {
    const virtualCanvas = document.createElement('canvas');
    virtualCanvas.width = 4000;
    virtualCanvas.height = 4000;

    const ctx = virtualCanvas.getContext('2d');
    this._setTextStyles(ctx);

    const textZoneProperties: ITextZoneProperties = {
      maxWidth: options.width as number,
      lineHeight: this.height,
      zoneHeight: this.originalHeight
        ? this.originalHeight * this.scaleY
        : (options.height as number) * this.scaleY,
      measureText: this._measureWord.bind(this),
    };

    // Assigning newText as 'small' variation of DefaultText in case upcoming loop fails to match

    if (messageOptions.length > 1) {
      let newText = messageOptions[messageOptions.length - 2];

      // Returning true breaks the 'some' loop once if statement passes

      messageOptions.some((sentence: string) => {
        if (this._simulateWrappedText(sentence, textZoneProperties)) {
          newText = sentence;
          return true;
        }
      });

      return newText;
    } else {
      return messageOptions[0];
    }
  },

  /**
   *  Method that simulates wrapping the text in an imaginary canvas to determine if the sentence
   *  would fit in the given textZone
   *  @param message
   *  @param {Object} textZoneProperties Object that contains properties of text and zone
   *  @return {boolean} True if the fully-wrapped text fits within textZone height
   */
  _simulateWrappedText: function (
    message: string,
    textZoneProperties: ITextZoneProperties
  ): boolean {
    let line = '';
    const splitText = message.split('');
    const lastIndex = splitText.length - 1;
    let virtualTextBoxHeight = textZoneProperties.lineHeight;

    splitText.forEach((word, index) => {
      const testLine = line + word + ' ';
      const testWidth = textZoneProperties.measureText(testLine, 0);
      if (testWidth > textZoneProperties.maxWidth) {
        line = this._isEndOfSentence(index, lastIndex) ? word : word + ' ';
        virtualTextBoxHeight += textZoneProperties.lineHeight;
      } else {
        line = testLine;
      }
    });

    return virtualTextBoxHeight <= textZoneProperties.zoneHeight;
  },

  _renderText: function (ctx: CanvasRenderingContext2D): void {
    if (this.paintFirst === 'stroke') {
      this._renderTextStroke(ctx);
      this._renderTextFill(ctx);
    } else {
      this._setShadow(ctx);
      this._renderTextFill(ctx);
      this._renderTextStroke(ctx);
    }
  },

  _shouldClearDimensionCache: function (): boolean {
    let shouldClear = this._forceClearCache;
    if (!shouldClear) {
      shouldClear = this.hasStateChanged('_dimensionAffectingProps');
    }
    if (shouldClear) {
      this.dirty = true;
    }
    return shouldClear;
  },

  setReady: function (): void {
    this._forceClearCache = true;
    this.ready = true;
    this.fire('ready');
  },

  fireOnObjectReady: function (): void {
    // Note: do not include the following lines in setReady function...
    if (
      this.canvas &&
      typeof this.canvas._onObjectReady === 'function' &&
      this.loadingFont == 0
    ) {
      this.canvas._onObjectReady({ target: this });
    }
  },

  triggerModifiedEvent: function (): void {
    if (this.canvas._fire) {
      this.canvas._fire('modified', {
        transform: { target: this },
        event: null,
      });
    }
  },

  fixTextFromOptions: function (text: string): string {
    // Remove non-ascii characters, note: this will not allow non-latin characters and may have to be made more robust in the future.

    text = text.replace(/[^\00-\xff]/g, '');
    if (this.maxCharacters > 0 && text.length > this.maxCharacters) {
      text = text.substring(0, this.maxCharacters);
    }
    if (this.textTransform === 'uppercase') {
      text = text.toUpperCase();
    } else if (this.textTransform === 'lowercase') {
      text = text.toLowerCase();
    } else if (this.textTransform === 'capitalize') {
      text = text.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
      );
    } else if (this.textTransform === 'numeric') {
      text = text.replace(/\D+/g, '');
    }

    return text;
  },

  onInput: function (e: never): void {
    this.hiddenTextarea.value = this.fixTextFromOptions(
      this.hiddenTextarea.value
    );
    this.callSuper('onInput', e);
  },

  /**
   * If we are loading a new object, and the font hasn't loaded, don't render it.
   *
   * @param ctx
   * @param noTransform
   */
  render: function (ctx: CanvasRenderingContext2D, noTransform: boolean): void {
    if (this.loadingFont !== 0) {
      return;
    }
    const useFont = useFontStore();
    // if we aren't ready yet, and have access to a font service
    if (!this.ready) {
      if (this.loadingFont === 0) {
        const fonts = FontHelper.fromShape(this);
        if (!useFont.areLoaded(fonts)) {
          this.loadingFont++;
          useFont
            .loadMultiple(fonts)
            .then((): void => {
              this.loadingFont--;
              if (this.canvas) {
                this.triggerModifiedEvent();
                fabric.util.clearFabricFontCache(this.fontFamily);
                this.setReady();
                this.canvas.renderAll();
                this.fireOnObjectReady();
              }
            })
            .catch((err: string) => {
              console.log('Error loading fonts', err);
              this.replaceFailedFont().then((defaultFont: string): void => {
                console.log(
                  `Default font (${defaultFont}) replaced the failed font`
                );
              });
            });
          this.callSuper('render', ctx, noTransform);
          return;
        } else {
          this.triggerModifiedEvent();
          this.setReady();
        }
      } else {
        return;
      }
    }

    this.callSuper('render', ctx, noTransform);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toObject: function (propertiesToInclude: string[]): any {
    return fabric.util.object.extend(
      this.callSuper('toObject', propertiesToInclude),
      {
        locked: this.locked,
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
        left: this.left,
        effects: this.effects ? fabric.util.object.clone(this.effects) : null,
      }
    );
  },

  setStyle: function (styleName: string, value: string | number): void {
    if (styleName == 'fontSize') {
      if (value < this.minFontSize || value > this.maxFontSize) {
        return;
      }
    }

    const useFont = useFontStore();
    if (
      styleName === 'fontFamily' ||
      styleName === 'fontWeight' ||
      styleName === 'fontStyle'
    ) {
      const fontFamily =
        styleName === 'fontFamily' ? value : this.getStyle('fontFamily');
      const font = useFont.get(fontFamily);

      if (!font) {
        console.warn('Unable to find font ' + fontFamily);
        return;
      }

      const fontWeight =
        styleName === 'fontWeight' ? value : this.getStyle('fontWeight');
      const fontStyle =
        styleName === 'fontStyle' ? value : this.getStyle('fontStyle');
      let styleProp = FontHelper.getStyleFromProperties(
        fontWeight === 'bold',
        fontStyle === 'italic'
      );

      // the user may be switching to a font family that may not have the italic or bold face
      if (styleName === 'fontFamily' && !font.hasStyle(styleProp)) {
        // switch it to first available style
        styleProp = font.styles[0];

        if (fontWeight === 'bold') {
          this.set(
            'fontWeight',
            styleProp === 'b' || styleProp === 'bi' ? 'bold' : 'normal'
          );
        }

        if (fontStyle === 'italic') {
          this.set(
            'fontStyle',
            styleProp === 'i' || styleProp === 'bi' ? 'italic' : 'normal'
          );
        }
      }

      if (!useFont.isLoaded(fontFamily, styleProp)) {
        this.loadingFont++;
        if (this.isRegularStyleLoaded(styleName, font, fontFamily)) {
          this.loadingFont--;
        }

        useFont
          .load(font, styleProp)
          .then((): void => {
            // clear font cache
            this._forceClearCache = true;

            // mark not loading
            if (this.loadingFont > 0) {
              this.loadingFont--;
              this.triggerModifiedEvent();
            }
            // re-render
            this.canvas.renderAll();
            fabric.util.clearFabricFontCache(this.fontFamily);
            this.fireOnObjectReady();
          })
          .catch((): void => {
            this.replaceFailedFont(this).then((defaultFont: string): void => {
              this._forceClearCache = true;
              this.replaceDefaultFontInDropDown(defaultFont);
              this.canvas.renderAll();
            });
          });
      }
    }

    // if we are editing, apply the style to the selection
    if (
      this.isEditing &&
      this.selectionStart !== this.selectionEnd &&
      styleName !== 'textAlign' &&
      !(this.selectionStart === 0 && this.selectionEnd === this.text.length)
    ) {
      const style: { [name: string]: string | number } = {};
      style[styleName] = value;
      this.setSelectionStyles(style);
      this.triggerModifiedEvent();
      return;
    }

    // do not apply fill color if it's empty
    if (styleName === 'fill' && !value) {
      value = DEFAULT_TEXT_COLOR;
    }
    const styles: {
      [name: string]: string | number;
    } = {};
    styles[styleName] = value;
    // apply the style to the whole object
    this.setSelectionStyles(styles, 0, this.text.length);
    this.set(styleName, value);
    this.triggerModifiedEvent();
  },

  isRegularStyleLoaded: function (): // styleName: string,
  // font: any,
  // fontFamily: any
  boolean {
    return false;
  },

  getStyle: function (styleName: string): string | number {
    if (this.isEditing) {
      const styles = this.getSelectionStyles();
      if (
        styles.length > 0 &&
        (styleName === 'fontWeight' ||
          styleName === 'fontStyle' ||
          styleName === 'underline')
      ) {
        return styles[0][styleName];
      } else if (Object.prototype.hasOwnProperty.call(styles, styleName)) {
        return styles[styleName];
      }
    }

    return this[styleName];
  },

  toUpperCase: function (): void {
    if (this.isEditing) {
      this.text =
        this.text.substring(0, this.selectionStart) +
        this._toUpperCase(
          this.text.slice(this.selectionStart, this.selectionEnd)
        ) +
        this.text.slice(this.selectionEnd);
    } else {
      this.text = this._toUpperCase(this.text);
    }
  },

  _toUpperCase: function (selection: string): string {
    return selection[
      selection === selection.toUpperCase() ? 'toLowerCase' : 'toUpperCase'
    ]();
  },

  enterEditing: function (args?: { skipParentEnterEditing?: boolean }): void {
    if (!this.isEditing) {
      if (!args || !args.skipParentEnterEditing) {
        const sessionStore = useSessionStore();
        this.edited = true;
        this.textBeforeEdit = this.text;
        if (
          this.placeHolderTranslations &&
          this.text == this.placeHolderTranslations[sessionStore.currentLanguage]
        ) {
          this.selectAll();
          this.removeChars(0, this.text.length);
          this.setSelectionStart(0);
          this.setSelectionEnd(0);
          this._forceClearCache = true;
          this.canvas.renderAll();
        }
      }
      this.callSuper('enterEditing');
    }
  },

  exitEditing: function (skipEmptyToDefault = false): void {
    if (!skipEmptyToDefault && this.text.trim() === '') {
      const sessionStore = useSessionStore();
      this.edited = false;
      if (this.placeHolderTranslations) {
        this.text = this.placeHolderTranslations[sessionStore.currentLanguage];
      }
    }
    this.callSuper('exitEditing');
  },

  /**
   * Override and convert to no-op. Will be removed after my next PR to fabric.js
   */
  setOnGroup: function (): void {
    /* */
  },

  replaceFailedFont: function (): Promise<string> {
    const useFont = useFontStore();
    return new Promise<string>((resolve: (font: string) => void, reject:(err:unknown) => void) => {
      useFont.getFirstLoadedFont().then((defaultFont: string | null) => {
        this.loadingFont--;
        this.fontFamily = defaultFont;
        this.setStyle('fontFamily', defaultFont);
        this.setReady();
        if (typeof this.canvas._fire == 'function') {
          if (this.failedFire++ < 5) {
            this.canvas._fire('modified', {
              transform: { target: this },
              event: null,
            });
          }
        }
        resolve(defaultFont || DEFAULT_FONT);
      }, err=> {
        reject(err);
      });
    });
  },

  _isEndOfSentence: function (index: number, lastIndex: number): boolean {
    return index == lastIndex;
  },

  isDoubleClick: function (newPointer: { x: number; y: number }): boolean {
    return (
      !this.fixed &&
      this.__newClickTime - this.__lastClickTime < 500 &&
      this.__lastPointer.x === newPointer.x &&
      this.__lastPointer.y === newPointer.y &&
      this.__lastIsEditing
    );
  },
});
