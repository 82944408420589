import { defineStore } from 'pinia';
import { computed, nextTick, ref } from 'vue';
import { IProductInfo, ISkuData } from '@/interfaces/baseInterface';
import { IBaseProjectsList, IProjectInfo } from '@/interfaces/projectInterface';
import { useBaseProjectStore } from '@/stores/baseProjectStore';
import { Helpers } from '@/modules/editor/helpers';
import { deepToRaw } from '@/helpers/vueHelpers';
import { useEditorCanvasStore } from '@/stores/editorCanvasStore';
import { useUIStateStore } from '@/stores/UIStateStore';
import { useSessionStore } from '@/stores/sessionStore';
import { extractBaseSkuIds } from '@/helpers/productInfoHelpers';
import { isSuperSku } from '@/helpers/catalogHelpers';
import { useCatalogStore } from '@/stores/catalogStore';
import { IPage, IPageEdits, Orientation } from '@/interfaces/editorInterfaces';
import { ServiceLocator, ServiceType } from '@/services/serviceLocator';
import { useShopProductProjectStore } from '@/stores/shopProductProjectStore';
import { useUndoRedoStore } from '@/stores/undoRedoStore';
import {
  getSkuIdFromVariant,
  getUniqueProjectGuidsFromBase,
  isSuperSkuShopProduct
} from '@/helpers/shopProductHelper';
import { IShopProduct } from '@/interfaces/shopProductInterface';
import { uuid } from '@/helpers/uuidHelpers';
import {
  DEFAULT_PREVIEW_SIZE, EMPTY_SHOP_PRODUCT,
  SPECIFIC_DESIGN_ATTRIBUTES,
  SPECIFIC_DESIGN_PROJECT, TENANT_SERVICE
} from '@/modules/editor/editorConstants';
import { IItePreviews } from '@/interfaces/iItePreviews';

export const useEditorProductStore = defineStore('editorProduct', () => {
  const lastSelectedProductSkuIds = ref<number[]>([]);
  const unSelectedProductSkuIds = ref<number[]>([]);
  const unSelectedSuperSkuProductInfoIds = ref<number[]>([]);
  const lastSelectedProductAttribute = ref<{
    attributeName: string;
    attributeValue: string;
    requiresCanvasReRender: boolean
  }>({ attributeValue: '', attributeName: '', requiresCanvasReRender: false });

  const editorProjects = ref<Map<number, IProjectInfo>>(new Map<number, IProjectInfo>()); // list of projects that are currently being edited. multiple product sku ids can share same project.
  const cachedItePreviews = ref<Map<number, string[]>>(new Map<number, string[]>());
  const currentSelectedItePreview = ref<string>('');
  const editorCanvasStore = useEditorCanvasStore();
  const uiStateStore = useUIStateStore();
  const baseProjectStore = useBaseProjectStore();
  const catalogStore = useCatalogStore();
  let itePreviews:IItePreviews|undefined;
  const itePreviewReady:Promise<void> = new Promise<void>((resolve,reject) => {
    ServiceLocator.getService<IItePreviews>(ServiceType.ItePreviews).then(ite => {
      itePreviews = ite;
      resolve();
    }, err => {
      reject(err);
    });
  });

  const pageOrientations = computed((): Orientation[] => {
    const sessionStore = useSessionStore();
    const orientations: Orientation[] = [];
    sessionStore.activeProductSkuIdList.forEach((skuId) => {
      const project = findProject.value(skuId);
      project?.pages.forEach((page: IPage) => {
        orientations.push(Helpers.getOrientation(page.edits.width, page.edits.height));
      });
    });
    return orientations;
  });

  const findProject = computed(() => {
    return (skuId: number): IProjectInfo | undefined => {
      return editorProjects.value.get(skuId);
    };
  });

  const hasSpecificDesign = computed(() => {
    return (skuId: number): boolean => {
      const project = editorProjects.value.get(skuId);
      return project ? project.client === SPECIFIC_DESIGN_PROJECT : false;
    };
  });

  const isProjectReady = computed((): boolean => {
    const sessionStore = useSessionStore();
    const bases: IProductInfo[] = [];
    if (sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length > 0) {
      bases.push(...sameAspectRatioSuperSkuProductsFromCurrentSelected.value);
    } else if (sessionStore.currentBase) {
      bases.push(sessionStore.currentBase);
    }
    if (bases.length > 0) {
      // the if condition is to determine whether the project is created from a catalog item or it's from a shop product that user is now editing
      if (sessionStore.currentCatalogItem) {
        return areProjectsFromCatalogReady(bases);
      } else {
        return areProjectsFromShopProductReady();
      }
    }
    return false;
  });

  const sameAspectRatioSuperSkuProductsFromCurrentSelected = computed<
    IProductInfo[]
  >((): IProductInfo[] => {
    const sessionStore = useSessionStore();
    if (
      isSuperSkuProduct()
      && sessionStore.currentBase?.dynamic_data.optimal_x
      && sessionStore.currentBase?.dynamic_data.optimal_y
    ) {
      return Helpers.getSameAspectRatioProductsForABase(sessionStore.currentBase, sessionStore.currentSuperSkuProductInfos);
    }
    return [];
  });

  function getRawCurrentEditorProjectList(): IProjectInfo[] {
    const projectSet = new Set(Array.from(editorProjects.value.values()));
    const rawProjectList = deepToRaw<IProjectInfo[]>(Array.from(projectSet.values()));
    return rawProjectList;
  }

  function setOriginalEditorProjects(isSettingInitialPhaseProjects: boolean): void {
    // initial phase projects are set only once when the user first enters the editor, original editor projects are set every time the user switches to design phase
    const sessionStore = useSessionStore();
    const undoRedoStore = useUndoRedoStore();
    let projectList: IProjectInfo[];
    if (!sessionStore.currentCatalogItem && sessionStore.currentShopProduct && editorProjects.value.size === 0) {
      const shopProductProjectStore = useShopProductProjectStore();
      const projectsInfo = shopProductProjectStore.shopProductsProjectsMap.get(sessionStore.currentShopProduct.guid);
      projectList = projectsInfo ? deepToRaw<IProjectInfo[]>(projectsInfo) : [];
    } else {
      projectList = Array.from(getRawCurrentEditorProjectList());
    }
    if (isSettingInitialPhaseProjects) {
      undoRedoStore.setInitialEditorProject(structuredClone(projectList));
    } else {
      undoRedoStore.setOriginalDesignPhaseProject(structuredClone(projectList));
    }
  }

  function areProjectsFromCatalogReady(bases: IProductInfo[]): boolean {
    if (baseProjectStore.baseProjectsList) {
      return bases.every((base: IProductInfo) => {
        return baseProjectStore.baseProjectsList?.find(
          (baseProject: IBaseProjectsList) => {
            return baseProject.base_id === base.id;
          }
        );
      });
    }
    return false;
  }

  function areProjectsFromShopProductReady(): boolean {
    const sessionStore = useSessionStore();
    if (sessionStore.currentShopProduct) {
      const shopProductGuid = sessionStore.currentShopProduct.guid;
      const shopProductProjectStore = useShopProductProjectStore();
      const shopProductProjects = shopProductProjectStore.shopProductsProjectsMap.get(shopProductGuid);
      const editorProjectList = Array.from(editorProjects.value.values());
      const bases: IProductInfo[] = [];
      if (sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length > 0) {
        bases.push(...sameAspectRatioSuperSkuProductsFromCurrentSelected.value);
      } else if (sessionStore.currentBase) {
        bases.push(sessionStore.currentBase);
      }
      const baseProjectsAreReady = areProjectsFromCatalogReady(bases);
      return sessionStore.currentActiveProductSkuIdList.every((skuId: number) => {
        const foundMatchingVariant = sessionStore.currentShopProduct?.variants.find((variant) => {
          return getSkuIdFromVariant(variant, (sessionStore.currentShopProduct as IShopProduct).guid) === skuId;
        });
        // if a matching variant is found, then there should be a project in either the editor projects map or shop product projects for that variant project
        if (foundMatchingVariant) {
          const foundProject = shopProductProjects?.some((project: IProjectInfo) => {
            return project.guid === foundMatchingVariant.project_guid;
          });
          const foundEditorProject = editorProjectList.some((project: IProjectInfo) => {
            return project.guid === foundMatchingVariant.project_guid;
          });
          return foundProject || foundEditorProject;
        }
        // if a matching variant is not found, it means that the sku is later added by user in addition to the shop product variants and should be ready from the base projects
        return baseProjectsAreReady;
      });
    }
    return false;
  }

  function setCachedItePreviews(skuId: number, dataUrls: string[]): void {
    cachedItePreviews.value.set(skuId, dataUrls);
  }

  function setCurrentSelectedItePreview(dataUrl: string): void {
    currentSelectedItePreview.value = dataUrl;
  }

  async function updateProjectsPagePreviews(skuId: number, product: IProductInfo, size = DEFAULT_PREVIEW_SIZE): Promise<string[]> {
    const project = findProject.value(skuId);
    let dataUrls: string[] = [];
    if (project) {
      const outputFormat = product.dynamic_data.output_format || 'jpeg';
      await itePreviewReady;
      if (!itePreviews) {
        throw new Error('ITE Previews is not defined');
      }
      dataUrls = dataUrls.concat(await itePreviews.getSnapShots(project.pages, size, outputFormat));
      project.pages.forEach((page: IPage, index: number) => {
        page.preview_image = dataUrls[index];
      });
    }
    return dataUrls;
  }

  function excludeSuperSkuProductInfoId(unSelectedProductId: number): void {
    if (!unSelectedSuperSkuProductInfoIds.value.includes(unSelectedProductId)) {
      unSelectedSuperSkuProductInfoIds.value.push(unSelectedProductId);
    }
    const removedBase = catalogStore.bases.get(unSelectedProductId);
    removedBase?.sku_data.forEach((skuData: ISkuData) => {
      editorProjects.value.delete(skuData.product_sku_id);
    });
  }

  function resetUnselectedSuperSkuProductInfoIds(): void {
    unSelectedSuperSkuProductInfoIds.value = [];
  }

  function setLastSelectedProductAttribute(name: string, value: string, requiresCanvasReRender: boolean): void {
    lastSelectedProductAttribute.value = {
      attributeName: name,
      attributeValue: value,
      requiresCanvasReRender: requiresCanvasReRender,
    };
  }

  function setLastSelectedProductSkuIds(skuIds: number[]): void {
    lastSelectedProductSkuIds.value = skuIds;
  }

  function setUnselectedProductSkuIds(skuIds: number[]): void {
    unSelectedProductSkuIds.value = skuIds;
  }

  function addToLastSelectedProductSkuIds(skuId: number) {
    if (!lastSelectedProductSkuIds.value?.includes(skuId)) {
      lastSelectedProductSkuIds.value?.push(skuId);
    }
  }

  function removeFromEditorProjects(skuIdsToRemove: number[]) {
    skuIdsToRemove.forEach((skuId: number) => {
      editorProjects.value.delete(skuId);
    });
  }

  function resetLastSelectedProductSkuIds() {
    lastSelectedProductSkuIds.value = [];
  }

  // either pick a new project if a matching one found based on the current product sku id (combination of attributes) or just go with the default one if no match found
  // previouslySelectedEditorProject is used when we are switching between super sku products within a same aspect ratio group
  function setEditorProject(): void {
    const shopProductProjectStore = useShopProductProjectStore();
    const sessionStore = useSessionStore();
    editorCanvasStore.setRefreshCanvas(false);
    const projectFromShopProduct = !sessionStore.currentCatalogItem && sessionStore.currentShopProduct !== null;
    nextTick().then(() => {
      const initializationPhase = editorProjects.value.size === 0;
      if ((!projectFromShopProduct && baseProjectStore.baseProjectsList) || (projectFromShopProduct && shopProductProjectStore.shopProductsProjectsMap.size > 0)) {
        if (unSelectedProductSkuIds.value.length > 0) {
          unSelectedProductSkuIds.value.forEach((skuId: number) => {
            editorProjects.value.delete(skuId);
          });
          editorCanvasStore.setRefreshCanvas(lastSelectedProductAttribute.value.requiresCanvasReRender);
        } else {
          const bases = sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length > 0 ? sameAspectRatioSuperSkuProductsFromCurrentSelected.value : [sessionStore.currentBase as IProductInfo];
          if (projectFromShopProduct && initializationPhase) {
            initializeProjectsFromShopProduct();
            setOriginalEditorProjects(initializationPhase);
          } else {
            bases.forEach((base: IProductInfo) => {
              let projects: IProjectInfo[] = [];
              if (projectFromShopProduct) {
                const projectGuids = getUniqueProjectGuidsFromBase(sessionStore.currentShopProduct as IShopProduct, base.id);
                projectGuids.forEach((projectGuid: string) => {
                  const project = shopProductProjectStore.getProjectByGuid(projectGuid);
                  if (project) {
                    projects.push(project);
                  }
                });
              }
              if (projects.length === 0){
                const foundBaseProjects = findBaseProjects(base.id);
                projects = foundBaseProjects ? foundBaseProjects.projects : [];
              }
              if (projects.length > 0) {
                const selectedSkuIds = extractBaseSkuIds(base, lastSelectedProductSkuIds.value);
                // if the product does not have any attributes, then just pick the default sku id
                if (selectedSkuIds.length === 0) {
                  selectedSkuIds.push(base.default_sku_id);
                }
                const currentEditingProject = findProject.value(selectedSkuIds[0]);
                // for super sku projects we need to set the current project from the existing non-empty one if current project is empty (because of its special editor design)
                if (currentEditingProject && !isEmptySuperSkuProject(currentEditingProject) && selectedSkuIds.length > 0) {
                  updateExistingBaseProject(selectedSkuIds, currentEditingProject);
                } else {
                  // first try to find a project that matches the current product sku id (combination of attributes)
                  let foundProject = findMatchingProjectBySkuId(projects, selectedSkuIds);
                  // if not found now go with the default one or the one from base projects...
                  if (!foundProject) {
                    foundProject = projects.find(
                      (project: IProjectInfo) => {
                        return project.product_sku_id === base.default_sku_id;
                      }
                    );
                    if (!foundProject) {
                      const baseProjects = findBaseProjects(base.id);
                      if (baseProjects) {
                        foundProject = findMatchingProjectBySkuId(baseProjects.projects, selectedSkuIds);
                      }
                    }
                  }
                  if (foundProject) {
                    setNewProject(selectedSkuIds, foundProject);
                  } else {
                    console.error('No project found for the current product sku id');
                  }
                  // set original editor projects with the initial projects that are set to the editor
                  setOriginalEditorProjects(initializationPhase);
                }
              }
            });
          }
          editorCanvasStore.setRefreshCanvas(initializationPhase || lastSelectedProductAttribute.value.requiresCanvasReRender);
        }
      }
    }, err=> {
      console.error('NextTick failed', err);
    });

  }

  function isEmptySuperSkuProject(project: IProjectInfo): boolean {
    return isSuperSkuProduct() && Helpers.isEmptyProject(project);
  }

  function initializeProjectsFromShopProduct(): void {
    const sessionStore = useSessionStore();
    const currentShopProduct = sessionStore.currentShopProduct;
    const shopProductProjectStore = useShopProductProjectStore();
    if (currentShopProduct) {
      currentShopProduct.variants.forEach((variant) => {
        const shopProductProject = shopProductProjectStore.getProjectByGuid(variant.project_guid);
        const skuId = getSkuIdFromVariant(variant, currentShopProduct.guid);
        const projectsInfo = editorProjects.value.get(skuId);
        if (!projectsInfo && shopProductProject) {
          const foundProject = Array.from(editorProjects.value.values()).find((project: IProjectInfo) => {
            return project.guid === shopProductProject.guid;
          });
          // if project has been added to editor projects map before, use the same project to keep the same reference for later updates to the project
          if (foundProject) {
            setEditorProjectsMap(skuId, foundProject);
          } else {
            setEditorProjectsMap(skuId, structuredClone(deepToRaw<IProjectInfo>(shopProductProject)));
          }
        }
      });
    }
  }

  function updateExistingBaseProject(skuIds: number[], project: IProjectInfo): void {
    if (uiStateStore.specificDesignSwitch) {
      setProjectWithSpecificDesign(project);
    } else {
      const defaultDesignSkus = getSkuIdListForDefaultDesigns();
      skuIds.forEach((skuId: number) => {
        if (hasSpecificDesign.value(skuId)) {
          if (defaultDesignSkus.length > 0) {
            const nonSpecificDesignProject = findProject.value(defaultDesignSkus[0]);
            if (nonSpecificDesignProject) {
              setEditorProjectsMap(skuId, nonSpecificDesignProject);
            }
          } else {
            // if there is no default design project, then just turn off the specific design switch on the current project and set the project as default design
            const currentProject = findProject.value(skuId);
            if (currentProject) {
              resetProjectClient(currentProject);
              setEditorProjectsMap(skuId, currentProject);
            }
          }
        } else {
          setEditorProjectsMap(skuId, project);
        }
      });
    }
  }

  function resetProjectClient(project: IProjectInfo) {
    const sessionStore = useSessionStore();
    project.client = sessionStore.isInEditingPublishedShopProductMode ? TENANT_SERVICE : EMPTY_SHOP_PRODUCT;
  }

  function setNewProject(skuIds: number[], project: IProjectInfo): void {
    if (!uiStateStore.specificDesignSwitch) {
      const specificDesignSkuIds = getSkuIdListForSpecificDesigns();
      if (specificDesignSkuIds.length > 0) {
        searchAndSetSpecificDesignProjects(skuIds, project);
      } else {
        setNewProjectWithDefaultDesign(skuIds, project);
      }
    } else {
      setProjectWithSpecificDesign(project);
    }
  }

  function searchAndSetSpecificDesignProjects(skuIds: number[], project: IProjectInfo): void {
    // get attribute values of specific design sku ids
    // if a sku id in skuIds share the same attribute value with the specific design sku ids then that sku id needs to be specific design as well
    const specificDesignSkuIds = getSkuIdListForSpecificDesigns();
    if (specificDesignSkuIds.length > 0) {
      const sessionStore = useSessionStore();
      const currentProduct = sessionStore.currentBase;
      const specificDesignProjectOptionNameValues = specificDesignSkuIds.map((specificDesignSkuId: number) => {
        const specificDesignProject = editorProjects.value.get(specificDesignSkuId);
        const foundSkuData = currentProduct?.sku_data.find((skuData: ISkuData) => {
          return skuData.product_sku_id === specificDesignSkuId;
        });
        if (foundSkuData) {
          const optionName = Object.keys(foundSkuData.options).find((key: string) => {
            return SPECIFIC_DESIGN_ATTRIBUTES.includes(key.toLowerCase());
          });
          if (optionName) {
            const optionValue = foundSkuData.options[optionName as keyof ISkuData];
            return {
              optionName: optionName,
              optionValue: optionValue,
              project: specificDesignProject,
              skuId: specificDesignSkuId
            };
          }
        }
        return { optionName: '', optionValue: '', project: specificDesignProject, skuId: 0 };
      });

      const skuIdsOptionNameValues = skuIds.map((skuId: number) => {
        const foundSkuData = currentProduct?.sku_data.find((skuData: ISkuData) => {
          return skuData.product_sku_id === skuId;
        });
        if (foundSkuData) {
          return {options: foundSkuData.options, skuId: skuId};
        } else {
          return { options: {}, skuId: 0 };
        }
      });
      skuIdsOptionNameValues.forEach((skuIdOptionNameValue) => {
        const optionNames = Object.keys(skuIdOptionNameValue.options);
        const optionValues = Object.values(skuIdOptionNameValue.options);
        const foundSpecificDesignOptionNameValue = specificDesignProjectOptionNameValues.find((specificDesignOptionNameValue) => {
          return specificDesignOptionNameValue.skuId && optionNames.includes(specificDesignOptionNameValue.optionName) && optionValues.includes(specificDesignOptionNameValue.optionValue);
        });
        if (foundSpecificDesignOptionNameValue) {
          const specificDesignProject = foundSpecificDesignOptionNameValue.project;
          if (specificDesignProject) {
            setEditorProjectsMap(skuIdOptionNameValue.skuId, specificDesignProject);
          }
        } else if (skuIdOptionNameValue.skuId) {
          setNewProjectWithDefaultDesign([skuIdOptionNameValue.skuId], project);
        }
      });
    }
  }

  function setProjectWithSpecificDesign(project: IProjectInfo): void {
    const sessionStore = useSessionStore();
    if (sessionStore.currentActiveProductSkuIdList.length > 0) {
      const newProject = structuredClone(deepToRaw<IProjectInfo>(project));
      // set the project as specific design and assign a new guid to make it a separate project from a default one
      if (newProject.client !== SPECIFIC_DESIGN_PROJECT) {
        newProject.client = SPECIFIC_DESIGN_PROJECT;
        newProject.guid = uuid();
      }
      newProject.product_sku_id = sessionStore.currentActiveProductSkuIdList[0];
      sessionStore.currentActiveProductSkuIdList.forEach((skuId: number) => {
        setEditorProjectsMap(skuId, newProject);
      });
    }
  }

  function setNewProjectWithDefaultDesign(skuIds: number[], newProject: IProjectInfo): void {
    const defaultDesignSkus = sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length > 0 ? skuIds : getSkuIdListForDefaultDesigns();
    if (defaultDesignSkus.length > 0) {
      const editorProject: IProjectInfo | undefined = editorProjects.value.get(defaultDesignSkus[0]);
      // for super sku projects we need to set the current project from the existing non-empty one if current project is empty (because of its special editor design)
      if (editorProject && !isEmptySuperSkuProject(editorProject)) {
        skuIds.forEach((skuId: number) => {
          setEditorProjectsMap(skuId, editorProject as IProjectInfo);
        });
      } else {
        const clonedProject = structuredClone(deepToRaw<IProjectInfo>(newProject));
        if (isSuperSkuProduct() && editorProjects.value.size > 0) {
          const projectToReplicate = pickNonEmptySuperSkuProjectToReplicate(newProject);
          if (projectToReplicate) {
            createProjectFromExistingOne(clonedProject, projectToReplicate);
          }
        }
        setEditorProjectsMap(skuIds[0], clonedProject);
      }
    } else {
      const clonedProject = structuredClone(deepToRaw<IProjectInfo>(newProject));
      skuIds.forEach((skuId: number) => {
        setEditorProjectsMap(skuId, clonedProject);
      });
    }
  }

  function isSuperSkuProduct(): boolean {
    const sessionStore = useSessionStore();
    const currentShopProduct = sessionStore.currentShopProduct;
    return (!sessionStore.currentCatalogItem && isSuperSkuShopProduct(currentShopProduct)) || isSuperSku(sessionStore.currentCatalogItem);
  }

  function pickNonEmptySuperSkuProjectToReplicate(newProject: IProjectInfo): IProjectInfo|undefined {
    const editorProjectList = Array.from(editorProjects.value.values());
    const nonEmptyProjects = editorProjectList.filter((project: IProjectInfo) => {
      return !isEmptySuperSkuProject(project);
    });
    const sameSizeProject = nonEmptyProjects.find((project: IProjectInfo) => {
      return project.pages[0].edits.width === newProject.pages[0].edits.width && project.pages[0].edits.height === newProject.pages[0].edits.height;
    });
    return sameSizeProject ? sameSizeProject : nonEmptyProjects[0];
  }

  // This works only for copying edits when we are in editor with super sku canvases
  function fillSuperSkuEmptyProjects(skuIds: number[]): void {
    const sessionStore = useSessionStore();
    const skusWithNoProject = skuIds.filter((skuId: number) => {
      return findProject.value(skuId) === undefined;
    });
    const projectToReplicate = editorProjects.value.entries().next().value[1] as IProjectInfo;
    skusWithNoProject.forEach((skuId: number) => {
      const matchedProducts = sessionStore.currentSuperSkuProductInfos.filter((productInfo: IProductInfo) => {
        return productInfo.sku_data.map((skuData: ISkuData) => skuData.product_sku_id).some((mappedSkuId: number) => mappedSkuId === skuId);
      });
      matchedProducts.forEach((productInfo: IProductInfo) => {
        let projectsInfo: IProjectInfo[] = [];
        if (sessionStore.currentShopProduct && !sessionStore.currentCatalogItem) {
          const shopProductGuid = sessionStore.currentShopProduct.guid;
          const shopProductProjectStore = useShopProductProjectStore();
          projectsInfo = shopProductProjectStore.shopProductsProjectsMap.get(shopProductGuid) || [];
        }
        const baseProjects = findBaseProjects(productInfo.id);
        if (baseProjects) {
          projectsInfo.push(...baseProjects.projects);
        }
        let emptyProject = projectsInfo.find((project: IProjectInfo) => {
          return project.product_sku_id !== undefined && skuId === project.product_sku_id;
        });
        // if no project found for the current sku id, then pick the default project
        // for example different paper types won't have separate projects, so matte-portrait project is the same as glossy-portrait project
        if (!emptyProject) {
          emptyProject = projectsInfo.find(
            (project: IProjectInfo) => {
              return project.product_sku_id === productInfo.default_sku_id;
            }
          );
        }
        if (emptyProject && emptyProject.product_sku_id !== undefined && editorProjects.value.size > 0) {
          const rawEmptyProject = structuredClone(deepToRaw<IProjectInfo>(emptyProject));
          createProjectFromExistingOne(rawEmptyProject, projectToReplicate);
          setEditorProjectsMap(skuId, rawEmptyProject);
        }
      });
    });
    // same aspect ratio canvases are updated only after the user is switched to a new product. If user changes edits in one project and hits on continue button, we need to set editor projects map
    // for the same aspect ratio canvases
    if (isSuperSkuProduct()) {
      updateSameAspectRatioProjectsFromCurrentSelected();
    }
  }

  function updateSameAspectRatioProjectsFromCurrentSelected(): void {
    const sessionStore = useSessionStore();
    const currentBase = sessionStore.currentBase;
    if (currentBase) {
      const currentSelectedSkuIds = extractBaseSkuIds(currentBase, lastSelectedProductSkuIds.value);
      const projectToUpdateFrom = findProject.value(currentSelectedSkuIds[0]);
      sameAspectRatioSuperSkuProductsFromCurrentSelected.value.forEach(
        (productInfo: IProductInfo) => {
          productInfo.sku_data.forEach((skuData: ISkuData) => {
            const projectToUpdate = findProject.value(skuData.product_sku_id);
            if (projectToUpdate && projectToUpdateFrom && productInfo.id !== currentBase.id) {
              updateSameAspectRatioProjects(projectToUpdateFrom, projectToUpdate);
            }
          });
        }
      );
    }
  }

  function setEditorProjectsMap(skuId: number, project: IProjectInfo): void {
    if (!project.guid) {
      project.guid = uuid();
    }
    editorProjects.value.set(skuId, project);
  }

  function createProjectFromExistingOne(emptyProject: IProjectInfo, projectToReplicate: IProjectInfo): void {
    if (editorProjects.value.size > 0) {
      const existingEdits = structuredClone(deepToRaw<IPageEdits>(projectToReplicate.pages[0].edits));
      if (editorCanvasStore.fabricCanvas) {
        Helpers.convertToSameAspectRatioProject(
          existingEdits,
          emptyProject.pages[0].edits,
        );
      }
    }
  }

  function updateSameAspectRatioProjects(previouslySelectedEditorProject: IProjectInfo, newProject: IProjectInfo): void {
    if (
      previouslySelectedEditorProject &&
      newProject.product_base_id !== previouslySelectedEditorProject.product_base_id
    ) {
      // if the new selected product is still in same aspect group as previously selected product (but it's not the same product that is already selected)
      // then set the free objects on the new selected product with the previous selected product multiplied by the scale
      const previouslySelectedProductInSameAspectGroup =
        sameAspectRatioSuperSkuProductsFromCurrentSelected.value.find(
          (product: IProductInfo) => {
            return product.id === previouslySelectedEditorProject.product_base_id;
          }
        );

      const newSelectedProductInSameAspectGroup =
        sameAspectRatioSuperSkuProductsFromCurrentSelected.value.find(
          (product: IProductInfo) => {
            return newProject && product.id === newProject.product_base_id;
          }
        );

      if (
        previouslySelectedProductInSameAspectGroup &&
        newSelectedProductInSameAspectGroup &&
        editorCanvasStore.fabricCanvas &&
        sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length > 0
      ) {
        if (
          newSelectedProductInSameAspectGroup.dynamic_data.optimal_x !==
          undefined &&
          previouslySelectedProductInSameAspectGroup.dynamic_data.optimal_x !==
          undefined
        ) {
          // do not change the original project data
          const currentEdits = structuredClone(
            deepToRaw<IPageEdits>(previouslySelectedEditorProject.pages[0].edits)
          );
          const newEdits = newProject.pages[0].edits;
          Helpers.convertToSameAspectRatioProject(
            currentEdits,
            newEdits
          );
        }
      }
    }
  }

  function getSkuIdListForDefaultDesigns(): number[] {
    const sessionStore = useSessionStore();
    return sessionStore.activeProductSkuIdList.filter((skuId: number) => {
      return editorProjects.value.get(skuId) && !hasSpecificDesign.value(skuId);
    });
  }

  function getSkuIdListForSpecificDesigns(): number[] {
    const sessionStore = useSessionStore();
    return sessionStore.activeProductSkuIdList.filter((skuId: number) => {
      return editorProjects.value.get(skuId) && hasSpecificDesign.value(skuId);
    });
  }

  function findBaseProjects(baseId?: number): IBaseProjectsList | undefined {
    if (baseProjectStore.baseProjectsList) {
      const sessionStore = useSessionStore();
      return baseProjectStore.baseProjectsList.find(
        (baseProject: IBaseProjectsList) => {
          return baseId !== undefined ? baseProject.base_id === baseId : baseProject.base_id === sessionStore.currentBase?.id;
        }
      );
    }
    return undefined;
  }

  function findMatchingProjectBySkuId(projects: IProjectInfo[], skuIds: number[]): IProjectInfo | undefined {
    const sessionStore = useSessionStore();
    return projects.find(
      (project: IProjectInfo) => {
        if (project.product_sku_id !== undefined) {
          if (isSuperSkuProduct()) {
            return skuIds.includes(project.product_sku_id);
          } else {
            return sessionStore.activeProductSkuIdList.includes(project.product_sku_id);
          }
        }
        return false;
      }
    );
  }


  function doesBaseProjectForAProductExist(product: IProductInfo): IBaseProjectsList | undefined {
    if (baseProjectStore.baseProjectsList) {
      return baseProjectStore.baseProjectsList.find(
        (baseProject: IBaseProjectsList) => {
          return baseProject.base_id === product.id;
        }
      );
    }
    return undefined;
  }

  function queryAndSetBaseProject(isSettingEditorProjects = true): void {
    const sessionStore = useSessionStore();
    if (sessionStore.currentBase) {
      // query the current active group of products
      if (
        sessionStore.currentSuperSkuProductInfos.length > 0
      ) {
        sessionStore.currentSuperSkuProductInfos.forEach(
          (currentActiveSuperSkuProductInfo: IProductInfo) => {
            const foundProject = doesBaseProjectForAProductExist(
              currentActiveSuperSkuProductInfo
            );
            if (!foundProject) {
              baseProjectStore.queryBaseProjects(currentActiveSuperSkuProductInfo);
            }
          }
        );

        if (isSettingEditorProjects) {
          nextTick().then(() => {
            setEditorProject();
          }, err => {
            console.error('NextTick failed', err);
          });
        }
      } else {
        const foundBaseProjects = findBaseProjects();
        if (!foundBaseProjects) {
          baseProjectStore.queryBaseProjects(sessionStore.currentBase);
        } else if (isSettingEditorProjects) {
          nextTick().then(() => {
            setEditorProject();
          }, err=> {
            console.error('NextTick failed', err);
          });
        }
      }
    }
  }

  async function queryAndSetShopProductProject(): Promise<void> {
      const sessionStore = useSessionStore();
      // currentActiveProductSkuIdList is empty when user goes to editor from a shop product for the first time
      const hasProjectsToLoad = sessionStore.currentActiveProductSkuIdList.length === 0 ? true : sessionStore.currentActiveProductSkuIdList.some((skuId: number) => {
        return !editorProjects.value.get(skuId);
      });
      if (sessionStore.currentBase && !sessionStore.currentCatalogItem && sessionStore.currentShopProduct && hasProjectsToLoad) {
        const shopProductProjectStore = useShopProductProjectStore();
        if (
          sessionStore.currentSuperSkuProductInfos.length > 0
        ) {
          for (const currentActiveSuperSkuProductInfo of sessionStore.currentSuperSkuProductInfos) {
            await shopProductProjectStore.triggerShopProductProjectsFromBase(currentActiveSuperSkuProductInfo.id, sessionStore.currentShopProduct as IShopProduct);
          }

        } else {
          await shopProductProjectStore.triggerShopProductProjectsFromBase(sessionStore.currentBase.id, sessionStore.currentShopProduct);
        }
      } else {
        return Promise.resolve();
      }

  }

  function clearEditorProductStoreValues(): void {
    lastSelectedProductSkuIds.value = [];
    unSelectedProductSkuIds.value = [];
    unSelectedSuperSkuProductInfoIds.value = [];
    cachedItePreviews.value.clear();
    currentSelectedItePreview.value = '';
    lastSelectedProductAttribute.value = { attributeName: '', attributeValue: '', requiresCanvasReRender: false };
    sameAspectRatioSuperSkuProductsFromCurrentSelected.value.splice(0, sameAspectRatioSuperSkuProductsFromCurrentSelected.value.length);
    uiStateStore.setSpecificDesignSwitch(false);
    editorProjects.value.clear();
  }

  return {
    lastSelectedProductSkuIds,
    unSelectedProductSkuIds,
    sameAspectRatioSuperSkuProductsFromCurrentSelected,
    lastSelectedProductAttribute,
    unSelectedSuperSkuProductInfoIds,
    queryAndSetBaseProject,
    queryAndSetShopProductProject,
    setEditorProject,
    clearEditorProductStoreValues,
    findProject,
    hasSpecificDesign,
    isProjectReady,
    currentSelectedItePreview,
    cachedItePreviews,
    updateSameAspectRatioProjects,
    addToLastSelectedProductSkuIds,
    resetLastSelectedProductSkuIds,
    setLastSelectedProductAttribute,
    setLastSelectedProductSkuIds,
    setUnselectedProductSkuIds,
    excludeSuperSkuProductInfoId,
    removeFromEditorProjects,
    resetUnselectedSuperSkuProductInfoIds,
    fillSuperSkuEmptyProjects,
    pageOrientations,
    updateProjectsPagePreviews,
    setCurrentSelectedItePreview,
    setCachedItePreviews,
    setOriginalEditorProjects,
    getRawCurrentEditorProjectList
  };
});
